<template>
  <div>
    <div class="start-loading" v-if='loading && yuan'>
      <van-loading size='50' />
    </div>
    <div class="sport-loader" v-else-if="loading">
      <!-- <div class="yuan">
        <img src="../../../assets/footBall.svg"/>
      </div>
      <div class="yuan"><img src="../../../assets/blackIcon.svg"/></div> -->
      <div class="yuan"><img src="../../../assets/home/home-logo.webp"/></div>
      <!-- <div class="yuan"><img src="../../../assets/zuqiu.svg"/></div> -->
    </div>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  name: 'van-skeleton',
  props: ['loading', 'yuan'],
}
</script>

<style scoped>
	.start-loading {
    height: 200px;
		display: flex;
    align-items: center;
		justify-content: center;
		/* background: rgba(0, 0, 0, 0.5); */
    background-color: #193146;
    margin: 11px;
    border-radius: 8px;
		z-index: 9999;
	}
  @keyframes zhuan {
    0% {
      transform: rotate(0);
      opacity: .5;
    }
    50% {
      transform: rotate(180deg);
      opacity: 1;
    }
    100% {
      transform: rotate(360deg);
      opacity: .5;
    }
  }
  .sport-loader {
    padding-top: 200px;
    .yuan {
      display: flex;
      justify-content: center;
      /* animation: zhuan 2s linear infinite; */
    }
    img {
      width: 88px;
      height: 88px;
    }
  }
</style>
