import { render, staticRenderFns } from "./sports.vue?vue&type=template&id=2658ea14&scoped=true"
import script from "./sports.vue?vue&type=script&lang=js"
export * from "./sports.vue?vue&type=script&lang=js"
import style0 from "./sports.vue?vue&type=style&index=0&id=2658ea14&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2658ea14",
  null
  
)

export default component.exports