<template>
	<div class='details sport-details' :style="$store.getters['sport/$theme']">
		<div class='header'>
			<div class='fixed'>
				<div class='back' @click="back">
					<van-icon name="arrow-left" color="#fff" size="20" />
				</div>
				<div class='title'>
					赛事搜索
				</div>
				<div class='right' @click='rightShow'>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M15.9593 4H18.125C18.6083 4 19 4.41141 19 4.91892V20.0811C19 20.5886 18.6083 21 18.125 21H5.875C5.39175 21 5 20.5886 5 20.0811V4.91892C5 4.41141 5.39175 4 5.875 4H8.5H8.9375V5.37838H15.0625V4H15.9593Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"/>
					<path d="M15 3H9V5H15V3Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M8 9H16" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
					<path d="M8 13H16" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
					<path d="M8 17H16" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
					</svg>

				</div>
			</div>
		</div>

		<div class="search-box" v-if="!isSearch">
			<div class="search">
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.74999 15.8334C12.662 15.8334 15.8333 12.6621 15.8333 8.75008C15.8333 4.83808 12.662 1.66675 8.74999 1.66675C4.83799 1.66675 1.66666 4.83808 1.66666 8.75008C1.66666 12.6621 4.83799 15.8334 8.74999 15.8334Z" stroke="#B3B9D2" stroke-width="1.3" stroke-linejoin="round"/>
				<path d="M11.1071 5.97629C10.5039 5.37308 9.67054 5 8.75004 5C7.82958 5 6.99624 5.37308 6.39304 5.97629" stroke="#B3B9D2" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M13.8423 13.8423L17.3779 17.3778" stroke="#B3B9D2" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<input type="text" v-model="value" placeholder="请输入" @blur="handleSearch">
				<van-icon v-if="value" name="cross" color='#fff' @click="value=''" />
			</div>
		</div>

		<div class='history-box box' v-if="!isSearch">
			<div class="top">
				<span>搜索历史</span>
				<span @click='handleDelete'>
					<i class="iconfont icon-delete"></i>
				</span>
			</div>
			<div class="list" v-if='historyList.length'>
				<span v-for="(item,i) in historyList" :key="i" @click="handleHistoryItem(item)">{{ item }}</span>
			</div>
			<div v-else>
				<van-empty
					class="custom-image"
					:image="emptyimg"
					description="暂无数据"
				/>
			</div>
		</div>

		<van-skeleton :row="10" :loading="loading" v-if="isSearch">
			
			<div class="search-res">
				<p class="res">共找到{{ len }}个结果</p>
				<div class="box" v-for="(attr,a) in list" :key='a'>
					<div class="block">
						<div class="title">{{ attr.title }}</div>
					</div>
					<ul>
						<li v-for="(item,i) in attr.list" :key="i" @click="showDetail(item)">
							<div class="name">
								<img :src="item.lg.lurl" />
								<span>{{ item.lg.na }}</span>
							</div>
							<div class="li">
								<div class="teams left">
									{{ item.ts[0].na }}
									<img :src="item.ts[0].lurl" style="margin-left: 2px;">
								</div>
								<div class="center">
									<div class="bifen" v-if="item.nsg && item.nsg[0] && item.ms!=8">
										{{ getNsgType(item.nsg, 5, 0) }}
										-
										{{ getNsgType(item.nsg, 5, 1) }}
									</div>
									<div v-else>-</div>
									<div class="info" v-if="item.ms!=8">
										<span class='bg' v-if="item.ms==5">live</span>
										<span>{{ sub_match_type[item.smt] }}</span>
										<span v-if="item?.mc.s">
											{{ secondsToHMS(item.mc.s) }}
										</span>
										<span v-if='item?.mc?.itd' class="color">+	{{ item.mc.itd }}</span>
									</div>
									<div class="info" v-else>
										暂停
									</div>
								</div>
								<div class="teams">
									<img :src="item.ts[1].lurl" style="margin-right: 2px;">
									{{ item.ts[1].na }}
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			
		</van-skeleton>


	</div>
</template>

<script>
import { Dialog } from 'vant'
import {
	queryMatchByRecommend
} from '@/request/sportApi.js'
import {
	sport
} from '../utils/config'
import mixin from '../utils/mixin'
export default {
	name: 'search-box',
	mixins: [mixin],
	data() {
		return {
			searchTimer1: null,
			searchTimer2: null,
			value: '',
			historyList: [],
			loading: false,
			isSearch: false,
			len: 0,
			list: [],
			sub_match_type: {
				"0": "常规赛",
				"1": "加时赛",
				"2": "点球赛",
				"3": "电子足球常规赛",
				"4": "电子足球加时赛",
				"5": "电子足球点球赛",
				"6": "五人足球常规赛",
				"7": "五人足球加时赛",
				"8": "五人足球点球",
			}
		}
	},


	created() {
		this.historyList = JSON.parse(localStorage.getItem('search_history')) || []
	},

	destroyed() {
		clearInterval(this.searchTimer1)
		clearInterval(this.searchTimer2)
		this.searchTimer1 = null
		this.searchTimer2 = null
	},


	methods: {


		showDetail(item) {
			this.$emit('showDetails', item)
		},

		handleSearch() {
			if(!this.value) return
			if (!this.historyList.find(item => item == this.value.trim())) {
				this.historyList.push(this.value.trim())
				localStorage.setItem('search_history', JSON.stringify(this.historyList))
			}
			this.loading = true
			this.getData()
			this.searchTimer2 = setInterval(this.getData, 5000)
		},

		handleHistoryItem(item) {
			this.value = item
			this.getData()
			this.searchTimer2 = setInterval(this.getData, 5000)
		},

		handleDelete() {
			Dialog.confirm({
				title: '温馨提示',
				message: '确定要清空历史记录吗',
			})
			.then(() => {
				this.historyList = []
				localStorage.setItem('search_history', '[]')
			})
			.catch(() => {
				console.log(123);
			})
		},

		// 获取数据
		getData() {
			this.isSearch = true
			// this.loading = true
			clearInterval(this.searchTimer1)
			this.searchTimer1 = null
			queryMatchByRecommend({
				languageType: "CMN",
				oddsType: 1,
				recommend: this.value
			})
				.then(res => {
					this.len = res.data.length
					this.data = res.data
					this.list = this.setUniteGame(res.data)
					this.searchTimer1 = setInterval(this.calcTIme, 1000)
					// console.log(this.data);
					this.loading = false
				})
		},


		back() {
			if (this.isSearch) {
				this.isSearch = false
				this.loading = false
				this.list = []
				this.value = ''
				clearInterval(this.searchTimer1)
				clearInterval(this.searchTimer2)
				this.searchTimer1 = null
				this.searchTimer2 = null
			} else {
				this.$emit('close')
			}
		},
		rightShow() {
			this.$emit('show')
		},

		// 计算小时、分钟和秒数
		formatTimestamp(timestamp) {
			const date = new Date(timestamp);

			// 获取月、日、小时、分钟、秒
			const year = date.getFullYear();
			const month = date.getMonth() + 1; // 月份是从0开始的，所以要加1
			const day = date.getDate();
			const hours = date.getHours();
			const minutes = date.getMinutes();
			const seconds = date.getSeconds();

			// 格式化成两位数
			const formattedYear = year;
			const formattedMonth = month < 10 ? `0${month}` : month;
			const formattedDay = day < 10 ? `0${day}` : day;
			const formattedHours = hours < 10 ? `0${hours}` : hours;
			const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
			const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

			// 返回格式化后的字符串
			return `${formattedYear}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		},

		// 把统一联赛的归到一起
		setUniteGame(resList) {
			let obj = {}
			resList.forEach((item) => {
				if (obj[item.lg.sid]) {
					obj[item.lg.sid].push(item)
				} else {
					obj[item.lg.sid] = [item]
				}
			})
			let arr = []
			Object.keys(obj).forEach(item => {
				arr.push({
					name: item,
					title: sport.find(attr => attr.CODE == item).DESC_CN,
					lurl: obj[item][0].lg?.lurl,
					list: obj[item]
				})
			})
			return arr
		},

		// 计算小时、分钟和秒数
		secondsToHMS111(seconds) {
			let hours = Math.floor(seconds / 3600);
			let minutes = Math.floor((seconds % 3600) / 60);
			let remainingSeconds = seconds % 60;

			let formattedTime
			if (hours <= 0) {
				formattedTime = (minutes < 10 ? "0" : "") + minutes + ":" +
					(remainingSeconds < 10 ? "0" : "") + remainingSeconds;
			} else {
				formattedTime =
					(hours < 10 ? "0" : "") + hours + ":" +
					(minutes < 10 ? "0" : "") + minutes + ":" +
					(remainingSeconds < 10 ? "0" : "") + remainingSeconds;
			}
			return formattedTime;
		},

		secondsToHMS(seconds) {
			const minutes = Math.floor(seconds / 60);
			const remainingSeconds = seconds % 60;

			// 确保秒数是两位数
			const formattedMinutes = minutes.toString().padStart(2, '0');
			const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
			return `${formattedMinutes}:${formattedSeconds}`;
		},


		calcTIme() {
			this.list.forEach(attr => {
				attr.list.forEach(item => {

					if (item.mc?.r) {
						if (item.mc.tp == 1) {
							item.mc.s++
						} else {
							if (item.mc.s >= 1) {
								item.mc.s--
							}
						}
					}
				})
			})
		},


	}
}
</script>

<style>
div, ul {
	&::-webkit-scrollbar {
		display: none;
	}
}
.sport-details div.van-dialog {
	background: #243B4F !important;
}
.sport-details .van-hairline--top {
	padding: 0 16px 16px;
}
.sport-details .van-button::after, .van-hairline--top::after {
	border: none;
}
.sport-details .van-button {
	border-radius: 10px;
	margin: 4px;
	height: 40px;
	background: none;
}
.sport-details .van-button__content {
	border-radius: 16px;
	background: #4774DA;
	color: #fff;
}
.sport-details .van-dialog__cancel .van-button__content {
	border-radius: 16px;
	border: 1px solid #4774DA;
	color: #4774DA;
	background: none;
}
.sport-details .van-dialog__header {
	background-color: #193146;
	color: #B3B9D2;
	padding-top: 0;
	line-height: 50px;
}
.sport-details .van-dialog__message {
	padding-top: 0;
	padding: 30px 0;
	color: #fff;
}
</style>

<style lang='less' scoped>
@import '@/style/global.less';

::v-deep div.van-dialog, .van-dialog__header {
	background: @243B4F !important;
}
.flex {
	display: flex;
	align-items: center;
}

.flex-c {
	display: flex;
	flex-direction: column;
}
.van-skeleton__row {
	background: @gujia-bg;
}
span,
div {
	box-sizing: border-box;
}
.details {
	font-size: 16px;
	background: @bg-color;
	min-height: 100vh;
	padding-bottom: 40px;
	position: relative;
	z-index: 9998;

	.header {
		padding: 14px 12px 0;
		background: #1e3142;
		// background-color: #362911;
		// background-image: url('@/assets/sport/top-bg.png');
		// background-size: 100%;
		// background-repeat: no-repeat;
		height: 50px;
		padding-top: 14px;
		background-position-y: -30px;
		position: sticky;
		top: 0;
		z-index: 2;

		.fixed {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;

			.title {
				color: #fff;

				img {
					width: 16px;
					height: 16px;
					margin-right: 4px;
				}

				i {
					margin-left: 4px;
					transition: all .3s
				}
			}

			.right {
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}


	}

	.search-box {
		// border-radius: 0 0 16px 16px;
		// background-image: url('@/assets/sport/top-bg.png');
		// background-size: 100%;
		// background-repeat: no-repeat;
		// background-color: #362911;
		// background-position-y: -88px;
		background: #1e3142;
		padding: 30px 20px 16px;
		.search {
			// border: 1px solid #ccc;
			border-radius: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #4babff0a;
			padding: 0 10px;
			height: 40px;
			color: @B3B9D2;
			input {
				background: none;
				flex: 1;
				color: #fff;
				font-size: 14px;
				padding: 0 6px;
				&::-webkit-input-placeholder {
					color: @B3B9D2;
				}
			}

		}
	}

	.history-box {
		color: #fff;
		padding: 10px;
		.top {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
		.list {
			display: flex;
			flex-wrap: wrap;
			margin-top: 16px;
			gap: 12px;
			span {
				border-radius: 20px;
				padding: 0 10px;
				background: #ffffff17;
				font-size: 13px;
				line-height: 24px;
				color: @B3B9D2;
			}
		}
	}

	.box {
		background: #4babff0a;
		border-radius: 8px;
		overflow: hidden;
		margin: 10px;
		font-size: 13px;
		.block {
			text-align: left;
			font-size: 15px;
			height: 38px;
			.title {
				display: flex;
				align-items: center;
				padding: 10px 0;
				background: @243B4F;
				color: #fff;
				&::before {
					content: "";
					display: inline-block;
					width: 4px;
					height: 20px;
					border-radius: 0 4px 4px 0;
					background: @theme-text;
					margin-right: 12px;
				}
			}
		}
		ul {
			background: #243b4f;
			li {
				border-bottom: 1px solid @bg-color;
				color: #fff;
				height: 120px;
				padding-top: 16px;
				background: #4babff0a;
				&:first-child {
					border-radius: 8px 8px 0 0;
				}
				.name {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 10px;
					color: @B3B9D2;
					font-size: 15px;
					img {
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background: #ccc;
						margin-right: 6px;
					}
				}
				.li {
					display: flex;
					justify-content: space-between;
					
					.teams {
						width: 30%;
						display: flex;
						align-items: center;
						font-size: 15px;
						img {
							width: 20px;
							height: 20px;
							border-radius: 50%;
							background: #ccc;
						}
					}
					.left {
						justify-content: end;
					}
					.center {
						width: 40%;
						margin-top: 6px;
						.bifen {
							font-size: 24px;
							line-height: 32px;
							font-weight: 700;
							height: 32px;
						}
						.info {
							margin-top: 8px;
							font-size: 12px;
							color: @B3B9D2;
							.bg {
								background: #c80815;
								color: #fff;
								font-size: 10px;
								border-radius: 2px;
								padding: 0 2px;
								margin-right: 4px;
							}
							.color {
								color: #FF3B30;
							}
						}
					}
				}
			}
		}
	}

	.search-res {
		font-size: 14px;
		.res {
			font-size: 16px;
			text-align: left;
			color: #fff;
			padding: 10px 10px 0;
		}
	}

}
</style>