<template>
	<div class="config" :style="$store.getters['sport/$theme']">
		<div class='userinfo flex'>
			<div class="touxiang mr10">
				<img src="@/assets/sport/left/touxiang.png" alt="">
			</div>
			<div class="bg1 space-c-b pl10 pr10" @click="handleRefresh">
				<span class="money flex-c">
					<img src="@/assets/sport/guoqi.png" alt="">
					<!-- {{ Math.ceil(userInfo.balance) || '0.00' }} -->
					<span class="bold mr4" v-html="numToStr(Math.floor(userInfo.balance), {opacity: 0.6, 'font-size': '11px'})"></span>
				</span>
				<span class="flex-c">
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
						class='refresh' :style="{animationPlayState: isRefresh ? 'running' : 'paused'}">
						<g clip-path="url(#clip0_3095_448)">
							<path
								d="M12.2016 14.1C12.05 14.1 11.9 14.0312 11.8016 13.9C11.6359 13.6797 11.6797 13.3656 11.9016 13.2C13.5531 11.9594 14.5 10.0641 14.5 8C14.5 4.41563 11.5844 1.5 8 1.5C7.72344 1.5 7.5 1.27656 7.5 1C7.5 0.723437 7.72344 0.5 8 0.5C12.1359 0.5 15.5 3.86406 15.5 8C15.5 10.3812 14.4062 12.5688 12.5 14C12.4109 14.0672 12.3047 14.1 12.2016 14.1ZM8 15.5C3.86406 15.5 0.5 12.1359 0.5 8C0.5 5.96875 1.29844 4.06719 2.75 2.64375C2.94688 2.45 3.26406 2.45313 3.45781 2.65C3.65156 2.84688 3.64844 3.16406 3.45156 3.35781C2.19219 4.59062 1.5 6.23906 1.5 8C1.5 11.5844 4.41563 14.5 8 14.5C8.27656 14.5 8.5 14.7234 8.5 15C8.5 15.2766 8.27656 15.5 8 15.5Z"
								fill="#fff" />
							<path
								d="M9.4 3.6C9.23906 3.6 9.07969 3.52188 8.98281 3.37656L7.58437 1.27813C7.43125 1.04844 7.49375 0.7375 7.72344 0.584375C7.95312 0.43125 8.26406 0.49375 8.41719 0.723437L9.81719 2.82344C9.97031 3.05313 9.90781 3.36406 9.67812 3.51719C9.59219 3.57344 9.49531 3.6 9.4 3.6ZM8 15.5C7.83906 15.5 7.67969 15.4219 7.58281 15.2766L6.18281 13.1766C6.02969 12.9469 6.09219 12.6359 6.32187 12.4828C6.55156 12.3297 6.86094 12.3922 7.01562 12.6219L8.41562 14.7219C8.56875 14.9516 8.50625 15.2625 8.27656 15.4156C8.19219 15.4734 8.09531 15.5 8 15.5Z"
								fill="#fff" />
						</g>
						<defs>
							<clipPath id="clip0_3095_448">
								<rect width="16" height="16" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</span>
			</div>
		</div>
		<div class='aboutmoney bg2'>
			<div class="chongzhi" @click='$router.push("/deposit")'>
				<img src="@/assets/sport/left/chongzhi.png" alt="">
				充值
			</div>
			<div class="tixian" @click='$router.push("/withdrawals")'>
				<img src="@/assets/sport/left/tixian.png" alt="">
				提现
			</div>
		</div>
		<div class="bg2 space-c-b" @click="openRight">
			<div class="flex-c">
				<img src="@/assets/sport/left/touzhu.png" alt="">
				投注记录
			</div>
			<div>
				<van-icon name="arrow" />
			</div>
		</div>
		<div v-for="(item, i) in sportConfig" :key="i" :style="{display: item.show ? '' : 'none'}">
			<div class="bg2 space-c-b" v-if="item.type != 'url' && item.show">
				<div class="flex-c">
					<!-- {{item.icon}} -->
					<img :src="require(`../../../assets/sport/left/${item.icon}.png`)" alt="">
					{{ item.name }}
				</div>
				<div v-if="item.type != 'color'" class="box close" @click="handleShowDownMenu(i)" :class="{rotate: showDownMenu == i}">
					<span class='close'>{{ item.list[item.active] }}</span>
					<van-icon class='close' name="arrow-down" size="12" color="#B3B9D2" />
				</div>
				<div class="box color close" v-else @click="handleShowDownMenu(i)">
					<span style="margin-right: 0;">
						<van-icon class='close' vold :name="item.list[item.active][0].icon" :color="item.list[item.active][0].color" />
						<span class='close'>{{ item.list[item.active][0].name }}</span>
					</span>
					<span style="margin-right: 0;">
						<van-icon vold :name="item.list[item.active][1].icon" :color="item.list[item.active][1].color" />
						<span>{{ item.list[item.active][1].name }}</span>
					</span>
					<van-icon name="arrow-down" size="12" color="#B3B9D2" />
				</div>
				<ul v-if="item.type == 'color'" class='color' :class="{open: showDownMenu == i}">
					<li @click="changeConfig(i, e)" v-for="(el, e) in item.list" :key="e" :class='{active: item.active==e}'>
						<span>
							<van-icon vold :name="el[0].icon" :color="el[0].color" />
							<span>{{ el[0].name }}</span>
						</span>
						<span>
							<van-icon vold :name="el[1].icon" :color="el[1].color" />
							<span>{{ el[1].name }}</span>
						</span>
					</li>
				</ul>

				<ul v-else :class="{open: showDownMenu == i}">
					<li 
						v-for="(attr, m) in item.list" 
						:key="m" 
						:class="{active: m == item.active}"
						@click="changeConfig(i, m)"
					>{{ attr }}</li>
				</ul>
			</div>
			<div v-if='item.type == "url"'>
				<div class="bg2 space-c-b" v-for="(el, e) in item.list" :key="e" @click='jump(el)'>
					<div class="flex-c">
						<img :src="require(`../../../assets/sport/left/${el.icon}.png`)" alt="">
						{{ el.name }}
					</div>
					<div>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import mixin from '../utils/mixin'
// var n
export default {
	name: "sHeader",
	mixins: [mixin],
	data() {
		return {
			showDownMenu: -1,
			isRefresh: false,

		}
	},
	computed: {
		sportConfig() {
			return this.$store.state.sport.sportConfig
		},
	},
	created() {
		// this.$store.dispatch('sport/getUserInfo')
		if (localStorage.getItem('token')) {
			this.$store.dispatch('sport/getUserInfo')
		}
	},
	methods: {
		// 余额刷新
		handleRefresh() {
			this.isRefresh = !this.isRefresh
			this.$store.dispatch('sport/getUserInfo')
			.then(() => {
				this.isRefresh = !this.isRefresh
			})
		},

		changeConfig(index, active) {
			this.$store.commit('sport/setSportConfig', { index, active })
			// this.$emit('close')
			// if (index ==)
			if (index == 1) {
				this.$emit('clear')
			}
			
		},
		jump(attr) {
			this.$router.push(attr.url);
		},
		openRight() {
			// this.$router.push('/rightSlider')
			this.$emit('openRight')
		},
		handleShowDownMenu(i) {
			if (this.showDownMenu == i) {
				this.showDownMenu = -1
			} else {
				this.showDownMenu = i
			}
			var fn = (e) => {
				if (!e.target.className.includes('close')) {
					this.showDownMenu = -1
					document.removeEventListener('click', fn, true)
				}
			}
			document.addEventListener('click', fn, true)
			// localStorage.setItem('showDownMenu', i)
		}
	}
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';

.flex-c {
	display: flex;
	align-items: center;
}

span,
div {
	box-sizing: border-box;
}


div, ul {
	&::-webkit-scrollbar {
		display: none;
	}
}

.config {
	// padding: 65px 15px 12px;
	padding: 12px 15px 12px;
	width: 300px;
	font-size: 14px;
	color: #fff;
	&>div {
		margin-bottom: 5px;
	}
	.color {
		span i {
			margin-right:4px; 
		}
	}
	.bg1 {
		width: 100%;
		height: 38px;
		border-radius: 33px;
		background: #223c53;
	}
	.bg2 {
		position: relative;
		margin-bottom: 5px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 45px;
		border-radius: 12px;
		background: #223c53;
		padding: 0 20px;
		img {
			width: 18px;
			height: 18px;
			margin-right: 7px;
		}
		.box {
			height: 25px;
			border-radius: 20px;
			padding: 0 10px;
			display: flex;
			align-items: center;
			background: #ffffff0a;
			color: #B3B9D2;
			font-size: 12px;
			span {
				margin-right: 7px;
			}
			i {
				transition: all .2s;
			}
		}
		.rotate {
			i {
				transform: rotate(180deg);
			}
		}

		ul {
			position: absolute;
			top: 45px;
			right: 20px;
			padding: 0 5px;
			background: #1E2A35;
			border-radius: 10px;
			z-index: 999;
			max-height: 0;
			// transform: scaleY(0);
			overflow: hidden;
			transition: all .2s;
			// transform-origin: top;
			&.open {
				padding: 5px;
				max-height: 200px;
				// transform: scaleY(1);
			}

			li {
				height: 33px;
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #B3B9D2;
				padding: 0 25px;
				&.active {
					color: #fff;
					background: #4774DA;
					border-radius: 10px;
				}
			}
		}
	}
	.userinfo {
		.touxiang {
			width: 38px;
			min-width: 38px;
			height: 38px;
			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
		.money {
			img {
				margin-right: 7px;
				width: 18px;
				height: 18px;
			}
		}
		@keyframes rotate {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
		.refresh {
      animation: rotate 1s linear infinite;
      animation-play-state: paused;
    }
	}
	.aboutmoney {
		display: flex;
		border-radius: 20px;
		div {
			height: 100%;
			flex: 1 1 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 24px;
				height: 24px;
				margin-right: 5px;
			}
			&:first-child {
				border-right: 1px solid #ffffff08;
			}
		}
	}

}

</style>