<template>
    <div class="watOut" :class="{ 'heightOut': isApp() }">
        <div class="dqklq" v-show="tabsList.length>0">当前可领取<div>{{ total }}</div>
        </div>
        <div class="watCont">
            <div class="leftOut" v-show="tabsList.length>0">
                <div class="watLeft">

                    <div class="tabs" v-for="(item, i) in tabsList" :key="i" :class="{ active: tabs == i }"
                        @click="changeTabs(i)">
                        <!-- <img src="../../assets/pwd-eye.svg" alt="" /> -->
                        <img class="tabImg" :src="require(`@/assets/activity/water/tab${tabs == i? 'B':'H'}${i}.png`)" />
                        <span>{{ item.name }}</span>
                    </div>
                    
                </div>
                <van-button class="yjlqBtn" :loading="subLoading" @click="setSubmit()">一键领取</van-button>
                <div class="btnOut">
                    <van-button class="lqjlBtn" @click="goTab(3)">领取记录</van-button>
                </div>
                
            </div>

            <div class="watRight" v-if="tabsList.length>0">
                <div class="rightCont" v-for="(item,index) in tabsList[tabs].platform" :key="index" @click="$router.push({path:'/activityContent',query:{id:item.platformId,name:item.name}})">
                    <div class="rightLeft">
                        <div class="leftTop">
                            <div class="leftImg"><img :src="require(`@/assets/activity/water/${item.name}.png`)" v-if="item.name!='PD体育'" /><span v-else>Pd</span></div>
                            <div class="klq">可领取<div>{{ item.receiveAmount || 0 }}</div>
                            </div>
                        </div>
                        <div class="tiaoOut">
                            <div class="tiao" :style="{width: item.effectiveBet>=item.nextLevel? '100%' : (item.effectiveBet / item.nextLevel *100 +'%') }"></div>
                            <div class="tiaoTxt">{{ item.effectiveBet>=item.nextLevel?`当前返水${item.nextRatio}% `: `再投注${(item.nextLevel - item.effectiveBet).toFixed(2) }可领${item.nextRatio}%` }}</div>
                        </div>
                        <div class="leftBom">
                            <div class="dqfs jsL">当前返水比例<div>{{item.ratio || 0}}%</div>
                            </div>
                            <div class="dqfs jsR">有效投注<div>{{ item.effectiveBet }}</div>
                            </div>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <path d="M4.25 2.5L8.25 6.5L4.25 10.5" stroke="white" stroke-opacity="0.45" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import {

    platformList,
    rebatePlatform,
    rebateReceive,
} from "@/request/api";
export default {
    name: "waterIndex",

    data() {
        return {
            active: 0,
            tabsList: [],
            tabs: 0,
            subLoading:false,
            total:0,
        }
    },
    mounted() {
        // this.getPlatformList();
        
        // this.getRebatePlatform();
    },
   
    methods: {
        ...mapActions(['setLoginShow','setRegisterShow']),
        goTab(num){
            this.$emit('goTab',num)
        },
        isApp() {
      var origin = window.location.origin
      return !origin.includes("app")
    },
        setSubmit(){
            if(!localStorage.getItem('token')){
                if(localStorage.getItem('loginStatus')){
          this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setLoginShow(true)
          return;
        }else{
          this.$notify({
            message: "请注册后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setRegisterShow(true)
        return;
        }
      }
            this.subLoading = true;
            rebateReceive().then((res)=>{
                if(res.code == 0){
                    this.$notify({
                        message: "领取成功",
                        duration: 2000,
                        background: '#1989fa'
                    });
                    this.getRebatePlatform();
                }
                this.$emit("setData",true);
                this.subLoading = false;
                
            })
        },
        changeTabs(index){
            this.tabs = index;
        },
        getPlatformList() {
            this.$store.commit("setLoadingShowTrue");
            platformList().then((res) => {
                if (res.code == 0) {
                    //   this.list.push(...res.data);
                    this.tabsList.push(...res.data);
                    //   this.sportsComponents = this.list.map(() => ({}));
                    //   this.scrollToItem(this.tabs);
                }
                this.$store.commit("setLoadingShowFalse");
            });
        },
        getRebatePlatform() {
            this.$store.commit("setLoadingShowTrue");
            rebatePlatform().then((res) => {
                if (res.code == 0) {
                    this.tabsList = res.data.platformType;
                    this.total = res.data.total;
                }
                this.$store.commit("setLoadingShowFalse");
            })
            
        },
    }
}
</script>
<style lang="less" scoped>
@import '@/style/global.less';
.watOut {
    padding-top: 20px;
    // min-height: calc(100dvh - 48px - 48px - 60px  );
 height: calc( 100dvh - 48px - 48px - 71px );
 padding-bottom: 20px;
  overflow-y: auto;
  background: @allBj;
    .dqklq {
        color: #B1BAD3;
        padding-left: 24px;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;

        div {
            color: #FFE816;

            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
        }
    }

    .watCont {
        display: flex;
        gap: 10px;
        width: 100%;
        padding: 0 15px;
        margin-top: 15px;
        .leftOut{
            width: 88px;
            .watLeft {
            padding: 10px;
            border-radius: 10px;
            background: radial-gradient(114.24% 141.42% at 0% 0%, rgba(155, 155, 186, 0.16) 31.69%, rgba(155, 155, 186, 0.04) 100%);
            overflow: hidden;
            width: 88px;

            .tabs {
                width: 100%;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                color: #B1BAD3;

                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                .tabImg{
                    width: 16px;
height: 16px;
                }
            }

            .active {
                color: #fff;
            }
        }
            .yjlqBtn{
                width: 88px;
                height: 41px;
                padding: 10px;
                border-radius: 10px;
                background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
                color: #FFF;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                border: 0;
                margin:10px 0;
            }
            .btnOut{
                height: 41px;
                display: flex;
                align-items: center;
                justify-content: center;
                .lqjlBtn{
                width: 88px;
                height: 41px;
               
                border-radius: 10px;
                background: radial-gradient(114.24% 141.42% at 0% 0%, rgba(155, 155, 186, 0.16) 31.69%, rgba(155, 155, 186, 0.04) 100%);
                color: #B1BAD3;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                border: 0;
                margin-top:0;
            }
            }
            
        }
       

        .watRight {
            width: calc(100% - 10px - 88px);

            .rightCont {
                display: flex;
                border-radius: 10px;
                background: radial-gradient(114.24% 141.42% at 0% 0%, rgba(155, 155, 186, 0.16) 31.69%, rgba(155, 155, 186, 0.04) 100%);
                padding: 10px 5px;
                align-items: center;
                align-self: stretch;
                height: 71px;
                margin-bottom:10px;
                .rightLeft {
                    width: calc(100% - 12px);

                    .leftTop {
                        padding: 0 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .leftImg {
                            max-height: 18px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color:#fff;
                            font-size: 14px;
                            font-weight: bolder;
                            img {
                                max-height: 18px;
                            }
                        }

                        .klq {
                            color: #B1BAD3;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 2px;
                            font-family: "PingFang SC";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;

                            div {
                                color: #FFE816;
                                font-family: "PingFang SC";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                            }
                        }
                    }

                    .tiaoOut {
                        width: 100%;
                        border-radius: 99px;
                        background: #202C37;
                        height: 18px;
                        flex-shrink: 0;
                        position: relative;
                        margin: 5px 0;

                        .tiao {
                            height: 14px;
                            border-radius: 99px;
                            background: linear-gradient(151deg, #183475 -36.62%, #4774DA 100%);
                            max-width: calc(100% - 6px) !important;
                            position: absolute;
                            top: 2px;
                            bottom: 2px;
                            margin-left: 2px;
                            margin-right: 3px;
                            z-index: 1;
                            width: 80%;
                        }

                        .tiaoTxt {
                            color: #B1BAD3;
                            position: relative;
                            z-index: 2;
                            text-align: center;
                            font-family: "PingFang SC";
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            width: 100%;
                        }
                    }

                    .leftBom {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .dqfs {
                            color: #B1BAD3;
                            display: flex;
                            align-items: center;
                            gap:2px;
                            font-family: "PingFang SC";
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 400;

                            div {
                                color: #FFF;
                                font-family: "PingFang SC";
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 600;
                            }
                        }

                        .jsL {
                            justify-content: flex-start;
                        }

                        .jsR {
                            justify-content: flex-end;
                        }
                    }
                }
            }


        }
    }

}
.heightOut{
  max-height: calc( 100dvh - 48px - 48px - 60px )!important;
}
</style>