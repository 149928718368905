<template>
  <div class="footer" :class="{ 'heightOut': isApp() }">
    <!-- :style="{ 'left': active == 0 ? '-8%' : active == 1 ? '12%' : active == 2 ? '40%' : active == 3 ? '60%' : active == 4 ? '80%' : ''}" -->
    <!-- <div
      class="aftive"
      :style="{ left: active == 0 ? '-3.5%' : active * 20 - 3.5 + '%' }"
    >
      
    </div> -->
    <!-- <div class="tt"><img src="../assets/footer/aftiveT.svg"/></div>
        <div class="zj">
            <img src="../assets/footer/aftiveCenter.svg"/>
        </div> -->
    <div class="footList" v-for="(item, index) in list" :key="item.name + index"
      @click="changeRouter(item)" :class="{ 'noActive': true }">
      <!-- :class="{'noActive':item.id!=active}" -->
      <div class="listImg" :class="{ activeImg: item.id == active }">
        <img :src="require(`@/assets/footer/${item.id == active ? 'white' : 'black'}${item.id
          }.svg`)
          " />
        <div class="redDemo" v-if="index == 2&&$route.path !== item.link" v-show="waitReceiveCount > 0">{{ waitReceiveCount }}</div>
      </div>
      <div class="listTitle" :class="{ afterTitle: active == item.id }">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import {


  welfareWait_receive,

} from "@/request/api";
export default {
  name: "AppFooter",
  data() {
    return {
      active: 0,
      activeLink: "/",
      list: [
        {
          name: "娱乐城",
          // link: "/PersonReal",
          link: "/",
          id: 1,
        },
        {
          name: "体育",
          link: "/sport",
          id: 0,
        },

        {
          name: "优惠",
          link: "/activityOut",
          id: 2,
        },
        // {
        //   name: "福利",
        //   link: "/fulis",
        //   id: 3,
        // },
        {
          name: "客服",
          link: "/service",
          id: 5,
        },
        {
          name: "我的",
          link: "/personal",
          id: 4,
        },
      ],
      waitReceiveCount: 0,
      timer: null,
      show:false,
    };
  },
  mounted() {
    if(localStorage.getItem("token")){
      this.getUserHome();
    }
    
    // this.timer = setInterval(() => {
    //   if (this.$route.path !== '/activityOut'&& localStorage.getItem("token")) {
        
    //   this.getUserHome();
    //   }
    // }, 5000);
    if(this.$route.path == '/serviceIn'){
          this.active = 5;
          return;
        }
  },
  methods: {
    changeRouter(item) {
      console.log(item, '-----=================');
      
      this.$router.push(item.link)
      if(localStorage.getItem("token")){
        this.getUserHome();
      }
    },
    getUserHome() {
      this.getHomeLoading = true;
      welfareWait_receive().then((res) => {
        if (res.code == 0) {
          this.waitReceiveCount = res.data.length;
        }
        this.getHomeLoading = false;
      })
    },
    isApp() {
      var origin = window.location.origin
      return !origin.includes("app")
    },
    goPage(e) {
      if (navigator.vibrate) {
        navigator.vibrate(1000);
      } else if (navigator.webkitVibrate) {
        navigator.webkitVibrate(1000);
      }
      this.$router.push({ path: e });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  watch: {
    $route(to, form) {
      this.activeLink = to.path;
      for (var i = 0; i < this.list.length; i++) {
        if(to.path == '/serviceIn'){
          this.active = 5;
          return;
        }
        if (to.path == this.list[i].link) {
          this.active = this.list[i].id;
        }
      }
      console.log(form)
      // if (to.path == '/activityOut') {
      //   this.show = true;
      //   if (this.timer) {
      //     clearInterval(this.timer);
      //     this.timer = null;
      //   }
      //   console.log(this.show)
      // } else if (form.path == '/activityOut') {
      //   this.getUserHome();
      //   this.timer = setInterval(() => {
      //     this.getUserHome();
      //   }, 1000);
      //   this.show = false;
      // }
    },
  },
  created() {
    console.log(this.$route.path);
    for (var i = 0; i < this.list.length; i++) {
      if (this.$route.path == this.list[i].link) {
        this.active = this.list[i].id;
      }
    }

    // this.$router.beforeEach((to, from, next) => {
    //   this.currentImage = this.images[to.path];
    //   next();
    // });
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100vw;
  height: 71px;
  flex-shrink: 0;
  background: #15212d;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 5px;
  z-index: 100;
  transition: all 1s;
  
  .footList {
    position: relative;
    z-index: 10;
    width: 20%;

    .listImg {
      width: 24px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 2px auto;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .redDemo {
        position: absolute;
        left: 100%;
        top: -6px;
        color: #FFF;
        padding: 0px 5px;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        border-radius: 99px;
        background: #F00;
      }
    }

    .activeImg {
      width: 24px;
      height: 20px;
    }

    .listTitle {
      color: #b3b9d2;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      /* 21px */
    }

    .afterTitle {
      color: #4774da;
    }
  }

  .noActive {
    padding-top: 10px;
  }

  .aftive {
    transition: all 0.5s;
    position: absolute;
    z-index: 8;
    background: url("../assets/footer/tupo.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 102.626px;
    height: 11px;
    flex-shrink: 0;
    top: -10px;

    .tt {
      width: 28px;
      height: 2px;
      flex-shrink: 0;
      border-radius: 0px 0px 4px 4px;
      background: #ef5249;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .zj {
      width: 50px;
      height: 32px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: linear-gradient(180deg, rgba(243, 84, 75, 0.5) 0%, #cc3a31 100%);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.heightOut {
  height: 56px !important;
  padding-top:0!important;
}
</style>
