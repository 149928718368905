<template>
	<!-- :class="{height: isChuanZi}" -->
  <!-- bg15212D -->
  <div class='bottompopup pb2 bg223b53' :class="{height: isChuanZi}"  :style="$store.getters['sport/$theme']">
    <div class="head pl15 pr15 flex space-c-b bg192A39">
      <div class="delete space-c-b" :style='{ left: deleteAllShow ? 0 : "100%" }'>
        <div class='bgFF3C44 flex1' @click="handleDeleteItem(false)">移除全部</div>
        <div class="bg47536E cross" @click='deleteAllShow = false'>
          <span>
            <van-icon name="cross" size='20' bold />
          </span>
        </div>
      </div>
      <div class="flex1 flex t-left" v-if='status!=2 || cgStatus!=2'>
        <span  class="mr6" @click="$emit('close')" >
          <van-icon name="arrow-down"></van-icon>
        </span>
        {{ isChuanZi ? '批量投注' : '单注投注' }}
      </div>
      <div v-else>
        投注结果
      </div>
      <div class='flex'>
        <div class='flex yuyue' @click='handleRefresh' v-if='$store.state.sport.user.username'>
          <!-- 余额 -->
          <span class="bold mr4" v-html="numToStr(Math.floor($store.state.sport.user.balance), {opacity: 0.6, 'font-size': '11px'})"></span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
						class='refresh' :style="{animationPlayState: isRefresh ? 'running' : 'paused'}">
						<g clip-path="url(#clip0_3095_448)">
							<path
								d="M12.2016 14.1C12.05 14.1 11.9 14.0312 11.8016 13.9C11.6359 13.6797 11.6797 13.3656 11.9016 13.2C13.5531 11.9594 14.5 10.0641 14.5 8C14.5 4.41563 11.5844 1.5 8 1.5C7.72344 1.5 7.5 1.27656 7.5 1C7.5 0.723437 7.72344 0.5 8 0.5C12.1359 0.5 15.5 3.86406 15.5 8C15.5 10.3812 14.4062 12.5688 12.5 14C12.4109 14.0672 12.3047 14.1 12.2016 14.1ZM8 15.5C3.86406 15.5 0.5 12.1359 0.5 8C0.5 5.96875 1.29844 4.06719 2.75 2.64375C2.94688 2.45 3.26406 2.45313 3.45781 2.65C3.65156 2.84688 3.64844 3.16406 3.45156 3.35781C2.19219 4.59062 1.5 6.23906 1.5 8C1.5 11.5844 4.41563 14.5 8 14.5C8.27656 14.5 8.5 14.7234 8.5 15C8.5 15.2766 8.27656 15.5 8 15.5Z"
								fill="#4774DA" />
							<path
								d="M9.4 3.6C9.23906 3.6 9.07969 3.52188 8.98281 3.37656L7.58437 1.27813C7.43125 1.04844 7.49375 0.7375 7.72344 0.584375C7.95312 0.43125 8.26406 0.49375 8.41719 0.723437L9.81719 2.82344C9.97031 3.05313 9.90781 3.36406 9.67812 3.51719C9.59219 3.57344 9.49531 3.6 9.4 3.6ZM8 15.5C7.83906 15.5 7.67969 15.4219 7.58281 15.2766L6.18281 13.1766C6.02969 12.9469 6.09219 12.6359 6.32187 12.4828C6.55156 12.3297 6.86094 12.3922 7.01562 12.6219L8.41562 14.7219C8.56875 14.9516 8.50625 15.2625 8.27656 15.4156C8.19219 15.4734 8.09531 15.5 8 15.5Z"
								fill="#4774DA" />
						</g>
						<defs>
							<clipPath id="clip0_3095_448">
								<rect width="16" height="16" fill="white" />
							</clipPath>
						</defs>
					</svg>
        </div>
        <div class="pl10" v-if="status==0 && cgStatus==0">
          <span v-if='!isChuanZi' @click="handleDzClose">
            <van-icon name="cross" size='24' color='#ccc' bold />
          </span>
          <span v-else @click='deleteAllShow=true' class="flex">
            <svg t="1724667325939" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="31837" width="22" height="22"><path d="M799.2 874.4c0 34.4-28.001 62.4-62.4 62.4H287.2c-34.4 0-62.4-28-62.4-62.4V212h574.4v662.4zM349.6 100c0-7.2 5.6-12.8 12.8-12.8h300c7.2 0 12.8 5.6 12.8 12.8v37.6H349.6V100z m636.8 37.6H749.6V100c0-48.001-39.2-87.2-87.2-87.2h-300c-48 0-87.2 39.199-87.2 87.2v37.6H37.6C16.8 137.6 0 154.4 0 175.2s16.8 37.6 37.6 37.6h112v661.6c0 76 61.6 137.6 137.6 137.6h449.6c76 0 137.6-61.6 137.6-137.6V212h112c20.8 0 37.6-16.8 37.6-37.6s-16.8-36.8-37.6-36.8zM512 824c20.8 0 37.6-16.8 37.6-37.6v-400c0-20.8-16.8-37.6-37.6-37.6s-37.6 16.8-37.6 37.6v400c0 20.8 16.8 37.6 37.6 37.6m-175.2 0c20.8 0 37.6-16.8 37.6-37.6v-400c0-20.8-16.8-37.6-37.6-37.6s-37.6 16.8-37.6 37.6v400c0.8 20.8 17.6 37.6 37.6 37.6m350.4 0c20.8 0 37.6-16.8 37.6-37.6v-400c0-20.8-16.8-37.6-37.6-37.6s-37.6 16.8-37.6 37.6v400c0 20.8 16.8 37.6 37.6 37.6" fill="#FFA103" p-id="31838"></path></svg>
          </span>
        </div>
      </div>
    </div>
		<!-- :style='{paddingBottom : calcShow ? "200px" : "0"}' -->
    <van-skeleton v-if='$store.state.sport.user.username' class='list'  :yuan="true" :loading="loading">
			<div :class='{move: moveTop}'>
      <div class="item" v-for="(item, i) in reslist" :key="i">
        <!-- {{ item }} -->
        <div class="space-c-b">
          <div class="flex mb8 _4774DA f16" style='font-weight: 600;'>
            <span class="shugang"></span>
            <span class="mr6" v-if="item.type">(滚球)</span>
            <span class="mr6" v-if='item.nm'>{{ item.nm }}</span>
            <span class="mr6">({{ oddsType[item?.op?.odt] }})</span>
            <span style="color: #fff" v-if='item.re' >
              ({{ item.re.replace('-', ':') }})
            </span>
          </div>
          <span v-if='isChuanZi'  @click="handleDeleteItem(item)">
            <van-icon name="cross" size='24' color='#ccc' bold />
          </span>
        </div>
        <p class="mb6">{{ item.lg?.na }}</p>
        <p class="mb6" v-if='item.ts && item.ts.length>=1'>
          {{ item.ts[0]?.na }} vs {{ item.ts[1]?.na }}
        </p>
        <div class='f16 mb6'>
          <span v-if="item.ts && item.na && item.ts.length > 1 && (item?.na == item?.ts[0]?.na || item.na == item?.ts[1]?.na)">
            {{ item.na }}
          </span>
          <span v-else class="_FFA103"> {{ item?.op?.nm }}</span>
          &nbsp;@&nbsp;
          <span>
            <span class="_34C759 bold">{{ item?.op.bod }}</span>
            <i v-if="item.className" :style='getColor(item.className)' :class="[item.className]" class='iconfont icon-peishuaibianhua-copy'></i>
          </span>
        </div>
        
        <div class="space-c-b" v-if="status==0 && item.ss!=-1">
					<!-- :class="{active: tzItem?.matchId == item.matchId}" -->
          <div class="ipt" @click="handleShowCalc(item, i)">
            <!-- <input class='ipt1' :value="item.tz" disabled :placeholder="`限额${item.smin}-${item.smax}`" /> -->
            <div class='show1 _788A9A' v-if='tzItem?.id != item?.id && !item.tz'>限额{{item.smin}}-{{item.smax}}</div>
            <div class='show2' v-else>
              {{ item.tz }}
              <span class="shan" v-if='shan && item.matchId && tzItem?.matchId == item.matchId'>|</span>
            </div>
          </div>
          <div v-if='item.maxTzStatus==0' @click="item.maxTzStatus++">
            最大投注金额
          </div>
          <div class='f12 t-center' v-if='item.maxTzStatus==1' @click="item.maxTzStatus++; item.tz = item.smax<=Math.floor($store.state.sport.user.balance) ? item.smax : Math.floor($store.state.sport.user.balance)">
            <p class="_B3B9D2">设置金额</p>
            <p>
              {{ item.smax.toFixed(2) }}
            </p>
          </div>
          <div class="f12 t-center" v-if='item.maxTzStatus==2'>
            <p class="_B3B9D2">可赢金额</p>
            <p class="_34C759">
              <!-- {{ (item.tz * (item.op.bod - 1)).toFixed(2) }} -->
              <!-- {{ calcProfit(item.tz, item.op.bod, item?.op?.odt) }} -->
            <span v-if="item.op.odt==1">{{ item.tz*(item.op.bod-1) | fixed }}</span>
            <span v-if="item.op.odt==2 || item.op.odt==3">{{ (item.op.bod < 0 ? item.tz : item.tz*item.op.bod) | fixed }}</span>
            <span v-if="item.op.odt==4">{{ (item.op.bod < 0 ? item.tz : item.tz*item.op.bod) | fixed }}</span>
            <span v-if="item.op.odt==5">{{ (item.op.bod < 0 ? item.tz : item.tz*item.op.bod/100) | fixed }}</span>
            </p>
          </div>
        </div>
        <p class='_FF3B30' v-if="item.tz && item.tz < item.smin">最低限额: {{ item.smin }}</p>
        <p class='_FFA103 f12' v-if="item.ss==-1">盘口已{{ item.ss==-1 ? '关闭' : '暂停' }}</p>
        <div class="space-c-b" v-if="item.tz">
          <p v-if='status==1' class="_FFA103 flex">
            投注中
            <van-loading size='14' />
          </p>
          <p v-else-if="item.st == 1" class="_34C759 flex">
            确认中
            <van-loading size='14' />
          </p>
          <p v-else-if="item.st == 2" class="_FF3B30 flex">
            已拒单
          </p>
          <p v-else-if="item.st == 4" class="_34C759 flex">
            已接单
          </p>
          <p v-else-if="item.st == 6" class="_34C759 flex">
            {{item.rej}}
          </p>
          <span v-if="item.resid" class="f12 _8391B8">{{ item.resid }}</span>
        </div>

        <div class="space-c-b flex f13 _B3B9D2 mt6" v-if="status==2 && item.tz && !item.rej">
          <div class="xiazhu mb2">
            <p class="_B3B9D2">下注金额</p>
            <p>{{ Number(item.tz).toFixed(2) }}</p>
          </div>
          <div class="t-right mb2">
            <p class="_B3B9D2">可赢金额</p>
            <!-- <p>{{ (item.tz * (item.op.bod - 1)).toFixed(2) }}</p> -->
            <!-- {{ calcProfit(item.tz, item.op.bod, item?.op?.odt) }} -->
            <span v-if="item.op.odt==1">{{ item.tz*(item.op.bod-1) | fixed }}</span>
            <span v-if="item.op.odt==2 || item.op.odt==3">{{ (item.op.bod < 0 ? item.tz : item.tz*item.op.bod) | fixed }}</span>
            <span v-if="item.op.odt==4">{{ (item.op.bod < 0 ? item.tz : item.tz*item.op.bod) | fixed }}</span>
            <span v-if="item.op.odt==5">{{ (item.op.bod < 0 ? item.tz : item.tz*item.op.bod/100) | fixed }}</span>
          </div>
        </div>
      </div>
      <div class="chuanzi-info" v-if="isChuanZi && listLen>1">
        <div v-if="(status==0 && cgStatus==0 && sosList.length) || cgStatus">
          <p class="flex f16 mb6">
            <span class="shugang bg34C759"></span>
            <span>综合过关</span>&nbsp;
            <span v-if="sosList[0]">@ {{ sosList[0].sodd.toFixed(2) }}</span>
          </p>
          <p v-if="hasClose" class="_8391B8 t-left mt6 pt6">含有已关闭赔率，请等待盘口开放或更改注项</p>
          <p v-else-if="isSame">无法在同一场比赛下过关单</p>
          <div v-if="cgStatus==0 && !hasClose">
            <div class="space-c-b" v-for="(sos, s) in sosList" :key="s">
              <div class="ipt" @click="handleShowCalc(sos, s)">
                <!-- <input :value="sos.tz" :class="{active: tzItem?.sodd == sos.sodd}" disabled :placeholder="`限额${sos.smin}-${sos.smax}`" /> -->

                <div class='show1 _788A9A' v-if='tzItem?.sodd != sos.sodd && !sos.tz'>限额{{sos.smin}}-{{sos.smax}}</div>
                <div class='show2' v-else>
                  {{ sos.tz }}
                  <span class="shan" v-if='shan && tzItem?.sodd == sos.sodd'>|</span>
                </div>
              </div>
              <div class="space-c-b flex1">
                <span>
                  {{listLen}}串1
                </span>
                <div v-if='sos.maxTzStatus==0' @click="sos.maxTzStatus++">
                  最大投注金额
                </div>
                <div class='f12 t-center' v-if='sos.maxTzStatus==1' @click="sos.maxTzStatus++; sos.tz = sos.smax">
                  <p class="_B3B9D2">设置金额</p>
                  <p>
                    {{ sos.smax.toFixed(2) }}
                  </p>
                </div>
                <div class="f12 t-center" v-if='sos.maxTzStatus==2'>
                  <p class="_B3B9D2">可赢金额</p>
                  <p class="_34C759">
                    {{ (Math.ceil(sos.tz * (sos.sodd - 1)*100)/100).toFixed(2) }}
                      <!-- {{ sos }} -->
                    {{ calcProfit(sos.tz, sos.sodd, 1) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="space-c-b" v-if="sosList[0].tz">
            <p v-if='cgStatus==1' class="_FFA103 flex">
              投注中
              <van-loading size='14' />
            </p>
            <p v-else-if="sosList[0].st == 1" class="_34C759 flex">
              确认中
              <van-loading size='14' />
            </p>
            <p v-else-if="sosList[0].st == 2" class="_FF3B30 flex">
              已拒单
            </p>
            <p v-else-if="sosList[0].st == 4" class="_34C759 flex">
              已接单
            </p>
            <p v-else-if="sosList[0].st == 6" class="_34C759 flex">
              {{sosList[0].rej}}
            </p>
            <span v-if="sosList[0].resid" class="f12 _8391B8">{{ sosList[0].resid }}</span>
          </div>
          <!-- <div v-else class="space-c-b flex f13 _B3B9D2 mt6">
            <div class="xiazhu mb2">
              <p class="_B3B9D2">下注金额</p>
              <p>{{ Number(sosList[0].tz).toFixed(2) }}</p>
            </div>
            <div class="t-right mb2">
              <p class="_B3B9D2">可赢金额</p>
              <p>{{ (sosList[0].tz * (sosList[0].sodd - 1)).toFixed(2) }}</p>
            </div>
          </div> -->
        </div>
        <div v-if="status==0 && cgStatus==0">
          <p class="flex f16 mb6">
            <span class="shugang bg34C759"></span>
            <span>单注</span>
          </p>
          <div class="space-c-b">
            <div class="ipt" @click="handleShowCalc(allMoney)">
							<!-- :class='{active: tzItem==allMoney}' -->
              <!-- <input class='ipt1' :value="allMoney.tz" disabled placeholder="输入下注金额" /> -->
              <div class='show1 _788A9A' v-if='tzItem!=allMoney && !allMoney.tz'>输入下注金额</div>
              <div class='show2' v-else>
                {{ allMoney.tz }}
                <span class="shan" v-if='shan && tzItem==allMoney'>|</span>
              </div>
            </div>
            <div class="space-c-b flex1">
              <span>* {{ listLen }}</span>
            </div>
          </div>
        </div>
      </div>
  
      <div class='tz-res f15' v-if="isChuanZi && listLen > 1">
        <div class="space-c-b mb6 mt4">
          <span>下注总额</span>
          <span>
            <span class="bold mr4">{{ tzAllMoney }}</span>
            RMB
          </span>
        </div>
        <div class="space-c-b pt6">
          <span>预估可赢</span>
          <span class="_34C759">
            <span class="bold mr4">{{ tzAllKyMoney }}</span>
            RMB
          </span>
        </div>
      </div>
			</div>
		</van-skeleton>

    <!-- <p class="removeclose _FF3B30" v-if="$store.state.sport.user.username && hasClose && isChuanZi" @click='removeClosePan'>移除被关盘选项</p> -->
    
		<div v-if='!$store.state.sport.user.username' style='height: 100%; display: flex; align-items: center; justify-content: center;'>
			<p class='text-c' style='padding: 100px 0;'>
				<!-- <span style='padding: 13px 40px; border-radius: 6px; background: #4774da;' @click='handlelogin'>
					未登录， 点击登录
				</span> -->
				<Btn sty='padding: 13px 40px; border-radius: 6px; background: #4774da;' @click='handlelogin'>未登录， 点击登录</Btn>
			</p>
		</div>
		
    <div v-if='$store.state.sport.user.username'>
			<p class="removeclose _FF3B30" v-if="$store.state.sport.user.username && hasClose && isChuanZi" @click='removeClosePan'>移除被关盘选项</p>
      <div class='calc pt6' id="calc" v-show="calcShow">
        <div class="grid">
          <Btn class="item" @click='clickCalcItem(1)'>1</Btn>
          <Btn class="item" @click='clickCalcItem(2)'>2</Btn>
          <Btn class="item" @click='clickCalcItem(3)'>3</Btn>
          <Btn class="item" @click='clickCalcItem(4)'>4</Btn>
          <Btn class="item" @click='clickCalcItem(5)'>5</Btn>
          <Btn class="item" @click='clickCalcItem(6)'>6</Btn>
          <Btn class="item" @click='clickCalcItem(7)'>7</Btn>
          <Btn class="item" @click='clickCalcItem(8)'>8</Btn>
          <Btn class="item" @click='clickCalcItem(9)'>9</Btn>
          <Btn class="item" @click='clickCalcItem(0)'>0</Btn>
          <Btn class="item" @click='clickCalcBack'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M7 5.5L2 12L7 18.5H22V5.5H7Z" stroke="white" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M10.5 9.5L15.5 14.5" stroke="white" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M15.5 9.5L10.5 14.5" stroke="white" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </Btn>
          <Btn class="item" @click='clickCalcItem(10)'>+10</Btn>
          <Btn class="item" @click='clickCalcItem(100)'>+100</Btn>
          <Btn class="item" @click='clickCalcItem(1000)'>+1000</Btn>
          <Btn class="item" @click='calcShow = false'>完成</Btn>
        </div>
      </div>

      <div class='footer-res' v-if="status==2 || cgStatus==2">
        <div class="bg4774DA mb6" @click="handleDeleteItem(false)">确定</div>
        <div @click="saveTouZhu">保留投注选项</div>
      </div>

      <div class='footer-btn space-c-b pl10 pr10' v-else>
        <div class="svg bg304b62" @click="peilvShow = !peilvShow">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6193 2.95237C15.2383 1.80951 14.1907 1.04761 12.9526 1.04761C11.7145 1.04761 10.6669 1.80951 10.2859 2.95237H1.14307V4.85713H10.2859C10.6669 5.99999 11.7145 6.76189 12.9526 6.76189C14.1907 6.76189 15.2383 5.99999 15.6193 4.85713H18.9526V2.95237H15.6193ZM12.8574 4.85713C12.2859 4.85713 11.905 4.47618 11.905 3.90475C11.905 3.33332 12.2859 2.95237 12.8574 2.95237C13.4288 2.95237 13.8097 3.33332 13.8097 3.90475C13.8097 4.47618 13.4288 4.85713 12.8574 4.85713ZM12.8574 13.2381C11.6193 13.2381 10.5716 14 10.1907 15.1428H1.14307V17.0476H10.2859C10.6669 18.1905 11.7145 18.9524 12.9526 18.9524C14.1907 18.9524 15.2383 18.1905 15.6193 17.0476H18.9526V15.1428H15.6193C15.1431 14 14.0954 13.2381 12.8574 13.2381ZM12.8574 17.0476C12.2859 17.0476 11.905 16.6667 11.905 16.0952C11.905 15.5238 12.2859 15.1428 12.8574 15.1428C13.4288 15.1428 13.8097 15.5238 13.8097 16.0952C13.8097 16.6667 13.4288 17.0476 12.8574 17.0476ZM7.14307 7.14285C5.90497 7.14285 4.85735 7.90475 4.4764 9.04761H1.14307V10.9524H4.4764C4.85735 12.0952 5.90497 12.8571 7.14307 12.8571C8.38116 12.8571 9.42878 12.0952 9.80973 10.9524H18.9526V9.04761H9.80973C9.42878 7.90475 8.38116 7.14285 7.14307 7.14285ZM7.14307 10.9524C6.57164 10.9524 6.19069 10.5714 6.19069 9.99999C6.19069 9.42856 6.57164 9.04761 7.14307 9.04761C7.7145 9.04761 8.09545 9.42856 8.09545 9.99999C8.09545 10.5714 7.61926 10.9524 7.14307 10.9524Z" fill="white"/>
          </svg>
        </div>
        <div class="bg304b62" v-if="!isChuanZi" @click='$emit("close")'>
          加入下注单
        </div>
        <div :class="[tzBtnStatus ? 'bg4774DA' : 'bg304b62']" @click="handleTouzhu" v-if="status==0 && cgStatus==0">
          <p>投注</p>
          <p>{{ tzAllMoney }} RMB</p>
        </div>
        <div class="bg4774DA" v-else>
          <van-loading size='16' />
        </div>
      </div>
    </div>

    <van-popup v-model="peilvShow" position="bottom">
      <div class="peilv">
        <div class="head pl15 pr15 flex space-c-b bg304b62">
          <div class="flex1 flex t-left">
            下注单设定
          </div>
          <span @click="peilvShow=false">
            <van-icon name="cross" size='20' bold />
          </span>
        </div>

        <div class="setting">
          <p>赔率偏好设定</p>
          <div class="space-c-b">
            <span class="f16 bold">
              是否接受更好的赔率
            </span>
            <van-switch v-model="peilvChecked" size="20px"  active-color="#4774da" inactive-color="#4d588280" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import mixin from '../utils/mixin'
  import { batchBetMatchMarketOfJumpLine, betMultiple, singlePass, getStakeOrderStatus } from '@/request/sportApi.js'
	import { mapActions } from 'vuex';
  export default {
    name: 'bottomPopup',
    mixins: [mixin],
    props: {
      typeList: {
        type: Array,
        default: () => []
      },
      orderSelectObj: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
      return {
				moveTop:false,
        isChuanZi: false,  // 是否是串子
        isRefresh: false, // 刷新预约
        deleteAllShow: false, // 删除全部
        peilvShow: false, // 赔率显示
        peilvChecked: true,
        calcShow: false, // 计算器显示

        list: [], // 单注列表
        sosList: [], // 串关列表
        lastList: [], // 上一次的数据
        bottomTimer: null,  // 数据轮询
        resTimer: null,   // 单注投注结果轮询
        cgResTimer: null, // 串关投注结果轮询
        shanTimer: null, 
        loading: true,
        shan: true,
        allMoney: {
          tz: ''
        },
        tzItem: {},

        status: 0,
        cgStatus: 0,

        oddsType: {
          "1": "欧洲盘",
          "2": "香港盘",
          "3": "马来盘",
          "4": "印尼盘",
          "5": "美式盘",
        },
        orderStatus: {
          "0": "未确认",
          "1": "确认中",
          "2": "已拒单",
          "3": "已取消",
          "4": "已接单",
          "5": "已结算",
        },
        rejectType: {
          "1": "系统异常",
          "2": "参数错误拒单",
          "3": "风控限制拒单",
          "4": "玩法暂停拒单",
          "5": "操盘手拒单",
          "6": "支付失败",
          "7": "结算时订单未确认拒单",
        },
      }
    },
    filters: {
      fixed(a) {
        return (Math.ceil(a*100)/100).toFixed(2)
      }
    },
    watch: {
      peilvChecked() {
        this.$store.commit('sport/setSportConfig', { index:3, active: this.peilvChecked ? 1 : 0 })
      },
      'allMoney.tz'(a) {
        this.list.forEach(item => {
          console.log(123);
          
          if (item.ss != -1) {
            if (a != 0) {
              item.maxTzStatus = 2
              if (a > item.smax) {
                item.tz = item.smax
              } else {
                item.tz = a
              }
            } else {
              item.maxTzStatus = 0
              item.tz = ''
            }
          }
        })
      }
    },
    computed: {
      // 总数
      listLen() {
        return Object.keys(this.orderSelectObj).length
      },
      requestData() {
        let betMatchMarketList = []
        Object.values(this.orderSelectObj).forEach(item => {
          betMatchMarketList.push({
            matchId: item.matchId,
            marketId: item.id,
            type: item.op.ty,
            // oddsType: this.$store.state.sport.sportConfig[1].active + 1
            oddsType: item.op.odt || this.$store.state.sport.sportConfig[1].active + 1
          })
        })
        return betMatchMarketList
      },
      reslist() {
        if (this.cgStatus != 0) {
          return this.list
        }
        if (this.status != 0) {
          return this.list.filter(item => item.tz)
        }
        return this.list
      },
      // 所有可赢总额
      tzAllKyMoney() {
        let n = 0
        this.list.forEach(item => {
          // n += item.tz * (item.op.bod - 1 <= 1 ? 1 : item.op.bod - 1)
          n += Number(this.calcProfit(item.tz, item.op.bod, item?.op?.odt))
        })
        if (this.sosList.length) {
          this.sosList.forEach(item => {
            if (item.tz) {
              // n += Number(item.tz)
              // n += item.tz * (item.sodd - 1 <= 1 ? 1 : item.sodd - 1)
              n += Number(this.calcProfit(item.tz, item.sodd, 1))
            }
          })
        }
        return n.toFixed(2)
      },
      // 所有总额
      tzAllMoney() {
        let n = Number(this.list.reduce((a, b) => a + b.tz * 1, 0)) || 0
        if (this.sosList.length) {
          this.sosList.forEach(item => {
            if (item.tz) {
              n += Number(item.tz)
							// * this.listLen
            }
          })
        }
        return n.toFixed(2)
      },
      // 是否是同一场比赛下
      isSame() {
        let bool = false
        this.list.forEach(item => {
          if (this.list.filter(attr => attr.matchId == item.matchId).length > 1) {
            bool = true
          }
        })
        return bool
      },
      // 底下按钮投注状态
      tzBtnStatus() {
        let bool = false
        this.list.filter(item => item.tz).forEach(item => {
          bool = true
          if (item.tz < item.smin) {
            bool = false
          }
        })
        if (this.sosList.length) {
          this.sosList.forEach(item => {
            if (item.tz >= item.smin) {
              bool = true
            }
          })
        }
        return bool
      },
      // 是否含有关闭盘
      hasClose() {
        return this.list.find(item => item.ss==-1)
      }
    },
    created() {
      this.peilvChecked = Boolean(this.$store.state.sport.sportConfig[3].active)
      this.isChuanZi = localStorage.getItem('isChuanZi')
      this.getData()
      this.bottomTimer = setInterval(this.getData, 4000)
      
    },
    mounted() {
      this.handleShan()
			console.log(this.$store.state.sport.user)
    },
    destroyed() {
      clearInterval(this.bottomTimer)
      clearInterval(this.resTimer)
      clearInterval(this.cgResTimer)
      clearInterval(this.shanTimer)
      this.cgResTimer = null
      this.bottomTimer = null
      this.resTimer = null
      this.getData = null
      this.handleShan = null
    },
    methods: {
			...mapActions(['setLoginShow','setRegisterShow']),
			handlelogin() {
				this.$emit('close')
				this.setLoginShow(true)
			},
      getData() {
        if (this.status!=0 || this.cgStatus!=0 || this.listLen==0) {
          return
        }
        let data = {
          currencyId: 1,
          isSelectSeries: true,
          languageType: "CMN",
          betMatchMarketList: this.requestData
        }

        batchBetMatchMarketOfJumpLine(data)
          .then(res => {
            if (res.code == 0) {
              let list = []
              res.data.bms.forEach((item, i) => {
                item.code = 0
                if (item.ss==-1) {
                  item.smin = 0
                  item.smax = 0
                  item.tz = ''
                  item.maxTzStatus = 0
                } else {
                  // item.smin = 10
                  // item.smax = 9999
                  item.maxTzStatus = this.list[i]?.maxTzStatus || 0
                  item.tz = this.list[i]?.tz || ''
                }
                list.push(Object.assign({}, this.orderSelectObj[item.mid], item))
              })
              
              this.list = this.comparedBigSmall(this.lastList, list)
              // console.log(this.list, 'this.list');
              this.lastList = this.list
              // console.log(this.list)
              // this.list = list
              
              this.loading = false
              if (res.data.sos) {
                this.sosList = res.data.sos.filter(item => item.sn == this.listLen).map((attr, i) => {
                  attr.maxTzStatus = 0
                  attr.smin = attr.mi || 10
                  attr.smax = attr.mx || 9999
                  attr.tz = this.sosList[i]?.tz || ''
                  attr.resid = this.sosList[i]?.resid || ''
                  return attr
                })
              }
            } else {
              this.$emit('close')
              this.$toast(res)
            }
          })
          .catch(() => { })
      },

      // 计算可赢
      calcProfit(tz, sodd, odt) {
        let n = 0
        if (odt == 1) {
          n = tz*(sodd-1)
        } else if (odt == 2 || odt == 3 || odt == 4) {
          if (sodd<=0) {
            n = tz
          } else {
            n = tz*sodd
          }
        } else if (odt == 5) {
          if (sodd<=0) {
            n = tz
          } else {
            n = (tz*sodd/100)
          }
        }
        return (Math.ceil(n*100)/100).toFixed(2)
      },

      // 投注
      handleTouzhu() {
        if (!this.tzBtnStatus) {
          return
        }
				if (Number(this.tzAllMoney) > this.$store.state.sport.user.balance) {
					this.$toast('余额不足')
					return
				}
        this.downOrder()
        this.cgDownOrder()
      },

      // 单注下单
      downOrder() {
        this.status = 1
        let singleBetList = []
        let data = {
          "languageType": "CMN",
          "currencyId": 1,
          "thirdRemark": "PD_SPORTS",
          singleBetList
        }
        this.list.forEach((item) => {
          if (item.tz && item.tz >= item.smin) {
            item.resid = ' '
            item.st = 1
            singleBetList.push({
              "thirdRemark": "PD_SPORTS",
              "unitStake": item.tz * 1,
              "oddsChange": this.$store.state.sport.sportConfig[3].active,
              "betOptionList": [
                {
                  "odds": item.op.bod,
                  "marketId": item.id,
                  "optionType": item.op.ty,
                  "oddsFormat": 1 // item.oddsType
                }
              ]
            })
          }
        })
        if (singleBetList.length) {
          singlePass(data)
            .then(res => {
              this.$store.dispatch('sport/getUserInfo')
              this.status = 2
              if (res.code == 0) {
                let ops = res.data
                this.list.forEach((item, i) => {
									if (item.tz) {
										
										item.st = 1
										item.resid = ops.find(attr => attr.ops[0].mid == item.id).id
										item.maxTzStatus = 0
										this.$set(this.list, i, item)
									}
                })
                this.resTimer = setInterval(this.getOrderStatus, 1000);
              } else {
                this.list.forEach((item, i) => {
									if (item.tz) {
										item.st = 6
										item.rej = res
										item.maxTzStatus = 0
										this.$set(this.list, i, item)
									}
                })
              }
            })
            .catch(() => {
              this.list.filter(item => item.tz).forEach(item => {
                item.st = 0
                item.maxTzStatus = 0
              })
            })
        }
      },

      // 串关下单
      cgDownOrder() {
        if (!this.sosList.find(item => item.tz)) {
          return
        }
        this.cgStatus = 1
        let betMultipleData = []
        let betOptionList = []
        let data = {
          thirdRemark: "PD_SPORTS",
          "languageType": "CMN",
          betMultipleData,
          betOptionList,             
          "currencyId": 1
        }
        this.sosList.forEach(item => {
          if (item.tz) {
            betMultipleData.push({
              "thirdRemark": "PD_SPORTS",
              "oddsChange": this.$store.state.sport.sportConfig[3].active,
              "seriesValue": item.sn,
              "itemCount": this.sosList.filter(a => a.tz).length,
              "unitStake": item.tz < item.mi ? item.mi : item.tz
            })
          }
        })
        this.list.forEach((item) => {
          betOptionList.push({
            "odds": item.op.od,
            "marketId": item.id,
            "optionType": item.op.ty,
            "oddsFormat": 1 // item.oddsType
          })
        })

        betMultiple(data)
          .then(res => {
            if (res.code == 0) {
              this.$store.dispatch('sport/getUserInfo')
              this.cgStatus = 2
              this.sosList[0].resid = res.data[0].id
              this.sosList[0].st = 1
              this.cgResTimer = setInterval(this.getCgOrderStatus, 1000);
            } else {
              this.sosList[0].rej = res
							this.cgStatus = 2
							this.sosList[0].st = 6
            }
          })
          .catch(() => { 
            
          })
      },

      // 单注确认
      getOrderStatus() {
        getStakeOrderStatus({
          languageType: "CMN",
          orderIds: this.list.filter(item => item.resid).map(item => item.resid)
        })
        .then(res => {
          this.list.forEach((item, i) => {
            if (item.resid) {
              item.st = res.data.find(attr => attr.oid == item.resid).st
              this.$set(this.list, i, item)
            }
          })
          if (res.data.every(item => item.st!=1)) {
            clearInterval(this.resTimer)
            this.resTimer = null
          }
        })
      },

      // 串关确认
      getCgOrderStatus() {
        getStakeOrderStatus({
          languageType: "CMN",
          orderIds: [this.sosList[0].resid]
        })
        .then(res => {
          this.sosList[0].st = res.data[0].st
          if (res.data[0].st == 4) {
            this.$set(this.sosList, 0, this.sosList[0])
            clearInterval(this.cgResTimer)
            this.cgResTimer = null
          }
        })
      },

      // 单注顶部关闭按钮
      handleDzClose() {
        this.$emit('close')
        this.handleDeleteItem(this.list[0])
      }, 

      // 余额刷新
      handleRefresh() {
				console.log(789)
        this.isRefresh = !this.isRefresh
        this.$store.dispatch('sport/getUserInfo')
        .then(() => {
          this.isRefresh = !this.isRefresh
        })
      },

      // 移除被关盘选项
      removeClosePan() {
        this.list.forEach(item => {
          if (item.ss == -1) {
            this.handleDeleteItem(item)
          }
        })
        this.getData()
      },

      // 删除某一单
      handleDeleteItem(item) {
        // this.orderSelectObj
        if (!item) {
          this.list = []
          this.lastList = []
        } else {
          this.list = this.list.filter(attr => attr.id != item.id)
          this.lastList = this.lastList.filter(attr => attr.id != item.id)
        }
        // this.getData()
        this.$emit('deleteItem', item)
      },

      // 根据配置获取颜色
      getColor(n) {
        if (!n) return {}
        if (n == 'up') {
          return {
            color: this.$store.state.sport.sjcolor.up
          }
        } else {
          return {
            color: this.$store.state.sport.sjcolor.down
          }
        }
      },

      // 保留投注项
      saveTouZhu() {
        this.status = 0
        this.cgStatus = 0
        this.list.forEach(item => {
          item.resid = ''
          item.st = ''
          item.tz = ''
          item.maxTzStatus = 0
        })
        this.sosList.forEach(item => {
          item.resid = ''
          item.st = ''
          item.tz = ''
          item.maxTzStatus = 0
        })

      },


      // 点击计算器数字
      clickCalcItem(n) {
        if (n == 0 && !this.tzItem.tz) {
          return
        }
        let num = this.tzItem.tz
        if (n < 10) {
          num += n
        } else {
          num = Number(num) + Number(n)
        }
        if (num > this.tzItem.smax) {
          num = this.tzItem.smax
        }
        this.tzItem.tz = num
        this.tzItem.maxTzStatus = 2
        this.list.forEach((item,i) => {
          if (item.matchId == this.tzItem.matchId && item.op.nm == this.tzItem.op.nm) {
            this.$set(this.list, i, this.tzItem)
            
          }
        })
        this.sosList.forEach((item,i) => {
          if (item.sodd == this.tzItem.sodd) {
            this.$set(this.sosList, i, this.tzItem)
          }
        })
      },

      handleShan() {
        this.shanTimer = setInterval(() => {
          this.shan = !this.shan
        }, 800)
      },

       // 计算器点击返回
      clickCalcBack() { 
        this.tzItem.tz = (this.tzItem.tz + '').slice(0, -1)
        if (!this.tzItem.tz) {
          this.tzItem.maxTzStatus = 0
        }
        this.list.forEach((item,i) => {
          if (item.matchId == this.tzItem.matchId) {
            this.$set(this.list, i, this.tzItem)
          }
        })
        this.sosList.forEach((item,i) => {
          if (item.sodd == this.tzItem.sodd) {
            this.$set(this.sosList, i, this.tzItem)
          }
        })
      },

      // 显示计算器
      handleShowCalc(item, str) {
        console.log(item);
        
				if ((!item.matchId || str >= 3) && this.reslist.length > 3 ) {
					this.moveTop = true
				}
        document.removeEventListener('click', fn, true)
        if (item.ss == -1) {
          return
        }
        if (str == undefined && this.same) {
          return
        }
        this.calcShow = true
        this.tzItem = item
				// console.log(this.tzItem, '--=')
        this.shan = true
        var fn = (e) => {
          try {
            let calc = document.getElementById('calc')
						// console.log(calc)
            if (!calc.contains(e.target) || e.target.className.includes('ipt')) {
              this.calcShow = false
							this.moveTop = false
              this.tzItem = null
              document.removeEventListener('click', fn, true)
            }
          } catch (e) {
            console.log(e)
          }
        }
        document.addEventListener('click', fn, true)
      },

      // 对比大小 设置升降的class
      comparedBigSmall(lastList, list) {
        if (lastList.length != list.length) {
          return list
        }
        list.forEach((item, i) => {
          if (item.ss != -1 && lastList[i]) {

            let od = lastList[i]?.op.od
            item.loading = false
            if (item.op.od < od) {
              item.className = 'down'
            }
            if (item.op.od > od) {
              item.className = 'up'
            }
            // 赔率变化
            if (item.op.bod != lastList[i].op.bod) {
              item.bodchange = true
            } else {
              item.bodchange = false
            }
          }
        })
        return list
      },
    }
  }
</script>

<style lang="less" scoped>
@import '@/style/global.less';
// .bg15212D {
//   background: #304b62 !important;
// }
.height {
  height: 100dvh;
}
.fixed {
	position:fixed;
	bottom:0;
	left:0;
	right:0;
	background: #15212D !important;
	padding-bottom: 6px;
}
::v-deep .start-loading {
  margin: 0;
  height: 165px;
  background: none;
}
.bottompopup {
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
	// padding-bottom: 50px;
	
	
  .shugang {
    display: inline-block;
    width: 4px;
    height: 16px;
    border-radius: 0 3px 3px 0;
    background-color: #3e87f6;
    margin-right: 6px;
  }

  .ipt {
    width: 200px;
    margin-right: 10px;
    height: 38px;
    background: #304b62;
    border-radius: 4px;
    input, div {
      pointer-events: none;
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 14px;
      padding-left: 10px;
      background: none;
      border: none;
      font-weight: 800;
      // @keyframes shan {
      //   50% { opacity: 0; }
      // }
      .shan {
        margin: 0 0 1px 1px;
      }
    }
    // .show2 {
    //   display: none;
    // }

    // &.active {
    //   border: 1px solid #ffffff10;
    //   .show1 {
    //     display: none;
    //   }
    //   .show2 {
    //     display: flex;
    //   }
    // }
  }

  .head {
    position: relative;
    height: 44px;
    // background: #192A39 !important;
    background: #193045 !important;
		z-index: 9999;
    // border-top: 1px solid #ffffff30;
    &>div {
      height: 100%;
    }
    @keyframes rotate {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .refresh {
      animation: rotate 1s linear infinite;
      animation-play-state: paused;
    }
    
    .delete {
      width: 100%;
      height: 100%;
      transition: .3s;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      // right: 0;
      // bottom: 0;
      &>div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .cross {
        width: 54px;
      }
    }
  }
	
  ::v-deep .list {
		.move {
			transform: translateY(-140px);
		}
    flex: 1;
    overflow: auto;
		transition:all .3s;
    .item {
      padding: 11px 15px;
      border-bottom: 1px solid #325371;
      text-align: left;
      &:last-child {
        border-bottom: none;
      }
    }

    .tz-res {
      height: 80px;
      border-top: 1px solid #325371;
      padding: 15px;
      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .chuanzi-info {
    background: #ffffff0a;
    border-top: 1px solid #325371;
    &>div {
      padding: 15px;
      height: 86px;
      &:first-child {
        border-bottom: 1px solid #15212d;
      }
    }
  }

  

  .removeclose {
    background: rgba(0,0,0,0.2);
    border-radius: 2px;
    margin: 4px 10px;
    padding: 2px 0;
    font-size: 13px;
  }

  .calc {
    padding: 6px 10px 5px;
		// border-top: 1px solid #ffffff10;

    .grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      /* 四列，每列平均分配剩余空间 */
      gap: 0;
      color: #fff;
      font-size: 16px;

      /* 格子之间的间距 */
      .item {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
				font-weight: 600;
        // background: #192A39;
        background: #19304599;
        opacity: 1;
				

        svg {
          width: 20px;
          height: 20px;
          padding-top: 2px;
        }
      }
    }
  }

  .footer-res {
    padding: 0 10px;
    div {
      width: 100%;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }
  }

  .footer-btn {
    height: 48px;
    gap: 3px;
    &>div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      // border-radius: 6px;
    }
    .svg {
      width: 50px !important;
      flex: 0 1 50px;
    }
  }

  .peilv {
    text-align: left;
    .setting {
      padding: 20px 15px 30px;
      background: #15212D;
      p {
        margin-bottom: 20px;
      }
    }
  }
}
</style>