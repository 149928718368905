<template>
  <div class="details" :class="{ 'heightOut': isApp() }">

    <!-- <div class="topNewOut">
      <div class="topNewTitle">
      <div class="new-title">促销活动</div>
      <div class="rightImg"><img src="@/assets/home/group-banner-default.webp" /></div>
    </div>
    </div> -->

    <div class="promo_top" v-if="false">
      <div class="number_container">
        <div class="num_module">
          <p class="top_label">本月总派发优惠金额</p>
          <div class="amount_num_scroll">
            <VueCountUp :start-val="0" :end-val="targetNumber" :duration="2"
              :options="{ useEasing: true, useGrouping: true, separator: ',' }" />
            <!-- <span>104,982,341</span> -->
            <span class="num_label">CN</span>
          </div>
          <div class="line_box">
            <!-- <img src="../../assets/details/gif1.gif" alt="" /> -->
          </div>
          <p class="bottom_label">每24小时更新</p>
        </div>

        <div class="num_module">
          <p class="top_label">本月总参与优惠人数</p>
          <div class="amount_num_scroll">
            <!-- <span>104,982,341</span> -->
            <VueCountUp :start-val="0" :end-val="targetPopNumber" :duration="2"
              :options="{ useEasing: true, useGrouping: true, separator: ',' }" />
            <span class="num_label">人</span>
          </div>
          <div class="line_box gif4">
            <!-- <img src="../../assets/details/gif2.gif" alt="" /> -->
          </div>
          <p class="bottom_label">每24小时更新</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="tabs">
        <div class="wrapper">
          <div class="item" v-for="(item, i) in tabsList" :key="i" :class="{ active: tabs == item.id }"
            @click="changeTabs(item.id)">
            <img :src="require(`@/assets/activity/${tabs == item.id ? 'bai' : 'hui'}${i}.png`)" alt="" />
            <!-- <img :src="item.icon" alt="" /> -->
            <!-- <van-icon
              :name="item.icon"
              :color="tabs == i ? '#fff' : '#B3B9D2'"
            /> -->
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>

      <!-- <div class="promo_lis">
        <div
          class="promo_item"
          @click="goActivity"
          v-for="(item, i) in 4"
          :key="i"
        >
          <div class="van-image">
            <img alt="" class="van-image__img" src="" />
          </div>
          <div class="count_time">长期有效</div>
        </div>
      </div> -->
      <div class="list" v-for="(item, index) in list" :key="index" @click="goActivity(item)">
        <img :src="item.h5Img" class="allImg" />
        <!-- <div class="listList">
          <img :src="item.h5Img" />
        </div>
        <div class="lestRight">
          <div class="listTitle">{{ item.title }}</div>
          <div class="listTips">{{ item.explain }}</div>

          <div class="listBom">
            <div class="ljcy">立即参与</div>
            <div
              v-if="
                item.id == 19 ||
                (item.activityStartTime && !item.activityEndTime)
              "
            >
              活动时间: 长期
            </div>
            <div v-else>
              活动时间: {{ item.activityStartTime | formattedDate }}至{{
                item.activityEndTime | formattedDateEnd
              }}
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import VueCountUp from "vue-countup-v2";
import { activityList } from "@/request/api.js";
export default {
  name: "detailsIndex",
  components: {
    VueCountUp,
  },
  data() {
    return {
      previousRoute: "",
      tabs: 1,
      tabsList: [
        // {
        //   name: "返水",
        //   icon: "label-o",
        // },
        // {
        //   name: "体育",
        //   icon: "filter-o",
        // },
        // {
        //   name: "限时",
        //   icon: "clock-o",
        // },
        // {
        //   name: "体育",
        //   icon: "filter-o",
        // },
        // {
        //   name: "真人",
        //   icon: "user-o",
        // },
        // {
        //   name: "电子",
        //   icon: "diamond-o",
        // },
        // {
        //   name: "捕鱼",
        //   icon: "diamond-o",
        // },
        // {
        //   name: "棋牌",
        //   icon: "diamond-o",
        // },

        // {
        //   name: "电竞",
        //   icon: "medal-o",
        // },
        // {
        //   name: "彩票",
        //   icon: "medal-o",
        // },
        // {
        //   name: "区块链",
        //   icon: "medal-o",
        // },
        // {
        //   name: "其他",
        //   icon: "label-o",
        // },
      ],
      list: [],
      dataList: [],
      targetNumber: 104982341,
      targetPopNumber: 11484,
    };
  },
  mounted() {
    this.getActivityList();
    // 初始化值
    var initialValue = 0;
    // 获取当前日期
    const today = new Date();
    // 获取当前日期的年份和月份
    // const currentYear = today.getFullYear();
    // const currentMonth = today.getMonth(); // 月份从0开始，即0为1月，11为12月

    // 获取当前日期的第几天
    const dayOfMonth = today.getDate();
    // 计算从本月的1号到今天已经过去的天数
    // const daysPassed = dayOfMonth - 1;
    if (localStorage.getItem("timeMoney")) {
      var sqlTime = JSON.parse(localStorage.getItem("timeMoney"));
      if (sqlTime.time == dayOfMonth) {
        initialValue = sqlTime.moneys;
      } else {
        initialValue =
          Math.floor(Math.random() * (3000000 - 2000000 + 1)) + 2000000;
      }
    } else {
      initialValue =
        Math.floor(Math.random() * (3000000 - 2000000 + 1)) + 2000000;
    }
    // 计算当前值
    // const currentValue = initialValue + (daysPassed * 50000);
    localStorage.setItem(
      "timeMoney",
      JSON.stringify({ time: dayOfMonth, moneys: initialValue })
    );
    this.targetNumber = initialValue * dayOfMonth;

    //人数计算
    if (localStorage.getItem("timePhoto")) {
      var sqlPhoto = JSON.parse(localStorage.getItem("timePhoto"));
      if (sqlPhoto.time == dayOfMonth) {
        this.targetPopNumber = sqlPhoto.photo;
      } else {
        if (sqlPhoto.time < dayOfMonth) {
          this.targetPopNumber =
            sqlPhoto.photo +
            (Math.floor(Math.random() * (200 - 100 + 1)) + 100) *
            (dayOfMonth - sqlPhoto.time);
        } else {
          this.targetPopNumber =
            (Math.floor(Math.random() * (200 - 100 + 1)) + 100) * dayOfMonth +
            10000;
        }
      }
    } else {
      //初始化人数
      const initPhoto = 10000;
      this.targetPopNumber =
        (Math.floor(Math.random() * (200 - 100 + 1)) + 100) * dayOfMonth +
        initPhoto;
    }
    localStorage.setItem(
      "timePhoto",
      JSON.stringify({ time: dayOfMonth, photo: this.targetPopNumber })
    );
  },
  methods: {
    ...mapActions(['setLoginShow','setRegisterShow']),
    isApp() {
      var origin = window.location.origin
      return !origin.includes("app")
    },
   
    changeTabs(i) {
      this.tabs = i;
      // if (i == 0) {
      //   this.list = this.dataList;
      //   return;
      // }
      this.list = [];
      // if (i == 1) {
      //   this.list = this.dataList;
      //   return;
      // }
      for (var j = 0; j < this.dataList.length; j++) {
        if (this.dataList[j].typeId == i) {
          this.list.push(this.dataList[j]);
        }
        // switch (i) {
        //   case 0:
        //     if (this.dataList[j].tag == "返水") {
        //       this.list.push(this.dataList[j]);
        //     }
        //     break;
        //   case 1:
        //     //长期
        //     if (this.dataList[j].tag == "长期") {
        //       this.list.push(this.dataList[j]);
        //     }
        //     break;
        //   case 2:
        //     //长期
        //     if (this.dataList[j].tag == "限时") {
        //       this.list.push(this.dataList[j]);
        //     }
        //     break;
        //   default:
        //     if (this.dataList[j].typeId == i - 1) {
        //       this.list.push(this.dataList[j]);
        //     }
        //     break;
        // }
      }
    },
    goActivity(item) {
      if(!localStorage.getItem('token')){
        if(localStorage.getItem('loginStatus')){
          this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setLoginShow(true)
          return;
        }else{
          this.$notify({
            message: "请注册后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setRegisterShow(true)
        return;
        }
      }
      if (item.code == "SIGN") {
        localStorage.setItem("detailTabs", this.tabs);
        this.$router.push("/activity");
      } else {
        var times = "";
        if (item.activityStartTime && !item.activityEndTime) {
          times = "长期";
        } else {
          times = `${this.formattedDates(
            item.activityStartTime
          )} 至 ${this.formattedDateEnds(item.activityEndTime)}`;
        }
        
        if (item.code == "zhizhen") {
          localStorage.setItem("detailTabs", this.tabs);
          this.$router.push({
            path: "/perfectionActivity",
            query: { code: item.code, time: times, id: item.id },
          });
        } else if (item.code == "EBPayDeposit" || item.code == 'EBPayWithdraw' || item.code == 'revive') {
          localStorage.setItem("detailTabs", this.tabs);
            this.$router.push({
              path: "/ebpayActivity",
              query: { code: item.code, time: times, id: item.id },
            });
        } else if (item.code == "xrlb3" || item.code == 'xrlb4') {
          localStorage.setItem("detailTabs", this.tabs);
            this.$router.push({
              path: "/newActivity2",
              query: { code: item.code, time: times, id: item.id },
            });
        } else if (item.code == "DZP" || item.code=='dzp') {
          localStorage.setItem("detailTabs", this.tabs);
            this.$router.push({
              path: "/turntableActivity",
              query: { code: item.code, time: times, id: item.id },
            });
        } else
          if (item.code == "xrlb1" || item.code == "xrlb2") {
            localStorage.setItem("detailTabs", this.tabs);
            this.$router.push({
              path: "/newActivity",
              query: { code: item.code, time: times, id: item.id },
            });
          }
          else if(item.code == 'mgGame'){
           
            window.open('https://mgr.basebit.net/#/static/exclusive-jackpot/zh-cn', "_blank");
          }

          else {
            localStorage.setItem("detailTabs", this.tabs);
            this.$router.push({
              path: "/sportsActivity",
              query: { code: item.code, time: times, id: item.id },
            });
          }
      }
    },
    getActivityList() {
      this.$store.commit("setLoadingShowTrue");
      // var that = this;
     
      activityList().then((res) => {
       
        if (res.code == 0) {
          
          for (var i = 0; i < res.data.categories.length; i++) {
            if (res.data.categories[i].name != "不显示到分类") {
              this.tabsList.push(res.data.categories[i]);
            }
          }
          this.tabs = res.data.categories[0].id;
          this.dataList = res.data.records;

          if (localStorage.getItem("detailTabs")) {
            this.tabs = Number(localStorage.getItem("detailTabs"));
            localStorage.removeItem("detailTabs");
          }

          // if (this.tabs == 1) {
          //   this.list = res.data.records;
          // } else {
            for (var j = 0; j < res.data.records.length; j++) {
              if (res.data.records[j].typeId == this.tabs) {
                this.list.push(res.data.records[j])
              }
            }
          // }
            setTimeout(()=>{
               this.$store.commit("setLoadingShowFalse");
            },2000)
          if (
            this.previousRoute != "/newActivity" && this.previousRoute != "/sportsActivity" &&
            this.previousRoute != "/activity" &&
            this.previousRoute != "/perfectionActivity"
          ) {
            localStorage.removeItem("scrollPosition");
            return;
          }
          var savedPosition = localStorage.getItem("scrollPosition");
          if (savedPosition != null) {
            this.$nextTick(() => {
              window.scrollTo({
                top: parseInt(savedPosition, 10),
                behavior: "smooth",
              });
              // 可选：清除保存的位置
              localStorage.removeItem("scrollPosition");
            });
          }
       
        }
        
          //
          
       
      });
    },
    formattedDates(time) {
      if (!time) {
        return;
      }
      // 将日期字符串转换为日期对象
      const date = new Date(time.replace(/-/g, "/")); // 需要将 - 替换为 /，因为在某些浏览器中日期字符串可能不支持 -

      // 获取月份和日期并格式化
      const month = date.getMonth() + 1; // 注意getMonth()返回的是0-11，所以要加1
      const day = date.getDate();

      // 返回格式化后的字符串
      return `${month}月${day}日`;
    },
    formattedDateEnds(time) {
      if (!time) {
        return "长期";
      }
      // 将日期字符串转换为日期对象
      const date = new Date(time.replace(/-/g, "/")); // 需要将 - 替换为 /，因为在某些浏览器中日期字符串可能不支持 -

      // 获取月份和日期并格式化
      const month = date.getMonth() + 1; // 注意getMonth()返回的是0-11，所以要加1
      const day = date.getDate();

      // 返回格式化后的字符串
      return `${month}月${day}日`;
    },
  },
  filters: {
    formattedDate(time) {
      if (!time) {
        return;
      }
      // 将日期字符串转换为日期对象
      const date = new Date(time.replace(/-/g, "/")); // 需要将 - 替换为 /，因为在某些浏览器中日期字符串可能不支持 -

      // 获取月份和日期并格式化
      const month = date.getMonth() + 1; // 注意getMonth()返回的是0-11，所以要加1
      const day = date.getDate();

      // 返回格式化后的字符串
      return `${month}月${day}日`;
    },
    formattedDateEnd(time) {
      if (!time) {
        return "长期";
      }
      // 将日期字符串转换为日期对象
      const date = new Date(time.replace(/-/g, "/")); // 需要将 - 替换为 /，因为在某些浏览器中日期字符串可能不支持 -

      // 获取月份和日期并格式化
      const month = date.getMonth() + 1; // 注意getMonth()返回的是0-11，所以要加1
      const day = date.getDate();

      // 返回格式化后的字符串
      return `${month}月${day}日`;
    },
  },
  beforeRouteEnter(to, from, next) {
    // 在进入当前路由之前，获取来源路由信息
    next((vm) => {
      // 这里的 `vm` 是指当前组件实例
      vm.previousRoute = from.path;
    });
  },
  beforeRouteLeave(to, from, next) {
    // 记录当前页面的滚动位置
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    localStorage.setItem("scrollPosition", scrollPosition);
    next();
  },
};
</script>
<style scoped lang="less">
@import '@/style/global.less';
div,
span {
  /* box-sizing: border-box; */
}

::-webkit-scrollbar {
  /* width: 3px; */
  display: none;
  /* 设置滚动条的宽度 */
}

.flex {
  display: flex;
  align-items: center;
}

.details {
  padding-top: 20px;
  padding-bottom: 20px;
  background: @allBj;
  // min-height: 100dvh;
  height: calc(100dvh - 48px - 48px - 71px);
  overflow-y: auto;

  .topNewOut {
    width: 100%;
    background: rgb(33, 55, 67);

    .topNewTitle {
      margin: 0 auto;
      width: 92%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .new-title {
        font-size: 20px;
        color: #ffffff;
        font-family: PingFang SC;
        font-weight: bold;
      }

      .rightImg {
        display: flex;
        align-items: center;
        justify-content: center;

        img {

          height: 80px;
        }
      }
    }
  }

  .promo_top {
    width: 92%;
    height: 85px;
    // background: url('../../assets/details/h5-bg.png') no-repeat;
    // background-size: 100% 100%;
    margin: 24px auto 0 auto;
    // padding: 13px 10px;
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    border: 1px solid rgba(155, 155, 186, 0.04);
    background: #283643;
    display: flex;
    align-items: center;
    justify-content: center;

    .number_container {
      display: flex;
      flex-direction: row;
      // background: url('../../assets/details/download.png') no-repeat;
      background-size: 100% 100%;
      //   height: 1.46667rem;
      background-size: cover;
      //   border-radius: 0.21333rem;
      justify-content: center;
      gap: 9px;

      .num_module {
        position: relative;
        width: 45.797%;
        height: 48px;
        background-size: 100% 100%;

        line-height: 1.2;
        text-align: center;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.09);

        .top_label {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          font-size: 0.26667rem;
          width: auto;
          padding: 0 0.08rem;
          top: -8px;
          font-weight: 600;
          min-width: 3.33333rem;
          color: #fff;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          /* 15px */
        }

        .amount_num_scroll {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          text-align: center;
          font-family: DIN;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          /* 24px */
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .num_label {
            margin-left: 0.13333rem;
            font-size: 16px;
            color: #fff;
          }
        }

        .line_box {
          width: 4.45333rem;
          height: 1.17333rem;
          //   background: url(../../assets/details/gif3.png) no-repeat;
          background-size: 100% 100%;
          padding: 0.05333rem 0.08rem;
          border-radius: 0.21333rem;

          img {
            width: 102%;
            height: 100%;
            border-radius: 0.21333rem;
            position: relative;
            left: -0.02667rem;
          }

          &.gif4 {
            // background: url(../../assets/details/gif4.png) no-repeat;
            background-size: 100% 100%;
            position: relative;
            left: -0.05333rem;
          }
        }

        .bottom_label {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          font-size: 0.26667rem;
          width: auto;
          padding: 0 0.08rem;
          bottom: -7px;
          min-width: 2.45333rem;
          color: rgba(255, 255, 255, 0.65);
          text-align: center;
          font-family: "PingFang SC";
          font-size: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          /* 12px */
        }
      }
    }
  }

  .content {
    position: relative;
    // margin-top: 20px;

    .tabs {
      width: 92%;
      margin: 0 auto 0 auto;

      .wrapper {
        width: 100%;
        height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background: radial-gradient(114.24% 141.42% at 0% 0%, rgba(155, 155, 186, 0.16) 31.69%, rgba(155, 155, 186, 0.04) 100%);

        padding: 0px 10px;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        .item {
      
          border-radius: 19px;
          display: flex;
          height: 38px;
          padding: 4px 10px;
          justify-content: center;
          align-items: center;
          gap: 5px;
          //   font-family: PingFang SC;
          //   font-size: 0.37333rem;
          //   font-style: normal;
          //   font-weight: 400;
          //   line-height: 120%;
          flex-shrink: 0;
          //   color: #fff;
          color: #B1BAD3;

          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          min-width: 25%;

          img {
            width: 16px;
            height: 16px;
          }

          &.active {
            // color: #f3b867;
            // font-family: PingFang SC;
            // font-size: 0.37333rem;
            // font-style: normal;
            // font-weight: 600;
            // line-height: 120%;

            display: flex;
            height: 38px;
            padding: 4px 10px;
            align-items: center;
            gap: 5px;
            border-radius: 19px;
            // background: #364452;
            color: #FFF;

            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }

    .promo_lis {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0 0.42667rem;

      .promo_item {
        position: relative;
        width: 48.5%;
        height: 5.52rem;
        margin-bottom: 0.21333rem;
        border-radius: 0.21333rem;

        &:nth-child(odd) {
          margin-right: 0.21333rem;
        }

        .van-image {
          width: 100%;
          height: 100%;
          position: relative;
          display: inline-block;

          .van-image__img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 0.21333rem;
          }
        }

        .count_time {
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 0;
          padding: 0.05333rem 0.10667rem;
          color: #fff;
          font-size: 0.32rem;
        }
      }
    }

    .list {
      display: flex;
      width: 92%;

      margin: 15px auto 0 auto;
      align-items: center;
      gap: 10px;

      // padding: 10px;
      // border-radius: 12px;
      // border: 1px solid rgba(155, 155, 186, 0.04);
      // background: #283643;
      .allImg {
        width: 100%;

      }

      .listList {
        width: 83px;
        height: 103px;
        flex-shrink: 0;
        border-radius: 12px;

        img {
          border-radius: 12px;
          width: 100%;
          height: 100%;
        }
      }

      .lestRight {
        text-align: left;

        .listTitle {
          color: #fff;
          text-overflow: ellipsis;
          font-family: "PingFang SC";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          /* 30px */
        }

        .listTips {
          color: #fff;
          text-overflow: ellipsis;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          /* 18px */
          margin: 5px auto;
        }

        .listBom {
          display: flex;
          align-items: center;
          color: rgba(255, 255, 255, 0.65);
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;

          padding-bottom: 8px;

          .ljcy {
            display: flex;
            height: 32px;
            padding: 0px 15px;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 0.5px solid #fff;
            color: #fff;
            font-family: "PingFang SC";
            font-size: 14px;

            font-weight: 400;
            margin-right: 10px;
            white-space: nowrap;
            margin-bottom: 3px;
          }
        }
      }
    }
  }
}

.heightOut {
  height: calc(100dvh - 48px - 48px - 60px) !important;
}
</style>
