<template>
	<div class='sport' :style="$store.getters['sport/$theme']">
		<div class="sport-loader" style="height: 100vh;" v-if='!maintainTime && !typeList.length'>
				<div class="yuan"><img src="../../assets/home/home-logo.webp"/></div>
			</div>
		<div v-else-if='!maintainTime'>
			<!-- 左侧配置 -->
			<van-popup v-model="leftshow" position="right" :style="{ height: '100%', background: '#193045' }">
				<leftSlider 
					@close="leftshow = false"
					@openRight="goRight"
					@clear="onLoad(true)"
				/>
			</van-popup>

			<!-- 右侧 -->
			<van-popup v-model="rightshow" position="right" duration="0" :style="{ height: '100%', width: '100%' }">
				<rightSlider v-if="rightshow" @close="rightshow = false" @saiguo="handleSaiguoShow" />
			</van-popup>

			<!-- 底部 @close="bottomShow1"-->
			<!-- boxShadow: '-1px 6px 10px 2px #fff', -->
			<!-- borderTop: '1px solid #3d3d3d', -->
			<van-popup v-model="bottomShow" :overlay='false' position="bottom" :style="{ zIndex: '99999 !important' }">
				<BottomPopup 
					v-if="bottomShow" 
					:typeList="typeList" 
					:orderSelectObj="orderSelectObj"
					@close="bottomShow = false"
					@deleteItem="handleDeleteOrderItem"
					@orderSuccess="handleOrderSuccess" 
				/>
			</van-popup>
			<van-popup v-model="touzhuShow" position="bottom" round :style="{ maxHeight: '90%' }">
				<TouzhuPopup 
					v-if='touzhuShow' 
					:orderIdList="orderIdList" 
					:typeList="typeList" 
					:list="orderSuccessList" 
					:cgList="cgOrderSuccessList" 
					@close="sureOrderSuccess" 
					@baoliu="sureOrderBaoliu" 
				/>
			</van-popup>

			<!-- 筛选 -->
			<van-popup v-model="filterShow" position="left" round
				:style="{ height: '100%', width: '100%', backgroundColor: 'transparent' }">
				<FilterData @close="handleFilterShow" :filterList='filterList' :filterData='filterData' />
			</van-popup>

			<!-- 详情 -->
			<van-popup v-model="detailsShow" position="center" round
				:style="{ height: '100%', borderRadius: 0, width: '100%', backgroundColor: 'transparent' }">
				<Details v-if="detailsShow" @close="detailsShow = false" :detailsItem='detailsItem' @show="rightshow = true; bottomShow = false"
					:orderSelectObj="orderSelectObj" @downOrder='handleDownOrder' :type="typeList[typeActive].ty" />
			</van-popup>
			
			<!-- 赛果 -->
			<van-popup v-model="saiguoShow" position="right" round
				:style="{ height: '100%', borderRadius: 0, width: '100%', backgroundColor: 'transparent' }">
				<Saiguo v-if="saiguoShow" @close="saiguoShow=false" @show="saiguoShow=false;rightshow=true" :item="saiguoItem"/>
			</van-popup>

			<!-- 搜索 -->
			<van-popup v-model="searchShow" position="rigcenterht" round
				:style="{ height: '100%', borderRadius: 0, width: '100%', backgroundColor: 'transparent' }">
				<Search v-if="searchShow" @close="searchShow=false" @showDetails="searchShowDetails"/>
			</van-popup>

			<!-- 头部 -->
			<!-- <div class='header' :style="{ zIndex: headerZIndex }">
				<sHeader @rightshow="rightshow = true" @leftshow="leftshow = true" />
			</div> -->

			<div class='container'>
				<!-- <div class="sticky"> -->
				<!-- 顶部第一级tabs -->
				<div class='typelist' :style='{zIndex: delayLeftShow}'>
					<ul class="scroll-tabs pan-x">
						<li v-for="(item, i) in typeList" :key="i" :class="{ active: i == typeActive }"
							@click='handleTypeList(item, i)'>
							<img v-if='item.img' :src="item.img" alt="">
							<span class="name">{{ item.des }}</span>
							<span class="num">{{ item.tc }}</span>
						</li>
					</ul>
					<div class='fixed flex' @click='handleOpenLeftSlider'>
						<!-- <img v-if='userInfo.username' src="@/assets/personal/userImg.png" alt=""> -->
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_3200_1916)">
							<path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#B3B9D2"/>
							<path d="M12.0002 14.4469C14.7027 14.4469 16.8935 12.2561 16.8935 9.55357C16.8935 6.85108 14.7027 4.66028 12.0002 4.66028C9.29774 4.66028 7.10693 6.85108 7.10693 9.55357C7.10693 12.2561 9.29774 14.4469 12.0002 14.4469Z" fill="#202C37"/>
							<path d="M12.0001 16.7768C12.0001 16.7768 10.4769 16.9672 7.95562 15.2826C7.39828 14.9104 6.6935 14.8336 6.06365 15.0617L5.84081 15.1425C5.01616 15.4412 4.35779 16.0773 4.03248 16.8918C3.78112 17.5207 3.95368 18.2419 4.468 18.6823C5.71803 19.7525 7.77048 21.2041 12.0006 21.2041C15.7149 21.2041 18.1173 19.564 19.4824 18.4851C19.8846 18.1675 20.0286 17.6237 19.8382 17.148L19.7826 17.0083C19.4258 16.1165 18.7186 15.4103 17.8263 15.0555C17.2806 14.8384 16.6565 14.9269 16.2021 15.2986C14.1652 16.9658 12.4206 16.7768 12.0011 16.7768H12.0001Z" fill="#202C37"/>
							</g>
							<defs>
							<clipPath id="clip0_3200_1916">
							<rect width="24" height="24" fill="white"/>
							</clipPath>
							</defs>
						</svg>
						
					</div>
				</div>
			
				<!-- 第二级 第一级tabs的子项 -->
				<!-- :style='{height: typeActive!=3 ? "72px" : "30px", top:typeActive!=3 ? "8px":"53px" }' -->
				<div class="child-typelist" v-if="childTypeList.length">
					<ul class="scroll-tabs pan-x" v-if="childTypeList.length && typeList[typeActive]?.des != '热门'">
						<li v-for="(item, i) in childTypeList" :key="i" :class="{ active: i == childTypeActive }"
							@click='handleChildTypeList(item, i)'>
							<div class="img">
								<img v-if="requestImg(item.icon)" :src="requestImg(item.icon)" alt="">
								<i v-else :class='[`icon-${item.icon}`]' class="iconfont"> </i>
								<!-- <span class="num">{{ item.c }}</span> -->
							</div>
							<div class="txt">
								<span class="name">{{ item.name }}</span>
							</div>
							<!-- <img class="bottom" v-show="i == childTypeActive" src="../../assets/sport/childtype-bg.png" alt=""> -->
						</li>
					</ul>
					<ul class="scroll-tabs pan-x" v-else>
						<!-- style='padding-top: 0' -->
						<li v-for="(item, i) in childTypeList" :key="i" :class="{ active: i == childTypeActive }"
							@click='handleChildTypeList(item, i)'>
							<div class="img bg">
								<img :src="item.lurl" alt="">
							</div>
							<div class="txt">
								<span class="name">{{ item.na }}{{ item.mt }}</span>
							</div>
							<!-- <img class="bottom" v-show="i == childTypeActive" src="../../assets/sport/childtype-bg.png" alt=""> -->
						</li>
					</ul>
				
				</div>
				<!-- 第三级时间筛选tabs -->
				<div class="time-list pan-x" v-if='typeList[typeActive]?.ty == 4'>
					<ul class="scroll-tabs pan-x">
						<li v-for="(item, i) in threeTimeList" :key="i" :class="{ active: i == threeTimeActive }"
							@click="changeThreeTimeActive(item, i)">
							{{ item.time }}
							<span v-if='item.c'>({{ item.c }})</span>
						</li>
					</ul>
				</div>
				
				<!-- 搜索一行 -->
				<div class="search-box">	
					
					<div class="search flex">
						<span @click="searchShow = true" class="search-icon">
							<img src="@/assets/sport/sousuo.png" alt="">
						</span>
						<div class="left flex">
							<span :class="{ active: !eventSort }" @click="changeOrder(0)">时间</span>
							<span v-if="!eventSort" @click="changeOrder(1)">
								<img src="@/assets/sport/qiehuan2.png">
							</span>
							<span v-else @click="changeOrder(0)">
								<img src="@/assets/sport/qiehuan2.png" style="transform: rotate(180deg)" >
							</span>
							<span :class="{ active: eventSort }" @click="changeOrder(1)">联赛</span>
						</div>
						
						<!-- <span>搜索联赛或队名</span> -->
					</div>
					<div class="filter">
						<span @click="handleFilterData">
							<!-- 赛事筛选 -->
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5 6L10.6 12.6252V17.5993L13.4 19V12.6252L19 6H5Z" stroke="#B3B9D2" stroke-width="1.3" stroke-linejoin="round"/>
							</svg>

							<!-- <van-icon name="filter-o" size='20' color="#b3b9d2"/> -->
						</span>
						<span @click='handleEventAllShow'>
							展开
							<svg :class="{ rotate: !eventAllOpen }" width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 4.6188L-5.96046e-08 9.2376V0L8 4.6188Z" fill="#B3B9D2"/>
							</svg>

							<!-- <img :class="{ rotate: !eventAllOpen }" src="@/assets/sport/top.svg" alt=""> -->
						</span>
					</div>
				</div>


				<van-skeleton :loading="loading">
					<!-- 等于7 代表是冠军界面 -->
					<div v-if="typeList[typeActive]?.ty != 7">
						<div class="pull-content" v-if='eventList.length'>
							<van-list
								v-model="resloading"
								:finished="finished"
								:finished-text=' !eventAllOpen ? "" : "没有更多了"'
								@load="onLoad"
								offset='100'
							>
								<van-cell class="ul"  v-for="(attr, a) in eventList" :key="a">
								<!-- <div v-for="(attr, a) in eventList" :key="a"> -->
									<!-- filterList 里面有的不进行显示 -->
									<div class="league" v-if='!filterList.includes(attr.name.split("-")[0])'>
										<div class="league-title" @click="attr.open = !attr.open">
											<div class="flex">
												<span class='img'>
													<img :src="attr.lurl || require('@/assets/sport/notimg.png')" alt="">
												</span>
												<span class="title" v-if='typeList[typeActive]?.ty != 5'>{{ attr.name }}</span>
												<span class="title" v-else>{{ childTypeList[childTypeActive].na }}</span>
											</div>
											<div class="icon">
												<!-- v-show="!attr.open" -->
												<van-icon name="arrow-down" :style="{transform: attr.open ? '' : 'rotate(180deg)'}" />
											</div>
										</div>
										<div class='border' :style="{ height: attr.open ? 'auto' : '0', borderBottom: attr.open ? 'none' : '1px solid @bg-color'  }" v-for="(item, i) in attr.list"
											:key="i">
											<div class="toggle">
												<div class="info">
													<div class="fenshu">
														<div class="changshu flex">
															<svg t="1715073443137" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1485" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" class="icon  mr4"><path d="M0 512A512 512 0 0 1 512 0 512 512 0 0 1 1024 512 512 512 0 0 1 512 1024 512 512 0 0 1 0 512z m81.92 0A430.08 430.08 0 0 0 512 942.08 430.08 430.08 0 0 0 942.08 512 430.08 430.08 0 0 0 512 81.92 430.08 430.08 0 0 0 81.92 512z m587.20256 138.6496l-177.3568-102.4a40.96 40.96 0 0 1-18.55488-22.97856 41.5744 41.5744 0 0 1-2.17088-13.27104v-286.72a40.96 40.96 0 0 1 40.96-40.96 40.96 40.96 0 0 1 40.96 40.96v263.70048l157.12256 90.7264a40.96 40.96 0 0 1 14.99136 55.95136 40.96 40.96 0 0 1-35.51232 20.48 40.71424 40.71424 0 0 1-20.43904-5.48864z" p-id="1486" fill="#DD5353"></path></svg>
															<div class="name" style='font-size:12px;color: #007fff;'>
				
																<p  v-if="item.ms==5" >
																	<!-- B3B9D2 -->
																	<span v-if='item?.mc?.pe' style='font-weight: bold;'>{{ codeToName(item.mc.pe) }}</span>
																	<span v-if='item?.mc?.s'>{{ secondsToHMS(item.mc.s) }}</span>
																	<span v-if='item?.mc?.itd'>+{{ item.mc.itd }}</span>
																	<!-- class="redfont" -->
																</p>
																<p v-else>
																	{{ item.ms == 4 ? getTime(item.bt) : match_status[item.ms] }}
																</p>
															</div>
															<div class="num" @click="goDetails(item)">
																{{ item.tms }}+>
															</div>
														</div>
														<div class="column">
															<div @click="goDetails(item)" 
																class="teams flex" 
																v-for='(a, b) in 2' 
																:key='a'
																:style="{height: item.zongList[0][item.zongList[0].length-1].op.length==3 && pankouShow ? '1.333rem' : '0.879rem'}"
															>
															<!-- style要和赔率那边居中 -->
																<div v-if="item.ms==5 && typeActive==0">
																	<!-- 篮球的比分超过3位数，所以要宽点 -->
																	<div class="num" v-if='item.nsg && item.nsg.length' :style="{width: childTypeList[childTypeActive].sid == 3 ? '27px' : '20px'}">
																		<!-- {{ item.nsg[0].sc[b] }} -->
																		{{ getNsgType(item.nsg, 5, b) || 0 }}
																	</div>
																</div>
																<div class="name" v-if='item.ts.length'>
																	<!-- <span class="img">
																		<img :src="item.ts[b].lurl || require('@/assets/sport/notimg.png')" alt="">
																		<i class="iconfont team-icon q-icon notranslate"></i>
																	</span> -->
																	<span>{{ item.ts[b].na }}</span>
																	<span class="redpai" v-if="getNsgType(item.nsg, 8, b)">{{ getNsgType(item.nsg, 8, b) }}</span>
																</div>
																<div class="name" v-else>
																	<span>暂无数据</span>
																</div>
																
															</div>
															<div class="infobottom" :style="{height: item.zongList[0][item.zongList[0].length-1].op.length==3 && pankouShow ? '1.333rem' : '0.879rem'}">

																<div class="flex">
																	<!-- 点击关注 -->
																	<span class="img flex" @click="handleFocusOn(item)">
																		<img v-if="!followList.includes(item.id)" src="@/assets/sport/foucs.png" alt="">
																		<img v-else src="@/assets/sport/foucs-active.png" alt="">
																	</span>
																	<!-- 有动画的进行显示 -->
																	<!-- <span v-if="item.as && item?.as[0]" >
																		<img src="@/assets/sport/video.svg" alt="">
																	</span> -->
																	<span v-if="item.nsg">
																		<img v-if="item?.nsg?.find(t => t.tyg==6)" src="@/assets/sport/score.svg" alt="">
																		{{ item.nsg ? seleceToggleBottomBiFenData(item.nsg, item) : '-' }}
																	</span>
																	<span v-if="item.nsg && item?.nsg?.find(t => t.tyg==6)">
																		<img src="@/assets/sport/flag.svg" style='transform: scale(0.9);' alt="">
																		{{ item.nsg ? seleceToggleBottomBiFenData(item.nsg, 6) : '-' }}
																	</span>
																</div>
																<!-- <div v-if="item.fidShow">
																	几局几胜
																</div> -->
															</div>
														</div>
													</div>
													<div class="swiper">
														<div v-if="pankouShow" class="zong">
															<van-swipe class="my-swipe" :initial-swipe='attr.initialSwipe' :loop='false'
																indicator-color="#4774da" @change='swiperChange'>
																<van-swipe-item v-for="(zong, z) in item.zongList" :key="z">
																	<div class='item' v-for="(zItem, zi) in zong" :key="zi">
																		<span class="name">{{ zItem.nm }}</span>
																		<div v-for="(a, b) in zItem.op" :key="b">
																			<div v-if="zItem.ss && a.na" @click="handleDownOrder(zItem, a, item, zong)"
																				:class="getOrderClass(zItem, a)">
																				<span style="margin-bottom: 2px; font-size: 12px;">
																					{{ a.nm }}
																				</span>
																				<span style='font-size: 14px;' :style='getColor(a.className)' v-if="a.bod!=0">
																					{{ a.bod.toFixed(2) }}
																					<i v-if="a.className" :class="[a.className]"
																						class='iconfont icon-peishuaibianhua-copy'></i>
																				</span>
																				<span v-else>
																					<i class='iconfont icon-lock' style='font-size: 16px;font-weight: 500;'></i>
																				</span>
																			</div>
																			<div class='block' v-else>
																				<i class='iconfont icon-lock' style='font-size: 16px;'></i>
																			</div>
																		</div>
																	</div>
																</van-swipe-item>
															</van-swipe>
														</div>
														<div class="heng" v-else>
															<van-swipe class="my-swipe" :initial-swipe='initialSwipe' :loop='false'
																indicator-color="#4774da" @change='swiperChange'>
																<van-swipe-item v-for="(heng, h) in item.hengList" :key="h">
																	<div class='item' v-for='(hItem, hi) in heng[0].op' :key='hi'>
																		<div v-if="heng[0].ss && hItem.na" :class="getOrderClass(heng[0], hItem)"
																			@click="handleDownOrder(heng[0], hItem, item)">
																			<span style="margin-bottom: 2px;">
																				{{ hItem.nm }}
																			</span>
																			<span :style='getColor(hItem.className)' v-if="hItem.bod!=0">
																				{{ hItem.bod.toFixed(2) }}
																				<i v-if="hItem.className" :class="[hItem.className]"
																					class='iconfont icon-peishuaibianhua-copy'></i>
																			</span>
																			<span v-else>
																				<i class='iconfont icon-lock' style='font-size: 16px;font-weight: 500;'></i>
																			</span>
																		</div>
																		<div class='block' v-else>
																			<i class='iconfont icon-lock' style='font-size: 16px;'></i>
																		</div>
																	</div>
																</van-swipe-item>

															</van-swipe>
														</div>
													</div>

												</div>
												
											</div>
										</div>
									</div>
								</van-cell>
							</van-list>
						</div>
						<div v-else>
							<van-empty class="custom-image" :image="emptyimg"
								description="没有数据" />
						</div>
					</div>
					<!-- 冠军的数据 -->
					<div class="indexbar" v-else>
						<van-index-bar :index-list="guanjunList.map(g => g.name)">
							<div v-for="(gj, g) in guanjunList" :key='g'>
								<van-index-anchor :index="gj.name" />
								<van-cell v-for="(li, l) in gj.list" :key="l" @click="goDetails(li)" style='margin-bottom: 6px;'>
									<img class="anchor-img" :src="li.lg.lurl" alt="">
									{{ li.nm }}
								</van-cell>
							</div>
						</van-index-bar>
					</div>

					<div class="copyright">
						<p>服务器时间: {{ currentTime }}</p>
						<p>Copyright © 2024 PANDA Group. All Rights Reserved</p>
					</div>
				</van-skeleton>
				<!-- <span style='color: #fff'>
					{{finished}}
				</span> -->
				<div class="placeholder"></div>
			</div>
			
			
				<!-- v-if='!(detailsShow && bottomShow) || (orderLen && footerTouzhu && !bottomShow) || (orderLen && detailsShow)' -->
				<!-- v-if='orderLen && footerTouzhu' -->
			<div class="footer-touzhu" 
				v-if='orderLen'
				:style='{bottom: detailsShow ? 0 : isApp() ? "52px" : "71px"}'
				@click="bottomShow = true">
				<div class='flex'>
					<span>{{ orderLen }}</span>
					投注单
				</div>
				<!-- class='arrow' -->
				<!-- <div> -->
					<!-- <i class="van-icon van-icon-arrow-down"></i> -->
					<svg t="1724663915030" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29685" width="16" height="16"><path d="M512 551.305846l393.846154-388.017231L812.898462 71.68 512 369.664 211.101538 71.68 118.153846 163.288615l393.846154 388.017231z m0 465.604923l393.846154-388.017231L812.898462 537.206154 512 835.347692 211.101538 537.206154 118.153846 628.893538l393.846154 388.017231z" fill="#ffffff" p-id="29686"></path></svg>
				<!-- </div> -->
			</div>
		
		</div>
		
		<div class='weihu' v-else>
			<van-empty 
				class="custom-image" 
				:image="require('@/assets/sport/weihu.png')"
				description="" 
			/>
			<p class='p'>系统维护中</p>
			<p>{{ maintainTime }}</p>
			
		</div>

	</div>
</template>

<script>
import {
	getList,
	statistical,
	getOnSaleLeagues,
	matchCount,
	noFbtoken
} from '@/request/sportApi.js'
import {
	sport,
	match_period,
	swiperList,
	match_status
} from './utils/config'
import { pinyin } from 'pinyin-pro'
import mixin from './utils/mixin'
import { mapActions } from 'vuex'

// import sHeader from './components/sHeader'
import leftSlider from './components/leftSlider.vue'
import rightSlider from './components/rightSlider.vue'
import BottomPopup from './components/bottomPopup.vue'
import TouzhuPopup from './components/touzhuPopup.vue'
import FilterData from './components/filterData.vue'
import Details from './components/details.vue'
import Saiguo from './components/saiguo.vue'
import Search from './components/search.vue'
// 首页头部固定
// 本金返回

export default {
	name: "sportIndex",
	mixins: [mixin],
	components: {
		// sHeader,
		leftSlider,
		rightSlider,
		BottomPopup,
		TouzhuPopup,
		FilterData,
		Details,
		Saiguo,
		Search
	},
	data() {
		return {
			current: 1, // 请求页数
			size: 50, // 请求数量
			sportId: '',

			initialSwipe: 0, // swiper 初始位置索引
			maintainTime: '', // 维护时间


			finished: false,
			resloading: false,
			loading: true, // 骨架屏加载状态
			// startLoading: true, // 初始化时整屏加载
			footerLoadingSatus: 'up',	// 滑到底部时底部加载状态

			headerZIndex: 9,	// 头部的层级
			leftshow: false,
			rightshow: false,
			bottomShow: false,
			// bottomShow1: false,
			touzhuShow: false,
			filterShow: false,
			detailsShow: false,
			saiguoShow: false,
			searchShow: false,

			detailsItem: {},	// 查看某个的详情item
			saiguoItem: {},

			guanjunList: [], // 首字母list  冠军列表z
			followList: [],	// 关注列表
			typeActive: 0,
			typeList: [], // 第一级tabs分类
			childTypeActive: 0,
			childTypeList: [],  // 二级分类
			threeTimeActive: 0,
			threeTimeList: [{ time: '所有日期' }],	// 三级分类

			resData: {}, // 包含页数，总数的数据
			resList: [],
			lastResList: [],	// 上一次的数据，需要对比大小
			eventAllOpen: true,
			eventList: [],
			match_status: match_status,	// 赛事进行状态

			filterData: [],	// 筛选请求的数据
			filterList: [], // 筛选反选的数据

			orderSelectObj: {}, // 订单选中的active集合，key， value形式
			orderLen: 0,
			orderSuccessList: [], // 下单成功时的显示列表
			cgOrderSuccessList: [], // 串关下单成功时的显示列表
			orderIdList: [],  // 下单成时的id列表
			
			firstTabsTypeTimer: null,
			dataListTimer: null,
			clacTimeTimer: null, // 计算时间的定时器
			updateTimeTimer: null, // 底部服务器时间

			observer: null,

			delayLeftShow: 3,  // 侧边栏隐藏后 顶部导航延迟变化， 否则会闪一下
			clickLeftSlideTime: Date.now(),   // 点击左侧栏时需要有个时间戳，两次时间相隔500ms内不执行
			currentTime:"",
		}
	},
	watch: {
		// 侧边栏切换排序的时候重新请求数据
		eventSort() {
			this.getDataList()
		},
		isClearInterval() {
			if (this.isClearInterval) {
				clearInterval(this.firstTabsTypeTimer)
				clearInterval(this.dataListTimer)
				clearInterval(this.clacTimeTimer)
				this.firstTabsTypeTimer = null
				this.dataListTimer = null
				this.clacTimeTimer = null
			} else {
				this.firstTabsTypeTimer = setInterval(this.getFirstTabsType, 6000)
				this.dataListTimer = setInterval(this.getDataList, 3000)
			}
		},
		// leftshow() {
			
		// 	if (this.leftshow) {
		// 		this.delayLeftShow = 9999999
		// 		// this.leftshow = true
		// 	} else {
		// 		// this.leftshow = false
		// 		setTimeout(() => {
		// 			this.delayLeftShow = 3
		// 		}, 300)
		// 	}
		// },
	},
	computed: {
		footerTouzhu() {
			// return !this.touzhuShow && !this.rightshow && !this.leftshow && !this.filterShow && !this.saiguoShow && !this.searchShow
			return !this.bottomShow && !this.touzhuShow && !this.rightshow && !this.leftshow && !this.filterShow && !this.saiguoShow && !this.searchShow
		},
		// 为true代表去别的页面， 停止定时器
		isClearInterval() {
			return this.rightshow || this.filterShow || this.saiguoShow || this.searchShow || this.detailsShow
		},
		eventSort() {
			// 赛事排序  active==0 时间排序 ==1 联赛排序
			return this.$store.state.sport.sportConfig[7].active
		},
		pankouShow() {
			// 盘口显示方式  active==0 横向显示 ==1 纵向显示
			return this.$store.state.sport.sportConfig[2].active
		},
		// 请求数据参数
		requestData() {
			let obj = {
				"current": this.current,
				"orderBy": this.eventSort,
				"type": this.typeList[this.typeActive]?.ty || 1,
				"size": this.size,
				"languageType": "CMN",
				"isPC": true,
				"sportId": this.sportId,
				"oddsType": this.pankou,
				// "marketTypes":[1000, 1005, 1007]
			}
			// ty == 4 代表早盘，是有日期选择的  ==0 是所有日期
			if (this.typeList[this.typeActive]?.des == '早盘' && this.threeTimeActive != 0) {
				let ttl = this.threeTimeList
				// console.log(ttl, 'ttl', this.threeTimeActive)
				if (ttl[this.threeTimeActive].time == '其他') {
					obj.beginTime = ttl[ttl.length - 1].bt
				} else {
					obj.beginTime = ttl[this.threeTimeActive].bt
					obj.endTime = ttl[this.threeTimeActive].bt + 1000 * 3600 * 24 - 1
				}
			}
			if (this.typeList[this.typeActive]?.des == '热门') {
				obj.leagueId = this.childTypeList[this.childTypeActive].id
			}
			if (this.typeList[this.typeActive]?.des == '关注') {
				let follow = localStorage.getItem('follow')
				let resobj = this.childTypeList[this.childTypeActive]
				obj.matchIds = JSON.parse(follow)[resobj?.sid]?.list || []
			}
			if (this.typeList[this.typeActive]?.ty != 7) {
				obj.marketTypes = swiperList[this.childTypeList[this.childTypeActive].sid].zongList[0].map(item => item.mty)
			}
			return obj
		},
	},

	created() {
		let typeList = localStorage.getItem('typeList')
		if (typeList) {
			this.typeList = JSON.parse(typeList)
			this.childTypeList = this.typeList[0]?.ssl
		}

		if (!localStorage.getItem('fbtoken')) {
			this.$store.commit('sport/setUserInfo', {})
		}
		this.$store.dispatch('sport/getUserInfo')
		this.$store.commit('sport/getStaateConfig')
		if (localStorage.getItem('orderSelectObj')) {
			this.orderSelectObj = JSON.parse(localStorage.getItem('orderSelectObj'))
			this.orderLen = Object.keys(this.orderSelectObj).length
		}
		this.setFollowList()
		if (!localStorage.getItem('requestUrl')) {
			noFbtoken()
			.then((res)=>{
				localStorage.setItem('serverInfo', JSON.stringify(res.data.serverInfo))
				localStorage.setItem('requestUrl', res.data.serverInfo.pushServerAddress)
				this.init()
			})
		} else {
			this.init()
		}
	},

	destroyed() {
		// this.observer && this.observer.disconnect()
		clearInterval(this.firstTabsTypeTimer)
		clearInterval(this.dataListTimer)
		clearInterval(this.clacTimeTimer)
		clearInterval(this.updateTimeTimer)
		this.firstTabsTypeTimer = null
		this.dataListTimer = null
		this.clacTimeTimer = null
		this.calcTIme = null
		this.getFirstTabsType = null
		this.getDataList = null
		this.updateTimeTimer = null
		window.removeEventListener('popstate', this.popstate, false)

		localStorage.setItem('typeList', JSON.stringify(this.typeList))
				
	},

	mounted() {
		window.addEventListener('popstate', this.popstate);
	},

	methods: {
		...mapActions(['setLoginShow','setRegisterShow']),
		init() {
			this.getFirstTabsType()
			.then(() => {
				this.getDataList()
				this.firstTabsTypeTimer = setInterval(this.getFirstTabsType, 6000)
				this.dataListTimer = setInterval(this.getDataList, 3000)
				this.updateTimeTimer = setInterval(this.updateTime, 1000)
			})
			.catch(() => {
				setTimeout(() => {
					this.init()
				}, 1000)
			})
		},

		updateTime() {
      this.currentTime = new Date().toLocaleString(); // 更新当前时间
    },

		// 阻止默认事件，起到禁止左滑右滑的功能
		popstate(e) {
			console.log('卸载11111')
			if (localStorage.getItem('back1')) {
				this.rightshow = false
				return
			}
			if (localStorage.getItem('back')) {
				this.detailsShow = false
			}
			e.preventDefault();
		},

		// 打开左侧菜单栏
		handleOpenLeftSlider() {
			
			if (Date.now() - this.clickLeftSlideTime < 500) {
				return
			}
			
			this.clickLeftSlideTime = Date.now()
			this.leftshow = !this.leftshow
			// if (this.leftshow) {
			// 	this.delayLeftShow = 999999
			// } else {
			// 	setTimeout(() => {
			// 		this.delayLeftShow = 3
			// 	}, 300)
			// }
			this.bottomShow = false
			
		},
		
		// 判断关注里面的比赛是否结束
		checkFollowEnd(data) {
			let list = []
			data.forEach(attr => {
				list = list.concat(attr.list)
			})
			// console.log(data, list)
			if (!data.length || list.length != this.requestData.matchIds.length) {
				let follow = JSON.parse(localStorage.getItem('follow'))
				let sid = this.childTypeList[this.childTypeActive].sid
				if (!data.length) {
					// this.childTypeActive = 0
					delete follow[sid]
				} else {
					follow[sid].list = list.map(attr => attr.id)
				}
				// localStorage.setItem('follow', JSON.stringify(follow))
				this.setFollowList()
				this.getFirstTabsType()
				
				// if (Object.keys(follow).length) {
				// 	this.childTypeList = Object.values(follow).map(attr => {
				// 		return {
				// 			icon: attr.icon,
				// 			name: attr.name,
				// 			sid: attr.sid,
				// 			c: attr.list.length
				// 		}
				// 	})
				// 	console.log(follow, 'follow', this.childTypeList)
				// } else {
				// 	this.childTypeList = []
				// }
			}
			
		},

		// 初始化数据
		initializationDAta() {
			this.current = 1
			this.eventList = []
			this.resList = []
			this.resData = {}
			this.filterList = []
			this.loading = true
			this.lastResList = []
			this.finished = true
			this.resloading = false
			this.eventAllOpen = true
		},

		onLoad(bool) {
			if (!this.eventAllOpen) {
				this.finished = true
				return
			}
			this.lastResList = []
			if (!bool) {
				this.current++
			}
			setTimeout(() => {
				this.getDataList()
				.then(() => {
					document.querySelector('.container').scrollTop = 0
				})
			}, 1000)
		},
		
		
		// 获取tabs的分类数据
		getFirstTabsType() {
			return new Promise((resolve, reject) => {
				statistical({
					"languageType": "CMN"
				})
					.then(res => {
						if (res.success) {
							let arr = ['滚球', '今日', '早盘', '热门', '关注', '冠军赛事']
							
							this.typeList = arr.map(item => {
								let resObj = res.data.sl.find(attr => attr.des == item)
								if (resObj) {
									if(item == '冠军赛事') {
										resObj.des = '冠军'
									}
									resObj.ssl = resObj.ssl.filter(a => {
										if (a.c) {
											let r = sport.find(attr => attr.CODE == a.sid)
											a.name = r.DESC_CN
											a.icon = r.ICON
											if (r.show !== false) {
												return a.c
											}
										}
									})
								}
								if (item == '热门') {
									resObj = {
										des: "热门",
										tc: res.data.ht,
										ty: 5,
										ssl: res.data.hls,
										leagueId: "11062"
									}
								}
								if (item == '关注') {
									// let follow = localStorage.getItem('follow')
									// let len = 0
									// if (follow) {
									// 	Object.values(JSON.parse(follow)).forEach(item => {
									// 		len += item.list.length
									// 	})
									// }
									resObj = {
										des: "关注",
										tc: this.followList.length,
										// ty: 3,
										// ssl: res.data.hls
									}
								}
								
								return resObj
							})

							// 二级数据的默认值
							if (this.typeList[this.typeActive].des != '关注') {								
								this.childTypeList = this.typeList[this.typeActive].ssl
								this.sportId = this.childTypeList[this.childTypeActive || 0].sid
							}
							resolve()
						} else {
							if (res.code == 8 || res) {
								let str = res.code || res
								let time = str.match(/\d+/g);
								// 系统维护中，维护时间[1735194600000-1735205400000]
								this.maintainTime = `维护时间（${this.changeTime(time[0])}-${this.changeTime(time[1])}）`
							}
						}
					})
					.catch(() => {
						reject()
					})
			})
		},

		changeTime(timestamp) {
			const date = new Date(timestamp * 1);
			// 获取年、月、日、时、分
			const month = date.getMonth() + 1; // 月，getMonth()返回的月份从0开始，所以需要+1
			const day = date.getDate(); // 日
			const hours = date.getHours(); // 时
			const minutes = date.getMinutes(); // 分

			// 将单个数字格式化为两位数（例如，1 -> 01）
			const formatNumber = (n) => n < 10 ? '0' + n : n;
			const formattedDate = `${formatNumber(month)}/${formatNumber(day)} ${formatNumber(hours)}:${formatNumber(minutes)}`;
			return formattedDate
		},

		// 获取早盘类型下 日期数据
		getZaoPanTime() {
			function calculateMonthAndDay(milliseconds) {
				let date = new Date(milliseconds);
				let month = date.getMonth() + 1
				let day = date.getDate();
				return `${month}月${day}日`
			}

			matchCount({ "sportId": this.sportId })
				.then(res => {
					let arr = [
						{ time: '所有日期' },
						...res.data.dl.map(item => {
							item.time = calculateMonthAndDay(item.bt)
							return item
						})
					]
					// o.c 存在代表有其他
					if (res.data.o && res.data.o.c) {
						arr.push({
							bt: res.data.o.bt,
							c: res.data.o.c,
							time: '其他'
						})
					}
					this.threeTimeList = arr
				})
				.catch(() => {})
		},

		// 获取数据
		getDataList() {
			if (this.typeList[this.typeActive]?.des == '关注' && this.requestData.matchIds.length==0) {
				this.eventList = []
				return
			} 
			return new Promise(response => {
				// if (this.footerLoadingSatus == 'loading') {
				// 	return
				// }
				this.resloading = true
				getList(this.requestData)
					.then(res => {
						clearInterval(this.clacTimeTimer)
						this.clacTimeTimer = null
						if (res.success) {
							if (this.typeList[this.typeActive]?.ty == 7) {
								this.loading = false
								// this.footerLoadingSatus = 'normal'
								this.guanjunList = this.hanziGetZimu(res.data.records)
								return
							}

							this.resData = res.data
							// 对比上一次数据大小， 设置up down 字段
							// 根据up down来选择字体颜色
							this.resList = this.comparedBigSmall(this.lastResList, res.data.records)
							this.lastResList = res.data.records

							// if (this.current == this.resData.pageTotal) {
							// 	this.footerLoadingSatus = 'normal'
							// } else {
							// 	this.footerLoadingSatus = 'up'
							// }

							// 根据定义好的数据字段，筛选出匹配的数据
							// 在纵向或横向显示时用，没有的数据设置为空对象，展示为锁子
							
							this.resList.forEach(item => {
								// console.log(item, this.eventList.find(a => a.name == item.name));
								let findres = this.eventList.find(a => a.name == item.name)
								this.$set(item, 'open', findres?.open == false ? false : true) 
								this.$set(item, 'initialSwipe', findres?.initialSwipe ? findres?.initialSwipe : 0) 
								item.list.forEach((attr) => {
									if (!swiperList[attr.sid]) return
									let hengList = JSON.parse(JSON.stringify(swiperList[attr.sid]?.hengList)) || []
									let zongList = JSON.parse(JSON.stringify(swiperList[attr.sid]?.zongList)) || []
									// 用户 判断空的里面该寸几个值
									if (attr.mg && attr.mg.length) {
										hengList.forEach((heng) => {
											let findres = attr.mg.find(a => a.mty == heng[0].mty && a.pe == heng[0].pe)
											
											if (findres && findres?.mks[0]) {
												heng[0].op = findres.mks[0].op
												heng[0].id = findres.mks[0].id
												heng[0].matchId = attr.id
												heng[0].ss = findres.mks[0].ss
											} else {
												heng[0].op = hengList.map(() => ({}))
												heng[0].ss = 0
											}
										})
										zongList.forEach((zong) => {
											zong.forEach((z)=> {
												let findres = attr.mg.find(a => a.mty == z.mty && a.pe == z.pe)
												if (findres) {
													let list = findres.mks[0].op.filter(o => o.na != '和')
													let he = findres.mks[0].op.filter(o => o.na == '和')
													if (he.length) {
														list.push(he[0])
													}
													z.op = list
													z.ss = findres.mks[0].ss
													z.id = findres.mks[0].id
													z.matchId = attr.id
												} else {
													// console.log(attr, '---------99', z)
													// if (attr.mg.find(a => a.mty == z.mty)?.mks[0]?.op.length==3) {
													if (z.mty == 1005) {
														// console.log(attr, '------------')
														z.op = [{}, {}, {}]
													} else {
														z.op = [{}, {}]
													}
													z.ss = 0
												}
											})
										})
									} else {
										hengList.forEach(heng => {
											heng[0].op = hengList.map(() => ({}))
											heng[0].ss = 0
										})

										zongList.forEach(zong => {
											zong.forEach(z => {
												z.op = [{}, {}]
												z.ss = 0
											})
										})
									}
									// fidShow 是否展示几局几胜
									attr.fidShow = swiperList[attr.sid].fid
									attr.hengList = hengList
									attr.zongList = zongList

									// 是否走表 r==true走  tp 逆向顺向走
									if (attr.mc?.r && attr.mc?.tp) {
										if (attr.mc.tp == 1) {
											attr.mc.s++
										} else {
											if (attr.mc.s > 0) {
												attr.mc.s--
											}
										}
									}
								})
							})
							
							this.eventList = this.resList
							this.clacTimeTimer = setInterval(this.calcTIme, 1000)
							this.loading = false
							if (this.current >= res.data.pageTotal) {
								this.finished = true
							} else {
								this.finished = false
							}
							
							this.resloading = false
							
							if (this.requestData.matchIds) {
								this.checkFollowEnd(this.resList)
							}

							// this.current++
							response()
						}
					})
					.catch(() => {})
			})
		},

		// 点击第一级tabs选项
		handleTypeList(item, i) {
			this.initializationDAta()
			this.typeActive = i
			if (item.des == '关注') {
				let follow = localStorage.getItem('follow')
				this.childTypeActive = 0
				if (follow && follow != '{}') {
					this.childTypeList = Object.values(JSON.parse(follow)).map(attr => {
						return {
							icon: attr.icon,
							name: attr.name,
							sid: attr.sid,
							c: attr.list.length
						}
					})
				} else {
					this.childTypeList = []
				}
			} else {
				this.childTypeActive = 0
				this.childTypeList = this.typeList[this.typeActive]?.ssl
			}
			
			
			// console.log(this.childTypeList, 'this.childTypeList')
			
			// ==4 为早盘，请求日期
			if (item.des == '早盘') {
				this.threeTimeActive = 0
				this.getZaoPanTime()
			}
			if (this.childTypeList.length) {
				this.sportId = this.childTypeList[0].sid
				this.getDataList()
			} else {
				this.eventList = []
				this.loading = false
				clearInterval(this.clacTimeTimer)
				this.clacTimeTimer = null
			}
		},

		// 点击第二季tabs选项
		handleChildTypeList(item, i) {
			this.initializationDAta()
			this.childTypeActive = i
			console.log(item, i, this.childTypeList)
			this.sportId = this.childTypeList[this.childTypeActive].sid
			if (this.typeList[this.typeActive].ty == 4) {
				this.getZaoPanTime()
			}
			this.getDataList()
		},

		// 时间tabs切换
		changeThreeTimeActive(item, i) {
			this.threeTimeActive = i
			this.initializationDAta()
			this.getDataList()
		},

		// 筛选出底部比分数据
		seleceToggleBottomBiFenData(nsg, item) {
			// 取nsg第一个pe和 第一个pe相等的最后一个的sc
			// let arr = nsg
			// if (arr[0]) {
			// 	if (reverse) {
			// 		arr = arr.filter(a => a.pe == arr[0].pe).reverse()
			// 	}
			// 	return arr[0].sc[0] + '-' + arr[0].sc[1]
			// } else {
			// 	return ''
			// }

			// let arr = nsg
			// if (arr[0]) {
			// 	// console.log(reverse);
				
			// 	if (reverse==6) {
			// 		// ==6 代表是角球
			// 		arr = arr.filter(item => item.tyg == 6)
			// 		return arr[0].sc[0] + '-' + arr[0].sc[1]
			// 	}
			// 	if (reverse==5) {
			// 		// ==6 代表是角球
			// 		arr = arr.filter(item => item.tyg == 5).reverse().slice(0,5)
			// 		let str = ''
			// 		arr.forEach(item => {
			// 			str = item.sc[0] +'-'+ item.sc[1] + ' '
			// 		})
					
			// 		return str
			// 	}
			// } else {
			// 	return ''
			// }
			// 6 是角球
			if (item==6) {
				let arr = nsg
				arr = arr.filter(item => item.tyg == 6)
				return arr[0].sc[0] + '-' + arr[0].sc[1]
			}
			
			let pe = item.mc.pe
			if (!nsg.length) {
				return
			}
			if (String(pe).endsWith('01')) {
				let sc = nsg.find(attr => attr.pe == pe && attr.tyg==5).sc
				return sc.join('-')
			} else {
				if (String(pe).startsWith('100')) {
					let sc = nsg.find(attr => attr.pe == 1002 && attr.tyg==5)?.sc
					return sc?.join('-') || ''
				} else {
					let arr = nsg
					arr = arr.filter(attr => !String(attr.pe).endsWith('01')).slice(0,3)
					// console.log(arr, '--');
					
					let str = ''
					arr.forEach(attr => {
						str += attr.sc.join('-') + ' '
					})
					
					return str
				}
			}
		},

		// 时间联赛排序
		changeOrder(active) {
			this.$store.commit('sport/setSportConfig', {
				index: 7,
				active
			})
			this.initializationDAta()
			this.getDataList()
		},

		// 点击下单
		handleDownOrder(item, a, b) {
			if (!a.bod) return
			let num = Object.keys(this.orderSelectObj).length
			if (num >= 1) {
				this.bottomShow = false
				localStorage.setItem('isChuanZi', 1)
			}
			let obj = {
				...item,
				op: a,
				// name: item.nm,
				oddsType: this.pankou,
				sc: b.nsg && b.nsg[0] ? b.nsg[0].sc : [],
				ts: b.ts || [],
				lg: b.lg || {},
				bt: b.bt,
				mask: false,
				tz: 0,
			}
			if (this.typeList[this.typeActive]?.ty == 1) {
				obj.type = '滚球'
			}

			if (this.orderSelectObj[item.id]?.op?.na == a.na) {
				this.$set(this.orderSelectObj, item.id, '')
				delete this.orderSelectObj[item.id]
			} else {
				this.$set(this.orderSelectObj, item.id, obj)
			}
			
			localStorage.setItem('orderSelectObj', JSON.stringify(this.orderSelectObj))
			this.orderLen = Object.keys(this.orderSelectObj).length
			if (num==0) {
				this.bottomShow = true
				localStorage.removeItem('isChuanZi')
			}
		},

		// 删除下单
		handleDeleteOrderItem(item) {
			if (item) {
				// this.$set(this.orderSelectObj, item.id || item.omid, '')
				this.$delete(this.orderSelectObj, item.id || item.omid)
				// delete this.orderSelectObj[item.id || item.omid]
			} else {
				this.orderSelectObj = {}
			}
			// console.log(this.orderSelectObj, 'this.orderSelectObj')
			localStorage.setItem('orderSelectObj', JSON.stringify(this.orderSelectObj))
			this.orderLen = Object.keys(this.orderSelectObj).length
			if (this.orderLen == 0) {
				this.bottomShow = false
				localStorage.removeItem('isChuanZi')
			}

		},

		// 获取订单class
		getOrderClass(item, a) {
			return [item.id && this.orderSelectObj[item.id]?.op?.na == a?.na ? 'block active' : 'block']
		},

		handleSaiguoShow(item) {
			this.saiguoItem = item
			this.saiguoShow = true
		},

		// 去详情
		goDetails(item) {
			// console.log(item);
			if (!this.$store.state.sport.user.username) {
				this.setRegisterShow(true)
			}	else {
				this.detailsItem = item
				this.bottomShow = false
				this.detailsShow = true
			}
		},
		
		// 去投注记录
		goRight() {
			if (!this.$store.state.sport.user.username) {
				this.setRegisterShow(true)
				this.leftshow=false
			} else {
				this.rightshow = true
				this.leftshow=false
				this.delayLeftShow = 3
			}
		},

		// 初次设置关注选项
		setFollowList() {
			let follow = localStorage.getItem('follow')
			let list = []
			if (follow && localStorage.getItem('follow') != '{}') {
				Object.values(JSON.parse(follow)).forEach(item => {
					list.push(...item.list)
				})
			}
			this.followList = list
		},

		// 点击关注
		handleFocusOn(item) {
			console.log(item, this.childTypeList[this.childTypeActive]);
			let sid = this.childTypeList[this.childTypeActive].sid
			let resobj = sport.find(attr => attr.CODE == sid)
			let icon = resobj.ICON
			let name = resobj.DESC_CN
			let follow = localStorage.getItem('follow')
			let f
			if (follow) {
				f = JSON.parse(follow)
				if (f[sid]) {
					let list = f[sid].list
					if (list.includes(item.id)) {
						list = list.filter(attr => attr != item.id)
					} else {
						list.push(item.id)
					}
					f[sid].list = list
					if (!list.length) {
						delete f[sid]
					}
				} else {
					f[sid] = {sid, icon, name, list:[item.id]}
				}
			} else {
				f = {[sid]: {sid, icon, name, list:[item.id]}}
			}
			localStorage.setItem('follow', JSON.stringify(f))
			let list = []
			Object.values(f).forEach(item => {
				list.push(...item.list)
			})
			this.followList = list

			// 如果时
			if (this.typeList[this.typeActive].des == '关注') {
				let follow = localStorage.getItem('follow')
				if (follow && follow != '{}') {
					if (this.childTypeList[this.childTypeActive].c == 1) {
						this.childTypeActive = 0
					}
					this.childTypeList = Object.values(JSON.parse(follow)).map(attr => {
						return {
							icon: attr.icon,
							name: attr.name,
							sid: attr.sid,
							c: attr.list.length
						}
					})
					this.getDataList()
				} else {
					this.childTypeList = []
					this.eventList = []
				}
			}

			this.typeList[4].tc = list.length
		},

		// 根据code筛选出name，并过滤掉相关字段
		codeToName(pe) {
			let str = match_period.find(m => m.CODE == pe)?.DESC_CN.replace(this.childTypeList[this.childTypeActive].name, '')

			if (str) {
				let res = str.split('-')
				return res[1] || res[0]
			}
			return ''
		},

		// 计算小时、分钟和秒数
		// secondsToHMS(seconds) {
		// 	const minutes = Math.floor(seconds / 60);
		// 	const remainingSeconds = seconds % 60;

		// 	// 确保秒数是两位数
		// 	const formattedMinutes = minutes.toString().padStart(2, '0');
		// 	const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
		// 	return `${formattedMinutes}:${formattedSeconds}`;
		// },

		// 时间转换
    getTime(time) {
      if (!time) return ''
      let date = new Date(time)
      // let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +  "/"
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      // let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      return M + D + h + m
    },

		calcTIme() {
			// console.log('首页计时器');
			// console.log(this.eventList, '--');
			this.eventList.forEach(attr => {
				attr.list.forEach(item => {

					if (item.mc?.r) {
						if (item.mc.tp == 1) {
							item.mc.s++
						} else {
							if (item.mc.s >= 1) {
								item.mc.s--
							}
						}
					}
				})
				// item.mc.time = this.secondsToHMS(item.mc.s)
			})
		},

		// 赛事筛选
		handleFilterData() {
			let obj = {
				"type": this.typeList[this.typeActive]?.ty,
				"sportId": this.sportId,
				"languageType": "CMN",
			}
			if (this.requestData.beginTime) {
				obj.beginTime = this.requestData.beginTime
			}
			if (this.requestData.endTime) {
				obj.endTime = this.requestData.endTime
			}
			this.filterShow = true
			getOnSaleLeagues(obj)
				.then(r => {
					this.filterData = r.data
				})
				.catch(() => {})
		},

		// 赛事筛选展示，并重新赋值未筛选的
		handleFilterShow(list) {
			console.log(list);
			this.filterShow = false
			if (list) {
				this.filterList = list
			}
		},

		// 对比大小 设置升降的class  数据嵌套有点深，仔细捋一下
		comparedBigSmall(lastList, list) {
			if (!lastList.length) {
				return this.setUniteGame(list)
			}
			list.forEach((item, i) => {
				if (item.mg.length) {
					item.mg.forEach((attr, a) => {
						if (attr.mks.length) {
							attr.mks.forEach(((el, e) => {
								if (el.op.length) {
									el.op.forEach((o, m) => {
										let bod = lastList[i]?.mg[a]?.mks[e]?.op[m]
										if (bod) {
											if (o.bod < bod.bod) {
												o.className = 'down'
											}
											if (o.bod > bod.bod) {
												o.className = 'up'
											}
										}
									})
								}
							}))
						}
					})
				}
			})
			return this.setUniteGame(list)
		},

		// 把统一联赛的归到一起
		setUniteGame(resList) {
			let obj = {}
			resList.forEach((item) => {
				if (obj[item.lg.na]) {
					obj[item.lg.na].push(item)
				} else {
					obj[item.lg.na] = [item]
				}
			})
			let arr = []
			Object.keys(obj).forEach(item => {
				arr.push({
					name: item,
					lurl: obj[item][0].lg?.lurl,
					list: obj[item]
				})
			})
			return arr
		},

		// 投注成功
		handleOrderSuccess(arr, cgArr, orderIdList) {
			// localStorage.setItem('arr', JSON.stringify(arr))
			// localStorage.setItem('cgArr', JSON.stringify(cgArr))
			// localStorage.setItem('orderIdList', JSON.stringify(orderIdList))
			this.orderSuccessList = arr
			this.cgOrderSuccessList = cgArr
			this.orderIdList = orderIdList
			// this.bottomShow1 = true
			this.bottomShow = false
			this.touzhuShow = true
		},

		// 投注成功确定
		sureOrderSuccess() {
			this.orderSelectObj = {}
			this.orderLen = 0
			this.orderIdList = []
			this.bottomShow = false
			// this.bottomShow1 = false
			this.touzhuShow = false
			localStorage.removeItem('orderSelectObj')
		},

		// 投注成功保留选项
		sureOrderBaoliu() {
			this.touzhuShow = false
			this.bottomShow = true
		},

		// 监测是否滑倒底部
		isScrollBottom() {
			// 初次加载不请求
			
			var options = {
				root: null,
				rootMargin: '0px',
				threshold: 0.5
			};
			// console.log(123);
			this.observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						if (this.footerLoadingSatus != 'normal' && this.eventList.length) {
							// this.current++
							// 每次滑倒底部请求完数据后重新回到顶部
							this.getDataList()
								.then(() => {
									window.scrollTo({
										top: 0,
										// behavior: 'smooth' // 平滑滚动
									});
								})
								
						}
					}
				});
			}, options);

			var target = document.getElementsByClassName('loading-data')[0];
			this.observer.observe(target);
		},

		// 根据汉字得到首字母， 要进行字母排序
		hanziGetZimu(list) {
			let obj = {}
			list.forEach(item => {
				let na = item.lg.na
				let f = pinyin(na[0], { pattern: 'first', toneType: "none" })
				if (obj[f]) {
					obj[f].push(item)
				} else {
					obj[f] = [item]
				}
			})
			let arr = []
			Object.keys(obj).forEach(item => {
				arr.push({
					name: item.toLocaleUpperCase(),
					list: obj[item]
				})
			})
			return arr.sort((a, b) => a.name.localeCompare(b.name))
		},

		// 根据配置获取颜色
		getColor(n) {
			if (!n) return {}
			if (n == 'up') {
				return {
					color: this.$store.state.sport.sjcolor.up
				}
			} else {
				return {
					color: this.$store.state.sport.sjcolor.down
				}
			}
		},

		// 搜索界面查看详情
		searchShowDetails(item) {
			this.detailsItem = item
			this.detailsShow = true
		},

		// 切换所有的折叠打开
		handleEventAllShow() {
			this.eventAllOpen = !this.eventAllOpen
			this.eventList.forEach(item => {
				item.open = this.eventAllOpen
			})
		},

		// swiper改变位置
		swiperChange(index) {
			this.initialSwipe = index
		},

		// 引入图片
		requestImg(img) {
			try {
				return require(`../../assets/sport/icon/${img}.svg`)
			} catch (e) {
				try {
					return require(`../../assets/sport/icon/${img}.png`)
				} catch (e) {
					return ''
				}
			}
		},
		
		isApp(){
			var origin = window.location.origin
			return !origin.includes("app")
		},

	},
}
</script>

<style lang="less">

// .sport * {
// 	touch-action: none;
// 	touch-action: pan-y;
// 	overscroll-behavior: none;
// }
// .sport .typelist *,
// .sport .child-typelist *,
// .sport .time-list *  {
// 	touch-action: auto;
// 	touch-action: pan-x;
// }

.sport-loader {
    display: flex;
		align-items: center;
		justify-content: center;
    .yuan {
      display: flex;
      justify-content: center;
      /* animation: zhuan 2s linear infinite; */
    }
    img {
      width: 88px;
      height: 88px;
    }
  }
.moneyColor {
	color: #4774da;
}

.sport .van-swipe-item { 
	display: flex;
	justify-content: space-between;
}

.sport .van-swipe {
	padding-bottom: 5px;
}

.sport  .van-swipe__indicators {
	bottom: 0px;
}

// .sport  .van-swipe__indicator {
// 	width: 12px;
// 	height: 2px;
// 	border-radius: 2px;
// 	background: rgba(0, 0, 0, 0.15);
// }

.sport  .van-cell {
	padding: 0;
	background: none;
	&::after {
		border-bottom: none;
	}
}
.sport .indexbar .van-cell__value {
	background: var(--304b62);
	border-radius: 40px;
	margin: 0 11px;
	color: #fff;
	display: flex;
	align-items: center;
	padding: 4px 0;
}

.sport .van-index-bar__sidebar {
	display: none;
}

.sport  .van-index-anchor {
	text-align: left;
	display: flex;
	align-items: center;
	padding: 0;
	color:#fff;
}
.sport .van-index-bar__index {
	color:#fff;
}

.sport .van-index-anchor:before {
	content: "";
	display: inline-block;
	height: 20px;
	width: 4px;
	border-radius: 0 2px 2px 0;
	background: #4774da;
	margin-right: 8px;
}

.anchor-img {
	min-width: 16px;
	max-width: 16px;
	height: 16px;
	margin: 0 6px;
	background-color: #fff;
	border-radius: 50%;
}
</style>
<style lang="less" scoped>
@import '@/style/global.less';
@import '@/style/font.css';

.flex {
	display: flex;
	align-items: center;
}

.flex-c {
	display: flex;
	flex-direction: column;
}

span,
div {
	box-sizing: border-box;
}

div, ul {
	&::-webkit-scrollbar {
		display: none;
	}
}

.van-skeleton__row {
	background: @gujia-bg;
}

.scroll-tabs {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	height: 100%;
	padding: 0 16px;
	color: @font-color;
	// color: #fff;

	&::-webkit-scrollbar {
		display: none;
	}

	.img img {
		width: 28px;
		height: 28px;
		border-radius: 50%;
		// border: 2px solid #c80815;
		// background: #ccc;
	}


}

.sport {
	height: 100%;
	overflow: hidden;
	font-size: 16px;
	background: @bg-color;
	// background:#193045;
	// padding-bottom: 20px;
	box-sizing: border-box;
	font-family: "PingFang SC";
	// font-family: Roboto, Microsoft YaHei, PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, "sans-serif";

	&::-webkit-scrollbar {
		display: none;
	}

	.placeholder {
		height: 100px;
	}

	.header {
		height: 53px;
		position: relative;
		// box-shadow: 0 0 10px 2px #ffffff08;
	}

	.container {
		position: relative;	
		height: 100vh;
		overflow: auto;

		.typelist {
			// border-top: 1px solid #eee;
			background: #193045;
			background: #213d56;
			height: 48px;
			padding-right: 40px;
			// padding: 8px 50px 8px 0;
			width: 100%;
			overflow: hidden;
			position: sticky;
			top: 0;
			z-index: 3;
			box-shadow: 0 0 10px 10px #00000020;

			// &:before {
			// 	content: "";
			// 	display: inline-block;
			// 	position: absolute;
			// 	left: 0;
			// 	top: 15px;
			// 	width: 4px;
			// 	height: 16px;
			// 	background: @theme-text;
			// 	margin-right: 8px;
			// 	border-radius: 0 2px 2px 0;
			// }

			.fixed {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 48px;
				z-index: 4;
				justify-content: center;
				background: #213d56;
				// box-shadow: -4px 0px 8px #132231;
				box-shadow: 0px 0 2px 0px #132231;
				svg {
					width: 24px;
					height: 24px;
					border-radius: 50%;
				}
				img {
					width: 28px;
					height: 28px;
					border-radius: 50%;
				}
			}

			ul {
				padding-left: 16px;
			}

			li {
				display: flex;
				align-items: center;
				height: 100%;
				color: #B3B9D2;
				box-sizing: border-box;
				font-size: 15px;
				margin-right: 22px;
				padding-left: 4px;

				&.active {
					color: #fff;
					font-weight: 800;
					border-bottom: 2px solid @theme-text;
				}

				.name {
					white-space: nowrap;
				}

				.num {
					transform: scale(0.7);
					margin-top: 1px;
				}

				img {
					width: 14px;
					height: 14px;
				}
			}
		}

		.child-typelist {
			background:#193045;
			height: 72px;
			position: sticky;
			z-index: 2;
			top: 10px;
			overflow-y: overlay;
			// border-bottom: 1px solid #2b445a;

			// &:before {
			// 	content: "";
			// 	display: inline-block;
			// 	position: absolute;
			// 	left: 0;
			// 	top: 16px;
			// 	width: 4px;
			// 	height: 16px;
			// 	background: @theme-text;
			// 	margin-right: 8px;
			// 	border-radius: 0 2px 2px 0;
			// }
		
			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 10px;
				font-size: 14px;
				position: relative;
				color: @index-font-color;
				margin-right: 24px;
				height: 100%;
				i {
					font-size: 20px;
				}
		
				&.active {
					border-bottom: 2px solid @theme-text;
					// color: @type-font-active;
					// .name {
					// 	font-weight: 900;
					// }
					// i {
					// 	color: @theme-text;
					// }
					// .img {
					// 	span {
					// 		background: @theme-text;
					// 	}
					// }
				}
				.bg {
					background-color: #fff;
					border-radius: 50%;
				}
				.img {
					transition: height .2s;
					overflow: hidden;
					// height: 38px;
					// margin-bottom: 4px;
					display: flex;
					// padding-top: 10px;
					
					span {
						font-size: 12px;
						min-width: 18px;
						height: 18px;
						margin: -8px 0 0 4px;
						display: flex;
						align-items: center;
						border-radius: 9px;
						justify-content: center;
					}
					img, i {
						width: 28px;
						font-size: 28px;
						height: 28px;
						border-radius: 50%;
					}
				}
		
				.bottom {
					height: 8px;
					position: absolute;
					left: 0;
					bottom: 3px;
				}
		
				.txt {
					display: flex;
					margin-top: 10px;
					font-size: 12px;
					line-height: 12px;
					span {
						white-space: nowrap;
					}
				}
			}
		}
		
		.time-list {
			background: #193045;
			width: 100%;
			margin: 4px 0;
			font-size: 13px;
			position: relative;
			overflow: hidden;
			// border-top: 4px solid @bg-color;
			// &:before {
			// 	content: "";
			// 	display: inline-block;
			// 	position: absolute;
			// 	left: 0;
			// 	top: 6px;
			// 	width: 4px;
			// 	height: 16px;
			// 	background: @theme-text;
			// 	margin-right: 8px;
			// 	border-radius: 0 2px 2px 0;
			// }
			li {
				padding: 0 16px;
				height: 28px;
				line-height: 28px;
				color: @index-font-color;
				border-radius: 100px;
				border: 1px solid @time-list-border;
				margin-right: 6px;
				white-space: nowrap;
				flex-direction: row;

				span {
					margin-left: 3px;
				}

				&.active {
					background: @theme-text;
					color: #fff;
					font-weight: 800;
				}
			}
		}

		.search-box {
			display: flex;
			height: 30px;
			align-items: center;
			justify-content: space-between;
			padding: 0 8px;
			gap: 6px;
			// background:#193045;
			background:#213d56;
			margin: 4px 0;
			// border-radius: 8px;


			.filter, .search {
				display: flex;
				align-items: center;
				color: #B3B9D2;
				font-size: 14px;
				line-height: 16px;
				img {
					height: 16px;
				}
			}

			.filter {
				justify-content: space-between;
				display: flex;
				align-items: center;
				span {
					// margin: 0 6px;
					// height: 18px;
					// line-height: 18px;
					margin-left: 10px;
				}
				// img {
				// 	width: 20px;
				// 	height: 20px;
				// 	margin-left: 8px;
				// 	filter: grayscale(100%) brightness(190%);
				// }
				.rotate {
					transform: rotate(90deg);
				}
			}
			.search {
				display: flex;
				align-items: center;
				// padding: 0 11px;
				height: 100%;
				color: #B3B9D2;
				font-size: 14px;
				line-height: 14px;
				span {
					margin-left: 4px;
				}
				.search-icon {
					margin-right: 12px;
				}
				.left {
					height: 100%;
					display: flex;
					align-items: center;
				}
				img {
					margin-top: 2px;
				}
			}
			
		}

		.indexbar {
			padding: 0 8px;
		}

		.pull-content {
			// padding: 0 8px;
			width: 100%;
			position: relative;
			z-index: 1;

			.league {
				// border-radius: 8px;
				overflow: hidden;
				margin-bottom: 4px;
				transition: all .3s;
				// background: #243B4F;
				background: @304b62;
				// #304d68

				.league-title {
					font-size: 14px;
					height: 32px;
					display: flex;
					padding: 0 10px 0 0;
					justify-content: space-between;
					align-items: center;
					color: #fff;
					
					.flex {
						max-width: 88%;
						&:before {
							content: "";
							display: inline-block;
							width: 4px;
							height: 18px;
							background: @theme-text;
							margin-right: 8px;
							border-radius: 0 2px 2px 0;
						}
						.img {
							width: 22px;
							min-width: 22px;
							height: 22px;
							border-radius: 50%;
							overflow: hidden;
							margin-right: 8px;
							background: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							img {
								width: 18px;
								min-width: 18px;
								height: 18px;
							}
						}
					}
					.icon i {
						transition: all .3s;
					}
					.title {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.border {
					&:last-child {
						.toggle {
							border-bottom: none;
						}
					}
					&:first-child {
						.toggle {
							border-radius: 8px 8px 0 0;
						}
					}
				}
				
				

				.toggle {
					overflow: hidden;
					background: @0019314d;;
					// padding: 6px 10px;
					padding-bottom: 8px;
					transition: background .3s;
					border-bottom: 1px solid @bg-color;
					// border-bottom: 1px solid #2c3f59;

					.info {
						display: flex;
						padding-right: 10px;
						position: relative;
						z-index: 9;
						&:after {
							content: "";
							position: absolute;
							top: 0;
							right: 0;
							width: 100%;
							z-index: -1;
							height: 28px;
							display: block;
							background: #193045;
						}
						.fenshu {
							width: 172px;
							display: flex;
							flex-direction: column;
							// padding-right: 10px;
							// padding-top: 1px;
							
							.changshu {
								justify-content: space-between;
								font-size: 13px;
								// margin-bottom: 6px;
								width: 100%;
								flex: 1;
								color: #b3b9d2;
								background: #193045;
								height: 28px;
								line-height: 28px;
								flex: 0 1 28px;
								padding: 0 4px 0 10px;

								.name {
									display: flex;
									align-items: center;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									flex: 1;
									margin-right: 5px;
									// svg, path {
									// 	width: 18px;
									// 	height: 18px;
									// }
									p, span {
										margin-right: 5px;
									}

									span:nth-child(2) {
										flex: 0 1 auto;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
										text-align: left;
										margin-right: 5px;
									}
								}

								.redfont {
									color: #c31337;
									font-weight: 900;
								}

								.img {
									margin-right: 4px;
								}
								.num {
									color: #677580;
									font-size: 12px;
								}
							}

							.column {
								display: flex;
								flex-direction: column;
								// justify-content: space-around;
								flex: 1;
								padding: 9px 4px 4px 10px;
								gap: 4px 0;
								// margin-top: 8px;
							}
							.teams {
								// margin-top: 14px;
								height: 20px;
								font-size: 14px;
								font-weight: 600;
								// justify-content: space-between;
								align-items: center;
								// color: #fff;
								color: #f7f7f7;
								
								&:last-child {
									margin-top: 18px;
								}
								&:nth-child(2) {
									color: #a8becb;
									// font-weight: 100;
									.num {
										color: #fff;
									}
								}

								.name {
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									display: flex;
									align-items: center;
									// padding: 0 4px;
									font-size: 13px;
									span {
										text-overflow: ellipsis;
										overflow:hidden;
										white-space:nowrap;
									}

									span:nth-child(2) {
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}

									.redpai {
										display: flex;
										align-items: center;
										height: 12px;
										background: #c31337;
										color: #fff;
										font-size: 9px;
										border-radius: 2px;
										margin-left: 3px;
										padding: 0 2px;
									}

									.img {
										height: 16px;
										min-width: 16px;
										max-height: 16px;
										width: 16px;
										margin-right: 6px;

										img {
											width: 100%;
											height: 100%;
										}
									}
									
								}

								.num {
									margin-right: 6px;
									font-size: 12px;
									font-weight: 400;
									border-radius: 2px;
									background: #ffffff17;
									// width: 20px;
									text-align:center;
									white-space: nowrap;
								}
							}
						}

						.swiper {
							flex: 1;
							overflow: hidden;
							// padding-top: 2px;

							.block {
								font-size: 10px;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								height: 50px !important;
								overflow:hidden;
								border-radius: 12px;
								color: #fff;
								border: 1px solid #9b9bba0a;
								background: #ffffff0a;
								margin: 4px 2px;
								transition: background-color 0.3s;
								position: relative;
								
								span {
									height: 14px;
									line-height: 14px;
								}

								i {
									display: inline-block;
									font-size: 7px;
									margin-left: -2px;
								}

								.up {
									transform: rotate(180deg);
								}

								span:last-child {
									font-size: 11px;
									font-weight: bold;
								}

								&.active {
									background: @theme-text;
									color: #fff;
								}
							}

							.zong {
								// padding: 0 10px 0 4px;
								// &::after {
								// 	content: "";
								// 	height: 28px;
								// 	display: block;
								// 	background: #193045;
								// }
								.item {
									// width: calc(50% - 2px);
									text-align: center;
									flex: 1;
									.name {
										// color: #788A9A;
										color: #677580;
										
										font-size: 12px;
										// padding-bottom: 5px;
										height: 28px;
										line-height: 28px;
										margin-bottom: 10px;
										display: block;
										background: #193045;
									}
								}
							}

							.heng {
								padding-top: 56px;
								padding-bottom: 20px;
								.item {
									text-align: center;
									flex: 1;
									.block {
										height: 50px;
									}
								}
								.van-swipe-item {
									gap: 4px;
								}
							}
						}

						::v-deep .van-swipe__indicator {
							background-color: #646464;
							transition: all .3s;
							width: 26px;
							border-radius: 4px;
							height: 2px !important;
						}
						::v-deep .van-swipe__indicator--active {
							background-color: #cecece !important;
						}
					}

					.infobottom {
						display: flex;
						// justify-content: space-between;
						font-size: 12px;
						// margin: 0 0 0 4px;
						height: 14px;
						color: #b3b9d2;

						span {
							height: 100%;
							display: flex;
							align-items: center;
							margin-right: 4px;
							white-space: nowrap;

							img {
								// margin-right: 2px;
								width: 18px;
								height: 18px;
							}
						}
					}
				}
			}


		}
	}

	.loading-data {
		width: 100%;
		height: 40px;
		padding: 4px 0 60px;
		font-size: 15px;
		color: @index-font-color;

		.flex {
			justify-content: center;
		}
	}

	

	.footer-touzhu {
		position: fixed;
		bottom: 50px;
		left: 0;
		right: 0;
		z-index: 1111;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// background: @bg-color;
		background: @193146;
		color: @type-font-active;
		font-weight: 800;
		padding: 0 12px;
		// box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.08);
		box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);
		border-top: 1px solid #253f57;

		span {
			display: inline-block;
			height: 18px;
			min-width: 18px;
			border-radius: 20px;
			background-color: #ffba07;
			color: #000;
			margin-right: 3px;
			font-size: 13px;
		}

		svg {
			transform: rotate(180deg);
		}
		
		.arrow {
			background:#ccc;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				color: #000;
				// margin-top:2px;
				transform: rotate(180deg);
			}
		}
	}
}
.weihu {
	width: 100%;
	height: 80vh;
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color:#fff;
	font-size: 14px;
	.custom-image {
		padding-bottom: 0;
	}
	.p {
		font-size: 20px;
		line-height: 30px;
	}
}

.copyright p {
	text-align: center;
	width: 89.3333%;
	margin: 6px auto 0 auto;
	color: rgba(255, 255, 255, 0.45);
	font-family: "PingFang SC";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	/* 12px */
}

</style>