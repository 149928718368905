<template>
  <div class="loginContentPop">
    <div class="rightImg" @click="loginClose"><img src="@/assets/app/close.webp" /></div>
    <!-- <div class="titleLine">
      <div class="titName">登录</div>
    </div> -->
    <div class="logoDemo"><img src="@/assets/home-logo.svg" /></div>

    <van-tabs v-model="active" swipeable title-active-color="#FFF" color="#82A7D3" background="transparent" animated>
      <van-tab title="账户登录">
        <div class="zlgOut">
          <div class="intOut" :class="{
            errorIntOut: nameDown && nameType,
            correctIntOut: nameDown && nameType == false,
          }">
            <div class="intLeftOut" @click="intChange(0)">
              <div class="intLeftImg">
                <img src="@/assets/app/loginPop/loginUser.png" />
              </div>
              <!-- <van-field
          class="ints"
          autocomplete="off"
          autocorrect="off"
          :border="false"
          v-model="username"
          placeholder="用户名"
          @focus="nameFocus"
          @blur="nameBlur"
          @input="nameInput"
          ref="myFieldUser"
        /> -->
              <input class="ints" v-model="username" placeholder="用户名/邮箱登录" @focus="nameFocus" @blur="nameBlur"
                @input="nameInput" ref="myFieldUser" />
            </div>
          </div>
          <!-- <div class="tips" v-if="nameDown" :class="{ errorIntTips: nameType }">
        用户名只能包含字母和数字
      </div> -->
          <!-- <div class="tips" v-else :class="{ errorIntTips: nameType }">
        
      </div> -->
          <div class="intOut " :class="{
            errorIntOut: pwdDown && pwdType,
            correctIntOut: pwdDown && pwdType == false,
          }" @click="intChange(1)">
            <div class="intLeftOut">
              <div class="intLeftImg">
                <img src="@/assets/app/loginPop/loginPwd.png" />
              </div>
              <!-- <van-field
            class="ints"
            autocomplete="off"
            autocorrect="off"
            :type="isPwd ? 'text' : 'password'"
            :border="false"
            v-model="pwd"
            placeholder="密码"
            @focus="pwdFocus"
            @blur="pwdBlur"
            @input="pwdInput"
            ref="myFieldPwd"
          /> -->
              <input class="ints" :type="isPwd ? 'text' : 'password'" :border="false" v-model="pwd" placeholder="登录密码"
                @focus="pwdFocus" @blur="pwdBlur" @input="pwdInput" ref="myFieldPwd" />
            </div>
            <div class="eyes" v-show="!isPwd" @click="isPwd = !isPwd"></div>
            <div class="closeEyes" v-show="isPwd" @click="isPwd = !isPwd"></div>
          </div>
          <!-- <div class="tips" v-if="pwdDown" :class="{ errorIntTips: pwdType }">
        密码为6-18位且包含字母和数字
      </div>
      <div class="tips" v-else :class="{ errorIntTips: pwdType }">
        
      </div> -->

          <div class="flex-ju">
            <div class="flex-left" @click="setUser = !setUser">
              <div class="yuanwai">
                <img v-show="!setUser" src="@/assets/app/loginPop/round.webp" />
                <img v-show="setUser" src="@/assets/app/loginPop/blue-round.webp" />
              </div>

              记住账号
            </div>
            <div class="flex-right" @click="setLoginShow(false), $router.push('/recover')">
              忘记密码?
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="手机号登录">
        <div class="zlgOut">
          <div class="line-int">
            <div class="intDemoOut">
              <input class="int" placeholder="+86" v-model="acra" disabled />
            </div>
            <div class="sjh">
              <input placeholder="手机号码" v-model="phone" />
            </div>
          </div>
          <div class="line-int topInt">
            <div class="codeDemo">
              <input class="int" placeholder="验证码" v-model="inCode" />
            </div>
            <van-button class="codeBtn" :loading="codeLoading" @click="getCodeBtn"
              :disabled="countdown > 0 || codeLoading || !reEmailTest.test(phone)">
              {{ countdown > 0 ? `${countdown} S` : '获取验证码' }}
            </van-button>
          </div>
          <div class="flex-ju topInt">
            <div class="flex-left" @click="setPhone = !setPhone">
              <div class="yuanwai">
                <img v-show="!setPhone" src="@/assets/app/loginPop/round.webp" />
                <img v-show="setPhone" src="@/assets/app/loginPop/blue-round.webp" />
              </div>

              记住手机号
            </div>

          </div>
        </div>
      </van-tab>
    </van-tabs>

    <div class="bomOut">
      <van-button class="btn" :loading="subLoading" :disabled="subLoading" @click="submit">登录</van-button>
      <div class="typse">
        新用户？<span @click="setRegisterShow(true)">创建账号</span>
      </div>
    </div>
    <div class="goLine" v-if="false">
      <div class="goDemo" @click="setRegisterShow(true)">
        <div class="toopImg"><img src="@/assets/login/goRegister.svg" /></div>
        <div class="goTitle">立即注册</div>
      </div>
      <div class="goDemo" @click="goTourists()">
        <div class="toopImg"><img src="@/assets/login/tourists.svg" /></div>
        <div class="goTitle">游客进入</div>
      </div>
      <div class="goDemo" @click="$store.commit('goService')">
        <div class="toopImg"><img src="@/assets/login/service.svg" /></div>
        <div class="goTitle">在线客服</div>
      </div>
    </div>

    <!-- <div class="dibu">
        <div class="titles">我们推荐使用以下浏览器以获得最佳使用体验</div>
        <div class="imgDemo">
          <img src="@/assets/login/google.svg" @click="goGoogle(0)" />
          <img src="@/assets/login/safari.svg" @click="goGoogle(1)" />
          <img src="@/assets/login/frame.svg" @click="goGoogle(2)" />
        </div>
      </div> -->
  </div>
</template>

<script>
import { EventBus } from '@/unit/event-bus.js';
import { mapActions } from 'vuex';
import { sysLogin, platformBalance, sysSend_sms } from "@/request/api";
import { fbtoken, noFbtoken } from '@/request/sportApi'

import ws from '@/request/ws';   // 看个人需求选择全局引入或按需引入
export default {
  name: "loginPop",
  data() {
    return {
      username: "",
      pwd: "",
      subLoading: false,
      setUser: true,
      isPwd: false,

      nameDown: false,
      pwdDown: false,
      pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$/,
      nameType: false,
      pwdType: false,
      active: 0,
      phone: "",
      inCode: "",
      codeLoading: false,
      countdown: 0,
      countdownTimer: null,
      reEmailTest: /^\d{11}$/,
      setPhone: false,
      acra: "+86",
    };
  },
  mounted() { },
  methods: {
    ...mapActions(['setLoginShow', 'setRegisterShow']),
    loginClose() {
      this.setLoginShow(false)
    },
    getFbToken() {
      fbtoken()
        .then(res => {
          if (res.code == 0 && res.data.token) {
            localStorage.setItem('fbtoken', res.data.token)
            localStorage.setItem('serverInfo', JSON.stringify(res.data.serverInfo))
            localStorage.setItem('requestUrl', res.data.serverInfo.apiServerAddress)
            this.$store.dispatch('sport/getUserInfo')
            // apiEmbeddedServerAddress
            // : 
            // "https://h5.st-newsports.com"
            // apiServerAddress
            // : 
            // "https://app-2.server.st-newsports.com"
            // h5Address
            // : 
            // "https://h5.st-newsports.com"
            // pcAddress
            // : 
            // "https://pc.st-newsports.com"
            // pushServerAddress
            // : 
            // "wss://sptph.server.st-newsports.com"
            // virtualAddress
            // : 
            // "https://virapp.server.st-newsports.com"
          } else {
            setTimeout(this.getFbToken, 1000);
          }
        })
        .catch(() => {
          setTimeout(this.getFbToken, 1000);
        })
    },
    intChange(num) {
      console.log(num)
      if (num == 0) {
        this.$refs.myFieldUser.focus()
      } else {
        this.$refs.myFieldPwd.focus()
      }
    },
    goTourists() {
      noFbtoken().then((res) => {
        // localStorage.setItem('fbtoken', res.data.token)
        localStorage.setItem('serverInfo', JSON.stringify(res.data.serverInfo))
        localStorage.setItem('requestUrl', res.data.serverInfo.pushServerAddress)
      })
      localStorage.setItem('userYou', 'tourists')
      this.setLoginShow(false)
      this.$router.push('/')
    },
    nameInput() {
      if (!/^[a-zA-Z0-9]+$/.test(this.username)) {
        this.nameType = true;
      } else {
        this.nameType = false;
      }
    },
    pwdInput() {
      var regPwd = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$/;
      if (!regPwd.test(this.pwd)) {
        this.pwdType = true;
      } else {
        this.pwdType = false;
      }
    },
    nameFocus() {
      this.nameDown = true;
    },
    nameBlur() {
      this.nameDown = false;
    },
    pwdFocus() {
      this.pwdDown = true;
    },
    pwdBlur() {
      this.pwdDown = false;
    },

    goGoogle(index) {
      switch (index) {
        case 0:
          window.open("https://www.google.com/chrome/", "_blank");
          break;
        case 1:
          window.open("https://support.apple.com/zh-cn/docs", "_blank");
          break;
        case 2:
          window.open("https://www.mozilla.org/en-US/", "_blank");
          break;

        default:
          break;
      }
    },
    getCodeBtn() {
      //  var reEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      // if (!reEmail.test(this.email)) {
      //   this.$notify({
      //     message: "请输入正确的手机地址",
      //     duration: 2000,
      //     background: '#FF4337'
      //   });
      //   return;
      // }
      var reEmail = /^\d{11}$/;
      if (!reEmail.test(this.phone)) {
        this.$notify({
          message: "请输入正确的手机号",
          duration: 2000,
          background: '#FF4337'
        });
        return;
      }
      this.codeLoading = true;

      var pot = {
        //username: this.username,
        phoneNumber: this.phone,

      }
      sysSend_sms(pot).then((res) => {
        if (res.code == 0) {

          this.$notify({
            message: "发送成功",
            duration: 1000,
            background: '#1989fa'
          });
          this.startCountdown();
        }
        this.codeLoading = false;
      })

    },
    startCountdown() {
      this.countdown = 60; // 设置倒计时初始值为60秒
      this.countdownTimer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--; // 每秒减一
        } else {
          clearInterval(this.countdownTimer); // 当倒计时结束，清除定时器
        }
      }, 1000);
    },
    submit() {
      var opt = {};
      if (this.active == 1) {
        var reEmail = /^\d{11}$/;
        if (!reEmail.test(this.phone)) {
          this.$notify({
            message: "请输入正确的手机号",
            duration: 2000,
            background: '#FF4337'
          });
          return;
        }
        if (this.inCode.length < 3) {
          this.$notify({
            message: "请输入验证码",
            duration: 2000,
            background: '#FF4337'
          });
          return;
        }
        this.subLoading = true;
        opt = {
          type: "sms",
          phoneNumber: this.phone,
          code: this.inCode,
        };
      } else {
        var regPwd = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$/;
        if (!/^[a-zA-Z0-9]+$/.test(this.username)) {
          this.$notify({
            message: "用户名只能包含字母和数字",
            duration: 2000,
            background: '#FF4337'
            //background: '#1989fa'
          });
          return;
        }
        if (!regPwd.test(this.pwd)) {
          this.$notify({
            message: "密码为6-18位且包含字母和数字",
            duration: 2000,
            background: '#FF4337'
          });
          return;
        }

        this.subLoading = true;
        opt = {
          username: this.username,
          password: this.pwd,
        };
      }

      sysLogin(opt).then((res) => {
        if (res.code == 0) {
          this.getPlatformBalance()
          localStorage.removeItem('userYou')
          localStorage.setItem("token", res.data);
          localStorage.setItem("loginStatus", true);
          this.$notify({
            message: "登入成功",
            duration: 1000,
            background: "#1989fa",
          });
          this.getFbToken()

          if (this.setUser) {
            localStorage.setItem("userPadaLogin", this.username);
          }
          if (this.setPhone) {
            localStorage.setItem("userPadaPhoneLogin", this.phone);
          }


          if (this.$route.path == '/') {
            // window.location.reload();
            EventBus.$emit('tokenChanged', 'newTokenValue');
          } else {
            this.$router.push("/");
          }
          this.setLoginShow(false)
          this.subLoading = false;


          let token = localStorage.getItem('token')
          if (token) {
            this.checkWs()
          }

        } else {
          this.subLoading = false;
        }

      });
    },
    getPlatformBalance() {
      platformBalance().then()
    },

    checkWs() {
      if (ws._ws.readyState == 1) {
        ws.send({ "reqId": 1, "method": "login", "params": { "token": localStorage.getItem('token') } })

        ws.registerCallback('message', (data) => {
          if (data.type == 'deposit') {
            this.$notify({
              message: "充值" + data.data.amount + "元成功",
              duration: 2000,
              background: "#1989fa",
            });
          }
          if (data.type == 'withdraw') {
            if (data.data.isSuccess) {
              this.$notify({
                message: "提款" + data.data.amount + "元成功",
                duration: 2000,
                background: "#1989fa",
              });
            } else {
              this.$notify({
                message: data.data.remark,
                duration: 2000,
                background: "#FF4337",
              });
            }
          }

        });
      } else {
        setTimeout(() => {
          this.checkWs()
        }, 2000)
      }
    }
  },
  created() {
    if (localStorage.getItem("userPadaLogin")) {
      this.username = localStorage.getItem("userPadaLogin");
    }
    if (localStorage.getItem("userPadaPhoneLogin")) {
      this.phone = localStorage.getItem("userPadaPhoneLogin");
    }

  },
  destroyed() {
    // 组件销毁时记得移除事件监听器
    document.removeEventListener("keydown", this.handleKeyDown);
  },
}
</script>

<style lang="less" scoped>
.loginContentPop {
  padding: 30px 20px;
  width: 335px;
  transition: all 0.5s ease;
  position: relative;
  // min-height: 55dvh;
  border-radius: 12px;
  background: #202C37;

  .rightImg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 19px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logoDemo {
    width: 120px;
    height: 40.305px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .titleLine {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 26px;

    width: 100%;
    margin: 0 auto 12px auto;

    .titName {
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
    }

  }

  .logo {
    margin: 40px auto 30px auto;
    width: 101px;
    height: 149px;
    transition: all 0.5s ease;
  }

  .overall {
    position: absolute;
    width: 100%;
    top: calc(149px + 40px + 30px);
    transition: all 0.5s ease;
  }

  .topTop {
    transition: all 0.5s ease;
    top: 40px;
  }

  .topInt {
    margin-top: 12px !important;
  }

  .intOut {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 8px 12px;
    height: 42px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.04);
    margin-bottom: 12px;

    // background: rgba(255, 255, 255, 0.04);
    .ints {
      background: transparent;
      border: 0;
      height: 21px;

      padding-left: 0;
      width: calc(100% - 16px);
      padding: 0;
      color: #fff;
      font-size: 14px;
      font-family: PingFang SC;
      text-indent: 1px;
      padding-left: 1px;
    }

    .ints::placeholder {
      color: #506D87;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }

    .intLeftOut {

      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      .intLeftImg {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }

    .eyes {
      background: url('@/assets/app/loginPop/eye.webp') no-repeat;
      width: 20px;
      height: 20px;
      background-size: 100% 100%;
    }

    .closeEyes {
      background: url('@/assets/app/loginPop/closeEye.webp') no-repeat;
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
    }
  }

  .errorIntOut {
    border-color: #ff4337;
  }

  .correctIntOut {
    border-color: #4774da;
  }

  .tips {
    color: #4774da;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 18px */
    width: 100%;
    margin: 4px auto 4px auto;
    text-align: left;
    height: 14px;
  }

  .errorIntTips {
    color: #ff4337;
  }

  .tops {
    margin-top: 12px;
  }

  .typse {
    width: 100%;
    margin: 0 auto 0 auto;
    align-items: center;
    color: rgba(177, 186, 211, 0.65);
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-align: left;

    span {
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }

  .btn {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 1);
    font-family: "PingFang SC";
    font-weight: 600;
    font-size: 16px;
    margin: 0 auto 12px auto;
    border: 0;
    border-radius: 4px;
    background: #4774DA;
  }

  .flex-ju {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0px auto 0 auto;

    .flex-left {
      display: flex;
      align-items: center;
      color: rgba(177, 186, 211, 0.65);

      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      /* 18px */

      letter-spacing: 0.5px;

      .yuanwai {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }

    .flex-right {
      color: rgba(177, 186, 211, 0.65);
      text-align: right;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0.5px;
    }
  }

  .goLine {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;

    .goDemo {
      width: 105px;
      display: flex;
      padding: 10px 0px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.04);

      .toopImg {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .goTitle {
        width: 100%;
        color: #82889b;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 18px */
      }
    }
  }

  .noUser {
    text-align: center;
    width: 100%;
    margin: 30px auto;
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .dibu {
    width: 100%;


    margin: 12px auto 0 auto;

    .titles {
      color: rgba(177, 186, 211, 0.65);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 12px;
    }

    .imgDemo {
      display: flex;
      align-items: center;
      margin: 12px auto 0 auto;
      width: 64%;
      justify-content: space-between;

      img {
        width: 48px;
        height: 55px;
      }
    }
  }
}

/deep/.van-field__control {
  color: #fff;
}

/deep/.van-tabs {
  border-radius: 12px;
  background: #263440;
}

/deep/.van-tabs__wrap {
  // margin-bottom:12px;
  border-radius: 12px 12px 0 0;

  .van-tabs__line {
    // background-color: #4774DA !important;
    display: none;
    width: 50%;
  }

  .van-tab {
    transition: all 0.5s;
    color: #627FA0;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    background: #1B262F;
  }

  .van-tab--active {
    background: #263440;

  }
}

.line-int {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;


  .intDemoOut {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.04);
    width: 66px;
    height: 42px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .int {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 0;
    color: #fff;
    font-size: 14px;
    font-family: PingFang SC;
    text-indent: 1px;
    padding-left: 1px;
    text-align: center;
  }

  .int::placeholder {
    color: #506D87;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
  }

  .codeDemo {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.04);
    width: 177px;
    height: 42px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .codeBtn {
    border-radius: 4px;
    background: #4774DA;
    width: 120px;

    height: 42px;
    border: 0;
    color: #FFF;

    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中（如果需要） */
  }

  .sjh {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.04);
    width: 231px;
    height: 42px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      padding: 0;
      color: #fff;
      font-size: 14px;
      font-family: PingFang SC;
      text-indent: 1px;
      padding-left: 1px;
    }

    input::placeholder {
      color: #506D87;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }
  }
}

.zlgOut {
  width: 100%;
  padding: 15px 15px 12px;
  background: #263440;
  border-radius: 0;
}

.bomOut {
  background: #263440;
  border-radius: 0 0 12px 12px;
  padding: 0 15px 30px;
}
</style>
