<template>
    <div class="watOut" :class="{ 'heightOut': isApp() }">
        <div class="dqklq">
            
            <img class="moneyLogo" src="@/assets/activity/water/money.png" />
            <div class="zhye">账户余额：</div>
            <div class="moneys">{{ balanceOne }}<span>.{{ balanceTwo }}</span></div>
            <!-- goset -->
            <div class=" reload" @click="init()" :class="{
                rotate:
                    loading || getHomeLoading || subLoading
            }">
                <!-- <img src="@/assets/activity/water/sx.png" /> -->
            </div>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list v-if="!isLoading" v-show="loading ||  (list !== undefined && list.length > 0)" v-model="loading" :finished="finished"
                finished-text="没有更多了" @load="onLoad" :immediate-check="false">
                <div class="conts" v-for="(item,index) in list" :key="index">
                    <div class="contItem">
                        <div class="left">
                            <div class="yuan" :style="{background:getBj(item.id % 3)}">
                                {{ item.title?.match(/[\u4e00-\u9fa5]/) ? item.title?.match(/[\u4e00-\u9fa5]/)[0] : '' }}
                            </div>
                            <div class="">
                                <div class="titles">{{ item.title }}</div>
                                <div class="times">{{ item.createTime }} <span><span class="jine">￥</span>{{ item.amount }}</span></div>
                            </div>
                        </div>

                        <div class="right">
                            <van-button class="linq" @click="getSubmit(item)"  :disabled="item.limitSecond && item.limitSecond > 0 || !item.expireSecond || item.expireSecond <= 0">{{ item.title == "返水"? '前往':'领取' }}</van-button>
                            <div class="djjs" v-if="(item.limitSecond && item.limitSecond > 0) || (item.expireSecond && item.expireSecond > 0)">
                                {{ formatTime(item.limitSecond ? item.limitSecond : item.expireSecond) }}
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
        <div class="noNewRecord" v-show="!loading &&  !(list !== undefined && list.length > 0)">
      <img class="noNewImg" src="@/assets/record/noRecord.svg" />
      <div class="noTitle">还没有新的记录</div>
      
    </div>
   
    <van-popup v-model="yumNotPopShow" class="yumPop"  position="bottom" get-container="body">
      
      <div class="yumOut">
        <div class="jiaob">
          <img src="@/assets/home/not/1.webp" />
        </div>
        <div class="closeYum" @click="yumNotPopShow = false">
            <img src="@/assets/home/not/closeNot.svg" />
          </div>
        <div class="notIn">
          

          <div class="notTitle">下载APP</div>
          <div class="notTips">
            app专属会员福利，请下载领取。
          </div>
          
          <van-button class="yjbctp"  @click="$router.push('/download')">去下载</van-button>
        </div>
      </div>

    </van-popup>
    </div>
</template>
<script>
import {

    platformBalance,
    welfareWait_receive,
    welfareReceive
} from "@/request/api";
export default {
    name: "waterIndex",

    data() {
        return {
            balanceOne: 0,

            getHomeLoading: false,
            isLoading: false,
            finished: false,
            list: [],
            loading: false,
            pageNumber: 1,
            subLoading: false,
            timer: null,
            yumNotPopShow:false,
            balanceTwo:'00',
        }
    },
    mounted() {
        // this.getWelfareWait_receive();
        // this.getPlatformBalance();
        // this.startCountDown();
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
    methods: {
        goActivity(item) {
      if (item.activityCode == "SIGN") {
        
        this.$router.push("/activity");
      } else {
        var times = "";
        times = "长期";
        if (item.activityCode == "zhizhen") {
          
          this.$router.push({
            path: "/perfectionActivity",
            query: { code: item.activityCode, time: times, id: item.id },
          });
        } 
        else if (item.activityCode == "EBPayDeposit" || item.activityCode == 'EBPayWithdraw' || item.activityCode == 'revive') {
          
            this.$router.push({
              path: "/ebpayActivity",
              query: { code: item.activityCode, time: times, id: item.id },
            });
        } else if (item.activityCode == "xrlb3" || item.activityCode == 'xrlb4') {
          
            this.$router.push({
              path: "/newActivity2",
              query: { code: item.activityCode, time: times, id: item.id },
            });
        } else if (item.activityCode == "DZP" || item.activityCode=='dzp') {
          
            this.$router.push({
              path: "/turntableActivity",
              query: { code: item.activityCode, time: times, id: item.id },
            });
        } 
         else
          if (item.activityCode == "xrlb1" || item.activityCode == "xrlb2") {
            
            this.$router.push({
              path: "/newActivity",
              query: { code: item.activityCode, time: times, id: item.id },
            });
          }
          else if(item.activityCode == 'mgGame'){
           
           window.open('https://mgr.basebit.net/#/static/exclusive-jackpot/zh-cn', "_blank");
         }

          else {
            
            this.$router.push({
              path: "/sportsActivity",
              query: { code: item.activityCode, time: times, id: item.id },
            });
          }
      }
    },
        isApp() {
      var origin = window.location.origin;
      if (origin.includes("app")) {
        localStorage.setItem("downShow", true);
      }
      return !origin.includes("app");
    },
        getSubmit(item){
            console.log(item);
            
            if(this.subLoading){
                return;
            }
            this.subLoading = true;
            // if(this.isApp()){
            //     this.yumNotPopShow = true;
                
            //     this.subLoading = false;
            //     return;
            // }
            if(item.title == "返水"){
                this.$emit('goTab',1)
                this.subLoading = false;
                return;
            }
            if(item.isJump){
                this.goActivity(item)
                return;
            }
            this.$store.commit("setLoadingShowTrue");
            welfareReceive(item.id,item.type).then((res) => {
                if (res.code == 0) {
                    this.$notify({
                        message: "领取成功",
                        duration: 2000,
                        background: '#1989fa'
                    });
                    this.onRefresh();
            this.getPlatformBalance();
                    
                }else{
                    this.$store.commit("setLoadingShowFalse");
                }
                this.subLoading = false;
                
            })
        },
        getBj(index){
            switch (index) {
                case 0:
                    return '#CC3EE9';
                    case 1:
                    return '#4774DA';
                    case 2:
                    return '#25BB42';
                    
                default:
                return '#4774DA';
            }
        },
        init() {
            if (this.loading || this.getHomeLoading || this.subLoading) {
                return;
            }
            this.onRefresh();
            this.getPlatformBalance();
        },
        onRefresh() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.pageNumber = 1;
            this.finished = false;
            this.list = [];
            this.getWelfareWait_receive();
            this.isLoading = false;
            this.$store.commit("setLoadingShowFalse");
        },
        onLoad() {
            // 异步更新数据
            // this.pageNumber++;
            // this.getWelfareWait_receive();
            this.loading = false;
        },
        getWelfareWait_receive() {
            this.loading = true;
            welfareWait_receive().then((res) => {
                if (res.code == 0) {
                    if (this.list.length > 0) {
                        // console.log(this.list.length)
                        this.list.push(...res.data);
                    } else {
                        this.list = res.data;
                    }
                    this.$emit("getWait",res.data.length);
                    this.startCountDown();
                }
                this.loading = false;
                if (res.data.totalPage <= this.pageNumber) {
                    this.finished = true;
                }
            })
        },
        getPlatformBalance() {
            platformBalance().then((res) => {
                this.getHomeLoading = true;
                if (res.code == 0) {
                    var balan = (res.data.balance + res.data.freeze + res.data.totalAmount).toFixed(2);
                    if (balan.indexOf(".") == -1) {
                        balan = `${balan}.00`;
                    }
                    var dotIndex = balan.indexOf(".");
                    this.balanceOne = balan.slice(0, dotIndex);
                    this.balanceTwo = balan.slice(dotIndex + 1);
                }
                this.getHomeLoading = false;
            })
        },
        startCountDown() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                let needRefresh = false;
                this.list = this.list.map(item => {
                    if (item.limitSecond > 0) {
                        item.limitSecond--;
                        if (item.limitSecond <= 0) {
                            needRefresh = true;
                        }
                    } else if (item.expireSecond > 0) {
                        item.expireSecond--;
                        if (item.expireSecond <= 0) {
                            needRefresh = true;
                        }
                    }
                    return item;
                });
                
                if (needRefresh) {
                    this.onRefresh();
                }
            }, 1000);
        },
        formatTime(seconds) {
            if (!seconds || seconds <= 0) return '00:00:00';
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        },
    }
}
</script>
<style lang="less" scoped>
@import '@/style/global.less';
.watOut {
    padding-top: 20px;
    // min-height: calc(100dvh - 48px - 48px - 60px);
    height: calc( 100dvh - 48px - 48px - 71px );
    padding-bottom: 20px;
  overflow-y: auto;
    background: @allBj;
    .dqklq {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left: 25px;
        .zhye{
            font-size: 14px;
            color:#fff;
        }
        .moneyLogo {
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .moneys {
            color: #FFF;
            text-align: right;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;

            span {
                color: rgba(255, 255, 255, 0.45);
                font-family: "PingFang SC";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
            }
        }

        .goset {
            width: 14px;
            height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .reload {
            background: url("@/assets/activity/water/sx.png") no-repeat;
            background-size: 100% 100%;
            width: 12px;
            height: 12px;
            margin-left: 6px;
        }
    }

    .conts {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.04);
        width: 92%;
        margin: 10px auto 0;

        .contItem {
            width: 100%;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
                gap: 14px;

                .yuan {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    color: #FFF;
                    text-align: center;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                }

                .titles {
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    text-align: left;
                }

                .times {
                    color: rgba(177, 186, 211, 0.65);
                    margin-top: 5px;
                    font-family: "PingFang SC";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;

                    span {
                        color: #FFE816;

                        text-align: right;
                        font-family: "PingFang SC";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                    }

                    .jine {
                        font-size: 10px;
                    }
                }
            }

            .right {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;

                .linq {
                    border-radius: 22px;
                    background: #4774DA;
                    color: #FFF;
                    text-align: center;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    border: 0;
                    height: 29px;

                    &.van-button--disabled {
                        background: #465666;
                        color: #8695A6;
                        opacity: 1;
                    }
                }

                .djjs {
                    color: #B1BAD3;
                    width: 100%;
                    text-align: center;
                    font-family: "PingFang SC";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    margin-top: 2px;
                }
            }

        }
    }
    .noNewRecord {
    padding-top: 130px;

    .noNewImg {
      width: 136px;
      height: 136px;
    }

    .noTitle {
      color: rgba(255, 255, 255, 0.45);
      text-align: center;

      /* 内容/S-辅助性文字 */
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      margin-top: 10px;
    }

    .isCkBtn {
      display: inline-flex;
      padding: 10px 34px;
      align-items: center;
      gap: 279px;
      border-radius: 8px;
      background: linear-gradient(90deg, #0385ff 0%, #379eff 100%);
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      margin-top: 30px;
    }
  }
}

.reload {
    &.rotate {
        animation: spin 1s linear infinite;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.yumPop {
  
  background: transparent;
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;

  .yumOut {
    // padding: 37px 16px;
    position: relative;
    .jiaob {
   
      width: 169px!important;
height: 159px!important;
      top: -48px;
      right: -20px;

      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      img {
        z-index: 0;
        width: 100%;
        height: 100%;
      }
    }
    .closeYum {
      position: absolute;
      top: -48px;
      width: 24px;
      height: 24px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 8;

      img {
        width: 100%;
        height: 100%;
        z-index: 8;
      }
    }
    .notIn {
      background: linear-gradient(203deg, #2964CC -30.44%, #202C37 65.13%) !important;
      width: 280px;
    
    flex-shrink: 0;
    border-radius: 20px;
    position: relative;
    padding: 40px 20px 15px;


    .notTitle {
      color: #fff;
      font-family: "Microsoft YaHei UI";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      /* 24px */
      text-align: left;
    }

    .notTips {
      color: #fff;
      font-family: "Microsoft YaHei UI";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 19.2px */
      text-align: left;
      margin-top: 30px;
    }
    .linkOut{
      display: flex;
      justify-content: flex-start;
      margin-top:15px;
      gap:  10px; 
      flex-wrap: wrap;
      width: 100%;
      
    }
    .qnljyxgfym{
      color: #82889B;
      margin: 10px 0 15px;
font-family: "Microsoft YaHei UI";
font-size: 14px;
font-style: normal;
font-weight: 400;
text-align: left;
    }
    .linkLine{
      border-radius: 4px;
background: #15212D;
display: flex;
width: fit-content;
padding: 5px 10px;
align-items: center;
gap: 5px;
justify-content: flex-start;
color: #1894FF;
height: 27px;
font-family: "Microsoft YaHei UI";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 27px;

      img{
        width: 12px;
height: 12px;
flex-shrink: 0;
      }
    }
    .notDetail {
      color: #82889b;
      font-family: "Microsoft YaHei UI";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 21px */
      text-align: left;
      margin-top: 15px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .ymBomTips {
      color: #82889b;
      font-family: "Microsoft YaHei UI";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 16.8px */
      text-align: left;
      margin-top:10px;
      margin-bottom:10px;
    }
    .yjbctp{
      border-radius: 8px;
      background: #0385ff;
      margin-top:22px;

      height: 36px;
      width: 100%;
      border:0 ;
      color: #FFF;
    line-height: 36px;
    color: #FFF;

font-family: "Microsoft YaHei UI";
font-size: 16px;
font-style: normal;
font-weight: 700;
    }
    }
  }



}
.heightOut{
  height: calc( 100dvh - 48px - 48px - 60px )!important;
}
</style>