<template>
    <div class="loginContentPop">
        <div class="rightImg" @click="successfulClose"><img src="@/assets/app/close.webp" /></div>
        <div class="okImg imgOut"><img src="@/assets/login/ok.png" /></div>
        <div class="gxnzh">恭喜您，账号创建成功</div>
        <div class="tipss">现在存款，即可参与力度空前的优惠活动。丰厚的回报、贴心的服务，让您的财富之路更加顺畅。快来加入，一起开启财富盛宴</div>
        <div class="btnLines">
            <div class="myBtn"  @click="gogo('/activityOut')">
                <div class="btnImg imgOut"><img src="@/assets/login/leftBtn.png" /></div>
                <div class="btnTxt">查看优惠</div>
            </div>
            <div class="myBtn" @click="gogo('/deposit')">
                <div class="btnImg imgOut"><img src="@/assets/login/rightBtn.png" /></div>
                <div class="btnTxt">去存款</div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions } from 'vuex';
export default {
    name: "successfulPop",
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {
        ...mapActions(['setSuccessfulShow']),
        successfulClose() {
            this.setSuccessfulShow(false)
        },
        gogo(url){
            this.$router.push(url)
        },



    },


}
</script>

<style lang="less" scoped>
.loginContentPop {
    padding: 20px 17px 15px;
    width: 335px;
    transition: all 0.5s ease;
    position: relative;
    border-radius: 12px;
    background: rgba(42, 54, 68, 1);
    .rightImg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 19px;

    img {
      width: 100%;
      height: 100%;
    }
  }
    .okImg {
        width: 110px;
        height: 110px;
        margin: 0 auto;
    }

    .gxnzh {
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 20.7px;
        line-height: 100%;
        letter-spacing: 0%;
        background: linear-gradient(180deg, #F3F7E3 0%, #EDF29E 100%);
        -webkit-background-clip: text;
        /* 必须加上这个属性来实现背景裁剪 */
        color: transparent;
        /* 设置文字颜色为透明，这样才能看到背景渐变 */
        margin: 23px auto 0;
    }

    .tipss {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0%;
        text-align: center;
        color: rgba(143, 150, 176, 1);
        margin: 12px 0 0;
    }

    .btnLines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        .myBtn {
            width: calc( 50% - 10px);
            height: 50px;
            top: 275px;
            left: 17px;
            border-radius: 4px;
            background: linear-gradient(267.72deg, #8634FF 0.88%, #F698F0 95.75%);
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14.75px;
            line-height: 100%;
            letter-spacing: 0%;
            color: #fff;

            .btnImg {
                width: 34px;
                height: 34px;
            }
        }
    }
}

.imgOut {
    display: flex;
    align-items: center;
    justify-content: center;


    img {
        width: 100%;
        height: 100%;
    }
}
</style>