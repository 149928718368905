<template>
    <div class="watOut" :class="{ 'heightOut': isApp() }">
        <div class="topLine">
            <div class="selectBtn" @click="btn1 = !btn1">
              {{ btnOneName
                  }}<img class="jt" :style="{ transform: btn1 ? 'rotate(180deg)' : '' }"
                    src="@/assets/record/jt.svg" />
            </div>
            <div class="selectBtn" @click="btn2 = !btn2">
              {{ btnTwoName
            }}<img class="jt"   :style="{ transform: btn2 ? 'rotate(180deg)' : '' }"
                    src="@/assets/record/jt.svg" />
            </div>
            <div class="selectBtn" @click="btn3 = !btn3">
                {{ btnStatusName }}<img class="jt"   :style="{ transform: btn3 ? 'rotate(180deg)' : '' }"
                    src="@/assets/record/jt.svg" />
            </div>
        </div>
        <div class="dqklq">
            <div class="tongj">总计<span>{{ total }}</span>笔</div>
            <div class="tongj">奖金<span class="fuh">￥</span><span class="rightM">{{ totalAmount }}</span></div>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-if="!isLoading"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <div class="conts">
            <div class="contItem" v-for="(item,index) in list" :key="index" >
                <div class="left">
                    <div class="yuan"   
                    :style="{background:getBj(item.id % 3)}"
                    >
{{ item.typeName.match(/[\u4e00-\u9fa5]/) ? item.typeName.match(/[\u4e00-\u9fa5]/)[0] : '' }}

                    </div>
                    <div class="">
                        <div class="titles">{{ item.typeName }}</div>
                        <div class="times">{{ item.createTime }} <span><span class="jine">￥</span>{{ item.amount }}</span></div>
                    </div>
                </div>

                <div class="right" :class="{ygq: item.status!=1}">
                    {{ item.status == 1 ? '已领取' : '已过期' }}
                </div>
            </div>
        </div>
    </van-list>
</van-pull-refresh>
<van-popup v-model="btn1" class="typePop" position="bottom" get-container="body">
      <div class="particulcss">
        <div class="tiaot"></div>
        <div class="titLine">
          <div class="titClose" @click="btn1 = false">取消</div>
          <div class="titConter">快捷筛选</div>
          <div class="titClose" style="opacity: 0">取消</div>
        </div>
        <div class="gdDiv">
          <div class="qbOut">
            <div
              class="qbList"
              v-for="(item, index) in typeList"
              :key="index"
              @click="(typeId = item.id), (btnOneName = item.name), onRefresh()"
            >
              {{ item.name }}
              <div
                class="customBom"
                :style="{ opacity: typeId == item.id ? '1' : '0' }"
              ></div>
            </div>
          </div>
          
        </div>
        <div class="dibu">
          <van-button class="closeBtn" @click="btn1 = false">取消</van-button>
          <van-button class="subBtn" @click="btn1 = false">确定</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="btn3" class="typePop" position="bottom" get-container="body">
      <div class="particulcss">
        <div class="tiaot"></div>
        <div class="titLine">
          <div class="titClose" @click="btn1 = false">取消</div>
          <div class="titConter">状态筛选</div>
          <div class="titClose" style="opacity: 0">取消</div>
        </div>
        <div class="gdDiv" style="margin: 10px auto 30px;">
    <div class="typeTitle">状态筛选</div>
    
          <div class="qbOut">
            <div
              class="qbList"
              v-for="(item, index) in statusList"
              :key="index"
              @click="setStatus(item)"
            >
              {{ item.name }}
              <div
                class="customBom"
                :style="{ opacity: statusActive == item.id ? '1' : '0' }"
              ></div>
            </div>
          </div>
          </div>
          <div class="dibu">
          <van-button class="closeBtn" @click="btn3 = false">取消</van-button>
          <van-button class="subBtn" @click="btn3 = false">确定</van-button>
        </div>
      </div>
        </van-popup>
    <van-popup v-model="btn2" class="typePop" position="bottom"  get-container="body">
      <div class="particulcss" style="height: auto">
        <div class="tiaot"></div>
        <div class="titLine">
          <div class="titClose" @click="btn2 = false">取消</div>
          <div class="titConter">时间筛选</div>
          <div class="titClose" style="opacity: 0">取消</div>
        </div>
        <div class="timeListOyt">
          <div
            class="timeDemo"
            v-for="(item, index) in timeList"
            :key="index"
            @click="selectTime(item, index)"
          >
            {{ item.name }}
            <div
              class="customBom"
              :style="{ opacity: timeActive == index ? '1' : '0' }"
            ></div>
          </div>
        </div>
        <div class="zdyTitle">自定义</div>
        <div class="zdyList">
          <div
            class="zdyTime"
            :style="{ color: timeActive == 4 ? '#4774DA' : '#607486' }"
            @click="
              (timeActive = 4), (btnTwoName = '自定义'), (timePopShow = true)
            "
          >
            {{ startTime.substring(0, 10) }}
          </div>
          <div class="zhi">至</div>
          <div
            class="zdyTime"
            :style="{ color: timeActive == 5 ? '#4774DA' : '#607486' }"
            @click="
              (timeActive = 5), (btnTwoName = '自定义'), (timePopShow = true)
            "
          >
            {{ endTime.substring(0, 10) }}
          </div>
        </div>
        <div class="dqzc">*当前系统支持查询最近30日的福利记录</div>

        <div class="dibu">
          <van-button class="closeBtn" @click="btn2 = false">取消</van-button>
          <van-button class="subBtn" @click="timeSmb">确定</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="timePopShow" class="typePop" position="bottom"  get-container="body">
      <div class="particulcss" style="height: auto">
        <div class="tiaot"></div>
        <div class="titLine">
          <div class="titClose" @click="timePopShow = false">取消</div>
          <div class="titConter">时间筛选</div>
          <div class="titClose" style="opacity: 0">取消</div>
        </div>
        <van-datetime-picker type="date" v-model="setDateTime" />
        <div class="dibu">
          <van-button class="closeBtn" @click="timePopShow = false"
            >取消</van-button
          >
          <van-button class="subBtn" @click="setZDYTime">确定</van-button>
        </div>
      </div>
    </van-popup>
    </div>
</template>
<script>
import {


    welfareRecord, welfareReceive,welfareType

} from "@/request/api";
export default {
    name: "fourIndex",

    data() {
        return {
            qbbtnState:false,
            isLoading: false,
      finished: false,
      list: [],
      loading: false,
      subLoading: false,
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      totalAmount: 0,
      btn1: false,
      btn2: false,
      timePopShow:false,
      typeList: [{ id: null, name: "全部" }],
      typeId: null,
      btn3:false,
      btnStatusName:"全部",
      statusList: [
        {
          name: "全部",
          id: null,
        },
        // {
        //   name: "待领取",
        //   id: 0,
        // },
        {
          name: "已领取",
          id: 1,
        },
        // {
        //   name: "已失效",
        //   id: 2,
        // },
        // {
        //   name: "申领审核中",
        //   id: 3,
        // },
        // {
        //   name: "申领成功",
        //   id: 4,
        // },
        // {
        //   name: "申领失败",
        //   id: 5,
        // },
        {
          name: "已过期",
          id: 6,
        },
      ],
      timeList: [
        {
          name: "今天",
        },
        {
          name: "昨天",
        },
        {
          name: "近7日",
        },
        {
          name: "近30日",
        },
      ],statusActive: null,
      timeActive: 3,
      btnOneName: "全部",
      btnTwoName: "近30日", //时间显示
      startTime: "",
      endTime: "",
     
      setDateTime: new Date(),
        }
    },
    created(){
      this.getSevenDay(29);
      this.getWelfareType();
    },
    mounted() {
      
        
        // this.getWelfareRecord();
    },
    methods: {
      timeSmb() {
      if (this.timeActive >= 4) {
        this.onRefresh();
      } else {
        this.btn2 = false;
      }
    },
    isApp() {
      var origin = window.location.origin
      return !origin.includes("app")
    },
    setStatus(item){
      this.statusActive = item.id;
      this.btnStatusName = item.name;
       this.onRefresh()
    },
    selectTime(item, index) {
      this.timeActive = index;
      this.btnTwoName = item.name;
      switch (index) {
        case 0:
          //全部
          // this.startTime = "";
          // this.endTime = "";
          //今日
          this.getNewDate();
          break;
        case 1:
          this.getSevenDay(1);
          break;
        case 2:
          this.getSevenDay(6);
          break;
        case 3:
          this.getSevenDay(29);
          break;
        default:
          return;
      }
      this.onRefresh();
    },
    setZDYTime() {
      console.log(this.setDateTime);
      if (this.timeActive == 4) {
        this.startTime = this.formatDate(this.setDateTime) + " 00:00:00";
        // this.startTime = `${e.getValues().join('-')} 00:00:00`
      } else if (this.timeActive == 5) {
        this.endTime = this.formatDate(this.setDateTime) + " 23:59:59";
        // this.endTime = `${e.getValues().join('-')} 23:59:59`
      }
      this.timePopShow = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    getNewDate() {
      var now = new Date();
      var year = now.getFullYear();
      var month = String(now.getMonth() + 1).padStart(2, "0");
      var day = String(now.getDate()).padStart(2, "0");
      this.startTime = `${year}-${month}-${day} 00:00:00`;
      this.endTime = `${year}-${month}-${day} 23:59:59`;
    },
    getSevenDay(time) {
      const endDate = new Date(); // 结束日期为今天
      endDate.setHours(23, 59, 59, 999); // 将时间设置为 23:59:59

      const startDate = new Date(endDate); // 起始日期初始化为结束日期
      startDate.setDate(startDate.getDate() - time); // 获取7天前的日期
      startDate.setHours(0, 0, 0, 0); // 将时间设置为 00:00:00
      // 格式化日期为 yyyy-MM-dd HH:mm:ss 格式
      const format = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      this.startTime = format(startDate);
      this.endTime = format(endDate);
    },
        getWelfareType() {
      welfareType().then((res) => {
        if (res.code == 0) {
          this.typeList.push(...res.data);
        }
      });
    },
        getBj(index){
            switch (index) {
                case 0:
                    return '#CC3EE9';
                    case 1:
                    return '#4774DA';
                    case 2:
                    return '#25BB42';
                    
                default:
                return '#4774DA';
            }
        },
        typeBtn(){},
        onRefresh() {
          this.btn1 = false;
      this.btn2 = false;
      this.btn3 = false;
      //下拉执行
      this.isLoading = false;
      this.pageNumber = 1;
      this.finished = false;
      this.list = [];
      this.getWelfareRecord();
    },
    onLoad() {
      // 异步更新数据
      this.pageNumber++;
      this.getWelfareRecord();
    },
    getWelfareRecord() {
      var opt = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        isActivity:true,
        typeId: this.typeId,
        startTime: this.startTime,
        endTime: this.endTime,
        status: this.statusActive,
      };
      welfareRecord(opt).then((res) => {
        if (res.code == 0) {
          if (res.code == 0) {
            if (this.list.length > 0) {
              this.list.push(...res.data.data);
            } else {
              this.list = res.data.data;
            }
          }
        }
        this.total = res.data.additional.count;
        this.totalAmount = res.data.additional.totalAmount;
        this.loading = false;
        if (res.data.totalPage <= this.pageNumber) {
          this.finished = true;
        }
      });
    },
    submit(item) {
      this.subLoading = true;
      welfareReceive(item.id).then((res) => {
        if (res.code == 0) {
          this.$notify({
            message: "领取成功",
            duration: 2000,
            background: "#1989fa",
          });
          this.onRefresh();
        }
        setTimeout(() => {
          this.subLoading = false;
        }, 2000);
      });
    },
    }
}
</script>
<style lang="less" scoped>
@import '@/style/global.less';
.watOut {
    padding-top: 20px;
    // min-height: calc(100dvh - 48px - 48px - 60px);
    height: calc( 100dvh - 48px - 48px - 71px );
    padding-bottom: 20px;
  overflow-y: auto;
  background: @allBj;
    .topLine {
        width: 92%;
        margin: 0 auto 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .selectBtn {

            transition: all 0.5s ease;
            display: flex;
            min-width: 72px;
            width: 32%;
            padding: 5px 15px;
            height: 36px;
            justify-content: space-between;
            align-items: center;
            gap: 2px;
            border-radius: 20px;
            background: rgba(255, 255, 255, 0.04);
            // color: rgba(255, 255, 255, 0.65);
            color: rgba(255, 255, 255, 1);
            text-align: center;

            /* 内容/M-大多数文字 */
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 100;

            .jt {
                transition: all 0.5s ease;
                margin-left: 2px;
                width: 12px;
                height: 12px;
            }
        }
    }

    .dqklq {
        display: flex;
        align-items: left;
        gap: 5px;
        padding: 0 25px;
        width: 100%;

        .tongj {
            width: 100%;
            text-align: left;
            color: #B1BAD3;

            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            span {
                color: #FFF;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                margin: 0 3px;
            }

            .fuh {
                color: #FFF;
                font-family: "PingFang SC";
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                margin-right: 0 !important;
            }

            .rightM {
                margin-left: 0 !important;
            }
        }



    }

    .conts {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.04);
        width: 92%;
        margin: 10px auto 0;

        .contItem {
            width: 100%;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
                gap: 14px;

                .yuan {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    color: #FFF;
                    text-align: center;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                }

                .titles {
                    color: #FFF;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    text-align: left;
                }

                .times {
                    color: rgba(177, 186, 211, 0.65);
                    margin-top: 5px;
                    font-family: "PingFang SC";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;

                    span {
                        color: #FFE816;

                        text-align: right;
                        font-family: "PingFang SC";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                    }

                    .jine {
                        font-size: 10px;
                    }
                }
            }

            .right {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                color: #2CD158;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;

                .linq {
                    border-radius: 22px;
                    background: #4774DA;
                    color: #FFF;
                    text-align: center;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    border: 0;
                    height: 29px;
                }

                .djjs {
                    color: #B1BAD3;
                    width: 100%;
                    text-align: center;
                    font-family: "PingFang SC";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    margin-top: 2px;
                }
            }
            .ygq{
              color:#768392!important;
            }
        }
    }
}
.typePop {
  background: transparent;
  .particulcss {
    // height: calc(100dvh - 100px);
    width: 100%;
    border-radius: 16px 16px 0 0;
    background: #202c37;
    padding-bottom: 25px;
    overflow: hidden;
    .tiaot {
      width: 120px;
      height: 4px;
      flex-shrink: 0;
      border-radius: 2px;
      background: #283643;
      margin: 15px auto 0 auto;
    }
    .detailTitle {
      margin-top: 40px;
      padding-left: 25px;
      text-align: left;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "SF Pro Display";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 36px */
    }
    .titTwo {
      padding-left: 25px;
      text-align: left;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 36px */
    }
    .partDetails {
      position: relative;
      z-index: 1;
      width: 92%;
      height: 523px;
      flex-shrink: 0;
      border-radius: 16px;
      background: linear-gradient(
        180deg,
        rgba(40, 54, 67, 0.6) 0%,
        rgba(32, 44, 55, 0.6) 35.63%
      );
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      margin: 20px auto 0 auto;
      padding: 20px;
      .redGem {
        position: absolute;
        top: -66px;
        right: 0;
        background-image: url("@/assets/share/redGem.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 206.582px;
        height: 80px;
      }
      .partList {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15px;
        .partLeft {
          width: 60px;
          height: 60px;
          flex-shrink: 0;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .partRight {
          padding: 10px 0;
          .partTitle {
            color: #fff;
            font-family: "SF Pro Display";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 24px */
            text-align: left;
          }
          .partTitTwo {
            color: #fff;
            font-family: "PingFang SC";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
            text-align: left;
            margin: 2px 0;
          }
          .partThree {
            color: #fff;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            text-align: left;
          }
        }
      }
    }
    .partDetails::before {
      width: 209.674px;
      height: 75.231px;
      content: " ";
      position: absolute;
      top: -33.65px;
      right: 0;
      background-image: url("@/assets/share/markRed.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 0;
    }

    .titLine {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 89.333333%;
      margin: 20px auto 0 auto;
      .titClose {
        color: #fff;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
      }
      .titConter {
        color: #fff;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 20px */
      }
    }
    .partakeLine {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 89.333333%;
      margin: 30px auto 0 auto;
      flex-wrap: wrap;
      .partakeDemo {
        display: flex;
        width: 80px;
        flex-direction: column;
        align-items: center;
        gap: 11px;
        margin-bottom: 20px;
        .partakeImg {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .partakeName {
          color: #fff;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
        }
      }
    }
    .submits {
      display: flex;
      width: 84%;
      height: 44px;
      padding: 8px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 22px;
      background: #4774da;
      border: 0;
      margin: 10px auto;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
    }
    .codeDetails {
      margin: 30px auto 0 auto;
      width: 92%;
      height: 493px;
      padding-top: 30px;
      background: linear-gradient(180deg, #283643 0%, #202c37 59.13%);
      .codeDemo {
        padding-top: 21px;
        margin: 0 auto;
        width: 250px;
        height: 250px;
        background-image: url("@/assets/share/codeDemo.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .wecome {
          color: #fff;
          text-align: center;
          text-overflow: ellipsis;
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 16px */
        }
        .ewm {
          margin: 10px auto;
          width: 156px;
          height: 156px;
          flex-shrink: 0;
          border-radius: 8px;
          background: lightgray 50% / cover no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            padding: 6px;
            margin: 0 auto;
          }
        }
        .qcfootBlack {
          color: #fff;
          text-align: center;
          text-overflow: ellipsis;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 14px */
        }
      }
      .drawings {
        width: 100%;
        background: url("@/assets/share/2.webp") no-repeat;
        background-size: 100% 100%;
        height: 193px;
        margin-top: 43px;
        border-radius: 0 0 20px 20px;
        position: relative;
        .usxr {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          .leftImg {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #4774da;
            border-radius: 100%;
            color: #fff;
            text-align: center;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 16px */
            margin-right: 10px;
          }
          .rightName {
            color: #fff;
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
          }
        }
      }
    }

    .gdDiv {
      height: calc(100% - 89px - 79px);
      overflow-y: scroll;
      width: 92%;
      margin: 30px auto 0 auto;
      .qbOut {
        display: flex;
        width: 100%;

        align-items: center;
        align-content: center;
        gap: 12px;
        flex-wrap: wrap;
        .qbList {
          width: 107px;
          height: 40px;
          background: #283643;

          color: #fff;

          text-align: center;
          text-overflow: ellipsis;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px; /* 14px */
          position: relative;
          border-radius: 4px;
          .customBom {
            opacity: 0;
            position: absolute;
            right: 0.5px;
            bottom: 0.5px;
            background-image: url("@/assets/postWith/enters.svg");
            background-repeat: no-repeat;
            width: 20px;
            height: 16.774px;
            z-index: 1;
            background-size: 100% 100%;
          }
        }
      }
      .typeTitle {
        color: #fff;
        text-align: center;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        width: 100%;
        text-align: left;
        margin: 20px auto 10px auto;
      }
    }
    .dibu {
      width: 92%;
      margin: 10px auto 0 auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .closeBtn {
        width: 165px;
        height: 44px;
        padding: 8px 15px;
        border-radius: 4px;
        background: #283643;
        border: 0;

        color: #fff;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
      }
      .subBtn {
        width: 165px;
        height: 44px;
        padding: 8px 15px;
        border-radius: 4px;
        background: #4774da;
        border: 0;
        color: #fff;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
      }
    }
    .timeListOyt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 92%;
      margin: 30px auto 0 auto;
      .timeDemo {
        height: 40px;
        white-space: nowrap;
        width: 79px;

        border-radius: 4px;
        background: #283643;
        color: #fff;
        text-align: center;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 14px */
        position: relative;
        .customBom {
          opacity: 0;
          position: absolute;
          right: 0.5px;
          bottom: 0.5px;
          background-image: url("@/assets/postWith/enters.svg");
          background-repeat: no-repeat;
          width: 20px;
          height: 16.774px;
          z-index: 1;
          background-size: 100% 100%;
        }
      }
    }
    .zdyTitle {
      width: 92%;
      margin: 20px auto 0 auto;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      text-align: left;
    }
    .zdyList {
      display: flex;
      align-items: center;
      width: 92%;
      margin: 15px auto 0 auto;
      .zdyTime {
        height: 44px;
        width: 151px;
        border-bottom: 1px #2a3d4d solid;

        color: #607486;
        text-align: center;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px;
      }
      .zhi {
        color: #fff;

        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px; /* 14px */
        margin: 0 14px;
      }
    }
    .dqzc {
      width: 92%;
      margin: 15px auto 20px auto;

      color: #ffa103;
      text-align: center;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
    }
    border-radius: 16px 16px 0px 0px;
    background: #202c37;

    /deep/.van-picker__toolbar {
      display: none;
    }
    /deep/.van-picker {
      margin: 10px auto 15px auto;
      background-color: transparent;
      .van-picker__frame {
        background-color: rgba(143, 201, 255, 0.09);
      }
      .van-picker__mask {
        background: transparent;
      }
      .van-hairline-unset--top-bottom::after {
        border-width: 0;
      }
      .van-ellipsis {
        color: #fff;
      }
      .van-picker-column__item--selected {
        position: relative;
        z-index: 5;
      }
    }
  }
}
.heightOut{
  height: calc( 100dvh - 48px - 48px - 60px )!important;
}
</style>