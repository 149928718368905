import emptyimg  from '@/assets/sport/empty.png'
import vanSkeleton from '../components/vanSkeleton.vue'
import btn from '../components/btn.vue'
export default {
  data() {
    return {
      emptyimg: emptyimg,
      oddsType: {
        "1": "欧洲盘",
        "2": "香港盘",
        "3": "马来盘",
        "4": "印尼盘",
        "5": "美式盘",
      },
    }
  },
  components: {
    "van-skeleton": vanSkeleton,
		"Btn": btn
  },
  computed: {
    pankou() {
      return this.$store.state.sport.sportConfig[1].active + 1
    },
    userInfo() {
			return this.$store.state.sport.user
		}
  },

  methods: {

    numToStr(number, styleObj={opacity: 0.6}, n=14) {
      if (!number && !Number(number)) {
        number = 0
      }
      let formattedNumber = number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      let strArr = formattedNumber.split('.')
      if (styleObj == 'fs') {
        styleObj={'font-size': (n-3)+'px'}
      }
      let str = ''
      Object.keys(styleObj).forEach(item => {
        str += item + ':' + styleObj[item] + ';'
      })
      return `<span style='${n+"px"}'>${strArr[0]}</span>.<span style='${str}'>${strArr[1]}</span>`
    },

    copy(str) {
      let tempTextArea = document.createElement('textarea');
      tempTextArea.value = str;
      // 将textarea元素添加到页面上
      document.body.appendChild(tempTextArea);
      // 选中textarea中的文本
      tempTextArea.select();
      // 执行复制命令
      document.execCommand('copy');
      // 清除临时的textarea元素
      document.body.removeChild(tempTextArea);
      this.$toast('复制成功')
    },

    // 计算小时、分钟和秒数
		formatTimestamp(timestamp, n) {
			const date = new Date(timestamp);

			// 获取月、日、小时、分钟、秒
			const year = date.getFullYear();
			const month = date.getMonth() + 1; // 月份是从0开始的，所以要加1
			const day = date.getDate();
			const hours = date.getHours();
			const minutes = date.getMinutes();
			const seconds = date.getSeconds();

			// 格式化成两位数
			const formattedYear = year;
			const formattedMonth = month < 10 ? `0${month}` : month;
			const formattedDay = day < 10 ? `0${day}` : day;
			const formattedHours = hours < 10 ? `0${hours}` : hours;
			const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
			const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

			// 返回格式化后的字符串
			if(n) {
				return `${formattedYear}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
			}
			return `${formattedMonth}/${formattedDay} ${formattedHours}:${formattedMinutes}`;
		},

    // 计算小时、分钟和秒数
		secondsToHMS(seconds) {
			const minutes = Math.floor(seconds / 60);
			const remainingSeconds = seconds % 60;
			// 确保秒数是两位数
			const formattedMinutes = minutes.toString().padStart(2, '0');
			const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
			return `${formattedMinutes}:${formattedSeconds}`;
		},

    // 比分、角球、红黄牌等类型
		getNsgType(list, n, m) {
			// console.log(m, '-===========----------');
			if (!list) return
			let resobj = list.find(item => item.tyg == n)
			if (m == 0 || m == 1) {
				if (resobj) {
					return resobj.sc[m]
				} else {
					return ''
				}
			} else {
				return resobj
			}
		},

  }

}