<template>
  <div class="homeDemo" :style="{ height: isFold ? '38px' : 'auto' }">
    <!-- <van-swipe
        class="my-swipeTwo"
        v-if="name == '体育'"
        :autoplay="3000"
        :show-indicators="false"
        :vertical="true"
        :touchable="false"
      >
        <van-swipe-item>
          <img class="lazyImg" src="@/assets/home/tab/sportSmall0.webp" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="lazyImg" src="@/assets/home/tab/sportSmall1.webp" />
        </van-swipe-item>
      </van-swipe> -->

    <!-- <div class="titleOut">体育投注</div> -->
    <div class="fold" @click="isFold = !isFold" ref="scrollTarget" v-if="false">
      <div class="foldLeft">
        <img :src="icon" />
        {{ name }}
      </div>

      <div @click.stop="$router.push('/sport')" class="lazyImg">
        <img v-if="name == '体育'" src="@/assets/home/tab/sportSmall0.webp" />
      </div>


      <div class="foldRight">
        <img src="@/assets/home/arrow.svg" :style="{ transform: isFold ? 'rotate(180deg)' : 'rotate(0deg)' }" />
      </div>
    </div>

    <div class="hotTopOut" v-if="name == '热门'">
      <div class="hotHot" v-for="(item, index) in hotTopList" :key="index" @click="topGo(item)" 
      :ref="'box' + index"  
      @touchstart="handleTouchStart(index, $event)"
      @touchend="handleTouchEnd(index)"
      >
        <img :src="item.img" class="hotImg" @load="onImageLoad(index)"  />
      </div>
    </div>


    <div class="smailOut" :class="{ zerenOut: name == '真人', xyxOut: name == '小游戏',hotOut: name == '热门' }">
      <!-- :style="{
          backgroundImage: `url(${item.img})`,
          backgroundSize: '100% 100%',
        }" -->
      <!-- <div v-for="(item, index) in list" :key="index" v-show="item.status != 2"> -->
        <div class="smailList" @click="goPage(item)" v-for="(item, index) in list" :key="index"
          :class="{ caicaic: name == '彩票', zeren: name == '真人', xyx: name == '小游戏',hotList:name == '热门' }" v-show="item.status != 2"
          :ref="'mybox' + index"  
      @touchstart="handleMyTouchStart(index)"
      @touchend="handleMyTouchEnd(index)">
          <img :src="item.img" class="inImg" @load="onImageLoad(index)"  />
          <!-- <div class="btmTwo">
        <div></div>

        <div class="loveImg" @click.stop="addLove" v-show="name == '热门'">
          <img v-show="!item.isFavorite" src="@/assets/class/isHui.webp" />
          <img v-show="item.isFavorite" src="@/assets/class/isRed.webp" />
        </div>
      </div> -->

          <!-- <div class="smailBj" :style="{'backgroundImage': `url(${item.image})` }"> -->
          <div class="smailMaintainDemo" v-show="item.status != 0">
            <img src="@/assets/home/tab/homeWhz.svg" />{{
              item.status | getStatus
            }}
          </div>
          <!-- </div> -->

        </div>
        <!-- <div class="gameTitle" v-show="name == '热门'" v-if="item.imageLoaded" >{{ item.name }}</div> -->
      <!-- </div> -->


    </div>
    <div v-show="name != '热门'">
      <div class="tiaoOut">
        <div class="tiao" :style="{ width: (list.length / total) * 100 + '%' }"></div>
      </div>
      <div class="dibTip">{{ `正在显示 ${total} 款游戏中的 ${list.length} 个` }}</div>
      <van-button class="jzgd-btn" :loading="loading" @click="jzgd()" v-show="totalPage > pageNumber">加载更多</van-button>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { pdgameLaunch, platformLogin, platformSlot, platformHot, platformSlotAddFavorite } from "@/request/api";
export default {
  name: "sportsDemo",
  props: ["name", "icon", "tabs", 'code', 'upName', "id",],
  data() {
    return {
      isFold: false,
      list: [],
      pageNumber: 1,
      loading: false,
      totalPage: 1,
      total: 0,
      hotTopList:[],
      // id:614,//pg:614
    };
  },
  mounted() {

    if (this.name == "热门") {
      this.getPlatformHot();
    } else {
      this.getPlatformSlot();
    }

  },
  methods: {
    ...mapActions(['setLoginShow','setRegisterShow']),
    onImageLoad(index) {
      this.$set(this.list, index, { ...this.list[index], imageLoaded: true });
    },
    addLove() {
      if (this.item.status != 0) {
        return;
      }
      this.$store.commit("setLoadingShowTrue");
      var opt = {
        id: this.item.id,
      };
      platformSlotAddFavorite(opt).then((res) => {
        if (res.code == 0) {
          this.$notify({
            message: "设置成功",
            duration: 1000,
            background: "#1989fa",
          });
          this.$emit("refresh");
        }
        this.$store.commit("setLoadingShowFalse");
      });
    },
    jzgd() {
      this.loading = true;
      this.pageNumber++;
      this.getPlatformSlot();
    },
    getPlatformHot() {

      this.loading = true;
      var opt = {
        pageNumber: 1,
        pageSize: 30,
      }
      platformHot(opt).then((res) => {
        if (res.code == 0) {
          this.hotTopList = res.data.data.slice(0, 2);
          // this.list.push(...res.data.data)
          this.list= res.data.data.slice(2);
          this.totalPage = res.data.totalPage
          this.total = res.data.total
        }
        this.loading = false;
      })
    },
    getPlatformSlot() {

      this.loading = true;
      var opt = {
        id: this.id,
        // code: this.code,//pg : PG_SLOT //we捕鱼 ：PD_FLSH
        tp: "SLOT",
        pageNumber: this.pageNumber,
        pageSize: 9,
      }
      platformSlot(opt).then((res) => {
        if (res.code == 0) {
          this.list.push(...res.data.data)
          this.totalPage = res.data.totalPage
          this.total = res.data.total
        }
        this.loading = false;
      })
    },
    setFold() {
      this.isFold = false;
    },
    topGo(item){
      
      if (!localStorage.getItem("token")) {
      if(localStorage.getItem('loginStatus')){
          this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setLoginShow(true)
          return;
        }else{
          this.$notify({
            message: "请注册后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setRegisterShow(true)
        return;
        }
      }
      if(item.platformLabel == "JDB捕鱼"){
        console.log(this.name)
        var twoList = item;
        twoList.id = item.platformId;
        this.$router.push({
          path: "/classIndex",
          query: {
            list: JSON.stringify(twoList),
            id: twoList.id,
            name: this.name,
            tabs: this.tabs,
          },
        });
      }else{
        this.goPage(item)
      }
    },
    
     goPage(item) {
     
      if (item.status != 0) {
        return;
      }

      

      if (!localStorage.getItem("token")) {
      // if (localStorage.getItem("userYou") && localStorage.getItem("userYou") == "tourists") {
      
      if(localStorage.getItem('loginStatus')){
          this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setLoginShow(true)
          return;
        }else{
          this.$notify({
            message: "请注册后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setRegisterShow(true)
        return;
        }
      }

      // if (localStorage.getItem("userYou") && localStorage.getItem("userYou") == "tourists") {
      //   this.$notify({
      //     message: "请登录后查看",
      //     duration: 2000,
      //     background: "#FF4337",
      //   });
      //   return;
      // }

      var regex = /we/i;
      if (!regex.test(this.upName)) {
        console.log(this.upName)
        // if(this.upName !="WE小游戏"){
        this.$store.commit("setLoadingShowTrue");
        var opt = {
          code: item.gameCode, id: this.id
        }
        if(this.name == "热门"){
          opt.id =  item.platformId
        }
        platformLogin(opt).then((res) => {
          if (res.code == 0) {
            this.$store.commit("setUrl", res.data.url);
            this.$router.push({ path: "/block", query: { 'upList': JSON.stringify(this.upList), id: this.id, 'name': this.blackName, 'link': '/', "tabs": this.tabs, url: res.data.url } });
            setTimeout(() => {
              this.$store.commit("setLoadingShowFalse");
            }, 5000);
          } else {
            this.$store.commit("setLoadingShowFalse");
          }
        });
      } else {
        this.oldSports(item);
      }

    },

    oldSports(item) {

      var opt = {
        code: this.code,
        gameId: item.id
      }
      //到这里就是名字有pd的 或者数量只有一个的   还有一个saba
      this.$store.commit("setLoadingShowTrue");

      pdgameLaunch(opt).then((res) => {
        if (res.code == 0) {
          // window.location.href = res.data
          this.$store.commit("setUrl", res.data);

          // if(!regex.test(this.list[index].name)){
          //     this.$router.push({path:'/block'})
          // }else{
          //     this.$router.push({path:'/sports'})
          // }
          this.$router.push({
            path: "/block",
            query: { link: "/", tabs: this.tabs, url: res.data },
          });
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    },

    goPages(index) {
      if (this.list[index].status != 0) {
        return;
      }
      var regex = /we/i;
      if (!regex.test(this.list[index].name)) {
        // if(this.upName !="WE小游戏"){
        if (this.list[index].gameCount > 0) {
          this.$router.push({
            path: "/classIndex",
            query: { list: JSON.stringify(this.list[index]) },
          });
          return;
        }
      }
      this.$store.commit("setLoadingShowTrue");
      var opt = {
        code: this.list[index].code,
      };
      pdgameLaunch(opt).then((res) => {
        if (res.code == 0) {
          // window.location.href = res.data
          this.$store.commit("setUrl", res.data);
          // if(!regex.test(this.list[index].name)){
          //     this.$router.push({path:'/block'})
          // }else{
          //     this.$router.push({path:'/sports'})
          // }
          this.$router.push({ path: "/block" });
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    },
    handleTouchStart(index) {
      
      this.$refs['box' + index][0].style.transform = `translateY(-7px)`;
    },
    
    handleTouchEnd(index) {
      setTimeout(()=>{
        this.$refs['box' + index][0].style.transform = '';
      },500)
      
    },
    handleMyTouchStart(index) {
      
      this.$refs['mybox' + index][0].style.transform = `translateY(-7px)`;
    },
    
    handleMyTouchEnd(index) {
      setTimeout(()=>{
        this.$refs['mybox' + index][0].style.transform = '';
      },500)
      
    }
  },
  filters: {
    getStatus(status) {
      switch (status) {
        case 0:
          return "";
        case 1:
          return "维护中";
        case 2:
          return "已停用";
        default:
          return "";
      }
    },
  },

};
</script>

<style lang="less" scoped>
.homeDemo {
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;
  padding: 0 10px;
  // margin-bottom: 8px;
  .fold {
    width: 100%;
    margin: 9px auto 12px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 38px;
    transition: all 0.5s ease;
    position: relative;

    .foldLeft {
      display: flex;
      align-items: center;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    .foldRight {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        transition: all 0.5s ease;
      }
    }
  }

  .hotTopOut{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    margin: 0 auto ;
    .hotHot{
      width: 50%;
      transition: all 0.5s;
      .hotImg{
        width: 100%;
        display: block;
        vertical-align: middle;
      }
    }
  }

  .titleOut {
    color: #fff;
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    text-align: left;
    width: 89.3333%;
    margin: 20px auto 0 auto;
  }

  .physical {
    background: linear-gradient(270deg, #ea4058 0%, #aa0018 100%);
    background-image: url("@/assets/home/tab/tabBj/tab1.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .physicalWe {
    background: linear-gradient(270deg, #e7b852 0%, #e0802e 100%);
    background-image: url("@/assets/home/tab/tabBj/tab2.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .physicalxnty {
    background: linear-gradient(270deg, #532ade 0%, #28208e 100%);
    background-image: url("@/assets/home/tab/tabBj/tab3.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .widthDemo {
    width: 100%;
    height: 130px;
    border-radius: 16px;
    overflow: hidden;
    margin: 0px auto 10px auto;
    padding: 19px 20px;
    position: relative;

    background: radial-gradient(114.24% 141.42% at 0% 0%,
        rgba(155, 155, 186, 0.16) 31.69%,
        rgba(155, 155, 186, 0.04) 100%);

    .maintainDemo {
      width: 138.5px;
      height: 100%;
      position: absolute;
      background-image: url("@/assets/home/tab/home-mark.svg");
      background-size: cover;
      border-radius: 0 0 16px 0;
      right: -0.5px;
      bottom: 0;
      margin: auto;
      z-index: 3;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: #fff;
      padding: 0 10px 12px 0;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }

    .tips {
      color: rgba(255, 255, 255, 0.65);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }

    .title {
      margin-top: 14px;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 24px */
      text-align: left;
      display: flex;
      align-items: flex-end;

      .titLeft {
        color: #fff;
        font-family: "FONTSPRING DEMO - Integral CF Demi Bold";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 95%;
        /* 30px */
      }

      .titRight {
        display: inline-flex;
        padding: 0 12px;
        height: 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.15);
        margin-left: 8px;
      }
    }

    .titTwo {
      margin-top: 10px;
      /* 18px */
      text-align: left;

      color: #fff;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    .inbtn {
      display: flex;
      width: 88px;
      height: 32px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex-shrink: 0;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.15);
      color: var(---100, #fff);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 12px;
    }
  }

  .smailOut {
    display: grid;

    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 109px);
    grid-gap: 8px;
    width: 100%;
    margin: 0px auto 0 auto;
    flex-wrap: wrap;

    .smailList {
      // width: 110px;
      // height: 148px;
      // border-radius: 12px;

      // background: radial-gradient(
      //   114.24% 141.42% at 0% 0%,
      //   rgba(155, 155, 186, 0.16) 31.69%,
      //   rgba(155, 155, 186, 0.04) 100%
      // );
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      align-self: stretch;
      width: 110px;
      overflow: hidden;
      position: relative;
      transition: all 0.5s;
      .inImg {
        max-width: 100%;
        // position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        border-radius: 10px;
        vertical-align: middle;
        display: block;
      }

      .btmTwo {
        padding-right: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        .csOut {
          display: inline-flex;
          height: auto;

          padding: 5px 10px;
          align-items: flex-start;
          gap: 2px;
          flex-shrink: 0;
          border-radius: 0px 0px 12px 0px;
          background: radial-gradient(114.24% 141.42% at 0% 0%,
              rgba(155, 155, 186, 0.86) 31.69%,
              rgba(155, 155, 186, 0.64) 100%);

          img {
            max-width: 25px;
            max-height: 15px;
            height: auto;
          }
        }

        .hotLabel {
          display: flex;
          width: 45px;
          height: 17px;
          padding: 4px 6px;
          align-items: flex-start;
          gap: 2px;
          flex-shrink: 0;
          border-radius: 0px 0px 12px 0px;
          background: radial-gradient(114.24% 141.42% at 0% 0%,
              rgba(155, 155, 186, 0.16) 31.69%,
              rgba(155, 155, 186, 0.04) 100%);
          color: #fff;
          font-family: "PingFang SC";
          font-size: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          img {
            width: 10px;
            height: 10px;
          }
        }

        .loveImg {
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .smailBj {
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }

      .smailMaintainDemo {
        width: 100%;
        height: 70px;
        position: absolute;
        background: linear-gradient(180deg,
            rgba(16, 27, 31, 0) 0%,
            rgba(16, 27, 31, 0.75) 66%);
        background-size: cover;
        border-radius: 0 0 12px 12px;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        color: #fff;
        padding: 0 10px 12px 0;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }
   

    .gameTitle {
    width: 100%;
    margin-top: 5px;
    overflow: hidden;
    color: #fff;
    text-align: center;
    text-overflow: ellipsis;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }
    .caicaic {
      width: 115px !important;
      height: 177px !important;
    }

    .zeren {
      width: 165px !important;
      height: 130px !important;
    }
  }

  .zerenOut {
    grid-template-columns: repeat(2, 165px) !important;
    grid-gap: 15px;
  }

  .xyxOut {}
  .hotOut{
    grid-template-columns: repeat(2, 173px) !important;
    gap: 9px;
    margin-top:9px;
    justify-content: center;
    .hotList{
      width: 100%!important;
      .inImg{
        border-radius: 12px!important;
      }
    }
  }
  .xyx {
    width: 110px !important;
    height: 110px !important;
  }
}

.my-swipeTwo {
  width: 138px;
  height: 28px;
  position: absolute;
  top: 6px;

  left: 0;
  right: 0;
  margin: auto;
  z-index: 4;

  // border-radius: 20px;
  .lazyImg {
    width: 138px;
    height: 28px;
  }
}

.lazyImg {
  width: 138px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 4;

  img {
    width: 100%;
    height: 100%;

  }
}

.jzgd-btn {
  margin: 11px auto 0 auto;
  border: 0;
  border-radius: 6px;
  width: 168px;
  height: 40px;
  background: rgba(71, 116, 218, 1);
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.tiaoOut {
  width: 168px;
  height: 4px;
  border-radius: 4px;
  background: rgba(155, 155, 186, 0.16);
  margin: 20px auto 0 auto;

  .tiao {
    width: 0;
    max-width: 100%;
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(91.35deg, rgba(3, 89, 255, 1) 0%, rgba(3, 133, 255, 1) 58.42%, rgba(148, 255, 255, 1) 98.95%);
  }
}

.dibTip {
  margin: 11px auto 0 auto;
  color: rgba(179, 185, 210, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 14px;
}

.xyx {
  .inImg {
    width: 100%;
    height: 100%;
  }
}
// .hotHot:active{
//       animation: bounce 0.5s ease;
//     }
//     .smailList:active{
//       // transform: translateY(-7px);
//       animation: bounce 0.5s ease;
//     }
</style>