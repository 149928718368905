<template>
  <div class="loginContentPop">
    <div class="rightImg" @click="registerClose"><img src="@/assets/app/close.webp" /></div>
    <div class="logoDemo"><img src="@/assets/home-logo.svg" /></div>
    <div class="titleLine">
      <!-- <div class="rightImg"></div> -->
      <div class="titName">创建账户</div>

    </div>
    <div class="zlgOut">
      <div class="intOut" :class="{
        errorIntOut: nameDown && nameType,
        correctIntOut: nameDown && nameType == false,
      }" @click.stop="intChange(0)">
        <div class="intLeftOut">
          <div class="intLeftImg">
            <img src="@/assets/app/loginPop/loginUser.png" />
          </div>

          <!-- <van-field
            class="ints"
            autocomplete="off"
            autocorrect="off"
            :border="false"
            v-model="username"
            placeholder="用户名"
            @focus="nameFocus"
            @blur="nameBlur"
            @input="nameInput"
            ref="myFieldUser"
          /> -->
          <input class="ints" v-model="username" placeholder="用户名6-18位" @focus="nameFocus" @blur="nameBlur"
            @input="nameInput" ref="myFieldUserRegist" />
        </div>
      </div>
      <!-- <div class="tips" v-if="nameDown" :class="{ errorIntTips: nameType }">
        用户名长度应该为4-18位且必须包含字母和数字
      </div>
      <div class="tips" v-else :class="{ errorIntTips: nameType }">
      </div> -->
      <div class="intOut" :class="{
        errorIntOut: pwdDown && pwdType,
        correctIntOut: pwdDown && pwdType == false,
      }" @click.stop="intChange(1)">
        <div class="intLeftOut">
          <div class="intLeftImg">
            <img src="@/assets/app/loginPop/loginPwd.png" />
          </div>
          <!-- <van-field
            class="ints"
            autocomplete="off"
            autocorrect="off"
            :border="false"
            :type="isPwd ? 'text' : 'password'"
            v-model="pwd"
            placeholder="密码"
            @focus="pwdFocus"
            @blur="pwdBlur"
            @input="pwdInput"
            ref="myFieldPwd"
          /> -->
          <input class="ints" :type="isPwd ? 'text' : 'password'" :border="false" v-model="pwd" placeholder="登录密码"
            @focus="pwdFocus" @blur="pwdBlur" @input="pwdInput" ref="myFieldPwdRegist" />
        </div>
        <div class="eyes" v-show="!isPwd" @click="isPwd = !isPwd"></div>
        <div class="closeEyes" v-show="isPwd" @click="isPwd = !isPwd"></div>
      </div>
      <!-- <div class="tips" v-if="pwdDown" :class="{ errorIntTips: pwdType }">
        密码为6-18位且包含字母和数字
      </div>
      <div class="tips" v-else :class="{ errorIntTips: pwdType }">
      </div> -->
      <div class="intOut " :class="{
        errorIntOut: confirmPwdDown && confirmPwdType,
        correctIntOut: confirmPwdDown && confirmPwdType == false,
      }" @click.stop="intChange(2)">
        <div class="intLeftOut">
          <div class="intLeftImg">
            <img src="@/assets/app/loginPop/loginPwd.png" />
          </div>
          <input class="ints" :type="isconPwd ? 'text' : 'password'" :border="false" v-model="confirm_password"
            placeholder="确认密码" @focus="confirmPwdFocus" @blur="confirmPwdBlur" @input="confirmPwdInput"
            ref="myFieldConfirmPwd" />
        </div>
        <div class="eyes" v-show="!isconPwd" @click="isconPwd = !isconPwd"></div>
        <div class="closeEyes" v-show="isconPwd" @click="isconPwd = !isconPwd"></div>
      </div>

      <!-- <div
        class="tips"
        v-if="confirmPwdDown"
        :class="{ errorIntTips: confirmPwdType }"
      >
        密码和确认密码不一致
      </div>
      <div
        class="tips"
        v-else
        :class="{ errorIntTips: confirmPwdType }"
      >
        
      </div> -->

      <div class="flex-ju">
        <div class="flex-left" @click="setUser = !setUser">
          <div class="yuanwai">
            <img v-show="!setUser" src="@/assets/app/loginPop/round.webp" />
            <img v-show="setUser" src="@/assets/app/loginPop/blue-round.webp" />
          </div>
          我同意<span @click.stop="setRegisterShow(false), $router.push('/terms?tab=0')">用户协议</span>
          <!-- 和<span @click.stop="setRegisterShow(false),$router.push('/terms?tab=1')">隐私政策</span> -->
          并确定我已满18岁
        </div>
      </div>

      <van-button class="btn" :loading="subLoading" :disabled="subLoading" @click="submit">注册</van-button>
      <div class="typse">
        已有账户？<span @click="setLoginShow(true)">登录</span>
      </div>
    </div>
    <div class="goLine" v-if="false">
      <div class="goDemo" @click="setLoginShow(true)">
        <div class="toopImg"><img src="@/assets/login/goRegister.svg" /></div>
        <div class="goTitle">前往登陆</div>
      </div>
      <div class="goDemo" @click="goTourists()">
        <div class="toopImg"><img src="@/assets/login/tourists.svg" /></div>
        <div class="goTitle">游客进入</div>
      </div>
      <div class="goDemo" @click="$store.commit('goService')">
        <div class="toopImg"><img src="@/assets/login/service.svg" /></div>
        <div class="goTitle">在线客服</div>
      </div>
    </div>

    <!-- <div class="dibu">
        <div class="titles">我们推荐使用以下浏览器以获得最佳使用体验</div>
        <div class="imgDemo">
          <img src="@/assets/login/google.svg" @click="goGoogle(0)" />
          <img src="@/assets/login/safari.svg" @click="goGoogle(1)" />
          <img src="@/assets/login/frame.svg" @click="goGoogle(2)" />
        </div>
      </div> -->

  </div>
</template>

<script>
import { EventBus } from '@/unit/event-bus.js';
import { mapActions } from 'vuex';
import { sysRegister, sysLogin, platformBalance } from "@/request/api";
import { fbtoken, noFbtoken } from '@/request/sportApi'
export default {
  name: "registerPop",
  data() {
    return {
      isPwd: false,
      isconPwd: false,
      username: "",
      pwd: "",
      subLoading: false,
      setUser: false,
      confirm_password: "",
      code: "",

      nameDown: false,
      pwdDown: false,
      nameType: false,
      pwdType: false,
      confirmPwdDown: false,
      confirmPwdType: false,
    };
  },
  mounted() {
    if (this.$route.query.code !== undefined) {
      // localStorage.setItem('myCode',this.$route.query.code)
      this.code = this.$route.query.code;
    } else {
      this.code = localStorage.getItem("myCode")
        ? localStorage.getItem("myCode")
        : "";
    }
  },
  methods: {
    ...mapActions(['setRegisterShow', 'setLoginShow', 'setSuccessfulShow']),
    registerClose() {
      this.setRegisterShow(false)
    },
    goGoogle(index) {
      switch (index) {
        case 0:
          window.open("https://www.google.com/chrome/", "_blank");
          break;
        case 1:
          window.open("https://support.apple.com/zh-cn/docs", "_blank");
          break;
        case 2:
          window.open("https://www.mozilla.org/en-US/", "_blank");
          break;

        default:
          break;
      }
    },
    getFbToken() {
      fbtoken()
        .then((res) => {
          if (res.code == 0 && res.data.token) {
            localStorage.setItem("fbtoken", res.data.token);
            localStorage.setItem(
              "serverInfo",
              JSON.stringify(res.data.serverInfo)
            );
            localStorage.setItem(
              "requestUrl",
              res.data.serverInfo.apiServerAddress
            );
          } else {
            setTimeout(this.getFbToken, 1000);
          }
        })
        .catch(() => {
          setTimeout(this.getFbToken, 1000);
        });
    },
    intChange(num) {
      // if (num == 0) {
      //   this.$refs.myFieldUserRegist.blur();
      //   setTimeout(() => {
      //     this.$refs.myFieldUserRegist.focus();
      //   }, 100);
      // } else if (num == 1) {
      //   this.$refs.myFieldPwdRegist.blur();
      //   setTimeout(() => {
      //     this.$refs.myFieldPwdRegist.focus();
      //   }, 100);
      // } else {
      //   this.$refs.myFieldConfirmPwd.blur();
      //   setTimeout(() => {
      //     this.$refs.myFieldConfirmPwd.focus();
      //   }, 100);
      //}
      if (num == 0) {
        this.$refs.myFieldUserRegist.focus();
      } else if (num == 1) {
        this.$refs.myFieldPwdRegist.focus();
      } else {
        this.$refs.myFieldConfirmPwd.focus();
      }

    },
    goTourists() {
      noFbtoken().then((res) => {
        // localStorage.setItem('fbtoken', res.data.token)
        localStorage.setItem('serverInfo', JSON.stringify(res.data.serverInfo))
        localStorage.setItem('requestUrl', res.data.serverInfo.pushServerAddress)
      })
      localStorage.setItem("userYou", "tourists");
      this.setRegisterShow(false)
      this.$router.push('/')
    },
    nameInput() {
      if (!/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{4,18}$/.test(this.username)) {
        this.nameType = true;
      } else {
        this.nameType = false;
      }
    },
    pwdInput() {
      var regPwd = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$/;
      if (!regPwd.test(this.pwd)) {
        this.pwdType = true;
      } else {
        this.pwdType = false;
        this.confirmPwdInput();
      }
    },
    nameFocus() {
      this.nameDown = true;
    },
    nameBlur() {
      this.nameDown = false;
    },
    pwdFocus() {
      this.pwdDown = true;
    },
    pwdBlur() {
      this.pwdDown = false;
    },
    confirmPwdFocus() {
      this.confirmPwdDown = true;
    },
    confirmPwdBlur() {
      this.confirmPwdDown = false;
    },
    confirmPwdInput() {
      if (this.confirm_password != this.pwd) {
        this.confirmPwdType = true;
      } else {
        this.confirmPwdType = false;
      }
    },
    submit() {
      var regPwd = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$/;
      if (!/^[a-zA-Z0-9]+$/.test(this.username)) {
        this.$notify({
          message: "用户名只能包含字母和数字",
          duration: 2000,
          background: "#FF4337",
          //background: '#1989fa'
        });
        return;
      }
      if (this.confirm_password != this.pwd) {
        this.$notify({
          message: "密码和确认密码不一致",
          duration: 2000,
        });
        return;
      }
      if (!regPwd.test(this.pwd)) {
        this.$notify({
          message: "密码为6-18位且包含字母和数字",
          duration: 2000,
          background: "#FF4337",
        });
        return;
      }
      if (this.setUser) {
        this.getInfo();
      } else {
        this.$notify({
          message: "阅读并同意相关条款和隐私政策",
          duration: 2000,
          background: "#FF4337",
        });
      }
    },
    getInfo() {
      this.subLoading = true;
      var opt = {
        username: this.username,
        password: this.pwd,
        code:
          this.code == undefined || this.code == "undefined" ? "" : this.code,
        url: localStorage.getItem("inUrl")
          ? localStorage.getItem("inUrl")
          : window.location.hostname,
      };
      sysRegister(opt).then((res) => {
        if (res.code == 0) {
          this.$notify({
            message: "注册成功,正在自动登录请稍等",
            duration: 2000,
            background: "#1989fa",
          });
          // this.$router.push("/login");
          // this.setLoginShow(true)


          sysLogin(opt).then((loginRes) => {
            if (loginRes.code == 0) {
              this.getPlatformBalance()
              localStorage.removeItem('userYou')
              localStorage.setItem("token", loginRes.data);
              this.$notify({
                message: "登入成功",
                duration: 1000,
                background: "#1989fa",
              });
              this.getFbTokenLogin()
              // if (this.setUser) {
              //   localStorage.setItem("userPadaLogin", this.username);
              // }
              localStorage.setItem("firstRegister",1)
              
              this.setRegisterShow(false)
              if (this.$route.path == '/') {
                EventBus.$emit('tokenChanged', 'newTokenValue');

                // window.location.reload();
              } else {
                this.$router.push("/");
              }
              this.subLoading = false;
              // setTimeout(() => {

              // }, 2000);


            } else {
              this.$notify({
                message: "自动登录失败，请手动登录",
                duration: 1000,
              });
              this.subLoading = false;
              this.setLoginShow(true)
            }

          });


        } else {
          this.subLoading = false;
        }
      });
    },
    getPlatformBalance() {
      platformBalance().then()
    },
    getFbTokenLogin() {
      fbtoken()
        .then(res => {
          if (res.code == 0 && res.data.token) {
            localStorage.setItem('fbtoken', res.data.token)
            localStorage.setItem('serverInfo', JSON.stringify(res.data.serverInfo))
            localStorage.setItem('requestUrl', res.data.serverInfo.apiServerAddress)
            this.$store.dispatch('sport/getUserInfo')

          } else {
            setTimeout(this.getFbToken, 1000);
          }
        })
        .catch(() => {
          setTimeout(this.getFbToken, 1000);
        })
    },
  },


}
</script>

<style lang="less" scoped>
.loginContentPop {
  padding: 30px 20px;
  width: 335px;
  transition: all 0.5s ease;
  position: relative;
  border-radius: 12px;
  background: #202C37;

  .rightImg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 19px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logoDemo {
    width: 120px;
    height: 40.305px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .titleLine {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background: #263440;
    width: 100%;
    margin: 0 auto 0 auto;
    border-radius: 12px 12px 0 0;

    .titName {
      color: #FFF;
      font-family: "PingFang SC";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
    }

  }

  .logo {
    margin: 40px auto 30px auto;
    width: 101px;
    height: 149px;
    transition: all 0.5s ease;
  }

  .overall {
    position: absolute;
    width: 100%;
    top: calc(149px + 40px + 30px);
    transition: all 0.5s ease;
  }

  .topTop {
    transition: all 0.5s ease;
    top: 40px;
  }

  .intOut {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 8px 12px;
    height: 42px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.04);
    margin-bottom: 12px;

    // background: rgba(255, 255, 255, 0.04);
    .ints {
      background: transparent;
      border: 0;
      height: 21px;

      padding-left: 0;
      width: calc(100% - 16px);
      padding: 0;
      color: #fff;
      font-size: 14px;
      font-family: PingFang SC;
      text-indent: 1px;
      padding-left: 1px;
    }

    .ints::placeholder {
      color: #506D87;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }

    .intLeftOut {

      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      .intLeftImg {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }

    .eyes {
      background: url('@/assets/app/loginPop/eye.webp') no-repeat;
      width: 20px;
      height: 20px;
      background-size: 100% 100%;
    }

    .closeEyes {
      background: url('@/assets/app/loginPop/closeEye.webp') no-repeat;
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
    }
  }

  .errorIntOut {
    border-color: #ff4337;
  }

  .correctIntOut {
    border-color: #4774da;
  }

  .tips {
    color: #4774da;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 18px */
    width: 100%;
    margin: 4px auto 4px auto;
    text-align: left;
    height: 14px;
  }

  .errorIntTips {
    color: #ff4337;
  }

  .tops {
    margin-top: 12px;
  }

  .typse {
    width: 100%;
    margin: 0 auto 0 auto;
    align-items: center;
    color: rgba(177, 186, 211, 0.65);
    font-family: "PingFang SC";
    font-weight: 600;
    font-size: 12px;
    text-align: left;

    span {
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
    }
  }

  .btn {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 1);
    font-family: "PingFang SC";
    font-weight: 600;
    font-size: 16px;
    margin: 12px auto;
    border: 0;
    border-radius: 4px;
    background: #4774DA;
  }

  .flex-ju {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0px auto 0 auto;
    height: 20px;

    .flex-left {
      display: flex;
      align-items: center;
      color: rgba(177, 186, 211, 0.65);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      /* 18px */
      .yuanwai {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        color: rgba(255, 255, 255, 1);
        margin: 0 5px;
      }

    }

    .flex-right {
      text-align: right;
      color: rgba(177, 186, 211, 0.65);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
    }
  }

  .goLine {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;

    .goDemo {
      width: 105px;
      display: flex;
      padding: 10px 0px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.04);

      .toopImg {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .goTitle {
        width: 100%;
        color: #82889b;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 18px */
      }
    }
  }

  .noUser {
    text-align: center;
    width: 100%;
    margin: 30px auto;
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .dibu {
    width: 100%;


    margin: 12px auto 0 auto;

    .titles {
      color: rgba(177, 186, 211, 0.65);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 12px;
    }

    .imgDemo {
      display: flex;
      align-items: center;
      margin: 12px auto 0 auto;
      width: 64%;
      justify-content: space-between;

      img {
        width: 48px;
        height: 55px;
      }
    }
  }
}

/deep/.van-field__control {
  color: #fff;
}

.zlgOut {
  width: 100%;
  padding: 15px 15px 30px;
  background: #263440;
  border-radius: 0 0 12px 12px;
}
</style>
