<template>
	<div class="btn-view" :style='sty' @click='handleTap'>
		<slot></slot>
		<div class="ripple" v-if='ripple && show'></div>
	</div>
</template>

<script>
	export default {
		name:'k-btn',
		data() {
			return {
				show: false
			}
		},
		props: {
			sty: {
				type: String,
			},
			ripple: {
				type: Boolean,
				default: true
			},
			time: {
				type: Number,
				default: 600
			},
		},
		methods: {
			handleTap() {
				this.$emit('click')
				// setTimeout(() => {
				// }, this.time)
				if (!this.ripple && !this.show) return
				this.show = true
				setTimeout(() => {
					this.show = false
				}, this.time)
			}
		}
	}
</script>

<style lang='less' scoped>
	@keyframes bowen {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			width: 1400rpx;
			height: 1400rpx;
		}
	}
	.btn-view {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		color: #fff;
		overflow: hidden;
		font-size: 32rpx;
		border-radius: 30rpx;

		.ripple {
			position: absolute;
			top: 0;
			left: 0;
			right:0;
			bottom:0;
			/* border-radius: 50%; */
			opacity: 1;
			background-color: rgba(0, 0, 0, 0.2);
			animation: ripple .6s linear;
			/* transition: all .6s; */
		}
		@keyframes ripple {
			0% {
				opacity: 1;	
			}
			/* 50% {
				opacity: 1;	
			} */
			100% {
				opacity: 0;	
			}
		}
	}
</style>