<template>
	<div class="filter" :style="$store.getters['sport/$theme']">
		<div class="top flex">
			<span>
				<van-icon name="arrow-left" @click='$emit("close")'/>
			</span>
			<div class="name">
				联赛筛选
			</div>
			<span></span>
		</div>
		<div class="content">
			<div class="box" v-if='list.length'>
				<div class="dl" v-for="(item, i) in list" :key="i">
					<div class="dt">
						<div class="name" @click="item.open = !item.open">
							{{ item.name }}
							<span class='down' :class="{active: item.open}">
								<van-icon name="arrow-up" color="#fff" />
							</span>
						</div>
						<div class="check">
							<span v-if="item.list.every(m => m.check)" @click="item.list.forEach(m => m.check=false)">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="10" cy="10" r="10" fill="#4774DA"/>
									<g clip-path="url(#clip0_3080_2732)">
									<path d="M8.87963 16.2313L4.00598 12.2508L5.33745 10.6205L8.44645 13.1597L14.2592 4.76865L15.9897 5.96719L8.87963 16.2313Z" fill="white"/>
									</g>
									<defs>
									<clipPath id="clip0_3080_2732">
									<rect width="12" height="12" fill="white" transform="translate(4 4.5)"/>
									</clipPath>
									</defs>
								</svg>
							</span>
							<span  v-else-if="item.list.every(m => !m.check)" @click="item.list.forEach(m => m.check=true)">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="10" cy="10" r="8.5" stroke="#4774DA" stroke-width="3"/>
								</svg>
							</span>
							<span v-else @click="item.list.forEach(m => m.check=true)">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="10" cy="10" r="10" fill="#4774DA"/>
									<g clip-path="url(#clip0_3080_2789)">
									<path d="M4.07191 11.0826L4.07193 9.0404L6.36078 8.98891L16.5684 9.04039L16.558 11.1454L4.07191 11.0826Z" fill="white"/>
									</g>
									<defs>
									<clipPath id="clip0_3080_2789">
									<rect width="12" height="12" fill="white" transform="translate(4 4.5)"/>
									</clipPath>
									</defs>
								</svg>

							</span>
						</div>
					</div>
					<ul :style="{height: item.open?'auto':'0'}">
						<li v-for='(attr,n) in item.list' :key="n">
							<div class="name">
								<span class='img'>
									<img :src="attr.lurl" alt="">
								</span>
								<span>{{attr.na}}</span>
							</div>
							<div class="checkbox">
								<span class="num">{{attr.mt}}</span>
								<span class="svg" v-if="attr.check" @click="changeCheck(item, attr, n)">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="10" cy="10" r="10" fill="#4774DA"/>
										<g clip-path="url(#clip0_3080_2732)">
										<path d="M8.87963 16.2313L4.00598 12.2508L5.33745 10.6205L8.44645 13.1597L14.2592 4.76865L15.9897 5.96719L8.87963 16.2313Z" fill="white"/>
										</g>
										<defs>
										<clipPath id="clip0_3080_2732">
										<rect width="12" height="12" fill="white" transform="translate(4 4.5)"/>
										</clipPath>
										</defs>
									</svg>
								</span>
								<span class="svg" v-else @click="changeCheck(item, attr, n)">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="10" cy="10" r="8.5" stroke="#4774DA" stroke-width="3"/>
									</svg>
								</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="footer">
			<div>
				<span @click="handleAllCheck">全选</span>
				<span @click="fanxuan" class="fanxuan">反选</span>
				{{filterData.length}}个联赛
			</div>
			<div class="sure" @click="sure">
				<span>确定</span>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "rightSlider",
	data() {
		return {
			list: []
		}
	},
	props: {
		filterData: {
			type: Array,
			default: () => []
		},
		filterList: {
			type: Array,
			default: () => []
		},
	},
	computed: {

	},
	mounted() {
		console.log(this.filterData , 'filterData');
	},
	watch: {
		filterData() {
			if (!this.filterData.length) return
			let obj = {}
			this.filterData.forEach(item => {
				this.$set(item, 'check', !this.filterList.includes(item.na))
				if (obj[item.rnm]) {
					obj[item.rnm].list.push(item)
				} else {
					obj[item.rnm] = {
						name: item.rnm,
						open: true,
						list: [item]
					}
				}
			})
			this.list = Object.values(obj)
		}
	},
	methods: {
		changeCheck(item, attr) {
			attr.check = !attr.check
		},
		handleAllCheck() {
			this.list.forEach(item => {
				item.list.forEach(attr => {
					attr.check = true
				})
			})
		},
		fanxuan() {
			this.list.forEach(item => {
				item.list.forEach(attr => {
					attr.check = !attr.check
				})
			})
		},
		sure() {
			this.$emit('close', this.filterData.filter(item => !item.check).map(item => item.na))
		}
	}
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';

.flex {
	display: flex;
	align-items: center;
}

.flex-c {
	display: flex;
	flex-direction: column;
}

span,
div {
	box-sizing: border-box;
}

div, ul {
	&::-webkit-scrollbar {
		display: none;
	}
}

.filter {
	text-align: left;
	color: #000;
	height: 100%;
	color: @font-color;
	font-size: 13px;
	position: relative;
	display: flex;
	flex-direction: column;
	font-size: 14px;

	.top {
		background-image: url('@/assets/sport/bg.png');
		background-size: 300px 300px;
		background-position: 150px -70px;
		background-color: #202C37;
		background-repeat: no-repeat;
		width: 100%;
		height: 53px;
		color: #fff;
		justify-content: space-between;
		display: flex;
		color: #fff;
		z-index: 5;
		span {
			width: 50px;
		}
		i {
			font-size: 18px;
			padding: 2px 14px;
		}
	}

	.content {
		flex: 1;
		margin-top: -53px;
		// padding-top: 44px;
		overflow: auto;
		position: relative;
		z-index: 4;
		background: #15212D;

		.box {
			// background: @sportdefault-bg;
			padding-top: 53px;
		}

		.dl {

			.dt {
				display: flex;
				height: 40px;
				align-items: center;
				justify-content: space-between;
				align-items: center;
				padding: 0 22px 0 16px;
				color: @index-font-color;
				position: relative;

				i {
					color: @index-font-color !important;
				}

				.name {
					.down {
						transition: all .3s;
					}
					.active i {
						transform: rotate(180deg);
					}
				}

				.check {
					svg {
						width: 20px;
						height: 20px;
					}
				}

				&:before {
					content: "";
					position: absolute;
					left: 0;
					background: @theme-text;
					width: 4px;
					height: 25px;
					border-radius: 0 9px 9px 0;
				}
			}

			ul {
				overflow: hidden;
				transition: all .3s;
				li {
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: 40px;
					padding: 0 22px;
					background: #202C37;
					margin-bottom: 6px;

					.name {
						display: flex;
						align-items: center;
						.img {
							margin-right: 6px;
							width: 26px;
							height: 26px;
							border-radius: 50%;
							background: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							img {
								width: 18px;
								height: 18px;
							}
						}
					}

					.checkbox {
						display: flex;
						align-items: center;
						.num {
							min-width: 16px;
							padding: 1px 2px;
							background: #FF3B30;
							border-radius: 2px;
							color: #fff;
							font-size: 10px;
							margin-right: 6px;
							text-align: center;
						}

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}
	}

	.footer {
		font-size: 14px;
		height: 53px;
		background: #202c37;;
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 14px;
		color: #fff;
		div {
			display: flex;
			align-items: center;
		}

		span {
			height: 30px;
			width: 76px;
			border-radius: 16px;
			border: 1px solid #fff;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.fanxuan {
			margin: 0 10px;
		}

		.sure {
			span {
				background: @theme-text;
				border: none;
			}
		}

	}


}
</style>