var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appOut",attrs:{"id":"app"}},[(_vm.$route.meta.isFooter)?_c('AppFooters'):_vm._e(),_c('van-popup',{staticClass:"loadingPop",class:{ newPop: _vm.$store.state.OptStatus },attrs:{"close-on-click-overlay":false},model:{value:(_vm.$store.state.loadingShow),callback:function ($$v) {_vm.$set(_vm.$store.state, "loadingShow", $$v)},expression:"$store.state.loadingShow"}},[_c('div',{staticClass:"loadLogoGif"},[_c('img',{attrs:{"src":require("@/assets/loadingLogo.webp")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.loadTextShow),expression:"$store.state.loadTextShow"}],staticClass:"loadingTxt"},[_vm._v("正在匹配中,请稍等…")])]),_c('router-view'),_c('div',{ref:"draggableApp",staticClass:"xfck",style:({ transform: `translate(${_vm.position.x}px, ${_vm.position.y}px)`, right: _vm.showXf ? '0px' : '-246px', visibility: _vm.showXf ? '' : 'hidden', }),on:{"touchstart":_vm.startDrag,"touchend":_vm.onTouchEnd,"touchmove":_vm.onTouchMove,"mousedown":_vm.startDrag}},[_c('div',{staticClass:"leftImg",on:{"click":function($event){_vm.showXf = false}}},[_c('img',{attrs:{"src":require("@/assets/app/right.webp")}})]),_c('div',{staticClass:"zImg",on:{"click":function($event){_vm.$router.push({
        path: '/record/content',
        query: {
          list: JSON.stringify(_vm.withList0),
          typeId: 1,
          typeName: '取款',
        },
      }), _vm.showXf = false}}},[_c('img',{attrs:{"src":require("@/assets/app/icon.webp")}})]),_c('div',{staticClass:"xfright",on:{"click":function($event){_vm.$router.push({
        path: '/record/content',
        query: {
          list: JSON.stringify(_vm.withList0),
          typeId: 1,
          typeName: '取款',
        },
      }), _vm.showXf = false}}},[_c('div',{staticClass:"xfTit"},[_vm._v("极速取款：处理中")]),_vm._m(0)])]),_c('van-popup',{staticClass:"downOutApp dhPop",attrs:{"position":"left","close-on-click-overlay":false},on:{"click-overlay":_vm.loginClose},model:{value:(_vm.loginPopupVisible),callback:function ($$v) {_vm.loginPopupVisible=$$v},expression:"loginPopupVisible"}},[_c('login')],1),_c('van-popup',{staticClass:"downOutApp dhPop",attrs:{"position":"right","close-on-click-overlay":false},on:{"click-overlay":_vm.registerClose},model:{value:(_vm.registerPopupVisible),callback:function ($$v) {_vm.registerPopupVisible=$$v},expression:"registerPopupVisible"}},[_c('register')],1),_c('van-popup',{staticClass:"downOutApp dhPop",attrs:{"position":"left","close-on-click-overlay":false},on:{"click-overlay":_vm.registerClose},model:{value:(_vm.successfulPopupVisible),callback:function ($$v) {_vm.successfulPopupVisible=$$v},expression:"successfulPopupVisible"}},[_c('successful')],1),_c('van-popup',{staticClass:"messageCs",attrs:{"close-on-click-overlay":false},on:{"click-overlay":_vm.registerClose},model:{value:(_vm.messagePopVisible),callback:function ($$v) {_vm.messagePopVisible=$$v},expression:"messagePopVisible"}},[_c('div',{staticClass:"messageIn"},[_c('div',{staticClass:"mtitLine"},[_c('div',{staticClass:"mtitRight"}),_c('div',{staticClass:"mtitName"},[_vm._v("提示")]),_c('div',{staticClass:"mtitRight",on:{"click":_vm.okMessage}},[_c('img',{attrs:{"src":require("@/assets/app/messageClose.webp")}})])]),_c('div',{staticClass:"mesDataOut"},[_c('div',{staticClass:"mesData"},[_vm._v(" "+_vm._s(_vm.messageData)+" ")])]),_c('van-button',{staticClass:"mbtn",on:{"click":_vm.okMessage}},[_vm._v("确定")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xfTips"},[_vm._v("进入详情 "),_c('img',{attrs:{"src":require("@/assets/app/blueRight.webp")}})])
}]

export { render, staticRenderFns }