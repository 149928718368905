<template>
  <div class="activity">
    <div class="topBj"></div>
      <div class="header ">
        <!--  @click='history.back()' -->
        <div class="left" @click="$router.go(-1)">
          <!-- <img src="../../assets/home/head-left.svg" /> -->
        </div>
        <div class="title">优惠中心</div>
        <!--  @click="$router.push({ path: '/sportsActivity', query: { tab: 1 } })" -->
        <div class="right">

        </div>
      </div>
      <!-- @click="switchBtn" swipeable-->
      <van-tabs v-model="active" class="tabsCs"  title-active-color="#fff" color="#4774DA" animated background="transparent" @change="setActChil" >
        <van-tab title="活动">
          <!-- :badge="3" -->
          <!-- <notify :selectShow="selectShow" @selectId="notifySelectId" ref="notifyRef" /> -->
           <Details v-if="!active" ref="detailsRef" />
        </van-tab>
        <van-tab title="返水">
          <Water @goTab="goTab" @setData="setData" ref="waterRef" />
        </van-tab>
        <van-tab title="待领取" :badge="waitReceiveCount">
          <Collected @getWait="getWait" @goTab="goTab" ref="collected"/>
        </van-tab>
        <van-tab title="领取记录">
          <Four ref="fourRef" />
        </van-tab>
      </van-tabs>
    

  </div>
</template>
<script>
import Details from "@/views/activity/details.vue";
import Water from "@/views/activity/water.vue";
import Collected from "@/views/activity/collected.vue";
import Four from "@/views/activity/four.vue";
import {


welfareWait_receive,

} from "@/request/api";
export default {
  name: "activityOut",
  components: {
    Details,Water,Collected,Four
  },
  data() {
    return {
      active: 0,
      waitReceiveCount: null,
      timer: null,
    }
  },
  mounted(){
    // console.log(78946, localStorage.getItem('token'))
    if(localStorage.getItem('token')){
      this.getUserHome();
    }
    if (this.$route.query.tab !== undefined) {
      this.active = Number(this.$route.query.tab)
    }
    // this.timer = setInterval(() => {
    //   if (this.$route.path == '/activityOut') {
    //   this.getUserHome();
    //   }
    // }, 1000);
    if(this.$route.query.tab == 1) {
      console.log(123)
      this.$nextTick(()=>{
        this.$refs.waterRef.getRebatePlatform();
      })
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },

  methods:{
    setActChil(e){
      switch (e) {
        case 0:
          
          break;
          case 1:
            this.$nextTick(()=>{
              this.$refs.waterRef.getRebatePlatform();
            })
          break;
          case 2:
          this.$nextTick(()=>{
              this.$refs.collected.init();
            })
          break;

          case 3:
          this.$nextTick(()=>{
              this.$refs.fourRef.onRefresh();
            })
          break;
      
        default:
          break;
      }
    },
    setData(){
      this.$refs.collected.init();
    },
    goTab(num){
      this.active = num;
      this.setActChil(num);
    },
    getWait(num){
      console.log(num)
      this.waitReceiveCount =( num == 0 ? null: num);
    },
    getUserHome(){
      this.getHomeLoading = true;
      welfareWait_receive().then((res)=>{
        if(res.code == 0){
          this.waitReceiveCount = (res.data.length == 0 ? null : res.data.length);
        }
        this.getHomeLoading = false;
      })
    },
  }
}
</script>
<style lang="less" scoped>
@import '@/style/global.less';
.activity {
  position: relative;
  overflow: hidden;
  background: @allBj;
  .topBj{
    background: radial-gradient(114.24% 141.42% at 0% 0%, rgba(155, 155, 186, 0.16) 31.69%, rgba(155, 155, 186, 0.04) 100%);
    position: absolute;
    top: 0;
    height: calc( 48px + 48px );
    z-index: 1;
    left: 0;
    width: 100%;
  }
  .header {
    // position: fixed;
    justify-content: space-between;
    left: 0;
    top: 0;
    z-index: 5;
    right: 0;
    height: 48px;
    // background: #171a21;
    padding: 0 0.3rem;

    
    display: flex;
    align-items: center;

    .title {
      flex: 1;
      text-align: center;

      font-size: 16px;
      font-weight: 600;
      color: #fff;
      height: 1.17333rem;
      line-height: 1.17333rem;
      min-width: 0.53333rem;
    }

    .left,
    .right {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .right {
      justify-content: flex-end;
    }
  }
  .tabsCs{
    position: relative;
    z-index: 5;
  }
}

/deep/.van-info {
  border: 0;
  right: -12px;
  font-size: 10px;
  font-weight: 100;
}

/deep/.van-tabs__line {
  width: 25%;
  height: 2px;
}

/deep/.van-tab {
  font-size: 14px;
}

/deep/.van-tabs__line::after {
  // content: "";
  //   background: url("@/assets/account/sang.svg");
  width: 80px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: -40px;
  left: -20px;
}

/deep/.van-popup {
  background: rgba(24, 35, 39, 0.6);
}

/deep/.van-tabs__wrap {
  height: 48px;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.20);
}

/deep/.van-tab {
  font-size: 16px;
  font-weight: 400;
  color: #B1BAD3;
  border-bottom: 1px solid #15212D;
}

/deep/.van-tab--active {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
/deep/.van-tabs__content{
  // max-height: calc( 100dvh - 48px - 48px - 60px );
  // overflow-y: auto;
}
/deep/.body{
  overflow: hidden;
}
</style>
