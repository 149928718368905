<template>
  <div id="app" class="appOut">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <AppFooters v-if="$route.meta.isFooter" />
    <van-popup class="loadingPop " :class="{ newPop: $store.state.OptStatus }" v-model="$store.state.loadingShow"
      :close-on-click-overlay="false">
      <!-- <div :class="{newBj:$store.state.OptStatus}">
             <div class="bouncing-loader">
            <div class="yuan">
              <img src="../src/assets/footBall.svg"/>
            </div>
            <div class="yuan"><img src="../src/assets/blackIcon.svg"/></div>
            <div class="yuan"><img src="../src/assets/slot.svg"/></div>
          </div> 
          
      </div> -->
      <div class="loadLogoGif"><img src="@/assets/loadingLogo.webp" /></div>
      <div class="loadingTxt" v-show="$store.state.loadTextShow">正在匹配中,请稍等…</div>
      <!-- <van-loading type="spinner" class="wmdxz" color="#0094ff" size="58" >
          <template #icon>
               <AppImage src="/icons/i_xzLoad.svg" />
          </template>
</van-loading> -->
    </van-popup>
    <!-- <keep-alive :include="[ 'personalIndex', 'sportIndex', 'activityOut', 'HomeView']"> -->
    <router-view />
    <!-- </keep-alive> -->
    <div class="xfck" ref="draggableApp"
      :style="{ transform: `translate(${position.x}px, ${position.y}px)`, right: showXf ? '0px' : '-246px', visibility: showXf ? '' : 'hidden', }"
      @touchstart="startDrag" @touchend="onTouchEnd" @touchmove="onTouchMove" @mousedown="startDrag">
      <div class="leftImg" @click="showXf = false">
        <img src="@/assets/app/right.webp" />
      </div>
      <div class="zImg" @click="$router.push({
        path: '/record/content',
        query: {
          list: JSON.stringify(withList0),
          typeId: 1,
          typeName: '取款',
        },
      }), showXf = false">
        <img src="@/assets/app/icon.webp" />
      </div>
      <div class="xfright" @click="$router.push({
        path: '/record/content',
        query: {
          list: JSON.stringify(withList0),
          typeId: 1,
          typeName: '取款',
        },
      }), showXf = false">
        <div class="xfTit">极速取款：处理中</div>
        <div class="xfTips">进入详情 <img src="@/assets/app/blueRight.webp" /></div>
      </div>
    </div>

    <van-popup v-model="loginPopupVisible" class="downOutApp dhPop" position="left" @click-overlay="loginClose"
      :close-on-click-overlay="false">
      <login></login>
    </van-popup>
    <van-popup v-model="registerPopupVisible" class="downOutApp dhPop" position="right" @click-overlay="registerClose"
      :close-on-click-overlay="false">
      <register></register>
    </van-popup>
    <van-popup v-model="successfulPopupVisible" class="downOutApp dhPop" position="left" @click-overlay="registerClose"
      :close-on-click-overlay="false">
      <successful></successful>
    </van-popup>

    <van-popup v-model="messagePopVisible" class="messageCs" @click-overlay="registerClose"
      :close-on-click-overlay="false">
      <div class="messageIn">
        <div class="mtitLine">
          <div class="mtitRight"></div>
          <div class="mtitName">提示</div>
          <div class="mtitRight" @click="okMessage"><img src="@/assets/app/messageClose.webp" /></div>
        </div>
        <div class="mesDataOut">
          <div class="mesData">
            {{ messageData }}
          </div>
        </div>
        <van-button class="mbtn" @click="okMessage">确定</van-button>

      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { noFbtoken } from '@/request/sportApi'
import { withdrawRecord } from '@/request/api'
import AppFooters from "@/components/footer.vue"
import login from "@/components/login/login.vue"
import register from "@/components/login/register.vue"
import successful from "@/components/login/successful.vue"


import ws from '@/request/ws';   // 看个人需求选择全局引入或按需引入

export default {
  components: {
    // HelloWorld
    AppFooters, login, register,successful
  },
  data() {
    return {
      startTime: "",
      endTime: "",
      showXf: false,
      withList0: {},
      touchOffset: { x: 0, y: 0 },
      isDragging: false,
      startPosition: { x: 0, y: 0 },
      currentPosition: { x: 0, y: 0 },
      position: { x: 0, y: 110 },
      containerWidth: 182, // 容器宽度
      containerHeight: 52, // 容器高度
      threshold: 20, // 边缘吸附阈值
      padding: 10, // 边缘吸附后的间距
      minPosX: 0, // X轴最小位置
      maxPosX: 0, // X轴最大位置
      minPosY: window.location.origin.includes("app") ? 70 : 110,
      maxPosY: window.innerHeight - 52,
      loginPopupVisible: false,
      registerPopupVisible: false,
      messagePopVisible: false,
      successfulPopupVisible: false,
      registerTipsPop:false,
    }
  },
  watch: {
    $route(to) {
      if (to.path == '/') {
        this.getWithdrawRecord();
      } else if (to.path == '/login') {
        this.showXf = false
      }
      if(localStorage.getItem('firstRegister') == 1){
      this.setSuccessfulShow(true)
      localStorage.removeItem('firstRegister')
      }else{
        this.setSuccessfulShow(false)
      }

    },
    loginPopShow(newVal) {
      this.loginPopupVisible = newVal;
    },
    registerPopShow(newVal) {
      this.registerPopupVisible = newVal;
    },
    successfulPopShow(newVal) {
      this.successfulPopupVisible = newVal;
    },

    messagePop(newVal) {
      this.messagePopVisible = newVal;
    },
  },
  methods: {
    ...mapActions(['setLoginShow', 'setRegisterShow', 'setMessagePop','setSuccessfulShow']),
    loginClose() {
      this.setLoginShow(false)
    },
    registerClose() {
      this.setRegisterShow(false)
    },
    okMessage() {
      // this.setMessagePop({type:1,data:"打开弹窗用这个方法"})
      this.setMessagePop()
    },
    checkWs() {
      if (ws._ws.readyState == 1) {
        ws.send({ "reqId": 1, "method": "login", "params": { "token": localStorage.getItem('token') } })

        ws.registerCallback('message', (data) => {
          if (data.type == 'deposit') {
            this.$notify({
              message: "充值" + data.data.amount + "元成功",
              duration: 2000,
              background: "#1989fa",
            });
          }
          if (data.type == 'withdraw') {
            if (data.data.isSuccess) {
              this.$notify({
                message: "提款" + data.data.amount + "元成功",
                duration: 2000,
                background: "#1989fa",
              });
            } else {
              this.$notify({
                message: data.data.remark,
                duration: 2000,
                background: "#FF4337",
              });
            }
          }

        });
      } else {
        setTimeout(() => {
          this.checkWs()
        }, 2000)
      }
    },
    getWithdrawRecord() {
      this.getSevenDay(14)
      if (localStorage.getItem('token')) {
        var opt = {
          startTime: this.startTime,
          endTime: this.endTime,
          pageNumber: 1,
          pageSize: 20
        }
        withdrawRecord(opt).then((res) => {
          if (res.code == 0) {
            if (res.data.data.length <= 0) {
              this.showXf = false
              return;
            }
            if (res.data.data[0].status == 0) {
              this.withList0 = res.data.data[0]
              this.showXf = true;
            } else {
              this.showXf = false
            }
          }
        })
      }

    },
    getSevenDay(time) {
      const endDate = new Date(); // 结束日期为今天
      endDate.setHours(23, 59, 59, 999); // 将时间设置为 23:59:59

      const startDate = new Date(endDate); // 起始日期初始化为结束日期
      startDate.setDate(startDate.getDate() - time); // 获取7天前的日期
      startDate.setHours(0, 0, 0, 0); // 将时间设置为 00:00:00
      // 格式化日期为 yyyy-MM-dd HH:mm:ss 格式
      const format = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      this.startTime = format(startDate);
      this.endTime = format(endDate);
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("touchmove", this.drag);
      document.removeEventListener("mouseup", this.stopDrag);
      document.removeEventListener("touchend", this.stopDrag);
    },
    drag(event) {
      if (!this.showXf) {
        return;
      }
      if (this.isDragging) {
        this.position.x = event.touches[0].clientX - this.touchOffset.x;
        this.position.y = event.touches[0].clientY - this.touchOffset.y;
        this.position.x = Math.max(
          this.minPosX,
          Math.min(this.maxPosX, this.position.x)
        );
        this.position.y = Math.max(
          this.minPosY,
          Math.min(this.maxPosY, this.position.y)
        );

        console.log(this.position);
        console.log(this.minPosX);
        console.log(this.minPosY);

        console.log(this.position.x, this.position.y, "啊速度撒");
        // 边缘吸附逻辑
        // if (this.position.y <= this.threshold) {
        //   this.position.y = -this.padding;
        // } else if (this.position.y >= this.containerHeight - this.threshold) {
        //   this.position.y = this.containerHeight - this.padding;
        // }
      }
    },
    startDrag(event) {
      if (!this.showXf) {
        return;
      }
      this.isDragging = true;
      const rect = this.$refs.draggableApp.getBoundingClientRect();
      console.log(rect.left);
      if (event.touches) {
        this.touchOffset.x = event.touches[0].clientX - rect.left;
        this.touchOffset.y = event.touches[0].clientY - rect.top;
      }
      document.addEventListener("touchmove", this.drag);
      document.addEventListener("touchend", this.stopDrag);
    },
    onTouchMove(event) {
      event.preventDefault();
    },
    onTouchEnd() { },
  },
  created() {


    let token = localStorage.getItem('token')
    if (token) {
      this.checkWs()
    }



    var origin = window.location.origin;
    if (origin.includes("app")) {
      // 创建一个新的 <style> 元素
      const style = document.createElement('style');
      style.textContent = `
          html {
            overscroll-behavior: none;
          }
        `;

      // 将 <style> 元素添加到 <head> 中
      document.head.appendChild(style);
    }
    if (!localStorage.getItem('requestUrl')) {
      noFbtoken()
        .then((res) => {
          localStorage.setItem('serverInfo', JSON.stringify(res.data.serverInfo))
          localStorage.setItem('requestUrl', res.data.serverInfo.pushServerAddress)
        })
    }
  },
  mounted() {
    
    this.getWithdrawRecord()
  },
  computed: {
    ...mapState({
      loginPopShow: state => state.loginPopShow,
      registerPopShow: state => state.registerPopShow,
      messagePop: state => state.messagePop,
      messageData: state => state.messageData,
      successfulPopShow: state => state.successfulPopShow,
    })
  }
}
</script>

<style lang="less">
@import '@/style/global.less';

html {
  // overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // height: 100dvh;
  // background: #101B1F;
  // position: fixed;
  width: 100%;
  // overflow-y: scroll;

  // z-index: 2;

}

body {
  background: #202C37;
  // overflow: hidden;
  // position: relative;
  -webkit-overflow-scrolling: touch;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

.contents {
  // background: radial-gradient(128.3% 54.62% at 131.6% -1.79%, #02568E 6%, #18222A 61.18%, #18222A 100%);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.loginContent {
  // background-image: url('@/assets/login/background.svg');
  background: #101B1F;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100dvh;
  height: 100dvh;
}

.newPop {
  height: 100dvh !important;

}

.loadingPop {
  background-color: transparent !important;
  // width:380px;
  // height:280px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadLogoGif {
    margin: 0 auto;
    width: 88px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.loadingTxt {
  font-size: 22px;
  color: #fff;
  position: absolute;
  bottom: 40%;
  left: 0;
  right: 0;
  margin: auto;
}


.loadingTxt {
  font-size: 22px;
  color: #fff;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -2rem, 0);
  }
}

.newBj {
  background-color: #202C37;
  width: 100vw;
  height: 100%;

}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader>div {
  width: 0.8rem;
  height: 0.8rem;
  margin: 3rem 0.2rem;
  //background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;

  img {
    width: 100%;
    height: 100%;
  }
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 0.4s;
}

img {
  pointer-events: none;
}

.van-popover__arrow {
  border-bottom-color: rgba(82, 89, 119, 1) !important;
}

.van-popover__content {
  background-color: rgba(82, 89, 119, 1);
  border-radius: 4px !important;
}


.xfck {
  width: 182px;
  height: 52px;
  border-radius: 44px 0px 0px 44px;
  background: rgba(43, 68, 90, 1);
  position: fixed;
  z-index: 200;
  top: 0;
  // bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 0 8px;
  transition: all 0.2s ease;

  .leftImg {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .zImg {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .xfright {
    .xfTit {
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 12px;
      text-align: left;
    }

    .xfTips {

      margin-top: 5px;
      color: rgba(71, 116, 218, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 12px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}

.downOutApp {
  width: 100%;
  background: transparent !important;
  padding: 0 20px;
}

.dhPop {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageCs {
  background: transparent !important;

  .messageIn {
    width: 280px;
    min-height: 188px;
    border-radius: 12px;
    background: rgba(32, 44, 55, 1);
    padding: 14px 12px 15px;

    .mtitLine {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mtitName {
        color: rgba(179, 185, 210, 1);
        font-family: "PingFang SC";
        font-weight: 600;
        font-size: 14px;
      }

      .mtitRight {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .mesDataOut {
      min-height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      .mesData {
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;

      }
    }

    .mbtn {
      margin: 15px auto 0 auto;
      width: 116px;
      height: 39px;
      border-radius: 25px;
      background: rgba(68, 92, 208, 1);
      border: 0;
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 16px;
    }
  }
}

</style>
