<template>
	<div class='details' :style="$store.getters['sport/$theme']">
		<div class='header' :class="{ active: toplistShow }">
			<div class='fixed'>
				<div class='back' @click="back">
					<van-icon name="arrow-left" color="#fff" size="20" />
				</div>
				<div class='title' v-if='data.lg' @click="toplistShow = !toplistShow">
					<span class="img">
						<img :src="data.lg?.lurl" />
					</span>
					<span>{{ data.lg?.na }}</span>
					<van-icon v-if="type!=7" name="arrow-down" />
				</div>
				<div class='right' @click='rightShow'>
					<svg t="1719154941715" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
						p-id="9702" width="200" height="200">
						<path
							d="M837.8 0H186.2C109.4 0 46.5 62.8 46.5 139.6v744.7c0 76.8 62.8 139.6 139.6 139.6h651.6c76.8 0 139.6-62.8 139.6-139.6V139.6C977.5 62.8 914.6 0 837.8 0z m93.1 884.4c0 51.3-41.8 93.1-93.1 93.1H186.2c-51.3 0-93.1-41.8-93.1-93.1V139.6c0-51.3 41.8-93.1 93.1-93.1h651.6c51.3 0 93.1 41.8 93.1 93.1v744.8z"
							p-id="9703" fill="#ffffff"></path>
						<path d="M232.7 232.7h558.5v46.5H232.7zM232.7 465.5h558.5V512H232.7zM232.7 698.2h558.5v46.5H232.7z"
							p-id="9704" fill="#ffffff"></path>
					</svg>
				</div>
			</div>
			<div class='maskbg' v-if="toplistShow" @click="toplistShow=false"></div>
			<div class='list' v-if="type!=7">
				<van-skeleton :row="3" :loading="topListLoading">
					<div class='item-box' v-for='(item,i) in toplist' :key="i" @click="changeData(item)">
						<div class='item' :class="{active: item.id==id}">
							<div class='name'>
								<span class="img">
									<img :src="item.ts[0].lurl" />
								</span>
								<span>{{item.ts[0].na}}</span>
							</div>
							<div class='center' v-if="item.nsg && item.nsg?.length && item.ms == 5">
								<span>
									{{ item.mc.pe | desccn }} {{ secondsToHMS(item.mc.s) }}
								</span>
								<span>
									{{ item.nsg[0].sc[0] }}-{{ item.nsg[0].sc[1] }}
								</span>
							</div>
							<div class="center" v-else>
								<div v-if="item.ms == 8">
									<span>暂停</span>
									<p>-</p>
								</div>
								<div v-else>
									{{ getTime(item.bt, 1) }}
									<p style="font-size: 13px; margin-top: 6px;">{{ getTime(item.bt) }}</p>
								</div>
							</div>
							<div class='name'>
								<span class="img">
									<img :src="item.ts[1].lurl" />
								</span>
								<span>{{item.ts[1].na}}</span>
							</div>
						</div>
					</div>
				</van-skeleton>
			</div>
		</div>

		<van-skeleton :row="10" :loading="loading">
			<div class="real" v-if="data.lg && type!=7 && !playAnimation" style='background: #ffffff0a'>
				<div class='topinfo'>
					<div class='team'>
						<div class="pai">
							<span class="img">
								<img v-if="data.ts[0].lurl" :src="data.ts[0].lurl" />
							</span>
							<span class="absolute">
								<span class="redpai" v-if="getNsgType(data.nsg, 7)">{{ getNsgType(data.nsg, 7, 0) }}</span>
								<span class="huangpai" v-if="getNsgType(data.nsg, 5)">{{ getNsgType(data.nsg, 5, 0) }}</span>
							</span>
						</div>
						<span class="mt10 f12">{{ data.ts[0].na }}</span>
					</div>
					<div class='info'>
						<div class='time' v-if="data.ms==5">
							<span v-if="data.mc.pe">
								{{ data.mc.pe | desccn }} 
							</span>
							<span v-if="data.mc.s">
								{{ secondsToHMS(data.mc.s) }}
							</span>
							<span v-if='data?.mc?.itd' class="redfont">+{{ data.mc.itd }}</span>
						</div>
						<div class="time" v-else>
							<p>{{ match_status[data.ms] }}</p>
							<span v-if='data.ms == 4'>{{ getTime1(data.bt) }}</span>
						</div>
						<div class='shengfu' v-if="data.nsg && data.nsg[0].sc && data.ms == 5">
							<span>{{ getNsgType(data.nsg, 5, 0) }}</span>
							-
							<span>{{ getNsgType(data.nsg, 5, 1) }}</span>
						</div>
						<div v-else>
							VS
						</div>
						<div class="infobottom" v-if="data.nsg && data.nsg[0].sc">
							<span>
								<img src="@/assets/sport/score.svg" alt="">
								{{ data.nsg[0]?.sc[0] }}
								-
								{{ data.nsg[0]?.sc[1] }}
							</span>
							<span>
								<img src="@/assets/sport/flag.svg" alt="">
								{{ (data.nsg.filter(a => a.pe == data.nsg[0].pe).reverse())[0]?.sc[0] }}
								-
								{{ (data.nsg.filter(a => a.pe == data.nsg[0].pe).reverse())[0]?.sc[1] }}
							</span>
						</div>

					</div>
					<div class='team'>
						<div class="pai">
							<span class="absolute1">
								<span class="huangpai" v-if="getNsgType(data.nsg, 5)">{{ getNsgType(data.nsg, 5, 0) }}</span>
								<span class="redpai" v-if="getNsgType(data.nsg, 7)">{{ getNsgType(data.nsg, 7, 0) }}</span>
							</span>
							<span class="img">
								<img v-if="data.ts[1].lurl" :src="data.ts[1].lurl" />
							</span>
						</div>
						<span class="mt10 f12">{{ data.ts[1].na }}</span>
					</div>
				</div>
				<div class='donghua'>
					<span class="flex" @click="showAnimation" v-if="data.as?.length">
						<img src="@/assets/sport/live-icon-new.png" /> 动画
					</span>
					<span class="flex" v-else @click="playAnimation = true">
						<img src="@/assets/sport/live-icon-new.png" />暂无动画
					</span>
				</div>
			</div>
			<div class="amiation" v-if="playAnimation">
				<span class="close" @click="playAnimation=false">
					<van-icon name="cross" color='#fff' size="22px"/>
				</span>
				<iframe :src="data.as[0]"></iframe>
			</div>

			<div class='tabs' v-if="type!=7">
				<!-- <ul>
					<li v-for='(item, i) in tabslist' :key='i' :class="{ active: tabsActive == i }" @click='changeTab(i)'>
						{{ item.DESC_CN }}
					</li>
				</ul>
				<span class="img" :class="{ active: allopen }" @click="handleAllOpen">
					<img src='@/assets/sport/top1.svg' />
				</span> -->
				<van-tabs @click="changeTab">
					<van-tab v-for="(item, i) in tabslist" :title="item.DESC_CN" :key="i"></van-tab>
				</van-tabs>
				<span class="img" :class="{ active: allopen }" @click="handleAllOpen">
					<img src='@/assets/sport/top1.svg' />
				</span>
			</div>

			<div>
				<div class='block' v-for="(item, i) in filterList" :key="i">
					<div class='title'>
						<span @click="changeHeight(item)">
							<span v-if="item.type" class="type">{{ item.type }}</span>
							{{ item.topname }}
						</span>
						<span @click="setTop(item)" class="flex-c">
							<svg v-if="!item.top" t="1719725029300" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="5146" width="64" height="64">
								<path
									d="M1024 0v102.4H0V0h1024zM512 153.6l512 496.7936h-297.984L727.1936 1024h-429.056v-373.6064H0L512 153.6z"
									fill="var(--theme-top-icon)" p-id="5147"></path>
							</svg>

							<svg v-else t="1719725029300" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="5146" width="64" height="64">
								<path
									d="M1024 0v102.4H0V0h1024zM512 153.6l512 496.7936h-297.984L727.1936 1024h-429.056v-373.6064H0L512 153.6z"
									fill="var(--theme-text)" p-id="5147"></path>
							</svg>
						</span>
					</div>
					<div class='contrast' :class="{ height: item.open }">
						<div class='list'>
							<div class='dl' v-for="(attr, m) in item.list" :key="m">
								<div class='dt' v-if="attr.name">
									
									<!-- <img v-if='attr.name!="和"' src='https://static.fastbs55.com/2ad7932c181e1c1d2c6a958c7c51186b.jpeg' /> -->
									<img v-if='attr.name!="和"' :src="data.ts[m?1:0].lurl || require('@/assets/sport/notimg.png')" alt="">
									<span>{{ attr.name }}</span>
								</div>
								<div class='dd' 
									v-for="(el, n) in attr.list" :key="n" 
									@click='handleDownOrder(el, item)'
									:class="{active: el.na == orderSelectObj[el.id]?.na}"
								>
									<div class="dditem" v-if='el.ss==1'>

										<span>{{ el.nm }}</span>
										<div v-if="el.bod" :style='getColor(el.className)'>
											<span>{{ el.od }}</span>
											<i v-if="el.className" :class="[el.className]" class='iconfont icon-peishuaibianhua-copy'></i>
											<!-- <i v-if="hItem.className" :class="[hItem.className]"
											class='iconfont icon-peishuaibianhua-copy'></i> -->
										</div>
										<div v-else>
											<i class='iconfont icon-lock' style='font-size: 16px;'></i>
										</div>
									</div>
									<div class="dditem" style="justify-content: center;" v-else>
										<i class='iconfont icon-lock' style='font-size: 16px;'></i>
									</div>
								</div>
							</div>
						</div>
						<div class='qi' v-if="item.qita">
							<div class='dl'>
								<div class='dd' 
									@click='handleDownOrder(item.qita, item)'
									:class="{active: item.qita.na == orderSelectObj[item.qita.id]?.na}"
								>
									<div class="dditem" v-if='item.qita.ss==1' :style='getColor(item.qita.className)'>
										其他
										<span>{{ item.qita.od }}</span>
										<i v-if="item.qita.className" :class="[item.qita.className]" class='iconfont icon-peishuaibianhua-copy'></i>
										<img src="" />
									</div>
									<div class="dditem" style="justify-content: center;" v-else>
										<i class='iconfont icon-lock' style='font-size: 16px;'></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<van-empty
				v-if="!filterList.length"
				class="custom-image"
				:image="emptyimg"
				description="没有数据"
			/>
		</van-skeleton>


	</div>
</template>

<script>
import {
	getMatchDetail, getList
} from '@/request/sportApi.js'
import mixin from '../utils/mixin'
import {market_tag, match_period, match_status} from '../utils/config'
export default {
	name: 'team-match',
	mixins: [mixin],
	props: {
		detailsItem: {
			type: Object,
			default: () => {}
		},
		orderSelectObj: {
			type: Object,
			default: () => {}
		},
		type: {
			type: Number,
		},
	},
	data() {
		return {
			id: this.detailsItem.id,
			toplistShow: false,
			toplist: [],
			data: {},
			timer: null,
			calcTimer: null,
			topTimer: null,
			topListLoading: true,
			loading: true,
			oddsTypeList: [1, 2, 3, 4, 5],	// 不能改，有对应的关系
			// 	"欧洲盘": 1,
			// 	"香港盘": 2,
			// 	"马来盘": 3,
			// 	"印尼盘": 4,
			// 	"美式盘": 5
			tabslist: [],
			tabsActive: 0,
			match_status: match_status,	// 赛事进行状态

			datalist: [
				// {
				// 	name: '让球4',
				// 	list: [
				// 		{
				// 			name: '冈萨多1',
				// 			list: [
				// 				{}, {}, {}, {}
				// 			]
				// 		},
				// 		{
				// 			name: '冈萨多2',
				// 			list: [
				// 				{}, {}, {}, {}
				// 			]
				// 		}
				// 	],
				// 	qita: {}
				// }
			],
			lastResList: {},	// 上一次的数据，需要对比大小

			allopen: true,
			playAnimation: false, // 播放动画
		}
	},
	computed: {
		filterList() {
			if (!this.datalist.length) return []
			let list1 = []
			let list2 = []
			let code = 0
			if (this.type != 7) {
				code = this.tabslist[this.tabsActive]?.CODE
			}
			let newList = this.datalist
			if (code != 0) {
				newList = this.datalist.filter(item => item.tps.includes(code))
			}
			newList.forEach(item => {
				if (item.top) {
					list1.push(item)
				} else {
					list2.push(item)
				}
			})
			return list1.concat(list2)
		}
	},
	filters: {
		desccn(pe) {
			return match_period.find(m => m.CODE == pe).DESC_CN
		},
		day(bt) {
			let date = new Date(bt);
			let month = date.getMonth() + 1;  // 月份是从0开始的，所以要加1
			let day = date.getDate();

			return (month<10 ? '0' : '') + month + ':' + day
		},
		hour(bt) {
			let date = new Date(bt);
			let hours = date.getHours();
			let minutes = date.getMinutes();
			return hours + ':' + (minutes < 10 ? '0' : '') + minutes
		}
	},
	watch: {
		toplistShow() {
			if (this.toplistShow) {
				if (!this.toplist.length) {
					this.getTopList()
				}
				let el = document.getElementsByClassName('footer-touzhu')[0]
				if (el) {
					el.style.display = 'none'
				}
			} else {
				clearInterval(this.topTimer )
				this.topTimer = null
				let el = document.getElementsByClassName('footer-touzhu')[0]
				if (el) {
					el.style.display = 'flex'
				}
			}
		}
	},
	created() {
		this.id = this.detailsItem.id
		// this.id = 918933
		this.getData()
	},
	mounted() {
		history.pushState('2', '', '');
		localStorage.setItem('back', 1)
	},
	destroyed() {
		clearInterval(this.dataTimer)
		clearInterval(this.calcTimer)
		clearInterval(this.topTimer)
		this.dataTimer = null
		this.calcTimer = null
		this.topTimer = null
		
		// localStorage.setItem('back', '')
		localStorage.removeItem('back')
		// window.removeEventListener('popstate')
		// window.removeEventListener('hashchange')
	},
	
	methods: {
		// 查看动画
		showAnimation() {
			if (this.data.ms == 4) {
				this.$toast('未开赛')
				return
			}
			this.playAnimation = true
		},

		// 对比大小 设置升降的class
		comparedBigSmall(lastList, list) {
			// console.log(lastList, 'lastList', !lastList.mg && lastList.mg.length==0);
			if (!lastList.mg) {
				return list
			}
			if (list.mg.length) {
				list.mg.forEach((attr, a) => {
					if (attr.mks.length) {
						attr.mks.forEach(((el, e) => {
							if (el.op.length) {
								el.op.forEach((o, m) => {
									try {
										let mga = lastList.mg[a]
										if (mga) {
											if (mga.mks && mga.mks[e]) {
												let {od} = mga.mks[e]?.op[m]
												if (od && o.od < od) {
													o.className = 'down'
												} 
												if (od && o.od > od) {
													o.className = 'up'
												}
											}
										}
									} catch (e) {
										console.log(e);
									}
									
								})
							}
						}))
					}
				})
			}
			return list
		},

		// 获取数据
		getData() {
			clearInterval(this.dataTimer)
			clearInterval(this.calcTimer)
			this.dataTimer = null
			this.calcTimer = null
			getMatchDetail({
				languageType: "CMN",
				matchId: this.id,
				oddsType: this.oddsTypeList[this.$store.state.sport.sportConfig[1].active]
			})
			.then(res => {

				this.data = this.comparedBigSmall(this.lastResList, res.data)
				this.lastResList = res.data
				this.calcTIme()
				if (this.data.mc.r && this.data.mc.tp == 1) {
					this.calcTimer = setInterval(this.calcTIme, 1000)
				}
				this.dataTimer = setInterval(this.getData, 4000)
				this.tabslist = this.getTabslist(res.data.mg)
				if (this.type==7) {
					this.datalist = this.gjDataToList(res.data)
				} else {
					this.datalist = this.dataToList(res.data)
				}
				this.loading = false
			})
		},

		// 获取顶部比赛列表
		getTopList() {
			this.topListLoading = true
			getList({
				languageType: "CMN",
				leagueId: this.detailsItem.lg.id,
				oddsType: this.oddsTypeList[this.$store.state.sport.sportConfig[1].active]
			})
			.then(res => {
				this.topListLoading = false
				this.toplist = res.data.records
				this.topTimer = setInterval(() => {
					this.toplist.forEach(item => {
						if (item.mc.r && item.mc.tp) {
							if (item.mc.tp == 1) {
								item.mc.s++
							} else {
								if (item.mc.s > 0) {
									item.mc.s--
								}
							}
						}
					})
				}, 1000)
			})
		},

		// 根据menus生成tabs列表
		getTabslist(list) {
			if (this.type==7) return
			let arr = [{'CODE': '0', 'DESC_CN': '全部'}]
			if (!list.length) {
				return arr
			}
			let tps = []
			list.forEach(item => {
				tps = tps.concat(item.tps)
			})
			new Set(tps).forEach(item => {
				arr.push(market_tag.find(el => el.CODE == item))
			})
			return arr
		},

		// 冠军数据变成自己想要的
		gjDataToList(data) {
			let mgList = data.mg
			let newList = []

			mgList.forEach((mg) => {
				let obj = {
					top: false,
					open: true,
					topname: mg.nm,
					name: mg.nm,
					list: [{list:[]}]
				}
				mg.mks.forEach(mks => {
					let op = mks.op[0]
					op.id = mks.id
					op.na = op.nm
					op.ss = mks.ss
					obj.list[0].list.push(op)
				})
				newList.push(obj)
			})
			return newList
		},
		
		// 非冠军数据变成自己想要的
		dataToList1(data) {
			if (!data.ts[0] || !data.mg[0]) return []
			let mgList = data.mg
			let na0 = data.ts[0].na
			let na1 = data.ts[1].na
			let newList = []

			mgList.forEach((mg) => {
				let nmArr = mg.nm.split('-')
				let obj = {
					top: false,
					open: this.allopen,
					topname: nmArr[0],
					name: mg.nm,
					tps: mg.tps,
					type: nmArr.length > 1 ? nmArr[1] : undefined,
					list: []
				}
				let opna = mg.mks[0].op[0].na 
				let notOne = ['末位', '独赢']
				let twoColumn = ['大', '小', '是', '否', '单', '双', '半/全场正确比分']
				if (twoColumn.includes(opna)) {
					mg.mks[0].op.forEach(() => {
						obj.list.push({ list: [] })
					})
					mg.mks.forEach((mks) => {
						let id = mks.id
						let ss = mks.ss
						mks.op.forEach((op, o) => {
							op.id = id
							op.ss = ss
							obj.list[o].list.push(op)
						})
					})
				} else if (mg.mks?.length == 1 && !notOne.find(one => mg.nm.includes(one))) {
					// 分两种情况
					// 波胆 分三列
					// 其余分一列
					// 波胆
					let notArr = ['节', '进球']
					if (mg.mks[0].op[0].nm == opna && !notArr.find(n => mg.nm.includes(n))) {
						obj.list = [
							{ name: na0, list: [] },
							{ name: '和', list: [] },
							{ name: na1, list: [] },
						]
						let id = mg.mks[0].id
						let ss = mg.mks[0].ss
						// console.log(mg.mks);
						mg.mks[0].op.forEach(el => {
							el.id = id
							el.ss = ss
							let arr = el.na.split('-')
							if (arr[0] > arr[1]) {
								obj.list[0].list.push(el)
							} else if (arr[0] < arr[1]) {
								obj.list[2].list.push(el)
							} else {
								if (el.na == '其他') {
									obj.qita = el
								} else {
									obj.list[1].list.push(el)
								}
							}
						})
					} else {
						let id = mg.mks[0].id
						let ss = mg.mks[0].ss
						obj.list = [{ 
							list: mg.mks[0].op.map(o => {
								o.id = id
								o.ss = ss
								return o
							}) 
						}]
					}
				} else if (opna == na0) {
					mg.mks[0].op.forEach((op) => {
						obj.list.push({ name: op.na, list: [] })
					})
					mg.mks.forEach((mks) => {
						let id = mks.id
						let ss = mks.ss
						mks.op.forEach((op, o) => {
							op.id = id
							op.ss = ss
							obj.list[o].list.push(op)
						})
					})
				} else {
					obj.list.push({ list: [] })
					mg.mks.forEach((mks) => {
						let id = mks.id
						let ss = mks.ss
						mks.op.forEach((op) => {
							op.id = id
							op.ss = ss
							op.na = op.nm
							obj.list[0].list.push(op)
						})
					})
				}
				newList.push(obj)
			})
			return newList
		},
		
		dataToList(data) {
			if (!data.ts[0] || !data.mg[0]) return []
			let mgList = data.mg
			let na0 = data.ts[0].na
			let na1 = data.ts[1].na
			let newList = []
			let notname = ['大/小', '球数', '单/双']
			let notthreerow = ['进球数', '净胜球数']
			let onerow = ['进球数', '胜球数', '双重', '全场/半场', '半场/全场', '独赢 &']
			let tworow = ['末位数', '正确比分']
			
			mgList.forEach((mg) => {
				let nmArr = mg.nm.split('-')
				let obj = {
					top: false,
					open: this.datalist.find(d => d.name==mg.nm)?.open == false ? false : true,
					topname: nmArr[0],
					name: mg.nm,
					tps: mg.tps,
					type: nmArr.length > 1 ? nmArr[1] : undefined,
					list: [],
				}

				let naa = mg.mks[0].op[0].na
				let naList = naa.split('-')
				// let isThree = naa == mg.mks[0].op[0].nm && naa.includes('-') && naa.includes('/')
				
				// 等于2 代表是比分，分三列
				if (naList.length == 2 && naList[0] && Number(naList[0]) && !notthreerow.find(a => mg.nm.includes(a))) {
					obj.list = [
						{ name: na0, list: [] },
						{ name: '和', list: [] },
						{ name: na1, list: [] },
					]
					let id = mg.mks[0].id
					let ss = mg.mks[0].ss
					// 波胆数据结构和其他的不一样
					if (mg.nm.includes('波胆')) {
						let mks = mg.mks[0].op
						mks = mks.sort((a,b) => {
							return Number(a.na.replace('-', '')) - Number(b.na.replace('-', ''))
						})
						mks.forEach(el => {
							el.id = id
							el.ss = ss
							let arr = el.na.split('-')
							if (arr[0] > arr[1]) {
								obj.list[0].list.push(el)
							} else if (arr[0] < arr[1]) {
								obj.list[2].list.push(el)
							} else {
								if (el.na == '其他') {
									obj.qita = el
								} else {
									obj.list[1].list.push(el)
								}
							}
						})
					} else {
						let mks = mg.mks
						
						mks = mks.sort((a,b) => {
							return Number(a.op[0].na.replace('-', '')) - Number(b.op[0].na.replace('-', ''))
						})
						mks.forEach(el => {
							el = el.op[0]
							el.id = id
							el.ss = ss
							let arr = el.na.split('-')
							if (arr[0] > arr[1]) {
								obj.list[0].list.push(el)
							} else if (arr[0] < arr[1]) {
								obj.list[2].list.push(el)
							} else {
								if (el.na == '其他') {
									obj.qita = el
								} else {
									obj.list[1].list.push(el)
								}
							}
						})
					}
				} else {
					// 两行情况下
					if (tworow.find(a => mg.nm.includes(a))) {
						obj.list.push({ list: [] }, { list: [] })
						let id = mg.mks[0].id
						let ss = mg.mks[0].ss
						mg.mks[0].op.forEach((op, o) => {
							op.id = id
							op.ss = ss
							obj.list[o%2].list.push(op)
						})
					} else {
						// 没有name的
						if (notname.find(a => mg.nm.includes(a))) {
							mg.mks[0].op.forEach(() => {
								obj.list.push({ list: [] })
							})
						} else {
							mg.mks[0].op.forEach((a) => {
								obj.list.push({ name: a.na, list: [] })
							})
						}
						// 一行情况下
						if (onerow.find(attr => mg.nm.includes(attr))) {
							let id = mg.mks[0].id
							let ss = mg.mks[0].ss
							obj.list = [{ 
								list: mg.mks[0].op.map(o => {
									o.id = id
									o.ss = ss
									return o
								}) 
							}]
						} else {
							mg.mks.forEach((mks) => {
								let id = mks.id
								let ss = mks.ss
								mks.op.forEach((op, o) => {
									op.id = id
									op.ss = ss
									obj.list[o].list.push(op)
								})
							})
						}
						
					}
				}
				
				
				newList.push(obj)
			})
			
			return newList
		},

		back() {
			this.$emit('close')
		},
		rightShow() {
			this.$emit('show')
			// this.$router.push('/rightslider')
		},

		// 获取订单class
		changeTab(i) {
			this.tabsActive = i
		},

		// 根据配置获取颜色
		getColor(n) {
			if (!n) return {}
			if (n == 'up') {
				return {
					color: this.$store.state.sport.sjcolor.up
				}
			} else {
				return {
					color: this.$store.state.sport.sjcolor.down
				}
			}
		},

		// 去顶部
		setTop(item) {
			this.datalist = this.datalist.map(attr => {
				if (attr.name == item.name) {
					attr.top = !attr.top
				}
				return attr
			})
		},

		// 改变高度
		changeHeight(item) {
			this.datalist = this.datalist.map(attr => {
				if (attr.name == item.name) {
					attr.open = !attr.open
				}
				return attr
			})
		},

		// 关闭或打开所有
		handleAllOpen() {
			this.allopen = !this.allopen
			this.datalist = this.datalist.map(attr => {
				attr.open = this.allopen
				return attr
			})
		},

		// 点击下单
		handleDownOrder(el, item) {
			if (!el.bod) return
			this.data.name = item.name
			console.log(el, item, this.data)
			if (el.ss) {
				el.matchId = this.data.id
				this.$emit('downOrder', el, el, this.data)
			}
		},

		// 计算小时、分钟和秒数
		secondsToHMS(seconds) {
			const minutes = Math.floor(seconds / 60);
			const remainingSeconds = seconds % 60;

			// 确保秒数是两位数
			const formattedMinutes = minutes.toString().padStart(2, '0');
			const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
			return `${formattedMinutes}:${formattedSeconds}`;
		},

		// 时间转换1
    getTime1(time) {
      if (!time) return ''
      let date = new Date(time)
      // let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +  "/"
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      // let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      return M + D + h + m
    },

		// 时间转换
    getTime(time,i) {
      if (!time) return ''
      let date = new Date(time)
      // let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) 
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      // let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      // return Y + M + D + h + m + s
			if (i) {
				return M +'/'+ D
			} else {
				return h + ':' + m
			}
    },


		calcTIme() {
			if (this.data.mc.s == 0) {
				return
			}
			if (this.data.mc.r && this.data.mc.tp) {
				if (this.data.mc.tp == 1) {
					this.data.mc.s++
				} else {
					this.data.mc.s--
				}
			}
		},

		changeData(item) {
			this.data = item
			this.id = item.id
			this.getData()
			this.toplistShow = false
		},
		
	}
}
</script>

<style lang='less' scoped>
@import '@/style/global.less';

.flex {
	display: flex;
	align-items: center;
}

.flex-c {
	display: flex;
	flex-direction: column;
}

span,
div {
	box-sizing: border-box;
}

.van-skeleton__row {
	background: @gujia-bg;
}

div, ul {
	&::-webkit-scrollbar {
		display: none;
	}
}

::v-deep .van-tabs {
	width: calc(100% - 50px);
	.van-tabs__nav {
		padding-left: 10px;
	}
	.van-tabs__content {
		display: none;
	}
	.van-tabs__nav, .van-tabs__line {
		background: none;
	}
	.van-tab {
		min-width: 55px;
		color: #fff;
		font-size: 13px;
		margin: 7px 0;
		flex: 0;
	}
	.van-tabs__nav--complete {
		.van-tab {
			flex: 1 0 auto;
		}
	}
	.van-tab--active {
		transition: background-color .3s;
		// font-size: 15px;
		// font-weight: 800;
		// padding: 5px 15px;
		border-radius: 16px;
		background: rgba(71, 116, 218, 1);
	}
}

.details {
	font-size: 16px;
	background: @bg-color;
	min-height: 100vh;
	padding-bottom: 40px;
	position: relative;
	z-index: 9998;
	font-family: "PingFang SC";

	.header {
		padding: 0 12px;
		display: flex;
		align-items: center;
		background: linear-gradient(90deg, #2c3f59 100%, #283643 100%);
		height: 53px;
		position: sticky;
		top: 0;
		z-index: 2;

		&.active {
			.fixed {
				.title {
					i {
						transform: rotate(180deg);
					}
				}
			}

			.list {
				max-height: 50vh;
			}
		}

		.fixed {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;

			.title {
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				
				flex: 1;
				.img {
					width: 26px;
					height: 26px;
					background: #ffffff;
					border-radius: 50%;
					margin-right: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: 18px;
						height: 18px;
					}
				}

				i {
					margin-left: 4px;
					transition: all .3s
				}
			}
			.back {
				width: 50px;
				height: 100%;
				text-align: left;
				display: flex;
				align-items: center;
			}
			.right {
				height: 100%;
				width: 50px;
				justify-content: end;
				display: flex;
				align-items: center;
				svg {
					width: 20px;
					/* height: 20px; */
				}
			}
		}


		.maskbg {
			z-index: 9999;
			position: fixed;
			top: 60px;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0,0,0,0.6);
		}
		.list {
			position: relative;
			z-index: 10000;
			overflow: auto;
			// transition: all .3s;
			max-height: 0;
			border-radius: 0 0 16px 16px;
			background: @theme-white;
			width: 100%;
			position: absolute;
			left: 0;
			right: 0;
			top: 60px;
			padding: 0 12px;

			.item-box {
				border-bottom: 1px solid @time-list-border;
				padding: 6px 0;
			}

			.item {
				display: flex;
				justify-content: space-between;
				padding: 10px 30px;
				border-radius: 6px;
				color: @index-font-color;

				&.active {
					background: @theme-text;
					color: #fff;
				}

				.name {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 13px;
					width: 28%;
					span {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.img {
						width: 48px;
						height: 48px;
						background: #788a9a;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 4px;
						img {
							width: 34px;
							height: 34px;
						}
					}
				}

				.center {
					display: flex;
					flex-direction: column;
					font-size: 15px;

					span:nth-child(1) {
						// font-size: 13px;
						padding: 4px 0 6px 0;
					}

					span:nth-child(2) {
						font-size: 15px;
					}
				}
			}
		}
	}


	::v-deep .amiation {
		width: 100%;
		height: 270px;
		position: relative;
		iframe {
			width: 100%;
			height: 100%;
		}
		.close {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			position: absolute;
			z-index: 99999;
			top: 20px;
			left: 20px;
			background: rgba(0,0,0,0.3);
			border-radius: 50%;
		}
	}

	::v-deep .real {
		border-radius: 0 0 10px 10px;
		background: @sportdefault-bg;

		.topinfo {
			display: flex;
			justify-content: space-between;
			color: #fff;
			padding: 20px 15px;
			padding-bottom: 0;
			font-size: 14px;

			.team {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100px;
				max-width: 32%;
				overflow: hidden;
				.pai {
					position: relative;
					.absolute {
						position: absolute;
						left: 50%;
						top: 22px;
						transform: translate(20px, -50%);
					}
					.absolute1 {
						position: absolute;
						right: 50%;
						top: 22px;
						transform: translate(-20px, -50%);
					}
				}
				div {
					display: flex;
					align-items: center;
					span {
						color: #fff;
						font-size: 9px;
						border-radius: 2px;
						margin-left: 3px;
						padding: 0 2px;
						margin: 0 2px;
					}
					.img {
						width: 44px;
						height: 44px;
						background: rgba(120, 138, 154, 1);
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.redpai {
						background: #c31337;
					}
					.huangpai {
						background: #fdca40;
					}
				}
				img {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					// margin-bottom: 6px;
				}
			}

			.info {
				flex: 1;
				.time {
					margin-bottom: 6px;
					font-size: 12px;
					color: rgba(255, 255, 255, 1);
					span {
						line-height: 22px;
					}
				}

				.redfont {
					font-size: 11px;
				}

				.shengfu {
					font-size: 15px;
					margin-bottom: 6px;
					font-weight: bold;
				}

				.infobottom {
					padding-bottom: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					margin-top: 2px;

					span {
						display: flex;
						align-items: center;
						margin-right: 4px;
					}
				}
			}
		}

		.donghua {
			color: #fff;
			display: inline-flex;
			align-items: center;
			margin: 12px auto 16px;
			font-size: 13px;
			padding: 0px 10px;
			height: 24px;
			border-radius: 20px;
			background: rgba(255, 255, 255, 0.15);

			.flex {
				display: flex;
				align-items: center;

				img {
					margin-right: 6px;
					height: 18px;
				}
			}
		}
	}

	::v-deep .tabs {
		display: flex;
		align-items: center;
		background: @243B4F;
		// padding: 0 0 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 5px 0;
		// border-radius: 20px;
		height: 40px;
		color: @font-color;

		ul {
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			overflow: auto;
			flex: 1;
			height: 100%;

			li {
				white-space: nowrap;
				// margin-right: 12px;
				padding: 5px 15px;
				font-size: 13px;
				
				&.active {
					transition: background-color .3s;
					// font-size: 15px;
					// font-weight: 800;
					// padding: 5px 15px;
					border-radius: 16px;
					background: rgba(71, 116, 218, 1);
				}
			}
		}

		.img {
			width: 50px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			&.active {
				transform: rotate(180deg);
				transition: all .3s;
			}
		}
	}

	::v-deep .block {
		// background: #193146;
		margin: 5px 0;
		color: @font-color;
		padding: 10px;
		border-radius: 12px;
		background: rgba(34, 60, 83, 1);

		.title {
			// padding: 12px 16px;
			padding-left: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			position: relative;
			color: @font-color;
			font-weight: bold;
			font-size: 16px;
			padding-right: 10px;

			span:nth-child(1) {
				flex: 1;
				text-align: left;
				display: flex;
				align-items: center;
			}

			.type {
				flex: 0 1 auto !important;
				font-size: 12px;
				font-weight: 500;
				background: @theme-text;
				color: #fff;
				border-radius: 50px;
				padding: 2px 6px;
				margin-right: 4px;
			}

			&:before {
				content: "";
				background: @theme-text;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 4px;
				height: 18px;
				border-radius: 0 4px 4px 0;
			}

			svg {
				width: 16px;
				height: 16px;
			}
		}

		.contrast {
			
			height: 0;
			overflow: hidden;
			transition: all .3s;

			&.height {
				height: auto;
			}
			.list {
				margin-top: 14px;
			}
			.list,
			.qi {
				padding: 0 5px 0;
				display: flex;
				gap: 6px;

				.dl {
					flex: 1;
					overflow: hidden;

					&:nth-child(2) {
						.dt {
							justify-content: center;
						}
					}
					&:last-child {
						.dt {
							justify-content: end;
						}
					}
					.dt {
						width: 100%;
						display: flex;
						// justify-content: center;
						align-items: center;
						font-size: 13px;
						margin-bottom: 10px;
						color: rgba(179, 185, 210, 1);
						font-weight: 400;
						img {
							width: 15px;
							height: 15px;
							margin-right: 4px;
						}
						span {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}

					.dd {
						font-size: 13px;
						width: 100%;
						transition: background-color 0.3s;
						margin-bottom: 6px;
						// background: @sportdefault-bg;
						margin-bottom: 6px;
						padding: 10px;
						border-radius: 8px;
						background: rgba(255, 255, 255, 0.04);
						

						div {
							// font-weight: 600;
							display: flex;
							align-items: baseline;
						}

						.dditem {
							display: flex;
							justify-content: space-between;
							align-items: center;
							height: 100%;
							font-size: 14px;
							&>span {
								color: rgba(179, 185, 210, 1);
								// font-weight: 400;
							}
							div {
								span {

									color: #fff;
								}
							}
						}

						&.active {
							background: @theme-text;
							color: #fff;
						}

						i {
							display: inline-block;
							font-size: 11px;
							margin-left: 2px;
						}
						.up {
							transform: rotate(180deg);
						}
					}
				}
			}

			// .list {
			// 	.dd {
			// 		span:nth-child(1) {
			// 			opacity: .8;
			// 		}
			// 	}
			// }

			.qi {
				.dl .dd {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}
</style>