<template>
	<div class='details' :style="$store.getters['sport/$theme']">
		<div class='header'>
			<div class='fixed'>
				<div class='back' @click="back">
					<van-icon name="arrow-left" color="#fff" size="20" />
				</div>
				<div class='title' v-if='data.lg'>
					<img :src="data.lg?.lurl" />
					<span>{{ data.lg?.na }}</span>
				</div>
				<div class='right' @click='rightShow'>
					<svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M15.9593 4H18.125C18.6083 4 19 4.41141 19 4.91892V20.0811C19 20.5886 18.6083 21 18.125 21H5.875C5.39175 21 5 20.5886 5 20.0811V4.91892C5 4.41141 5.39175 4 5.875 4H8.5H8.9375V5.37838H15.0625V4H15.9593Z" stroke="white" stroke-width="1.2" stroke-linejoin="round"/>
					<path d="M15 3H9V5H15V3Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M8 9H16" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
					<path d="M8 13H16" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
					<path d="M8 17H16" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
					</svg>

				</div>
			</div>

		</div>

		<van-skeleton :row="10" :loading="loading">
			<div class="real" v-if="data.lg">
				<div class='topinfo'>
					<div class='team'>
						<div>
							<img v-if="data.ts[0].lurl" :src="data.ts[0].lurl" />
						</div>
						<span>{{ data.ts[0].na }}</span>
					</div>
					<div class='info'>
						<div class='time'>
							{{ formatTimestamp(data.bt) }}
						</div>
						<div class='shengfu' v-if="data.nsg && data.nsg[0].sc">
							<span>{{ data.nsg[0]?.sc[0] }}</span>
							-
							<span>{{ data.nsg[0]?.sc[1] }}</span>
						</div>
						<div class='shengfu' v-else>
							0-0
						</div>
					</div>
					<div class='team'>
						<div>
							<img v-if="data.ts[1].lurl" :src="data.ts[1].lurl" />
						</div>
						<span>{{ data.ts[1].na }}</span>
					</div>
				</div>
			</div>
			
			<div class='box'>
				<table>
					<thead>
						<tr>
							<td style="width: 30%;">赛果类型</td>
							<td style="width: 40%;">时段</td>
							<td style="width: 30%;">比分</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,i) in data.nsg" :key="i">
							<td>{{ item.tyg | tyg }}</td>
							<td>{{ item.pe | pe }}</td>
							<td>{{ item.sc.join('-') }} </td>
						</tr>
					</tbody>
				</table>
				<div class="ul" v-for="(item,i) in data.mgs" :key="i">
					<div>
						{{ item.nm }}
					</div>
					<div>
						<p v-for="(attr,a) in item.mks[0].ops" :key="a">
							{{ attr.nm }}
						</p>
					</div>
				</div>
			</div>
		</van-skeleton>


	</div>
</template>

<script>
import {
	matchResultInfo
} from '@/request/sportApi.js'

import {period, result_type_group} from '../utils/config'
export default {
	name: 'team-match',
	props: {
		item: {
			type: Object
		}
	},
	data() {
		return {
			data: {},
			loading: false
		}
	},

	filters: {
		pe(n) {
			return period.find(item => item.CODE == n)?.DESC_CN
		},
		tyg(n) {
			return result_type_group.find(item => item.CODE == n)?.DESC_CN
		}
	},
	
	created() {
		console.log(this.$route)
		this.getData()
	},

	
	methods: {

		// 获取数据
		getData() {
			// console.log(this.item);
			matchResultInfo({
				languageType: "CMN",
				// matchId: 990618 || this.item.ops[0].mid,
				matchId: this.$route.query.mid,
			})
			.then(res => {
				this.data = res.data
				console.log(res);
			})
		},


		back() {
			// this.$emit('close')
			this.$router.replace('/sport')
		},
		rightShow() {
			// this.$emit('show')
			this.$router.back()
		},

		// 计算小时、分钟和秒数
		formatTimestamp(timestamp) {
			const date = new Date(timestamp);

			// 获取月、日、小时、分钟、秒
			const year = date.getFullYear();
			const month = date.getMonth() + 1; // 月份是从0开始的，所以要加1
			const day = date.getDate();
			const hours = date.getHours();
			const minutes = date.getMinutes();
			const seconds = date.getSeconds();

			// 格式化成两位数
			const formattedYear = year;
			const formattedMonth = month < 10 ? `0${month}` : month;
			const formattedDay = day < 10 ? `0${day}` : day;
			const formattedHours = hours < 10 ? `0${hours}` : hours;
			const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
			const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

			// 返回格式化后的字符串
			return `${formattedYear}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		},

		
	}
}
</script>

<style lang='less' scoped>
@import '@/style/global.less';
div, ul {
	&::-webkit-scrollbar {
		display: none;
	}
}
.flex {
	display: flex;
	align-items: center;
}

.flex-c {
	display: flex;
	flex-direction: column;
}

span,
div {
	box-sizing: border-box;
}
.details {
	font-size: 16px;
	background: @bg-color;
	min-height: 100vh;
	padding-bottom: 40px;
	position: relative;
	z-index: 9998;

	.header {
		padding: 14px 12px 0;
		// background-color: #362911;
		background: linear-gradient(90deg, #2c3f59 0%, #283643 100%);
		// background-image: url('@/assets/sport/top-bg.png');
		// background-size: 100%;
		// background-repeat: no-repeat;
		// background-position-y: -30px;
		height: 53px;
		padding-top: 14px;
		position: sticky;
		top: 0;
		z-index: 2;

		.fixed {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;

			.title {
				color: #fff;

				img {
					width: 16px;
					height: 16px;
					margin-right: 4px;
				}

				i {
					margin-left: 4px;
					transition: all .3s
				}
			}

			.right {
				svg {
					width: 24px;
					height: 24px;
				}
			}
		}


	}
	
	
	
	.real {
		border-radius: 0 0 16px 16px;
		background: linear-gradient(90deg, #2c3f59 0%, #283643 100%);

		.topinfo {
			display: flex;
			justify-content: space-between;
			color: #fff;
			padding: 20px 20px 20px;

			.team {
				display: flex;
				flex-direction: column;
				align-items: center;
				div {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 20px;
					width: 48px;
					height: 48px;
					background: #eee;
					border-radius: 50%;
					margin-bottom: 6px;
					overflow: hidden;
				}
				img {
					width: 36px;
					height: 36px;
				}
			}

			.info {
				padding-top: 16px;
				.time {
					font-size: 13px;
				}

				.shengfu {
					font-size: 18px;
					/* margin-bottom: 6px; */
					margin-top: 30px;
				}

				.infobottom {
					padding-bottom: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					margin-top: 2px;

					span {
						display: flex;
						align-items: center;
						margin-right: 4px;
					}
				}
			}
		}

		
	}
	
	.box {
		background: @193146;
		border-radius: 8px;
		margin: 10px;
		font-size: 13px;
	}
	
	table {
		width: 100%;
		border-collapse: collapse;
		thead {
			color: #fff;
		}
		tbody {
			color: #eee;
		}
		tr {
			// border-bottom: 1px solid #c9c9c9;
			line-height: 32px;
			background: #ffffff0a;
			border-radius: 8px;
			margin: 6px;
		}
	}

	.ul {
		display: flex;
		justify-content: space-between;
		// border-bottom: 1px solid #c9c9c9;
		padding: 10px 28px;
		align-items: center;
		// color: #888;
		color: #eee;
		background: #ffffff0a;
		// border-radius: 8px;
		margin: 1px;
	}

}
</style>