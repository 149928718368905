
export const sport = [{
	"CODE": 1,
	"DESC_EN": "Soccer",
	"DESC_CN": "足球",
	"ICON": "zuqiu"
}, {
	"CODE": 2,
	"DESC_EN": "IceHockey",
	"DESC_CN": "冰球",
	"ICON": "bingqiu"
}, {
	"CODE": 3,
	"DESC_EN": "Basketball",
	"DESC_CN": "篮球",
	"ICON": "lanqiu"
}, {
	"CODE": 4,
	"DESC_EN": "Rugby",
	"DESC_CN": "橄榄球",
	"ICON": "ganlanqiu"
}, {
	"CODE": 5,
	"DESC_EN": "Tennis",
	"DESC_CN": "网球",
	"ICON": "wangqiu"
}, {
	"CODE": 6,
	"DESC_EN": "American Football",
	"DESC_CN": "美式足球",
	"ICON": "meishizuqiu"
}, {
	"CODE": 7,
	"DESC_EN": "Baseball",
	"DESC_CN": "棒球",
	"ICON": "bangqiu"
}, {
	"CODE": 8,
	"DESC_EN": "Handball",
	"DESC_CN": "手球",
	"ICON": "shouqiu"
}, {
	"CODE": 10,
	"DESC_EN": "Floorball",
	"DESC_CN": "地板球",
	"ICON": "dibanqiu"
}, {
	"CODE": 12,
	"DESC_EN": "Golf",
	"DESC_CN": "高尔夫",
	"ICON": "gaoerfu"
}, {
	"CODE": 13,
	"DESC_EN": "Volleyball",
	"DESC_CN": "排球",
	"ICON": "paiqiu"
}, {
	"CODE": 14,
	"DESC_EN": "Cricket",
	"DESC_CN": "玩板球",
	"ICON": "banqiu"
}, {
	"CODE": 15,
	"DESC_EN": "TableTennis",
	"DESC_CN": "乒乓球",
	"ICON": "pingpangqiu"
}, {
	"CODE": 16,
	"DESC_EN": "Snooker",
	"DESC_CN": "斯诺克",
	"ICON": "sinuoke"
}, {
	"CODE": 17,
	"DESC_EN": "Futsal",
	"DESC_CN": "五人制足球",
	"ICON": "shineiwurenzuqiu"
}, {
	"CODE": 18,
	"DESC_EN": "MixedMartialArts",
	"DESC_CN": "综合格斗",
	"ICON": "zonghegedou"
}, {
	"CODE": 19,
	"DESC_EN": "Boxing",
	"DESC_CN": "拳击",
	"ICON": "quanji"
}, {
	"CODE": 20,
	"DESC_EN": "Darts",
	"DESC_CN": "飞镖",
	"ICON": "feibiao"
},
{
	"CODE": 21,
	"DESC_EN": "Bowls",
	"DESC_CN": "草地滚球",
	"ICON": "caodigunqiu"
},
{
	"CODE": 24,
	"DESC_EN": "WaterPolo",
	"DESC_CN": "水球",
	"ICON": "shuiqiu"
},
{
	"CODE": 25,
	"DESC_EN": "Cycling",
	"DESC_CN": "自行车",
	"ICON": "zixingche"
},

{
	"CODE": 47,
	"DESC_EN": "Badminton",
	"DESC_CN": "羽毛球",
	"ICON": "yumaoqiu"
},
{
	"CODE": 51,
	"DESC_EN": "BeachVolleyball",
	"DESC_CN": "沙滩排球",
	"ICON": "shatanpaiqiu"
},
{
	"CODE": 92,
	"DESC_EN": "Formula 1",
	"DESC_CN": "F1赛车",
	"ICON": "F1"
},
{
	"CODE": 93,
	"DESC_EN": "Specials",
	"DESC_CN": "特殊投注",
	"ICON": ""
},
{
	"CODE": 94,
	"DESC_EN": "Stock Car Racing",
	"DESC_CN": "赛车",
	"ICON": ""
},
{
	"CODE": 95,
	"DESC_EN": "Motorcycle Racing(Support market type: outright)",
	"DESC_CN": "摩托车",
	"ICON": "motuoche"
},

{
	"CODE": 100,
	"DESC_EN": "Olympic",
	"DESC_CN": "奥林匹克",
	"ICON": "",
	"show": false
}, 
{
	"CODE": 101,
	"DESC_EN": "Asian Game(Support market type: outright)",
	"DESC_CN": "亚运会 （支持玩法：冠军）",
	"ICON": ""
},
{
	"CODE": 164,
	"DESC_EN": "Dota2",
	"DESC_CN": "刀塔2",
	"ICON": ""
},
{
	"CODE": 165,
	"DESC_EN": "LOL",
	"DESC_CN": "英雄联盟",
	"ICON": ""
},
{
	"CODE": 177,
	"DESC_EN": "E-Football",
	"DESC_CN": "电竞足球",
	"ICON": "dianjingzuqiu"
}, {
	"CODE": 178,
	"DESC_EN": "E-Basketball",
	"DESC_CN": "电竞篮球",
	"ICON": "dianjinglanqiu"
},
{
	"CODE": 179,
	"DESC_EN": "CS:GO",
	"DESC_CN": "反恐精英",
	"ICON": ""
}, {
	"CODE": 180,
	"DESC_EN": "KOG",
	"DESC_CN": "王者荣耀",
	"ICON": ""
}, {
	"CODE": 1001,
	"DESC_EN": "Virtual Soccer(Support market type: common)",
	"DESC_CN": "虚拟足球 （支持玩法：普通）",
	"ICON": ""
}, {
	"CODE": 1020,
	"DESC_EN": "Virtual Horse(Support market type: common)",
	"DESC_CN": "虚拟赛马 （支{持玩法：普通）",
	"ICON": ""
}, {
	"CODE": 1021,
	"DESC_EN": "Virtual Greyhounds(Support market type: common)",
	"DESC_CN": "虚拟赛狗{ （支持玩法：普通）",
	"ICON": ""
}, {
	"CODE": 1022,
	"DESC_EN": "Virtual Speedway(Support market type: common)",
	"DESC_CN": "虚拟沙地{摩托车 （支持玩法：普通）",
	"ICON": ""
}, {
	"CODE": 1023,
	"DESC_EN": "Virtual Motorbike(Support market type: common)",
	"DESC_CN": "虚拟摩托车{ （支持玩法：普通）",
	"ICON": ""
}
]


export const match_period = [{
	"CODE": "1001",
	"DESC_EN": "soccer Not started",
	"DESC_CN": "足球比赛未开始"
},
{
	"CODE": "1002",
	"DESC_EN": "soccer First Half",
	"DESC_CN": "足球上半场"
},
{
	"CODE": "1003",
	"DESC_EN": "soccer Half Time",
	"DESC_CN": "足球中场休息"
},
{
	"CODE": "1004",
	"DESC_EN": "soccer Second Half",
	"DESC_CN": "足球下半场"
},
{
	"CODE": "1005",
	"DESC_EN": "soccer FT-Finish",
	"DESC_CN": "足球常规时间结束"
},
{
	"CODE": "1006",
	"DESC_EN": "soccer ET-First Half",
	"DESC_CN": "足球ET(加时赛)上半场"
},
{
	"CODE": "1007",
	"DESC_EN": "soccer ET-Half Time",
	"DESC_CN": "足球ET(加时赛)半场时间"
},
{
	"CODE": "1008",
	"DESC_EN": "soccer ET-Second Half",
	"DESC_CN": "足球ET(加时赛)-后半场"
},
{
	"CODE": "1009",
	"DESC_EN": "soccer ET-Finish",
	"DESC_CN": "足球ET(加时赛)-结束"
},
{
	"CODE": "1010",
	"DESC_EN": "soccer Penalty",
	"DESC_CN": "足球点球大战"
},
{
	"CODE": "1011",
	"DESC_EN": "soccer Finish",
	"DESC_CN": "足球比赛结束"
},
{
	"CODE": "1012",
	"DESC_EN": "soccer Awaiting ET",
	"DESC_CN": "足球等待加时"
},
{
	"CODE": "1013",
	"DESC_EN": "soccer Awaiting Penalty",
	"DESC_CN": "足球等待点球大战"
},
{
	"CODE": "1014",
	"DESC_EN": "soccer Penalty-Finish",
	"DESC_CN": "足球点球大战结束"
},
{
	"CODE": "1015",
	"DESC_EN": "soccer Interrupted",
	"DESC_CN": "足球中断"
},
{
	"CODE": "1016",
	"DESC_EN": "soccer Abandoned",
	"DESC_CN": "足球废弃"
},
{
	"CODE": "3001",
	"DESC_EN": "basketball Not started",
	"DESC_CN": "篮球比赛未开始"
},
{
	"CODE": "3002",
	"DESC_EN": "basketball First Half",
	"DESC_CN": "篮球上半场"
},
{
	"CODE": "3003",
	"DESC_EN": "basketball Half Time",
	"DESC_CN": "篮球中场休息"
},
{
	"CODE": "3004",
	"DESC_EN": "basketball Second Half",
	"DESC_CN": "篮球下半场"
},
{
	"CODE": "3005",
	"DESC_EN": "basketball FirstQuarter",
	"DESC_CN": "篮球第一节"
},
{
	"CODE": "3006",
	"DESC_EN": "basketball Break 1",
	"DESC_CN": "篮球中场休息 1/2"
},
{
	"CODE": "3007",
	"DESC_EN": "basketball SecondQuarter",
	"DESC_CN": "篮球第二节"
},
{
	"CODE": "3008",
	"DESC_EN": "basketball Half Time",
	"DESC_CN": "篮球中场休息 2/3"
},
{
	"CODE": "3009",
	"DESC_EN": "basketball ThirdQuarter",
	"DESC_CN": "篮球第三节"
},
{
	"CODE": "3010",
	"DESC_EN": "basketball Break 3",
	"DESC_CN": "篮球中场休息 3/4"
},
{
	"CODE": "3011",
	"DESC_EN": "basketball FourthQuarter",
	"DESC_CN": "篮球第四节"
},
{
	"CODE": "3012",
	"DESC_EN": "basketball OverTime",
	"DESC_CN": "篮球加时"
},
{
	"CODE": "3013",
	"DESC_EN": "basketball RT-Finish",
	"DESC_CN": "篮球常规时间结束"
},
{
	"CODE": "3014",
	"DESC_EN": "basketball Finish",
	"DESC_CN": "篮球比赛结束"
},
{
	"CODE": "3015",
	"DESC_EN": "basketball Awaiting OT",
	"DESC_CN": "篮球等待加时"
},
{
	"CODE": "3016",
	"DESC_EN": "basketball OT-Finish",
	"DESC_CN": "篮球加时赛结束"
},
{
	"CODE": "3017",
	"DESC_EN": "basketball Delayed",
	"DESC_CN": "篮球延期"
},
{
	"CODE": "3018",
	"DESC_EN": "basketball Interrupted",
	"DESC_CN": "篮球中断"
},
{
	"CODE": "3019",
	"DESC_EN": "basketball Abandoned",
	"DESC_CN": "篮球废弃"
},
{
	"CODE": "13001",
	"DESC_EN": "volleyball Not started",
	"DESC_CN": "排球比赛未开始"
},
{
	"CODE": "13002",
	"DESC_EN": "volleyball 1st Set",
	"DESC_CN": "排球第1局"
},
{
	"CODE": "13003",
	"DESC_EN": "volleyball 1st Set Ended",
	"DESC_CN": "排球第1局结束"
},
{
	"CODE": "13004",
	"DESC_EN": "volleyball 2nd Set",
	"DESC_CN": "排球第2局"
},
{
	"CODE": "13005",
	"DESC_EN": "volleyball 2nd Set Ended",
	"DESC_CN": "排球第2局结束"
},
{
	"CODE": "13006",
	"DESC_EN": "volleyball 3rd Set",
	"DESC_CN": "排球第3局"
},
{
	"CODE": "13007",
	"DESC_EN": "volleyball 3rd Set Ended",
	"DESC_CN": "排球第3局结束"
},
{
	"CODE": "13008",
	"DESC_EN": "volleyball 4th Set",
	"DESC_CN": "排球第4局"
},
{
	"CODE": "13009",
	"DESC_EN": "volleyball 4th Set Ended",
	"DESC_CN": "排球第4局结束"
},
{
	"CODE": "13010",
	"DESC_EN": "volleyball 5th Set",
	"DESC_CN": "排球第5局"
},
{
	"CODE": "13011",
	"DESC_EN": "volleyball 5th Set Ended",
	"DESC_CN": "排球第5局结束"
},
{
	"CODE": "13012",
	"DESC_EN": "volleyball 6th Set",
	"DESC_CN": "排球第6局"
},
{
	"CODE": "13013",
	"DESC_EN": "volleyball 6th Set Ended",
	"DESC_CN": "排球第6局结束"
},
{
	"CODE": "13014",
	"DESC_EN": "volleyball 7th Set",
	"DESC_CN": "排球第7局"
},
{
	"CODE": "13015",
	"DESC_EN": "volleyball End",
	"DESC_CN": "排球比赛结束"
},
{
	"CODE": "13016",
	"DESC_EN": "volleyball Awaiting GSet",
	"DESC_CN": "排球等待金局"
},
{
	"CODE": "13017",
	"DESC_EN": "volleyball Golden Set",
	"DESC_CN": "排球金局"
},
{
	"CODE": "13018",
	"DESC_EN": "volleyball After GSet End",
	"DESC_CN": "排球金局结束"
},
{
	"CODE": "13019",
	"DESC_EN": "volleyball Walkover [P1 Won]",
	"DESC_CN": "排球散步结束，选手1胜"
},
{
	"CODE": "13020",
	"DESC_EN": "volleyball Walkover [P2 Won]",
	"DESC_CN": "排球散步结束，选手2胜"
},
{
	"CODE": "13021",
	"DESC_EN": "volleyball Retired P1 [P2 Won]",
	"DESC_CN": "排球选手1退赛，选手2胜"
},
{
	"CODE": "13022",
	"DESC_EN": "volleyball Walkover P2 [P1 Won]",
	"DESC_CN": "排球选手2退赛，选手1胜"
},
{
	"CODE": "13023",
	"DESC_EN": "volleyball Delayed",
	"DESC_CN": "排球延期"
},
{
	"CODE": "13024",
	"DESC_EN": "volleyball Interrupted",
	"DESC_CN": "排球中断"
},
{
	"CODE": "13025",
	"DESC_EN": "volleyball Abandoned",
	"DESC_CN": "排球废弃"
},
{
	"CODE": "5001",
	"DESC_EN": "tennis Not started",
	"DESC_CN": "网球比赛未开始"
},
{
	"CODE": "5002",
	"DESC_EN": "tennis 1st Set",
	"DESC_CN": "网球第1盘"
},
{
	"CODE": "5003",
	"DESC_EN": "tennis 1st Set Ended",
	"DESC_CN": "网球第1盘结束"
},
{
	"CODE": "5004",
	"DESC_EN": "tennis 2nd Set",
	"DESC_CN": "网球第2盘"
},
{
	"CODE": "5005",
	"DESC_EN": "tennis 2nd Set Ended",
	"DESC_CN": "网球第2盘结束"
},
{
	"CODE": "5006",
	"DESC_EN": "tennis 3rd Set",
	"DESC_CN": "网球第3盘"
},
{
	"CODE": "5007",
	"DESC_EN": "tennis 3rd Set Ended",
	"DESC_CN": "网球第3盘结束"
},
{
	"CODE": "5008",
	"DESC_EN": "tennis 4th Set",
	"DESC_CN": "网球第4盘"
},
{
	"CODE": "5009",
	"DESC_EN": "tennis 4th Set Ended",
	"DESC_CN": "网球第4盘结束"
},
{
	"CODE": "5010",
	"DESC_EN": "tennis 5th Set",
	"DESC_CN": "网球第5盘"
},
{
	"CODE": "5011",
	"DESC_EN": "tennis Ended",
	"DESC_CN": "网球比赛结束"
},
{
	"CODE": "5012",
	"DESC_EN": "tennis Walkover [P1 Won]",
	"DESC_CN": "网球消极比赛，选手1胜"
},
{
	"CODE": "5013",
	"DESC_EN": "tennis Walkover [P2 Won]",
	"DESC_CN": "网球消极比赛，选手2胜"
},
{
	"CODE": "5014",
	"DESC_EN": "tennis Retired P1 [P2 Won]",
	"DESC_CN": "网球选手1退赛，选手2胜"
},
{
	"CODE": "5015",
	"DESC_EN": "tennis Retired P2 [P1 Won]",
	"DESC_CN": "网球选手2退赛，选手1胜"
},
{
	"CODE": "5016",
	"DESC_EN": "tennis Retired",
	"DESC_CN": "网球选手退赛"
},
{
	"CODE": "5017",
	"DESC_EN": "tennis Defaulted P1 [P2 Won]",
	"DESC_CN": "网球选手1违规"
},
{
	"CODE": "5018",
	"DESC_EN": "tennis Defaulted P2 [P1 Won]",
	"DESC_CN": "网球选手2违规"
},
{
	"CODE": "5019",
	"DESC_EN": "tennis Delayed",
	"DESC_CN": "网球推迟"
},
{
	"CODE": "5020",
	"DESC_EN": "tennis Interrupted",
	"DESC_CN": "网球中断"
},
{
	"CODE": "5021",
	"DESC_EN": "tennis Abandoned",
	"DESC_CN": "网球废弃"
},
{
	"CODE": "19001",
	"DESC_EN": "boxing Not started",
	"DESC_CN": "拳击未开赛"
},
{
	"CODE": "19020",
	"DESC_EN": "boxing Ended",
	"DESC_CN": "拳击结束"
},
{
	"CODE": "19021",
	"DESC_EN": "boxing Postponed",
	"DESC_CN": "拳击延迟"
},
{
	"CODE": "19022",
	"DESC_EN": "boxing Cancelled",
	"DESC_CN": "拳击取消"
},
{
	"CODE": "2001",
	"DESC_EN": "iceHockey Not started",
	"DESC_CN": "冰球比赛未开始"
},
{
	"CODE": "2002",
	"DESC_EN": "iceHockey 1st Period",
	"DESC_CN": "冰球第一节"
},
{
	"CODE": "2003",
	"DESC_EN": "iceHockey 1st Period Ended",
	"DESC_CN": "冰球中场休息1/2"
},
{
	"CODE": "2004",
	"DESC_EN": "iceHockey 2nd Period",
	"DESC_CN": "冰球第二节"
},
{
	"CODE": "2005",
	"DESC_EN": "iceHockey 2nd Period Ended",
	"DESC_CN": "冰球中场休息2/3"
},
{
	"CODE": "2006",
	"DESC_EN": "iceHockey 3rd Period",
	"DESC_CN": "冰球第三节"
},
{
	"CODE": "2007",
	"DESC_EN": "iceHockey Finish",
	"DESC_CN": "冰球比赛结束"
},
{
	"CODE": "2008",
	"DESC_EN": "iceHockey Awaiting OT",
	"DESC_CN": "冰球等待加时"
},
{
	"CODE": "2009",
	"DESC_EN": "iceHockey Over Time",
	"DESC_CN": "冰球加时"
},
{
	"CODE": "2010",
	"DESC_EN": "iceHockey After OT",
	"DESC_CN": "冰球加时结束"
},
{
	"CODE": "2011",
	"DESC_EN": "iceHockey Awaiting PEN",
	"DESC_CN": "冰球等待点球"
},
{
	"CODE": "2012",
	"DESC_EN": "iceHockey Penalty",
	"DESC_CN": "冰球点球"
},
{
	"CODE": "2013",
	"DESC_EN": "iceHockey Penalty-Finish",
	"DESC_CN": "冰球点球结束"
},
{
	"CODE": "2014",
	"DESC_EN": "iceHockey Interrupted",
	"DESC_CN": "冰球中断"
},
{
	"CODE": "2015",
	"DESC_EN": "iceHockey Abandoned",
	"DESC_CN": "冰球废弃"
},
{
	"CODE": "2016",
	"DESC_EN": "iceHockey Ended",
	"DESC_CN": "冰球比赛结束"
},
{
	"CODE": "15001",
	"DESC_EN": "table tennis Not started",
	"DESC_CN": "乒乓球比赛未开始"
},
{
	"CODE": "15002",
	"DESC_EN": "table tennis 1st Game",
	"DESC_CN": "乒乓球第1盘"
},
{
	"CODE": "15003",
	"DESC_EN": "table tennis 1st Game Ended",
	"DESC_CN": "乒乓球第1盘结束"
},
{
	"CODE": "15004",
	"DESC_EN": "table tennis 2nd Game",
	"DESC_CN": "乒乓球第2盘"
},
{
	"CODE": "15005",
	"DESC_EN": "table tennis 2nd Game Ended",
	"DESC_CN": "乒乓球第2盘结束"
},
{
	"CODE": "15006",
	"DESC_EN": "table tennis 3rd Game",
	"DESC_CN": "乒乓球第3盘"
},
{
	"CODE": "15007",
	"DESC_EN": "table tennis 3rd Game Ended",
	"DESC_CN": "乒乓球第3盘结束"
},
{
	"CODE": "15008",
	"DESC_EN": "table tennis 4th Game",
	"DESC_CN": "乒乓球第4盘"
},
{
	"CODE": "15009",
	"DESC_EN": "table tennis 4th Game Ended",
	"DESC_CN": "乒乓球第4盘结束"
},
{
	"CODE": "15010",
	"DESC_EN": "table tennis 5th Game",
	"DESC_CN": "乒乓球第5盘"
},
{
	"CODE": "15011",
	"DESC_EN": "table tennis 5th Game Ended",
	"DESC_CN": "乒乓球第5盘结束"
},
{
	"CODE": "15012",
	"DESC_EN": "table tennis 6th Game",
	"DESC_CN": "乒乓球第6盘"
},
{
	"CODE": "15013",
	"DESC_EN": "table tennis 6th Game Ended",
	"DESC_CN": "乒乓球第6盘结束"
},
{
	"CODE": "15014",
	"DESC_EN": "table tennis 7th Game",
	"DESC_CN": "乒乓球第7盘"
},
{
	"CODE": "15015",
	"DESC_EN": "table tennis Ended",
	"DESC_CN": "乒乓球结束"
},
{
	"CODE": "15016",
	"DESC_EN": "table tennis Walkover [P1 Won]",
	"DESC_CN": "乒乓球散步结束，选手1胜"
},
{
	"CODE": "15017",
	"DESC_EN": "table tennis Walkover [P2 Won]",
	"DESC_CN": "乒乓球散步结束，选手2胜"
},
{
	"CODE": "15018",
	"DESC_EN": "table tennis Retired P1 [P2 Won]",
	"DESC_CN": "乒乓球选手1退赛，选手2胜"
},
{
	"CODE": "15019",
	"DESC_EN": "table tennis Retired P2 [P1 Won]",
	"DESC_CN": "乒乓球选手2退赛，选手1胜"
},
{
	"CODE": "15020",
	"DESC_EN": "table tennis Defult P1 [P2 Won]",
	"DESC_CN": "乒乓球选手1犯规，选手2胜"
},
{
	"CODE": "15021",
	"DESC_EN": "table tennis Defult P2 [P1 Won]",
	"DESC_CN": "乒乓球选手2犯规，选手1胜"
},
{
	"CODE": "15022",
	"DESC_EN": "table tennis Delayed",
	"DESC_CN": "乒乓球推迟"
},
{
	"CODE": "15023",
	"DESC_EN": "table tennis Interrupted",
	"DESC_CN": "乒乓球中断"
},
{
	"CODE": "15024",
	"DESC_EN": "table tennis Abandoned",
	"DESC_CN": "乒乓球废弃"
},
{
	"CODE": "7001",
	"DESC_EN": "baseball Not started",
	"DESC_CN": "棒球比赛未开始"
},
{
	"CODE": "7003",
	"DESC_EN": "baseball Inning 1 Top",
	"DESC_CN": "棒球第一局"
},
{
	"CODE": "7004",
	"DESC_EN": "baseball Break 1st Top 1st Bottom",
	"DESC_CN": "棒球打破第一顶第一底"
},
{
	"CODE": "7005",
	"DESC_EN": "baseball Inning 1 Bottom",
	"DESC_CN": "棒球局 1 底"
},
{
	"CODE": "7006",
	"DESC_EN": "baseball Inning 1 Ended",
	"DESC_CN": "棒球第1局结束"
},
{
	"CODE": "7008",
	"DESC_EN": "baseball Inning 2 Top",
	"DESC_CN": "棒球第二局"
},
{
	"CODE": "7009",
	"DESC_EN": "baseball Break 2nd Top 2nd Bottom",
	"DESC_CN": "棒球打破第二顶第二底"
},
{
	"CODE": "7010",
	"DESC_EN": "baseball Inning 2 Bottom",
	"DESC_CN": "棒球局 二 底"
},
{
	"CODE": "7011",
	"DESC_EN": "baseball Inning 2 Ended",
	"DESC_CN": "棒球第二局结束"
},
{
	"CODE": "7013",
	"DESC_EN": "baseball Inning 3 Top",
	"DESC_CN": "棒球第三局"
},
{
	"CODE": "7014",
	"DESC_EN": "baseball Break 3rd Top 3rd Bottom",
	"DESC_CN": "棒球打破第三顶第三底"
},
{
	"CODE": "7015",
	"DESC_EN": "baseball Inning 3 Bottom",
	"DESC_CN": "棒球局三底"
},
{
	"CODE": "7016",
	"DESC_EN": "baseball Inning 3 Ended",
	"DESC_CN": "棒球第三局结束"
},
{
	"CODE": "7018",
	"DESC_EN": "baseball Inning 4 Top",
	"DESC_CN": "棒球第四局"
},
{
	"CODE": "7019",
	"DESC_EN": "baseball Break 4th Top 4th Bottom",
	"DESC_CN": "棒球打破第四顶第四底"
},
{
	"CODE": "7020",
	"DESC_EN": "baseball Inning 4 Bottom",
	"DESC_CN": "棒球局四底"
},
{
	"CODE": "7021",
	"DESC_EN": "baseball Inning 4 Ended",
	"DESC_CN": "棒球第四局结束"
},
{
	"CODE": "7023",
	"DESC_EN": "baseball Inning 5 Top",
	"DESC_CN": "棒球第五局"
},
{
	"CODE": "7024",
	"DESC_EN": "baseball Break 5th Top 5th Bottom",
	"DESC_CN": "棒球打破第五顶第五底"
},
{
	"CODE": "7025",
	"DESC_EN": "baseball Inning 5 Bottom",
	"DESC_CN": "棒球局五底"
},
{
	"CODE": "7026",
	"DESC_EN": "baseball Inning 5 Ended",
	"DESC_CN": "棒球第五局结束"
},
{
	"CODE": "7028",
	"DESC_EN": "baseball Inning 6 Top",
	"DESC_CN": "棒球第六局"
},
{
	"CODE": "7029",
	"DESC_EN": "baseball Break 6th Top 6th Bottom",
	"DESC_CN": "棒球打破第六顶第六底"
},
{
	"CODE": "7030",
	"DESC_EN": "baseball Inning 6 Bottom",
	"DESC_CN": "棒球局六底"
},
{
	"CODE": "7031",
	"DESC_EN": "baseball Inning 6 Ended",
	"DESC_CN": "棒球第六局结束"
},
{
	"CODE": "7033",
	"DESC_EN": "baseball Inning 7 Top",
	"DESC_CN": "棒球第七局"
},
{
	"CODE": "7034",
	"DESC_EN": "baseball Break 7th Top 7th Bottom",
	"DESC_CN": "棒球打破第七顶第七底"
},
{
	"CODE": "7035",
	"DESC_EN": "baseball Inning 7 Bottom",
	"DESC_CN": "棒球局七底"
},
{
	"CODE": "7036",
	"DESC_EN": "baseball Inning 7 Ended",
	"DESC_CN": "棒球第七局结束"
},
{
	"CODE": "7038",
	"DESC_EN": "baseball Inning 8 Top",
	"DESC_CN": "棒球第八局"
},
{
	"CODE": "7039",
	"DESC_EN": "baseball Break 8th Top 8th Bottom",
	"DESC_CN": "棒球打破第八顶第八底"
},
{
	"CODE": "7040",
	"DESC_EN": "baseball Inning 8 Bottom",
	"DESC_CN": "棒球局八底"
},
{
	"CODE": "7041",
	"DESC_EN": "baseball Inning 8 Ended",
	"DESC_CN": "棒球第八局结束"
},
{
	"CODE": "7043",
	"DESC_EN": "baseball Inning 9 Top",
	"DESC_CN": "棒球第九局"
},
{
	"CODE": "7044",
	"DESC_EN": "baseball Break 9th Top 9th Bottom",
	"DESC_CN": "棒球打破第九顶第九底"
},
{
	"CODE": "7045",
	"DESC_EN": "baseball Inning 9 Bottom",
	"DESC_CN": "棒球局九底"
},
{
	"CODE": "7046",
	"DESC_EN": "baseball Inning 9 Ended",
	"DESC_CN": "棒球第九局结束"
},
{
	"CODE": "7048",
	"DESC_EN": "baseball Extra Inning Top",
	"DESC_CN": "棒球额外局"
},
{
	"CODE": "7049",
	"DESC_EN": "baseball Break EI Top EI Bottom",
	"DESC_CN": "棒球打破 EI 顶部 EI 底部"
},
{
	"CODE": "7050",
	"DESC_EN": "baseball Extra Inning Bottom",
	"DESC_CN": "棒球额外的局底"
},
{
	"CODE": "7051",
	"DESC_EN": "baseball Ended",
	"DESC_CN": "棒球结束"
},
{
	"CODE": "7052",
	"DESC_EN": "baseball Interrupted",
	"DESC_CN": "棒球中断"
},
{
	"CODE": "7053",
	"DESC_EN": "baseball Abandoned",
	"DESC_CN": "棒球废弃"
},
{
	"CODE": "47001",
	"DESC_EN": "badminton Not started",
	"DESC_CN": "羽毛球比赛未开始"
},
{
	"CODE": "47002",
	"DESC_EN": "badminton 1st Game",
	"DESC_CN": "羽毛球第1盘"
},
{
	"CODE": "47003",
	"DESC_EN": "badminton 1st Game Ended",
	"DESC_CN": "羽毛球第1盘结束"
},
{
	"CODE": "47004",
	"DESC_EN": "badminton 2nd Game",
	"DESC_CN": "羽毛球第2盘"
},
{
	"CODE": "47005",
	"DESC_EN": "badminton 2nd Game Ended",
	"DESC_CN": "羽毛球第2盘结束"
},
{
	"CODE": "47006",
	"DESC_EN": "badminton 3rd Game",
	"DESC_CN": "羽毛球第3盘"
},
{
	"CODE": "47007",
	"DESC_EN": "badminton 3rd Game Ended",
	"DESC_CN": "羽毛球第3盘结束"
},
{
	"CODE": "47008",
	"DESC_EN": "badminton 4th Game",
	"DESC_CN": "羽毛球第4盘"
},
{
	"CODE": "47009",
	"DESC_EN": "badminton 4th Game Ended",
	"DESC_CN": "羽毛球第4盘结束"
},
{
	"CODE": "47010",
	"DESC_EN": "badminton 5th Game",
	"DESC_CN": "羽毛球第5盘"
},
{
	"CODE": "47015",
	"DESC_EN": "badminton Ended",
	"DESC_CN": "羽毛球结束"
},
{
	"CODE": "47016",
	"DESC_EN": "badminton Walkover [P1 Won]",
	"DESC_CN": "羽毛球散步结束，选手1胜"
},
{
	"CODE": "47017",
	"DESC_EN": "badminton Walkover [P2 Won]",
	"DESC_CN": "羽毛球散步结束，选手2胜"
},
{
	"CODE": "47018",
	"DESC_EN": "badminton Retired P1 [P2 Won]",
	"DESC_CN": "羽毛球选手1退赛，选手2胜"
},
{
	"CODE": "47019",
	"DESC_EN": "badminton Retired P2 [P1 Won]",
	"DESC_CN": "羽毛球选手2退赛，选手1胜"
},
{
	"CODE": "47020",
	"DESC_EN": "badminton Defult P1 [P2 Won]",
	"DESC_CN": "羽毛球选手1犯规，选手2胜"
},
{
	"CODE": "47021",
	"DESC_EN": "badminton Defult P2 [P1 Won]",
	"DESC_CN": "羽毛球选手2犯规，选手1胜"
},
{
	"CODE": "47022",
	"DESC_EN": "badminton Delayed",
	"DESC_CN": "羽毛球推迟"
},
{
	"CODE": "47023",
	"DESC_EN": "badminton Interrupted",
	"DESC_CN": "羽毛球中断"
},
{
	"CODE": "47024",
	"DESC_EN": "badminton Abandoned",
	"DESC_CN": "羽毛球废弃"
},
{
	"CODE": "6001",
	"DESC_EN": "american football Not started",
	"DESC_CN": "美式橄榄球比赛未开始"
},
{
	"CODE": "6002",
	"DESC_EN": "american football 1Q",
	"DESC_CN": "美式橄榄球第一节"
},
{
	"CODE": "6003",
	"DESC_EN": "american football Pause 1",
	"DESC_CN": "美式橄榄球第一节结束"
},
{
	"CODE": "6004",
	"DESC_EN": "american football 2Q",
	"DESC_CN": "美式橄榄球第二节"
},
{
	"CODE": "6005",
	"DESC_EN": "american football Pause 2",
	"DESC_CN": "美式橄榄球第二节结束"
},
{
	"CODE": "6006",
	"DESC_EN": "american football 3Q",
	"DESC_CN": "美式橄榄球第三节"
},
{
	"CODE": "6007",
	"DESC_EN": "american football Pause 3",
	"DESC_CN": "美式橄榄球第三节结束"
},
{
	"CODE": "6008",
	"DESC_EN": "american football 4Q",
	"DESC_CN": "美式橄榄球第四节"
},
{
	"CODE": "6009",
	"DESC_EN": "american football RT-Finish",
	"DESC_CN": "美式橄榄球常规时间结束"
},
{
	"CODE": "6010",
	"DESC_EN": "american football Awaiting OT",
	"DESC_CN": "美式橄榄球等待加时"
},
{
	"CODE": "6011",
	"DESC_EN": "american football Over Time",
	"DESC_CN": "美式橄榄球加时"
},
{
	"CODE": "6012",
	"DESC_EN": "american football OT-Finish",
	"DESC_CN": "美式橄榄球加时赛结束"
},
{
	"CODE": "6013",
	"DESC_EN": "american football Finish",
	"DESC_CN": "美式橄榄球比赛结束"
},
{
	"CODE": "6014",
	"DESC_EN": "american football Interrupted",
	"DESC_CN": "美式橄榄球中断"
},
{
	"CODE": "6015",
	"DESC_EN": "american football Abandoned",
	"DESC_CN": "美式橄榄球废弃"
},
{
	"CODE": "16001",
	"DESC_EN": "snooker Not started",
	"DESC_CN": "斯诺克比赛未开始"
},
{
	"CODE": "16002",
	"DESC_EN": "snooker In Progress",
	"DESC_CN": "斯诺克进行中"
},
{
	"CODE": "16003",
	"DESC_EN": "snooker Frame Break",
	"DESC_CN": "斯诺克每局中间休息"
},
{
	"CODE": "16004",
	"DESC_EN": "snooker Paused",
	"DESC_CN": "斯诺克比赛暂停"
},
{
	"CODE": "16005",
	"DESC_EN": "snooker Ended",
	"DESC_CN": "斯诺克已结束"
},
{
	"CODE": "16006",
	"DESC_EN": "snooker Walkover [P1 Won]",
	"DESC_CN": "斯诺克散步结束，选手1胜"
},
{
	"CODE": "16007",
	"DESC_EN": "snooker Walkover [P2 Won]",
	"DESC_CN": "斯诺克散步结束，选手2胜"
},
{
	"CODE": "16008",
	"DESC_EN": "snooker Retired P1 [P2 Won]",
	"DESC_CN": "斯诺克选手1退赛，选手2胜"
},
{
	"CODE": "16009",
	"DESC_EN": "snooker Retired P2 [P1 Won]",
	"DESC_CN": "斯诺克选手2退赛，选手1胜"
},
{
	"CODE": "16010",
	"DESC_EN": "snooker Defult P1 [P2 Won]",
	"DESC_CN": "斯诺克选手1犯规，选手2胜"
},
{
	"CODE": "16011",
	"DESC_EN": "snooker Defult P2 [P1 Won]",
	"DESC_CN": "斯诺克选手2犯规，选手1胜"
},
{
	"CODE": "16012",
	"DESC_EN": "snooker Delayed",
	"DESC_CN": "斯诺克推迟"
},
{
	"CODE": "16013",
	"DESC_EN": "snooker Interrupted",
	"DESC_CN": "斯诺克中断"
},
{
	"CODE": "16014",
	"DESC_EN": "snooker Abandoned",
	"DESC_CN": "斯诺克废弃"
},
{
	"CODE": "177001",
	"DESC_EN": "e-soccer Not started",
	"DESC_CN": "比赛未开始"
},
{
	"CODE": "177002",
	"DESC_EN": "e-soccer First Half",
	"DESC_CN": "上半场"
},
{
	"CODE": "177003",
	"DESC_EN": "e-soccer Half Time",
	"DESC_CN": "中场休息"
},
{
	"CODE": "177004",
	"DESC_EN": "e-soccer Second Half",
	"DESC_CN": "下半场"
},
{
	"CODE": "177005",
	"DESC_EN": "e-soccer FT-Finish",
	"DESC_CN": "常规时间结束"
},
{
	"CODE": "177006",
	"DESC_EN": "e-soccer Awaiting ET",
	"DESC_CN": "等待加时"
},
{
	"CODE": "177007",
	"DESC_EN": "e-soccer ET-First Half",
	"DESC_CN": "ET(加时赛)-上半场"
},
{
	"CODE": "177008",
	"DESC_EN": "e-soccer ET-Half Time",
	"DESC_CN": "ET(加时赛)-中场休息"
},
{
	"CODE": "177009",
	"DESC_EN": "e-soccer ET-Second Half",
	"DESC_CN": "ET(加时赛)-后半场"
},
{
	"CODE": "177010",
	"DESC_EN": "e-soccer ET-Finish",
	"DESC_CN": "ET(加时赛)-结束"
},
{
	"CODE": "177011",
	"DESC_EN": "e-soccer Awaiting Penalty",
	"DESC_CN": "等待点球大战"
},
{
	"CODE": "177012",
	"DESC_EN": "e-soccer Penalty",
	"DESC_CN": "点球大战"
},
{
	"CODE": "177013",
	"DESC_EN": "e-soccer Penalty-Finish",
	"DESC_CN": "点球大战结束"
},
{
	"CODE": "177014",
	"DESC_EN": "e-soccer Finish",
	"DESC_CN": "比赛结束"
},
{
	"CODE": "177015",
	"DESC_EN": "e-soccer Interrupted",
	"DESC_CN": "中断"
},
{
	"CODE": "177016",
	"DESC_EN": "e-soccer Abandoned",
	"DESC_CN": "废弃"
},
{
	"CODE": "178001",
	"DESC_EN": "e-basketball Not started",
	"DESC_CN": "比赛未开始"
},
{
	"CODE": "178002",
	"DESC_EN": "e-basketball 1Q",
	"DESC_CN": "第一节"
},
{
	"CODE": "178003",
	"DESC_EN": "e-basketball Break 1",
	"DESC_CN": "第一节结束"
},
{
	"CODE": "178004",
	"DESC_EN": "e-basketball 2Q",
	"DESC_CN": "第二节"
},
{
	"CODE": "178005",
	"DESC_EN": "e-basketball Half Time",
	"DESC_CN": "中场休息"
},
{
	"CODE": "178006",
	"DESC_EN": "e-basketball 3Q",
	"DESC_CN": "第三节"
},
{
	"CODE": "178007",
	"DESC_EN": "e-basketball Break 3",
	"DESC_CN": "第三节结束"
},
{
	"CODE": "178008",
	"DESC_EN": "e-basketball 4Q",
	"DESC_CN": "第四节"
},
{
	"CODE": "178009",
	"DESC_EN": "e-basketball RT-Finish",
	"DESC_CN": "常规时间结束"
},
{
	"CODE": "178010",
	"DESC_EN": "e-basketball Awaiting OT",
	"DESC_CN": "等待加时"
},
{
	"CODE": "178011",
	"DESC_EN": "e-basketball Over Time",
	"DESC_CN": "加时"
},
{
	"CODE": "178012",
	"DESC_EN": "e-basketball OT-Finish",
	"DESC_CN": "加时赛结束"
},
{
	"CODE": "178013",
	"DESC_EN": "e-basketball Finish",
	"DESC_CN": "比赛结束"
},
{
	"CODE": "178014",
	"DESC_EN": "e-basketball Delayed",
	"DESC_CN": "推迟"
},
{
	"CODE": "178015",
	"DESC_EN": "e-basketball Interrupted",
	"DESC_CN": "中断"
},
{
	"CODE": "178016",
	"DESC_EN": "e-basketball Abandoned",
	"DESC_CN": "废弃"
},
{
	"CODE": "8001",
	"DESC_EN": "Handball Not Started",
	"DESC_CN": "未开赛"
},
{
	"CODE": "8002",
	"DESC_EN": "Handball First Half",
	"DESC_CN": "上半场"
},
{
	"CODE": "8003",
	"DESC_EN": "Handball Half Time",
	"DESC_CN": "半场"
},
{
	"CODE": "8004",
	"DESC_EN": "Handball Second Half",
	"DESC_CN": "下半场"
},
{
	"CODE": "8005",
	"DESC_EN": "Handball FT-Finish",
	"DESC_CN": "常规结束"
},
{
	"CODE": "8006",
	"DESC_EN": "Handball Awaiting ET",
	"DESC_CN": "等待加时"
},
{
	"CODE": "8007",
	"DESC_EN": "Handball ET-First Half",
	"DESC_CN": "加时上半场"
},
{
	"CODE": "8008",
	"DESC_EN": "Handball ET-Half Time",
	"DESC_CN": "加时半场"
},
{
	"CODE": "8009",
	"DESC_EN": "Handball ET-Second Half",
	"DESC_CN": "加时下半场"
},
{
	"CODE": "8010",
	"DESC_EN": "Handball ET-Finish",
	"DESC_CN": "加时结束"
},
{
	"CODE": "8011",
	"DESC_EN": "Handball Awaiting Penalty",
	"DESC_CN": "等待处罚"
},
{
	"CODE": "8012",
	"DESC_EN": "Handball Penalty",
	"DESC_CN": "点球"
},
{
	"CODE": "8013",
	"DESC_EN": "Handball Penalty-Finish",
	"DESC_CN": "点球结束"
},
{
	"CODE": "8014",
	"DESC_EN": "Handball Finish",
	"DESC_CN": "结束"
},
{
	"CODE": "8015",
	"DESC_EN": "Handball Interrupted",
	"DESC_CN": "中断"
},
{
	"CODE": "8016",
	"DESC_EN": "Handball Abandoned",
	"DESC_CN": "废弃"
},
{
	"CODE": "51001",
	"DESC_EN": "Beach Volleyball Not Started",
	"DESC_CN": "沙滩排球未开始"
},
{
	"CODE": "51002",
	"DESC_EN": "Beach Volleyball 1st Set",
	"DESC_CN": "沙滩排球第一局"
},
{
	"CODE": "51003",
	"DESC_EN": "Beach Volleyball 1st Set Ended",
	"DESC_CN": "沙滩排球第一局结束"
},
{
	"CODE": "51004",
	"DESC_EN": "Beach Volleyball 2nd Set",
	"DESC_CN": "沙滩排球第二局"
},
{
	"CODE": "51005",
	"DESC_EN": "Beach Volleyball 2nd Set Ended",
	"DESC_CN": "沙滩排球第二局结束"
},
{
	"CODE": "51006",
	"DESC_EN": "Beach Volleyball 3rd Set",
	"DESC_CN": "沙滩排球第三局"
},
{
	"CODE": "51007",
	"DESC_EN": "Beach Volleyball 3rd Set Ended",
	"DESC_CN": "沙滩排球第三局结束"
},
{
	"CODE": "51008",
	"DESC_EN": "Beach Volleyball 4th Set",
	"DESC_CN": "沙滩排球第四局"
},
{
	"CODE": "51009",
	"DESC_EN": "Beach Volleyball 4th Set Ended",
	"DESC_CN": "沙滩排球第四局结束"
},
{
	"CODE": "51010",
	"DESC_EN": "Beach Volleyball 5th Set",
	"DESC_CN": "沙滩排球第五局"
},
{
	"CODE": "51011",
	"DESC_EN": "Beach Volleyball Ended",
	"DESC_CN": "沙滩排球已结束"
},
{
	"CODE": "51012",
	"DESC_EN": "Beach Volleyball Walkover [P1 Won]",
	"DESC_CN": "沙滩排球散步结束，选手1胜"
},
{
	"CODE": "51013",
	"DESC_EN": "Beach Volleyball Walkover [P2 Won]",
	"DESC_CN": "沙滩排球散步结束，选手2胜"
},
{
	"CODE": "51014",
	"DESC_EN": "Beach Volleyball Retired P1 [P2 Won]",
	"DESC_CN": "沙滩排球选手1退赛，选手2胜"
},
{
	"CODE": "51015",
	"DESC_EN": "Beach Volleyball Retired P2 [P1 Won]",
	"DESC_CN": "沙滩排球选手2退赛，选手1胜"
},
{
	"CODE": "51016",
	"DESC_EN": "Beach Volleyball Interrupted",
	"DESC_CN": "沙滩排球中断"
},
{
	"CODE": "51017",
	"DESC_EN": "Beach Volleyball Abandoned",
	"DESC_CN": "沙滩排球暂不提供"
},
{
	"CODE": "18001",
	"DESC_EN": "MMA Not Started",
	"DESC_CN": "格斗未开始"
},
{
	"CODE": "18020",
	"DESC_EN": "boxing Ended",
	"DESC_CN": "格斗结束"
},
{
	"CODE": "18021",
	"DESC_EN": "boxing Postponed",
	"DESC_CN": "格斗延迟"
},
{
	"CODE": "18022",
	"DESC_EN": "boxing Cancelled",
	"DESC_CN": "格斗取消"
},
{
	"CODE": "4001",
	"DESC_EN": "Rugby Not Started",
	"DESC_CN": "橄榄球未开始"
},
{
	"CODE": "4002",
	"DESC_EN": "Rugby First Half",
	"DESC_CN": "橄榄球上半场"
},
{
	"CODE": "4003",
	"DESC_EN": "Rugby Half Time",
	"DESC_CN": "橄榄球半场"
},
{
	"CODE": "4004",
	"DESC_EN": "Rugby Second Half",
	"DESC_CN": "橄榄球下半场"
},
{
	"CODE": "4005",
	"DESC_EN": "Rugby FT-Finish",
	"DESC_CN": "橄榄球结束"
},
{
	"CODE": "4006",
	"DESC_EN": "Rugby Awaiting OT",
	"DESC_CN": "橄榄球等待加时"
},
{
	"CODE": "4007",
	"DESC_EN": "Rugby Over Time",
	"DESC_CN": "橄榄球加时"
},
{
	"CODE": "4008",
	"DESC_EN": "Rugby OT-First Half",
	"DESC_CN": "橄榄球加时上半场"
},
{
	"CODE": "4009",
	"DESC_EN": "Rugby OT-Half Time",
	"DESC_CN": "橄榄球加时半场"
},
{
	"CODE": "4010",
	"DESC_EN": "Rugby OT-Second Half",
	"DESC_CN": "橄榄球加时下半场"
},
{
	"CODE": "4011",
	"DESC_EN": "Rugby OT-Finish",
	"DESC_CN": "橄榄球 OT-Finish"
},
{
	"CODE": "4012",
	"DESC_EN": "Rugby Awaiting SD",
	"DESC_CN": "橄榄球Awaiting SD"
},
{
	"CODE": "4013",
	"DESC_EN": "Rugby SD",
	"DESC_CN": "橄榄球SD"
},
{
	"CODE": "4014",
	"DESC_EN": "Rugby After SD",
	"DESC_CN": "橄榄球After SD"
},
{
	"CODE": "4015",
	"DESC_EN": "Rugby Awaiting Penalty",
	"DESC_CN": "橄榄球等待点球"
},
{
	"CODE": "4016",
	"DESC_EN": "Rugby Penalty",
	"DESC_CN": "橄榄球点球"
},
{
	"CODE": "4017",
	"DESC_EN": "Rugby Penalty-Finish",
	"DESC_CN": "橄榄球点球结束"
},
{
	"CODE": "4018",
	"DESC_EN": "Rugby Postponed",
	"DESC_CN": "橄榄球Postponed"
},
{
	"CODE": "4019",
	"DESC_EN": "Rugby Interrupted",
	"DESC_CN": "橄榄球中断"
},
{
	"CODE": "4020",
	"DESC_EN": "Rugby Abandoned",
	"DESC_CN": "橄榄球暂不提供"
},
{
	"CODE": "24001",
	"DESC_EN": "Water Polo Not Started",
	"DESC_CN": "水球未开始"
},
{
	"CODE": "24020",
	"DESC_EN": "Water Polo Ended",
	"DESC_CN": "水球结束"
},
{
	"CODE": "24021",
	"DESC_EN": "Water Polo Postponed",
	"DESC_CN": "水球延迟"
},
{
	"CODE": "24022",
	"DESC_EN": "Water Polo Cancelled",
	"DESC_CN": "水球取消"
},
{
	"CODE": "92001",
	"DESC_EN": "FORMULA 1 Not Started",
	"DESC_CN": "F1赛车未开始"
},
{
	"CODE": "92020",
	"DESC_EN": "FORMULA 1 Ended",
	"DESC_CN": "F1赛车结束"
},
{
	"CODE": "92021",
	"DESC_EN": "FORMULA 1 Postponed",
	"DESC_CN": "F1赛车延迟"
},
{
	"CODE": "92022",
	"DESC_EN": "FORMULA 1 Cancelled",
	"DESC_CN": "F1赛车取消"
},
{
	"CODE": "14001",
	"DESC_EN": "Cricket Not Started",
	"DESC_CN": "板球未开始"
},
{
	"CODE": "14003",
	"DESC_EN": "Cricket First Innings, Home Team",
	"DESC_CN": "板球主队第一局"
},
{
	"CODE": "14004",
	"DESC_EN": "Cricket First Innings, Away Team",
	"DESC_CN": "板球客队第一局"
},
{
	"CODE": "14006",
	"DESC_EN": "Cricket Second Innings, Home Team",
	"DESC_CN": "板球主队第二局"
},
{
	"CODE": "14007",
	"DESC_EN": "Cricket Second Innings, Away Team",
	"DESC_CN": "板球客队第二局"
},
{
	"CODE": "14008",
	"DESC_EN": "Cricket Awaiting Super Over",
	"DESC_CN": "板球等待加时"
},
{
	"CODE": "14010",
	"DESC_EN": "Cricket Super Over, home team",
	"DESC_CN": "板球主队加时"
},
{
	"CODE": "14011",
	"DESC_EN": "Cricket Super Over, away team",
	"DESC_CN": "板球客队加时"
},
{
	"CODE": "14012",
	"DESC_EN": "Cricket After Super Over",
	"DESC_CN": "板球加时结束"
},
{
	"CODE": "14013",
	"DESC_EN": "Cricket Innings Break",
	"DESC_CN": "板球中場休息"
},
{
	"CODE": "14014",
	"DESC_EN": "Cricket Super Over Break",
	"DESC_CN": "板球加时休息"
},
{
	"CODE": "14015",
	"DESC_EN": "Cricket Lunch Break",
	"DESC_CN": "板球午間休息"
},
{
	"CODE": "14016",
	"DESC_EN": "Cricket Tea Break",
	"DESC_CN": "板球下午茶休息"
},
{
	"CODE": "14017",
	"DESC_EN": "Cricket Stumps",
	"DESC_CN": "板球比赛待续"
},
{
	"CODE": "14018",
	"DESC_EN": "Cricket Ended",
	"DESC_CN": "板球结束"
},
{
	"CODE": "14019",
	"DESC_EN": "Cricket Interrupted",
	"DESC_CN": "板球中断"
},
{
	"CODE": "14020",
	"DESC_EN": "Cricket Abandoned",
	"DESC_CN": "板球暂不提供"
},
{
	"CODE": "20001",
	"DESC_EN": "Darts Not started",
	"DESC_CN": "飞镖比赛未开始"
},
{
	"CODE": "20002",
	"DESC_EN": "Darts In Progress",
	"DESC_CN": "飞镖进行中"
},
{
	"CODE": "20003",
	"DESC_EN": "Darts Paused",
	"DESC_CN": "飞镖比赛暂停"
},
{
	"CODE": "20004",
	"DESC_EN": "Darts Ended",
	"DESC_CN": "飞镖比赛结束"
},
{
	"CODE": "20005",
	"DESC_EN": "Darts Walkover [P1 Won]",
	"DESC_CN": "飞镖P1贏"
},
{
	"CODE": "20006",
	"DESC_EN": "Darts Walkover [P2 Won]",
	"DESC_CN": "飞镖P2贏"
},
{
	"CODE": "20007",
	"DESC_EN": "Darts Retired P1 [P2 Won]",
	"DESC_CN": "飞镖P1退赛"
},
{
	"CODE": "20008",
	"DESC_EN": "Darts Retired P2 [P1 Won]",
	"DESC_CN": "飞镖P2退赛"
},
{
	"CODE": "20009",
	"DESC_EN": "Darts Defult P1 [P2 Won]",
	"DESC_CN": "飞镖P1犯规"
},
{
	"CODE": "20010",
	"DESC_EN": "Darts Defult P2 [P1 Won]",
	"DESC_CN": "飞镖P2犯规"
},
{
	"CODE": "20011",
	"DESC_EN": "Darts Delayed",
	"DESC_CN": "飞镖延期"
},
{
	"CODE": "20012",
	"DESC_EN": "Darts Interrupted",
	"DESC_CN": "飞镖中断"
},
{
	"CODE": "20013",
	"DESC_EN": "Darts Abandoned",
	"DESC_CN": "飞镖废弃"
},
{
	"CODE": "17001",
	"DESC_EN": "Futsal Not started",
	"DESC_CN": "比赛未开始"
},
{
	"CODE": "17002",
	"DESC_EN": "Futsal First Half",
	"DESC_CN": "上半场"
},
{
	"CODE": "17003",
	"DESC_EN": "Futsal Half Time",
	"DESC_CN": "中场休息"
},
{
	"CODE": "17004",
	"DESC_EN": "Futsal Second Half",
	"DESC_CN": "下半场"
},
{
	"CODE": "17005",
	"DESC_EN": "Futsal FT-Finish",
	"DESC_CN": "常规时间结束"
},
{
	"CODE": "17006",
	"DESC_EN": "Futsal Awaiting ET",
	"DESC_CN": "等待加时"
},
{
	"CODE": "17007",
	"DESC_EN": "Futsal ET-First Half",
	"DESC_CN": "ET 上半场"
},
{
	"CODE": "17008",
	"DESC_EN": "Futsal ET-Half Time",
	"DESC_CN": "ET(加时赛)半场时间"
},
{
	"CODE": "17009",
	"DESC_EN": "Futsal ET-Second Half",
	"DESC_CN": "ET(加时赛)-后"
},
{
	"CODE": "17010",
	"DESC_EN": "Futsal ET-Finish",
	"DESC_CN": "ET(加时赛)-结束"
},
{
	"CODE": "17011",
	"DESC_EN": "Futsal Awaiting Penalty",
	"DESC_CN": "等待点球大战"
},
{
	"CODE": "17012",
	"DESC_EN": "Futsal Penalty",
	"DESC_CN": "点球大战"
},
{
	"CODE": "17013",
	"DESC_EN": "Futsal Penalty-Finish",
	"DESC_CN": "点球大战结束"
},
{
	"CODE": "17014",
	"DESC_EN": "Futsal Finish",
	"DESC_CN": "比赛结束"
},
{
	"CODE": "17015",
	"DESC_EN": "Futsal Interrupted",
	"DESC_CN": "中断"
},
{
	"CODE": "17016",
	"DESC_EN": "Futsal Abandoned",
	"DESC_CN": "废弃"
}
]


export const market_tag = [
	{ "CODE": "p", "DESC_EN": "Popular	", "DESC_CN": "热门" },
	{ "CODE": "h", "DESC_EN": "Handicap & Over/Under", "DESC_CN": "让球 & 大/小" },
	{ "CODE": "s", "DESC_EN": "Score	", "DESC_CN": "进球" },
	{ "CODE": "f", "DESC_EN": "Half	", "DESC_CN": "半场" },
	{ "CODE": "c", "DESC_EN": "Corner	", "DESC_CN": "角球" },
	{ "CODE": "i", "DESC_EN": "Special	", "DESC_CN": "特殊投注" },
	{ "CODE": "cs", "DESC_EN": "Correct Score	", "DESC_CN": "波胆" },
	{ "CODE": "b", "DESC_EN": "Booking	", "DESC_CN": "罚牌" },
	{ "CODE": "o", "DESC_EN": "Other	", "DESC_CN": "其它" },
	{ "CODE": "q", "DESC_EN": "Quarter	", "DESC_CN": "节" },
	{ "CODE": "t", "DESC_EN": "Intervals	", "DESC_CN": "15分钟" },
	{ "CODE": "j", "DESC_EN": "Snooker Frame	", "DESC_CN": "斯诺克赛局" },
	{ "CODE": "set", "DESC_EN": "Sets	", "DESC_CN": "赛盘" },
	{ "CODE": "qu", "DESC_EN": "Quinella	", "DESC_CN": "前二组合" },
	{ "CODE": "z", "DESC_EN": "Exacta	", "DESC_CN": "准确前二" },
	{ "CODE": "ps", "DESC_EN": "Penalty Shootout	", "DESC_CN": "点球大战" },
	{ "CODE": "pro", "DESC_EN": "Promotion Team	", "DESC_CN": "晋级球队" },
	{ "CODE": "1st", "DESC_EN": "Champion Team	", "DESC_CN": "赢得冠军" },
	{ "CODE": "3rd", "DESC_EN": "Third Place Team	", "DESC_CN": "赢得季军" },
	{ "CODE": "pa", "DESC_EN": "Player	", "DESC_CN": "球员玩法" },
	{ "CODE": "leg", "DESC_EN": "Darts Leg	", "DESC_CN": "飞镖赛局" },
	{ "CODE": "180s", "DESC_EN": "180s	", "DESC_CN": "180分" },
	{ "CODE": "vset", "DESC_EN": "Volleyball/Beach Volleyball Set	", "DESC_CN": "排球、沙滩排球赛局" },
	{ "CODE": "inning", "DESC_EN": "Baseball Inning	", "DESC_CN": "棒球赛局" },
	{ "CODE": "game", "DESC_EN": "Badminton/Table Tennis Game	", "DESC_CN": "羽毛球、乒乓球赛局" }
]


export const swiperList = {
	"1": {
		name: '足球',
		zongList: [
			[
				{
					nm: '全场让球',
					mty: 1000,
					pe: 1001
				},
				{
					nm: '全场大小',
					mty: 1007,
					pe: 1001
				},
				{
					nm: '全场独赢',
					mty: 1005,
					pe: 1001
				},
			],
			[
				{
					nm: '上半让球',
					mty: 1000,
					pe: 1002
				},
				{
					nm: '上半大小',
					mty: 1007,
					pe: 1002
				},
				{
					nm: '上半独赢',
					mty: 1005,
					pe: 1002
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 1005,
					pe: 1001
				}
			],
			[
				{
					nm: '让球',
					mty: 1000,
					pe: 1001
				}
			],
			[
				{
					nm: '大小',
					mty: 1007,
					pe: 1001
				}
			],
		]
		
	},
	"3": {
		name: '篮球',
		zongList: [
			[
				{
					nm: '全场让球',
					mty: 3002,
					pe: 3001
				},
				{
					nm: '全场大小',
					mty: 3003,
					pe: 3001
				},
				{
					nm: '全场独赢',
					mty: 3004,
					pe: 3001
				},
			],
			[
				{
					nm: '上半让球',
					mty: 3003,
					pe: 3002
				},
				{
					nm: '上半大小',
					mty: 3003,
					pe: 3003
				},
				{
					nm: '上半独赢',
					mty: 3020,
					pe: 3003
				},
			]
		],
		hengList: [
			[
				{
					nm: '让分',
					mty: 3000,
					pe: 3001
				}
			],
			[
				{
					nm: '大小',
					mty: 3003,
					pe: 3001
				}
			]
		]
	},
	"5": {
		name: '网球',
		fid: true,
		zongList: [
			[
				{
					nm: '全场让盘',
					mty: 5004,
					pe: 5001
				},
				{
					nm: '全场让局',
					mty: 5002,
					pe: 5001
				},
				{
					nm: '全场独赢',
					mty: 5001,
					pe: 5001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 5007,
					pe: 5001
				},
			],
			[
				{
					nm: '让盘',
					mty: 5004,
					pe: 5001
				}
			]
		]
	},
	"16": {
		name: '斯诺克',
		fid: true,
		zongList: [
			[
				{
					nm: '全场让局',
					mty: 16001,
					pe: 16001
				},
				{
					nm: '全场大小',
					mty: 16002,
					pe: 16001
				},
				{
					nm: '全场独赢',
					mty: 16003,
					pe: 16001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 16003,
					pe: 16001
				}
			],
			[
				{
					nm: '让局',
					mty: 16001,
					pe: 16001
				}
			]
		]
	},
	"15": {
		name: '乒乓球',
		fid: true,
		zongList: [
			[
				{
					nm: '全场让分',
					mty: 15002,
					pe: 15001
				},
				{
					nm: '全场让分',
					mty: 15003,
					pe: 15001
				},
				{
					nm: '全场独赢',
					mty: 15003,
					pe: 15001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 15003,
					pe: 15001
				}
			],
			[
				{
					nm: '让分',
					mty: 15002,
					pe: 15001
				}
			]
		]
	},
	"8": {
		name: '手球',
		zongList: [
			[
				{
					nm: '让球',
					mty: 8001,
					pe: 8001
				},
				{
					nm: '大小',
					mty: 8002,
					pe: 8001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 8005,
					pe: 8001
				},
			],
			[
				{
					nm: '让球',
					mty: 8001,
					pe: 8001
				}
			],
			[
				{
					nm: '大小',
					mty: 8002,
					pe: 8001
				}
			]
		]
	},
	"2": {
		name: '冰球',
		zongList: [
			[
				{
					nm: '全场让球',
					mty: 2001,
					pe: 2001
				},
				{
					nm: '全场大小',
					mty: 2002,
					pe: 2001
				},
				{
					nm: '全场独赢',
					mty: 2003,
					pe: 2001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 2003,
					pe: 2001
				},
			],
			[
				{
					nm: '让球',
					mty: 2001,
					pe: 2001
				}
			],
			[
				{
					nm: '大小',
					mty: 2002,
					pe: 2001
				}
			]
		]
	},
	"13": {
		name: '排球',
		zongList: [
			[
				{
					nm: '全场让分',
					mty: 13002,
					pe: 13001
				},
				{
					nm: '全场大小',
					mty: 13003,
					pe: 13001
				},
				{
					nm: '全场独赢',
					mty: 13001,
					pe: 13001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 13001,
					pe: 13001
				}
			],
			[
				{
					nm: '让分',
					mty: 13002,
					pe: 13001
				}
			]
		]
	},
	"6": {
		name: '美式足球',
		zongList: [
			[
				{
					nm: '全场让球',
					mty: 6001,
					pe: 6001
				},
				{
					nm: '全场大小',
					mty: 6002,
					pe: 6001
				},
				{
					nm: '全场独赢',
					mty: 6003,
					pe: 6001
				},
			],
			[
				{
					nm: '上半让球',
					mty: 6001,
					pe: 6003
				},
				{
					nm: '上半大小',
					mty: 6002,
					pe: 6003
				},
				{
					nm: '上半独赢',
					mty: 60010,
					pe: 6003
				},
			]
		],
		hengList: [
			[
				{
					nm: '胜负',
					mty: 6003,
					pe: 6001
				},
			],
			[
				{
					nm: '让球',
					mty: 6001,
					pe: 6001
				}
			],
			[
				{
					nm: '大小',
					mty: 6002,
					pe: 6001
				}
			]
		]
	},
	"4": {
		name: '橄榄球',
		zongList: [
			[
				{
					nm: '让球',
					mty: 4001,
					pe: 4001
				},
				{
					nm: '大小',
					mty: 4002,
					pe: 4001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 4003,
					pe: 4001
				},
			],
			[
				{
					nm: '让球',
					mty: 4001,
					pe: 4001
				}
			],
			[
				{
					nm: '大小',
					mty: 4002,
					pe: 4001
				}
			]
		]
	},
	"18": {
		name: '综合格斗',
		zongList: [
			[
				{
					nm: '全场比分',
					mty: 18003,
					pe: 18001
				},
				{
					nm: '全场大小',
					mty: 18001,
					pe: 18001
				},
				{
					nm: '全场独赢',
					mty: 18002,
					pe: 18001
				},
			]
		],
		hengList: [
			[
				{
					nm: '大小',
					mty: 18001,
					pe: 18001
				}
			],
			[
				{
					nm: '独赢',
					mty: 18002,
					pe: 18001
				}
			]
		]
	},
	"19": {
		name: '拳击',
		zongList: [
			[
				{
					nm: '奋战到底',
					mty: 19003,
					pe: 19001
				},
				{
					nm: '全场大小',
					mty: 19001,
					pe: 19001
				},
				{
					nm: '全场独赢',
					mty: 19002,
					pe: 19001
				}
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 19002,
					pe: 19001
				}
			],
			[
				{
					nm: '大小',
					mty: 19001,
					pe: 19001
				}
			]
		]
	},
	"14": {
		name: '板球',
		zongList: [
			[
				{
					nm: '全场大小',
					mty: 1403,
					pe: 14001
				},
				{
					nm: '全场总分',
					mty: 14003,
					pe: 14001
				},
				{
					nm: '全场1X2',
					mty: 14001,
					pe: 14001
				},
			]
		],
		hengList: [
			[
				{
					nm: '1X2',
					mty: 14001,
					pe: 14001
				}
			],
			[
				{
					nm: '总分',
					mty: 14003,
					pe: 14001
				}
			]
		]
	},
	"24": {
		name: '水球',
		zongList: [
			[
				{
					nm: '1X2',
					mty: 24002,
					pe: 24001
				},
				{
					nm: '大小',
					mty: 24001,
					pe: 24001
				},
			]
		],
		hengList: [
			[
				{
					nm: '1X2',
					mty: 24002,
					pe: 24001
				}
			],
			[
				{
					nm: '大小',
					mty: 24001,
					pe: 24001
				}
			]
		]
	},
	"20": {
		name: '飞镖',
		fid: true,
		zongList: [
			[
				{
					nm: '独赢',
					mty: 20003,
					pe: 20001
				},
				{
					nm: '让局',
					mty: 20005,
					pe: 20001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 20003,
					pe: 20001
				}
			],
			[
				{
					nm: '让局',
					mty: 20005,
					pe: 20001
				}
			]
		]
	},
	"47": {
		name: '羽毛球',
		zongList: [
			[
				{
					nm: '独赢',
					mty: 47001,
					pe: 47001
				},
				{
					nm: '让分',
					mty: 47002,
					pe: 47001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 47001,
					pe: 47001
				}
			],
			[
				{
					nm: '让分',
					mty: 47002,
					pe: 47001
				}
			]
		]
	},
	"177": { 
		name: '电子足球',
		zongList: [
			[
				{
					nm: '让球',
					mty: 177001,
					pe: 177007
				},
				{
					nm: '让分',
					mty: 177002,
					pe: 177001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 147001,
					pe: 177007
				}
			],
			[
				{
					nm: '让分',
					mty: 177002,
					pe: 177001
				}
			]
		]
	},
	"178": {
		name: '电子篮球',
		zongList: [
			[
				{
					nm: '独赢',
					mty: 178001,
					pe: 178001
				},
				{
					nm: '让分',
					mty: 178002,
					pe: 178001
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 178001,
					pe: 178001
				}
			],
			[
				{
					nm: '让分',
					mty: 178002,
					pe: 178001
				}
			]
		]
	},
	"51": {
		name: '沙滩排球',
		zongList: [
			[
				{
					nm: '大小',
					mty: 51003,
					pe: 51001
				},
				{
					nm: '让分',
					mty: 51002,
					pe: 51001
				},
			]
		],
		hengList: [
			[
				{
					nm: '大小',
					mty: 51003,
					pe: 51001
				}
			],
			[
				{
					nm: '让分',
					mty: 51002,
					pe: 51001
				}
			]
		]
	},
	"17":{
		"name": "五人制足球",
		zongList: [
			[
				{
					nm: '全场让球',
					mty: 17001,
					pe: 17001
				},
				{
					nm: '全场大小',
					mty: 17002,
					pe: 17001
				},
				{
					nm: '全场独赢',
					mty: 17003,
					pe: 17001
				},
			],
			[
				{
					nm: '上半让球',
					mty: 17001,
					pe: 17002
				},
				{
					nm: '上半大小',
					mty: 17002,
					pe: 17002
				},
				{
					nm: '上半独赢',
					mty: 17003,
					pe: 17002
				},
			]
		],
		hengList: [
			[
				{
					nm: '独赢',
					mty: 17003,
					pe: 17001
				}
			],
			[
				{
					nm: '让球',
					mty: 17001,
					pe: 17001
				}
			]
		]
	},
	"7": {
		name: '棒球',
		zongList: [
			[
				{
					nm: '全场让分',
					mty: 7001,
					pe: 7001
				},
				{
					nm: '全场大小',
					mty: 7002,
					pe: 7001
				},
				{
					nm: '全场胜负',
					mty: 7003,
					pe: 7001
				},
			]
		],
		hengList: [
			[
				{
					nm: '主',
					mty: 7003,
					pe: 7003
				}
			],
			[
				{
					nm: '客',
					mty: 7003,
					pe: 7003
				}
			]
		]
	},
	
}




export const period = [
	{"CODE": "1000", "DESC_EN": "SoccerRealTime", "DESC_CN": "足球实时"},
	{"CODE": "1001", "DESC_EN": "SoccerFullTime", "DESC_CN": "足球全场"},
	{"CODE": "1002", "DESC_EN": "SoccerFistHalf", "DESC_CN": "足球上半场"},
	{"CODE": "1003", "DESC_EN": "SoccerSecondHalf", "DESC_CN": "足球下半场"},
	{"CODE": "1004", "DESC_EN": "SoccerExtraFirstHalf", "DESC_CN": "足球加时上半场"},
	{"CODE": "1005", "DESC_EN": "SoccerExtraSecondHalf", "DESC_CN": "足球加时下半场"},
	{"CODE": "1006", "DESC_EN": "SoccerPenaltyKick", "DESC_CN": "足球点球罚球"},
	{"CODE": "1007", "DESC_EN": "Soccer 0-15Min", "DESC_CN": "足球0-15min"},
	{"CODE": "1008", "DESC_EN": "Soccer 15-30Min", "DESC_CN": "足球15-30min"},
	{"CODE": "1009", "DESC_EN": "Soccer 30-45Min", "DESC_CN": "足球30-45min"},
	{"CODE": "1010", "DESC_EN": "Soccer 45-60Min", "DESC_CN": "足球45-60min"},
	{"CODE": "1011", "DESC_EN": "Soccer 60-75Min", "DESC_CN": "足球60-75min"},
	{"CODE": "1012", "DESC_EN": "Soccer 75-90Min", "DESC_CN": "足球75-90min"},
	{"CODE": "1013", "DESC_EN": "SoccerExtraFullTime", "DESC_CN": "足球加时全场"},
	{"CODE": "1014", "DESC_EN": "Soccer Full Time(Incl ET)", "DESC_CN": "足球全场包含加时"},
	{"CODE": "1015", "DESC_EN": "Soccer PenaltyKick(first 5 rounds)", "DESC_CN": "足球点球大战前5回合"},
	{"CODE": "3001", "DESC_EN": "BasketballFullTime", "DESC_CN": "篮球全场（包含加时）"},
	{"CODE": "3002", "DESC_EN": "BasketBallRegularTime", "DESC_CN": "篮固定时间"},
	{"CODE": "3003", "DESC_EN": "BasketballFirstHalf", "DESC_CN": "篮球上半场"},
	{"CODE": "3004", "DESC_EN": "BasketballSecondHalf", "DESC_CN": "篮球下半场"},
	{"CODE": "3005", "DESC_EN": "BasketballFirstQuarter", "DESC_CN": "篮球第一节"},
	{"CODE": "3006", "DESC_EN": "BasketballSecondQuarter", "DESC_CN": "篮球第二节"},
	{"CODE": "3007", "DESC_EN": "BasketballThirdQuarter", "DESC_CN": "篮球第三节"},
	{"CODE": "3008", "DESC_EN": "BasketballFourthQuarter", "DESC_CN": "篮球第四节"},
	{"CODE": "3009", "DESC_EN": "BasketballOverTime", "DESC_CN": "篮球第一个加时"},
	{"CODE": "3010", "DESC_EN": "Second Half (Incl OT)", "DESC_CN": "篮球下半场（包含加时）"},
	{"CODE": "3011", "DESC_EN": "Fourth Quarter (Incl OT)", "DESC_CN": "篮球第四节（包含加时）"},
	{"CODE": "13000", "DESC_EN": "volleyballRealTime", "DESC_CN": "排球实时"},
	{"CODE": "13001", "DESC_EN": "volleyballFullTime", "DESC_CN": "排球全场"},
	{"CODE": "13002", "DESC_EN": "volleyball", "DESC_CN": "排球第1场"},
	{"CODE": "13003", "DESC_EN": "volleyballSecond Set", "DESC_CN": "排球第2场"},
	{"CODE": "13004", "DESC_EN": "volleyball Third Set", "DESC_CN": "排球 第3场"},
	{"CODE": "13005", "DESC_EN": "volleyballFourth Set", "DESC_CN": "排球 第四场"},
	{"CODE": "13006", "DESC_EN": "volleyballFifth Set", "DESC_CN": "排球 第五场"},
	{"CODE": "13007", "DESC_EN": "volleyballSixth Set", "DESC_CN": "排球 第六场"},
	{"CODE": "13008", "DESC_EN": "volleyballSeventh Set", "DESC_CN": "排球 第七场"},
	{"CODE": "13009", "DESC_EN": "volleyballGolden Set", "DESC_CN": "排球 金局"},
	{"CODE": "5000", "DESC_EN": "tennis Real Time", "DESC_CN": "网球实时"},
	{"CODE": "5001", "DESC_EN": "tennisFull Time", "DESC_CN": "网球全场"},
	{"CODE": "5002", "DESC_EN": "tennis First Set", "DESC_CN": "网球第一盘全盘"},
	{"CODE": "5003", "DESC_EN": "tennis Second Set", "DESC_CN": "网球第二盘全盘"},
	{"CODE": "5004", "DESC_EN": "tennisThird Set", "DESC_CN": "网球第三盘全盘"},
	{"CODE": "5005", "DESC_EN": "tennisFourth Set", "DESC_CN": "网球第四盘全盘"},
	{"CODE": "5006", "DESC_EN": "tennisFifth Set", "DESC_CN": "网球第五盘全盘"},
	{"CODE": "19001", "DESC_EN": "Boxing Full Time", "DESC_CN": "拳击全场"},
	{"CODE": "179001", "DESC_EN": "CSGOFullTime", "DESC_CN": "反恐精英 整场比赛"},
	{"CODE": "15000", "DESC_EN": "table tennis Real Time", "DESC_CN": "乒乓球实时"},
	{"CODE": "15001", "DESC_EN": "table tennis Full Time", "DESC_CN": "乒乓球全场"},
	{"CODE": "15002", "DESC_EN": "table tennis First Game", "DESC_CN": "乒乓球第1局"},
	{"CODE": "15003", "DESC_EN": "table tennis Second Game", "DESC_CN": "乒乓球第2局"},
	{"CODE": "15004", "DESC_EN": "table tennis Third Game", "DESC_CN": "乒乓球第3局"},
	{"CODE": "15005", "DESC_EN": "table tennis Fourth Game", "DESC_CN": "乒乓球第4局"},
	{"CODE": "15006", "DESC_EN": "table tennis Fifth Game", "DESC_CN": "乒乓球第5局"},
	{"CODE": "15007", "DESC_EN": "table tennis Sixth Game", "DESC_CN": "乒乓球第6局"},
	{"CODE": "15008", "DESC_EN": "table tennis Seventh Game", "DESC_CN": "乒乓球第7局"},
	{"CODE": "7001", "DESC_EN": "baseball Full Time(Incl Extra Inns)", "DESC_CN": "棒球 全场(包含加时)"},
	{"CODE": "7002", "DESC_EN": "baseball Full Time", "DESC_CN": "棒球 全场"},
	{"CODE": "7003", "DESC_EN": "baseball Inning 1 to 5", "DESC_CN": "棒球 前五局"},
	{"CODE": "7004", "DESC_EN": "Baseball Inning 1", "DESC_CN": "棒球 第一局"},
	{"CODE": "7005", "DESC_EN": "baseball Inning 2", "DESC_CN": "棒球 第二局"},
	{"CODE": "7006", "DESC_EN": "baseball Inning 3", "DESC_CN": "棒球 第三局"},
	{"CODE": "7007", "DESC_EN": "baseball Inning 4", "DESC_CN": "棒球 第四局"},
	{"CODE": "7008", "DESC_EN": "baseball Inning 5", "DESC_CN": "棒球 第五局"},
	{"CODE": "7009", "DESC_EN": "baseball Inning 6", "DESC_CN": "棒球 第六局"},
	{"CODE": "7010", "DESC_EN": "baseball Inning 7", "DESC_CN": "棒球 第七局"},
	{"CODE": "7011", "DESC_EN": "baseball Inning 8", "DESC_CN": "棒球 第八局"},
	{"CODE": "7012", "DESC_EN": "baseball Inning 9", "DESC_CN": "棒球 第九局"},
	{"CODE": "7013", "DESC_EN": "baseball Extra Inning", "DESC_CN": "棒球 加时"},
	{"CODE": "47000", "DESC_EN": "badminton Real Time", "DESC_CN": "羽毛球 实时"},
	{"CODE": "47001", "DESC_EN": "badminton Full Time", "DESC_CN": "羽毛球 全场"},
	{"CODE": "47002", "DESC_EN": "badminton First Game", "DESC_CN": "羽毛球第一局"},
	{"CODE": "47003", "DESC_EN": "badminton Second Gam", "DESC_CN": "羽毛球第二局"},
	{"CODE": "47004", "DESC_EN": "badminton Third Game", "DESC_CN": "羽毛球第三局"},
	{"CODE": "47005", "DESC_EN": "badminton Fourth Game", "DESC_CN": "羽毛球第四局"},
	{"CODE": "47006", "DESC_EN": "badminton Fifth Game", "DESC_CN": "羽毛球第五局"},
	{"CODE": "2000", "DESC_EN": "iceHockey Real Time", "DESC_CN": "冰球实时全场"},
	{"CODE": "2001", "DESC_EN": "iceHockey Full Time", "DESC_CN": "冰球 全场"},
	{"CODE": "2002", "DESC_EN": "iceHockey Full Time(incl OT&PEN)", "DESC_CN": "冰球全场(包含加时和点球)"},
	{"CODE": "2003", "DESC_EN": "iceHockey First Period", "DESC_CN": "冰球第一节"},
	{"CODE": "2004", "DESC_EN": "iceHockey Second Period", "DESC_CN": "冰球第二节"},
	{"CODE": "2005", "DESC_EN": "iceHockey Third Period", "DESC_CN": "冰球第三节"},
	{"CODE": "2006", "DESC_EN": "iceHockey Over Time", "DESC_CN": "冰球加时"},
	{"CODE": "2007", "DESC_EN": "iceHockey Penalty", "DESC_CN": "冰球点球"},
	{"CODE": "6001", "DESC_EN": "american football Full Time", "DESC_CN": "美式橄榄球全场"},
	{"CODE": "6002", "DESC_EN": "american football Regular Time", "DESC_CN": "美式橄榄球常规时间"},
	{"CODE": "6003", "DESC_EN": "american football First Half", "DESC_CN": "美式橄榄球上半場"},
	{"CODE": "6004", "DESC_EN": "american football Second Half", "DESC_CN": "美式橄榄球下半場"},
	{"CODE": "6005", "DESC_EN": "american football First Quarter", "DESC_CN": "美式橄榄球第一节"},
	{"CODE": "6006", "DESC_EN": "american football Second Quarter", "DESC_CN": "美式橄榄球第二节"},
	{"CODE": "6007", "DESC_EN": "american football Third Quarter", "DESC_CN": "美式橄榄球第三节"},
	{"CODE": "6008", "DESC_EN": "american football Fourth Quarter", "DESC_CN": "美式橄榄球第四节"},
	{"CODE": "6009", "DESC_EN": "american football Over time", "DESC_CN": "美式橄榄球加时"},
	{"CODE": "16001", "DESC_EN": "snooker Full Time", "DESC_CN": "斯洛克全场"},
	{"CODE": "16002", "DESC_EN": "snooker Frame1", "DESC_CN": "斯洛克第1局"},
	{"CODE": "16003", "DESC_EN": "snooker Frame2", "DESC_CN": "斯洛克第2局"},
	{"CODE": "16004", "DESC_EN": "snooker Frame3", "DESC_CN": "斯洛克第3局"},
	{"CODE": "16005", "DESC_EN": "snooker Frame4", "DESC_CN": "斯洛克第4局"},
	{"CODE": "16006", "DESC_EN": "snooker Frame5", "DESC_CN": "斯洛克第5局"},
	{"CODE": "16007", "DESC_EN": "snooker Frame6", "DESC_CN": "斯洛克第6局"},
	{"CODE": "16008", "DESC_EN": "snooker Frame7", "DESC_CN": "斯洛克第7局"},
	{"CODE": "16009", "DESC_EN": "snooker Frame8", "DESC_CN": "斯洛克第8局"},
	{"CODE": "16010", "DESC_EN": "snooker Frame9", "DESC_CN": "斯洛克第9局"},
	{"CODE": "16011", "DESC_EN": "snooker Frame10", "DESC_CN": "斯洛克第10局"},
	{"CODE": "16012", "DESC_EN": "snooker Frame11", "DESC_CN": "斯洛克第11局"},
	{"CODE": "16013", "DESC_EN": "snooker Frame12", "DESC_CN": "斯洛克第12局"},
	{"CODE": "16014", "DESC_EN": "snooker Frame13", "DESC_CN": "斯洛克第13局"},
	{"CODE": "16015", "DESC_EN": "snooker Frame14", "DESC_CN": "斯洛克第14局"},
	{"CODE": "16016", "DESC_EN": "snooker Frame15", "DESC_CN": "斯洛克第15局"},
	{"CODE": "16017", "DESC_EN": "snooker Frame16", "DESC_CN": "斯洛克第16局"},
	{"CODE": "16018", "DESC_EN": "snooker Frame17", "DESC_CN": "斯洛克第17局"},
	{"CODE": "16019", "DESC_EN": "snooker Frame18", "DESC_CN": "斯洛克第18局"},
	{"CODE": "16020", "DESC_EN": "snooker Frame19", "DESC_CN": "斯洛克第19局"},
	{"CODE": "16021", "DESC_EN": "snooker Frame20", "DESC_CN": "斯洛克第20局"},
	{"CODE": "16022", "DESC_EN": "snooker Frame21", "DESC_CN": "斯洛克第21局"},
	{"CODE": "16023", "DESC_EN": "snooker Frame22", "DESC_CN": "斯洛克第22局"},
	{"CODE": "16024", "DESC_EN": "snooker Frame23", "DESC_CN": "斯洛克第23局"},
	{"CODE": "16025", "DESC_EN": "snooker Frame24", "DESC_CN": "斯洛克第24局"},
	{"CODE": "16026", "DESC_EN": "snooker Frame25", "DESC_CN": "斯洛克第25局"},
	{"CODE": "16027", "DESC_EN": "snooker Frame26", "DESC_CN": "斯洛克第26局"},
	{"CODE": "16028", "DESC_EN": "snooker Frame27", "DESC_CN": "斯洛克第27局"},
	{"CODE": "16029", "DESC_EN": "snooker Frame28", "DESC_CN": "斯洛克第28局"},
	{"CODE": "16030", "DESC_EN": "snooker Frame29", "DESC_CN": "斯洛克第29局"},
	{"CODE": "16031", "DESC_EN": "snooker Frame30", "DESC_CN": "斯洛克第30局"},
	{"CODE": "16032", "DESC_EN": "snooker Frame31", "DESC_CN": "斯洛克第31局"},
	{"CODE": "16033", "DESC_EN": "snooker Frame32", "DESC_CN": "斯洛克第32局"},
	{"CODE": "16034", "DESC_EN": "snooker Frame33", "DESC_CN": "斯洛克第33局"},
	{"CODE": "16035", "DESC_EN": "snooker Frame34", "DESC_CN": "斯洛克第34局"},
	{"CODE": "16036", "DESC_EN": "snooker Frame35", "DESC_CN": "斯洛克第35局"},
	{"CODE": "51001", "DESC_EN": "Beach Volleyball FullTime", "DESC_CN": "沙滩排球全场"},
	{"CODE": "51002", "DESC_EN": "Beach Volleyball FirstSet", "DESC_CN": "沙滩排球第一局"},
	{"CODE": "51003", "DESC_EN": "Beach Volleyball SecondSet", "DESC_CN": "沙滩排球第二局"},
	{"CODE": "51004", "DESC_EN": "Beach Volleyball ThirdSet", "DESC_CN": "沙滩排球第三局"},
	{"CODE": "51005", "DESC_EN": "Beach Volleyball ForthSet", "DESC_CN": "沙滩排球第四局"},
	{"CODE": "51006", "DESC_EN": "Beach Volleyball FifthSet", "DESC_CN": "沙滩排球第五局"},
	{"CODE": "8000", "DESC_EN": "Handball Real Time", "DESC_CN": "手球实时"},
	{"CODE": "8001", "DESC_EN": "Handball Full Time", "DESC_CN": "手球全场"},
	{"CODE": "8002", "DESC_EN": "Handball Full Time(Incl OT&Pen)", "DESC_CN": "手球全场包含加时和点球"},
	{"CODE": "8003", "DESC_EN": "Handball First Half", "DESC_CN": "手球上半场"},
	{"CODE": "8004", "DESC_EN": "Handball Second Half", "DESC_CN": "手球下半场"},
	{"CODE": "8005", "DESC_EN": "Handball Over Time", "DESC_CN": "手球加时"},
	{"CODE": "8006", "DESC_EN": "Handball Penalty", "DESC_CN": "手球点球"},
	{"CODE": "4000", "DESC_EN": "Rugby Real Time", "DESC_CN": "橄榄球实时"},
	{"CODE": "4001", "DESC_EN": "Rugby Full Time", "DESC_CN": "橄榄球实时"},
	{"CODE": "4003", "DESC_EN": "Rugby First Half", "DESC_CN": "橄榄球上半場"},
	{"CODE": "4004", "DESC_EN": "Rugby Second Half", "DESC_CN": "橄榄球下半場"},
	{"CODE": "4005", "DESC_EN": "Rugby Over Time", "DESC_CN": "橄榄球加时"},
	{"CODE": "4009", "DESC_EN": "Rugby Penalty", "DESC_CN": "橄榄球点球"},
	{"CODE": "18001", "DESC_EN": "MMA Full Time", "DESC_CN": "格斗全场"},
	{"CODE": "92001", "DESC_EN": "Formula 1 Full Time", "DESC_CN": "F1赛车全场"},
	{"CODE": "14001", "DESC_EN": "Cricket Full Time", "DESC_CN": "板球全场"},
	{"CODE": "14002", "DESC_EN": "Cricket Full Time(Incl Super over)", "DESC_CN": "板球全场包含加时"},
	{"CODE": "14003", "DESC_EN": "Cricket Home First Inning", "DESC_CN": "板球主队第一局"},
	{"CODE": "14004", "DESC_EN": "Cricket Away First Inning", "DESC_CN": "板球客队第一局"},
	{"CODE": "14005", "DESC_EN": "Cricket Home Second Inning", "DESC_CN": "板球主队第二局"},
	{"CODE": "14006", "DESC_EN": "Cricket Away Second Inning", "DESC_CN": "板球客队第二局"},
	{"CODE": "14007", "DESC_EN": "Cricket Super Over Home", "DESC_CN": "板球主队加时"},
	{"CODE": "14008", "DESC_EN": "Cricket Super Over Away", "DESC_CN": "板球客队加时"},
	{"CODE": "20000", "DESC_EN": "Darts Real Time", "DESC_CN": "飞镖实时"},
	{"CODE": "20001", "DESC_EN": "Darts Full Time", "DESC_CN": "飞镖全场"},
	{"CODE": "20002", "DESC_EN": "Darts Set 1", "DESC_CN": "飞镖第一盘"},
	{"CODE": "20003", "DESC_EN": "Darts Set 2", "DESC_CN": "飞镖第二盘"},
	{"CODE": "20004", "DESC_EN": "Darts Set 3", "DESC_CN": "飞镖第三盘"},
	{"CODE": "20005", "DESC_EN": "Darts Set 4", "DESC_CN": "飞镖第四盘"},
	{"CODE": "20006", "DESC_EN": "Darts Set 5", "DESC_CN": "飞镖第五盘"},
	{"CODE": "20007", "DESC_EN": "Darts Set 6", "DESC_CN": "飞镖第六盘"},
	{"CODE": "20008", "DESC_EN": "Darts Set 7", "DESC_CN": "飞镖第七盘"},
	{"CODE": "20009", "DESC_EN": "Darts Set 8", "DESC_CN": "飞镖第八盘"},
	{"CODE": "20010", "DESC_EN": "Darts Set 9", "DESC_CN": "飞镖第九盘"},
	{"CODE": "20011", "DESC_EN": "Darts Set 10", "DESC_CN": "飞镖第十盘"},
	{"CODE": "20012", "DESC_EN": "Darts Set 11", "DESC_CN": "飞镖第十一盘"},
	{"CODE": "20013", "DESC_EN": "Darts Set 12", "DESC_CN": "飞镖第十二盘"},
	{"CODE": "20014", "DESC_EN": "Darts Set 13", "DESC_CN": "飞镖第十三盘"},
	{"CODE": "20015", "DESC_EN": "Darts Set 14", "DESC_CN": "飞镖第十四盘"},
	{"CODE": "20016", "DESC_EN": "Darts Set 15", "DESC_CN": "飞镖第十五盘"},
	{"CODE": "17000", "DESC_EN": "Futsal Real Time", "DESC_CN": "五人制足球实时"},
	{"CODE": "17001", "DESC_EN": "Futsal Full Time", "DESC_CN": "五人制足球全场"},
	{"CODE": "17002", "DESC_EN": "Futsal First Half", "DESC_CN": "五人制足球上半场"},
	{"CODE": "17003", "DESC_EN": "Futsal Second Half", "DESC_CN": "五人制足球下半场"},
	{"CODE": "17004", "DESC_EN": "Futsal ET-Full Time", "DESC_CN": "五人制足球加时全场"},
	{"CODE": "17007", "DESC_EN": "Futsal Penalty", "DESC_CN": "五人制足球点球"},
	{"CODE": "177000", "DESC_EN": "e-soccer Real Time", "DESC_CN": "电子足球实时"},
	{"CODE": "177001", "DESC_EN": "e-soccer Full Time", "DESC_CN": "电子足球全场"},
	{"CODE": "177002", "DESC_EN": "e-soccer First Half", "DESC_CN": "电子足球上半场"},
	{"CODE": "177003", "DESC_EN": "e-soccer Second Half", "DESC_CN": "电子足球下半场"},
	{"CODE": "177004", "DESC_EN": "e-soccer ET-First Half", "DESC_CN": "电子足球加时上半场"},
	{"CODE": "177005", "DESC_EN": "e-soccer ET-Second Half", "DESC_CN": "电子足球加时下半场"},
	{"CODE": "177006", "DESC_EN": "e-soccer Penalty", "DESC_CN": "电子足球点球"},
	{"CODE": "177007", "DESC_EN": "e-soccer ET-Full Time", "DESC_CN": "电子足球加时全场"},
	{"CODE": "178001", "DESC_EN": "e-basketball Full Time", "DESC_CN": "电子篮球全场"},
	{"CODE": "178002", "DESC_EN": "e-basketball Regular Time", "DESC_CN": "电子篮球常规时间"},
	{"CODE": "178003", "DESC_EN": "e-basketball First Half", "DESC_CN": "电子篮球上半场"},
	{"CODE": "178004", "DESC_EN": "e-basketball Second Half", "DESC_CN": "电子篮球下半场"},
	{"CODE": "178005", "DESC_EN": "e-basketball First Quarter", "DESC_CN": "电子篮球第一节"},
	{"CODE": "178006", "DESC_EN": "e-basketball Second Quarter", "DESC_CN": "电子篮球第二节"},
	{"CODE": "178007", "DESC_EN": "e-basketball Third Quarter", "DESC_CN": "电子篮球第三节"},
	{"CODE": "178008", "DESC_EN": "e-basketball Fourth Quarter", "DESC_CN": "电子篮球第四节"},
	{"CODE": "178009", "DESC_EN": "e-basketball Over time", "DESC_CN": "电子篮球加时"},
	{"CODE": "24001", "DESC_EN": "Water Polo Full Time", "DESC_CN": "水球全场"},
	{"CODE": "1999", "DESC_EN": "soccer Outright Period", "DESC_CN": "足球冠军阶段"},
	{"CODE": "3999", "DESC_EN": "basketball Outright Period", "DESC_CN": "篮球冠军阶段"},
	{"CODE": "5999", "DESC_EN": "tennis Outright Period", "DESC_CN": "网球冠军阶段"},
	{"CODE": "13999", "DESC_EN": "volleyball Outright Period", "DESC_CN": "排球冠军阶段"},
	{"CODE": "15999", "DESC_EN": "table tennis Outright Period", "DESC_CN": "乒乓球冠军阶段"},
	{"CODE": "47999", "DESC_EN": "badminton Outright Period", "DESC_CN": "羽毛球冠军阶段"},
	{"CODE": "2999", "DESC_EN": "iceHockey Outright Period", "DESC_CN": "冰球冠军阶段"},
	{"CODE": "177999", "DESC_EN": "e soccer Outright Period", "DESC_CN": "电子足球冠军阶段"},
	{"CODE": "178999", "DESC_EN": "e basketball Outright Period", "DESC_CN": "电子篮球冠军阶段"},
	{"CODE": "8999", "DESC_EN": "handball Outright Period", "DESC_CN": "手球冠军阶段"},
	{"CODE": "16999", "DESC_EN": "snooker Outright Period", "DESC_CN": "斯诺克冠军阶段"},
	{"CODE": "7999", "DESC_EN": "baseball Outright Period", "DESC_CN": "棒球冠军阶段"},
	{"CODE": "6999", "DESC_EN": "american football Outright Period", "DESC_CN": "美式橄榄球冠军阶段"},
	{"CODE": "179999", "DESC_EN": "cs go Outright Period", "DESC_CN": "CS_GO冠军阶段"},
	{"CODE": "180999", "DESC_EN": "KOG Outright Period", "DESC_CN": "王者荣耀冠军阶段"},
	{"CODE": "164999", "DESC_EN": "DOTA Outright Period", "DESC_CN": "电竞DOTA冠军阶段"},
	{"CODE": "165999", "DESC_EN": "LOL Outright Period", "DESC_CN": "英雄联盟冠军阶段"},
	{"CODE": "93999", "DESC_EN": "Specials Outright Period", "DESC_CN": "特殊投注冠军阶段"},
	{"CODE": "100999", "DESC_EN": "olympic Outright Period", "DESC_CN": "奥林匹克冠军阶段"},
	{"CODE": "92999", "DESC_EN": "Formula 1 Outright Period", "DESC_CN": "F1赛车冠军阶段"},
	{"CODE": "10999", "DESC_EN": "Floor ball Outright Period", "DESC_CN": "地板球冠军阶段"},
	{"CODE": "14999", "DESC_EN": "Cricket Outright Period", "DESC_CN": "板球冠军阶段"},
	{"CODE": "18999", "DESC_EN": "MMA Outright Period", "DESC_CN": "格斗冠军阶段"},
	{"CODE": "25999", "DESC_EN": "Cycling Outright Period", "DESC_CN": "自行车冠军阶段"},
	{"CODE": "51999", "DESC_EN": "BeachVolleyball Outright Period", "DESC_CN": "沙滩排球冠军阶段"},
	{"CODE": "94999", "DESC_EN": "stock car racing Outright Period", "DESC_CN": "赛车冠军阶段"},
	{"CODE": "12999", "DESC_EN": "golf Outright Period", "DESC_CN": "高尔夫冠军阶段"},
	{"CODE": "17999", "DESC_EN": "futsal Outright Period", "DESC_CN": "五人制足球冠军阶段"},
	{"CODE": "19999", "DESC_EN": "boxing Outright Period", "DESC_CN": "拳击冠军阶段"},
	{"CODE": "20999", "DESC_EN": "dart Outright Period", "DESC_CN": "飞镖冠军阶段"},
	{"CODE": "21999", "DESC_EN": "bowls Outright Period", "DESC_CN": "草地滚球冠军阶段"},
	{"CODE": "24999", "DESC_EN": "WaterPolo Outright Period", "DESC_CN": "水球冠军阶段"},
	{"CODE": "4999", "DESC_EN": "RUGBY Outright Period", "DESC_CN": "橄榄球冠军阶段"},
	{"CODE": "95999", "DESC_EN": "Motorcycle Racing Outright Period", "DESC_CN": "摩托车赛冠军阶段"},
	{"CODE": "101999", "DESC_EN": "Asian Game", "DESC_CN": "亚运会冠军阶段"},
	{"CODE": "1001001", "DESC_EN": "Virtual Soccer Full Time", "DESC_CN": "虚拟足球全场"},
	{"CODE": "1001002", "DESC_EN": "Virtual Soccer First Half", "DESC_CN": "虚拟足球上半场"},
	{"CODE": "1001003", "DESC_EN": "Virtual Soccer Extra First Half", "DESC_CN": "虚拟足球加时上半场"},
	{"CODE": "1001004", "DESC_EN": "Virtual Soccer Extra Full Time", "DESC_CN": "虚拟足球加时全场"},
	{"CODE": "1001005", "DESC_EN": "Virtual Soccer Penalty", "DESC_CN": "虚拟足球点球大战"},
	{"CODE": "1020001", "DESC_EN": "Virtual Horse Default Period", "DESC_CN": "虚拟赛马默认阶段"},
	{"CODE": "1021001", "DESC_EN": "Virtual Greyhounds Default Period", "DESC_CN": "虚拟赛狗默认阶段"},
	{"CODE": "1022001", "DESC_EN": "Virtual Speedway Default Period", "DESC_CN": "虚拟沙地摩托车默认阶段"},
	{"CODE": "1023001", "DESC_EN": "Virtual Motorbike Default Period", "DESC_CN": "虚拟摩托车默认阶"},
]

export const result_type_group = [
	{ "CODE": "5", "DESC_EN": "Score", "DESC_CN": "得分", "REMAPK" :"S"},
	{ "CODE": "6", "DESC_EN": "Corner", "DESC_CN": "角球", "REMAPK" :"C"},
	{ "CODE": "7", "DESC_EN": "Yellow Card", "DESC_CN": "黄牌", "REMAPK" :"Y"},
	{ "CODE": "8", "DESC_EN": "Red Card", "DESC_CN": "红牌", "REMAPK" :"R"},
	{ "CODE": "9", "DESC_EN": "booking", "DESC_CN": "得牌(黄牌 + 红牌)", "REMAPK" :"B"},
	{ "CODE": "127", "DESC_EN": "Kill", "DESC_CN": "击杀数(电竞)", "REMAPK" :"KS"},
	{ "CODE": "5556", "DESC_EN": "Set Score", "DESC_CN": "盘分(网球、排球、沙滩排球)", "REMAPK" :"SET"},
	{ "CODE": "5559", "DESC_EN": "Game Score", "DESC_CN": "局分(网球、乒乓球、羽毛球)", "REMAPK" :"GS"},
	{ "CODE": "5557", "DESC_EN": "Ace", "DESC_CN": "发球直接得分(网球)", "REMAPK" :"AS"},
	{ "CODE": "5558", "DESC_EN": "Double Fault Score", "DESC_CN": "双误(网球)", "REMAPK" :"DFS"},
	{ "CODE": "12", "DESC_EN": "Frame Score", "DESC_CN": "局分(斯诺克)", "REMAPK" :"FS"},
	{ "CODE": "13", "DESC_EN": "Break Score", "DESC_CN": "一杆得分(斯诺克)", "REMAPK" :"MBS"},
	{ "CODE": "14", "DESC_EN": "Home-Run", "DESC_CN": "本垒打(棒球)", "REMAPK" :"Home-Run"},
	{ "CODE": "15", "DESC_EN": "Hit", "DESC_CN": "安打(棒球)", "REMAPK" :"Hit"},
	{ "CODE": "16", "DESC_EN": "Touchdown", "DESC_CN": "达阵(美式足球)", "REMAPK" :"Touchdown"},
	{ "CODE": "17", "DESC_EN": "Field Goal", "DESC_CN": "射门(美式足球)", "REMAPK" :"Field-Goal"},
	{ "CODE": "18", "DESC_EN": "Rank", "DESC_CN": "排名(赛马、赛狗、赛车等)", "REMAPK" :"Rank"},
	{ "CODE": "19", "DESC_EN": "Runs", "DESC_CN": "得分(板球)", "REMAPK" :"Runs"},
	{ "CODE": "20", "DESC_EN": "Fours", "DESC_CN": "四分(板球)", "REMAPK" :"Fours"},
	{ "CODE": "21", "DESC_EN": "Sixes", "DESC_CN": "六分(板球)", "REMAPK" :"Sixes"},
	{ "CODE": "22", "DESC_EN": "Wicket", "DESC_CN": "击落三柱门(板球)", "REMAPK" :"Wicket"},
	{ "CODE": "23", "DESC_EN": "Runs 单个", "DESC_CN": "得分(板球)", "REMAPK" :"Runs"},
	{ "CODE": "24", "DESC_EN": "Fours 单个", "DESC_CN": "四分(板球)", "REMAPK" :"Fours"},
	{ "CODE": "25", "DESC_EN": "Sixes 单个", "DESC_CN": "六分(板球)", "REMAPK" :"Sixes"},
	{ "CODE": "26", "DESC_EN": "Wicket 单个", "DESC_CN": "击落三柱门(板球)", "REMAPK" :"Wicket"},
	{ "CODE": "27", "DESC_EN": "Leg Score", "DESC_CN": "飞镖局分", "REMAPK" :"Leg"},
	{ "CODE": "28", "DESC_EN": "180s", "DESC_CN": "飞镖180分", "REMAPK" :"180s"},
	{ "CODE": "29", "DESC_EN": "Checkout", "DESC_CN": "决胜镖", "REMAPK" :"Checkout"},
	{ "CODE": "30", "DESC_EN": "Extra", "DESC_CN": "額外分(非主客队)", "REMAPK" :"Extra"},
	{ "CODE": "31", "DESC_EN": "Extras", "DESC_CN": "額外分(主客队)", "REMAPK" :"Extra"},
]


export const match_status = {
	"0": "已结束",
	"1": "延",
	"2": "中断",
	"3": "取消",
	"4": "未开赛",
	"5": "进行中",
	"6": "推",
	"7": "废弃",
	"8": "暂停",
	"9": "滚球不覆",
}