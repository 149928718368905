<template>
  <div class="home" ref="myDiv">
    <div class="downLoadOut" v-if="downShow && isApp() && isLogin"></div>
    <div class="homeDownload" v-if="downShow && isApp() && isLogin">
      <div class="downLeft">
        <div class="down-close" @click="closeDown">
          <img src="../assets/home/down-close.svg" />
        </div>
        <div class="appLogo">
          <img src="@/assets/appLogo.svg" />
        </div>
        <div class="fonts">
          <div class="down-name">下载APP领取38彩金</div>
          <div class="down-tips">世界领先得在线品牌</div>
        </div>
      </div>

      <van-button class="down-btn" @click="$router.push('/download')"></van-button>
      <!-- <div class="down-title">下载我们的APP应用，享受更流畅的体验！</div> -->
      <!-- <div class="down-btn" @click="$router.push('/download')">
        <img src="../assets/home/down-phone.svg" />下载
      </div> -->
    </div>
    <home-header isHome class="navbar" :isNewHome="true" :downShow="downShow" :nowBom="true" />
    <!-- <img v-if="isBanner" class="banners" src="@/assets/home/tab/home-lb.webp" /> -->
    <van-swipe class="my-swipe" :autoplay="5000" style="width: calc(100% - 20px );">
      <van-swipe-item v-for="(image, index) in images" :key="index" @click="goImg(image)">
        <!-- v-lazy="image" -->
        <img class="lazyImg" :src="image.h5Img" />
      </van-swipe-item>
    </van-swipe>

    <!-- <div class="qsryxmc" @click="searchShow = true">
      <div class="cearch-ss"><img src="@/assets/home/search-icon.svg" /></div>
      <div>请输入游戏名称</div>
    </div> -->
    <div class="ggOut" v-if="announcementText.length > 0" @click="$router.push('/info/index?tab=2')">
      <div class="ggImg"><img src="@/assets/home/downPop/gg.svg" /></div>
      <div class="announcement-container" ref="noticeainer">
        <div class="announcement-content" :style="contentStyle" v-html="announcementText">
          <!-- <p v-html="announcementText"></p> -->
        </div>
      </div>
      <div class="ggIcon" @click.stop="$router.push('/sport')">
        <div class="rmImg"><img src="@/assets/home/tab/tabIcon/tabB0.png" /></div>
        <div>热门赛事</div>
      </div>
    </div>


    <div class="placeholder-tabs sticky" v-show="tabShow" :class="{ isDownTop: (downShow && isApp() && isLogin) }" >
      <!-- :class="{ 'tab-fixed': isFixed }" ,isDz:  tabs == 3-->
      <div class="tabOut">
        <div class="tabs">
          <!-- ref="scrollContainerTab" -->
          <div class="wrapper" ref="container">
            <!-- v-show="item.platform" -->
            <!-- ref="itemTabRef" -->
            <div class="item" v-for="(item, i) in tabsList" :ref="'itemTab-' + i" :key="i"
              :class="{ active: tabs == i }" @click="changeTabs(i)">
              <!-- <img src="../../assets/pwd-eye.svg" alt="" /> v-lazy="item.icon"-->
              <img class="tabImg" :src="require(`@/assets/home/tab/tabIcon/tab${tabs == i ? 'B' : 'H'}${i}.png`)" />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="scrollContainer">

      <div v-for="(item, index) in list" :key="index" v-show="tabs == index">

        <!-- <ElectronicDemo 
        :name="item.name"
          ref="sportsRef"
          :icon="item.icon"
          :tabs="tabs"
          :code="item.platform[0].code"
          :upName="item.platform[0].name"
          :fromList="item"
          v-if="(item.name == '电子')&&item.platform"/> -->
        <games-demo :name="item.name" ref="sportsRef" :icon="item.icon" :tabs="tabs" :id="item.platform[0].id"
          :code="item.platform[0].code" :upName="item.platform[0].name"
          v-if="(item.name == '小游戏' || item.name == '区块链' || item.name == '热门') && item.platform" />
        <sports-demo  :name="item.name" :list="item.platform" ref="sportsRef" :icon="item.icon" :tabs="tabs" v-else />
      </div>
    </div>
    <div class="dbOut">
      <!-- <div class="dbdemo">
        <div class="dbLeft">
          <div class="dbTit">合作伙伴</div>
          <div class="dbImghzhb">
            <img class="flba" src="@/assets/home/tab/home-hzhb.png" />
          </div>
        </div>
        <div class="dbLeft">
          <div class="dbTit">资质认证</div>
          <div class="dbImghzhb">
            <img class="guracao" src="@/assets/home/tab/home-curacao.png" />
          </div>
        </div>
      </div> -->

      <!-- <div class="yxtgs">
        <div class="dbTit">游戏提供商</div>
        
      </div>

      <van-swipe class="gameWork" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(image, index) in gameImage" :key="index">
        
          <img class="workImg" :src="image" />
        </van-swipe-item>
      </van-swipe> -->

      <div class="fwtk">
        <div @click="$router.push('/terms?tab=0')">服务条款</div>
        <div @click="$router.push('/terms?tab=1')">隐私政策</div>
        <div @click="$router.push('/terms?tab=2')">反洗钱规则</div>
        <div @click="$router.push('/about')">关于我们</div>
      </div>
      <div class="logos" ><img src="@/assets/home-logo.svg" /></div>
      <div class="copyright">© 2024 PANDA版权所有</div>
      <div class="gcs">
        PANDA由MediumRareN.V.所属和经营，注册编号：145353，注册地址：Korporaalweg10,WillemstadCuracao。请通过pd520@gmail.com与我们联系。支付代理公司是MediumRareLimited，地址于7-9RigaFeraiou,LIZANTIACOURT,Office310,Agioi
        Omologites,1087Nicosia,Cyprus以及注册号：HE410775
      </div>
      <div class="dbImg">
        <img src="@/assets/home/bottom.png" />
      </div>
      <div class="copyright mt15">服务器时间: {{ currentTime }}</div>
      <div class="copyright mt6">Copyright © 2024 PANDA Group. All Rights Reserved</div>
      <div style="width: 100%; height: 90px"></div>
    </div>


    <!-- 重要公告 -->
    <van-popup v-model="not2Show" class="notPop" @close="closeNotBtn2()">
      <div class="notIn">
        <div class="closeNot" @click="closeNotBtn2()">
          <img src="@/assets/home/not/closeNot.svg" />
        </div>
        <div class="jiaob">
          <img src="@/assets/home/not/2.webp" />
        </div>
        <div class="notTitle">重要公告</div>
        <div class="notTips" v-if="notiList.length > 0 && notiList[1]">
          {{ notiList[1].title }}
        </div>
        <div class="notDetail" v-if="notiList.length > 0 && notiList[1]" v-html="notiList[1].content"></div>
        <div class="notBomTips">如有疑问即使联系24小时在线客服</div>
      </div>
    </van-popup>

    <van-popup v-model="not1Show" class="notPop" @close="closeNotBtn1()">
      <div class="notIn">
        <div class="closeNot" @click="closeNotBtn1()">
          <img src="@/assets/home/not/closeNot.svg" />
        </div>
        <div class="jiaob">
          <img src="@/assets/home/not/1.webp" />
        </div>
        <div class="notTitle">域名公告</div>
        <div class="notTips" v-if="notiList.length > 0">
          {{ notiList[0].title }}
        </div>
        <div class="notDetail" v-if="notiList.length > 0" v-html="notiList[0].content"></div>
        <div class="notBomTips">如有疑问即使联系24小时在线客服</div>
      </div>
    </van-popup>
    <div @touchmove.stop>
    <van-popup v-model="yumNotPopShow" class="yumPop" position="center" :lock-scroll="false" @click-overlay="closeYum()">
      <div class="popupview">
        <div class="popup">
          <div class="gonggao">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_222_3372)">
                  <path
                    d="M7.56223 0.932934C7.34846 0.831771 7.09614 0.865165 6.91459 1.01691L3.41386 3.94866L0.621279 3.93737C0.460713 3.93737 0.306003 4.00121 0.192289 4.11563C0.0775988 4.23005 0.0126891 4.38572 0.0126891 4.54876L0 9.44828C0 9.61131 0.0634456 9.76748 0.177648 9.88239C0.291362 9.99829 0.446559 10.0626 0.60859 10.0626H3.43192L6.91508 12.9836C7.02635 13.0769 7.16545 13.125 7.30454 13.125C7.39239 13.125 7.48023 13.1058 7.56271 13.0671C7.77648 12.9664 7.91313 12.7503 7.91313 12.5126V1.48736C7.91313 1.25017 7.77648 1.0341 7.56223 0.932934ZM9.9224 3.99531C9.62226 3.85241 9.2655 3.98255 9.11957 4.28309C8.97413 4.58461 9.10005 4.94948 9.39776 5.10025C9.43631 5.12038 10.348 5.59722 10.348 6.95506C10.348 8.40571 9.49536 8.88648 9.46706 8.90219C9.16642 9.05345 9.04441 9.42176 9.19473 9.72426C9.3021 9.93936 9.51635 10.0631 9.74036 10.0631C9.83162 10.0631 9.92484 10.0425 10.0117 9.99878C10.0752 9.96637 11.5652 9.19046 11.5652 6.95506C11.5652 4.79823 9.98926 4.02724 9.9224 3.99531ZM11.8175 2.19649C11.5368 2.01528 11.1645 2.09778 10.981 2.37966C10.7984 2.66154 10.8785 3.04065 11.1576 3.22628C11.2235 3.26999 12.7828 4.33907 12.7828 7.00024C12.7828 9.6673 11.287 10.7374 11.2274 10.7781C10.9473 10.9662 10.8721 11.3458 11.0586 11.6277C11.1757 11.8045 11.369 11.9002 11.5656 11.9002C11.6813 11.9002 11.7989 11.8664 11.9029 11.7971C11.9883 11.7402 14 10.3524 14 6.99975C14 3.64075 11.9068 2.25394 11.8175 2.19649Z"
                    fill="#C8D7F8" />
                </g>
                <defs>
                  <clipPath id="clip0_222_3372">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>站内公告</span>
            </div>

            <div class="closeYum" @click="closeYum()">
              <!-- <img src="@/assets/home/not/closeNot.svg" /> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path
                  d="M14 26.25C7.24501 26.25 1.75 20.755 1.75 14C1.75 7.24501 7.24501 1.75 14 1.75C20.755 1.75 26.25 7.24501 26.25 14C26.25 20.755 20.755 26.25 14 26.25ZM14 3.50786C8.21449 3.50786 3.50786 8.21449 3.50786 14C3.50786 19.7846 8.21449 24.4921 14 24.4921C19.7846 24.4921 24.4921 19.7847 24.4921 14C24.4921 8.21449 19.7846 3.50786 14 3.50786Z"
                  fill="#B0C0D0" />
                <path
                  d="M15.2318 14.0376L19.0153 10.2952C19.3592 9.95571 19.3618 9.40185 19.0223 9.05797C18.6828 8.71408 18.128 8.71145 17.785 9.05096L13.9963 12.7977L10.2653 9.05533C9.92315 8.71319 9.37018 8.71145 9.02804 9.05359C8.68589 9.39484 8.68505 9.9487 9.0263 10.2908L12.752 14.028L8.99563 17.7441C8.65174 18.0844 8.64911 18.6375 8.98862 18.9813C9.16012 19.1546 9.385 19.2412 9.61073 19.2412C9.83298 19.2412 10.0552 19.1564 10.2259 18.9884L13.9875 15.2678L17.7797 19.0724C17.9504 19.2439 18.1744 19.3296 18.3993 19.3296C18.6232 19.3296 18.8464 19.2439 19.017 19.0741C19.3591 18.7329 19.36 18.1798 19.0187 17.8368L15.2318 14.0376Z"
                  fill="#B0C0D0" />
              </svg>
            </div>

          </div>
          <!-- <div class="titlelist"> -->
          <van-tabs swipeable swipe-threshold="2">
            <van-tab v-for="(item, index) in homePopupList" :title="item.title" :key="index" >
              <div @click="goMyUrl(item.url)">
                <img :src="item.image" alt="" >
              </div>
              
            </van-tab>
          </van-tabs>
          <!-- </div> -->
        </div>

      </div>
      <!-- <div class="yumOut">
        <div class="jiaob">
          <img src="@/assets/home/not/1.webp" />
        </div>
        <div class="closeYum" @click="closeYum()">
            <img src="@/assets/home/not/closeNot.svg" />
          </div>
        <div class="notIn">
          

          <div class="notTitle">域名公告</div>
          <div class="notTips">
            PANDA域名公告
          </div>
          <div class="linkOut">
            <div class="linkLine" v-for="(item,index) in domain" :key="index">
            <img src="@/assets/home/link.png" />{{ item }}
          </div>
          
          </div>
          
          <van-button class="yjbctp" :loading="yjbLoading" @click="yjbctp()">关注官网 截图保存 防止失联</van-button>
        </div>
      </div> -->

    </van-popup>
  </div>
    <!-- <van-popup v-model="downPopShow" class="downPop" @close="closeDownPop" position="bottom">
      <div class="downOut">
        <div class="downIn">
         
          <div class="downClose" @click="closeDownPop"><img src="@/assets/home/downPop/close.svg" /></div>
          <div class="downLogo">
            <img src="@/assets/appLogo.svg" />
          </div>
          <div class="dwon-tit-big">
            下载APP
          </div>
          <div class="dwon-tit">
            注册联系客服领取38礼金
          </div>
          <van-button class="goDown" @click="closeDown">
            去下载
          </van-button>
        </div>
        
      </div>
    </van-popup> -->

    <!-- <div class="bztx" @click="isYes = !isYes">
          <img :src="isYes ? require('@/assets/home/downPop/yes.svg') : require('@/assets/home/downPop/noYes.svg')" />
          不再提醒
        </div> -->
  </div>
</template>

<script>
import { EventBus } from '@/unit/event-bus'; 
import { mapActions } from "vuex";
import html2canvas from "html2canvas";
import HomeHeader from "@/components/HomeHeader.vue";
import {
  homeInit,
  platformList,
  homeBanner,
  homeImportant_notification,
  userInfo,
  homePopup
} from "@/request/api";
import SportsDemo from "@/components/home/sports.vue";
import GamesDemo from "@/components/home/games.vue";
// import ElectronicDemo from "@/components/home/electronic.vue";
export default {
  name: "HomeView",
  components: {
    HomeHeader,
    SportsDemo,
    GamesDemo,

    // ElectronicDemo,
    // PhotoList,
  },
  data() {
    return {
      isLogin: false,
      downShow: false,
      active: 0,
      images: [],

      isFixed: false, // 导航栏是否浮动显示
      list: [{ id: 0, name: "热门", icon: require("@/assets/home/newTab/red.png"), platform: [{ id: -1 }], }],
      sportLists: [],
      photoLists: [],
      electronLists: [],
      chessLists: [],
      fishLists: [],
      smallGameLists: [],
      isBanner: true,
      tabsList: [{ id: 0, name: "热门", icon: require("@/assets/home/newTab/red.png"), platform: [{ id: -1 }], }],
      tabs: 0,
      sportsComponents: [],
      tabBtnLock: false,
      lastScrollTop: 0,
      timeer: null,
      gameImage: [
        require("@/assets/home/tab/gameWork/all1.png"),
        require("@/assets/home/tab/gameWork/all2.png"),
        require("@/assets/home/tab/gameWork/all3.png"),
      ],
      notiList: [],
      not1Show: false,
      not2Show: false,
      uid: "",
      downPopShow: false,
      isYes: true,
      announcementText: "",
      scrollSpeed: 100,  // 每秒滚动的像素数
      contentStyle: {},  // 动态样式对象
      currentTime: "",
      timer: null,
      yumNotPopShow: false,
      yjbLoading: false,
      domain: [],
      tabShow: false,
      homePopupList: [],
    };
  },
  watch: {
    tabs(newValue) {
      this.$nextTick(() => {
        if (this.$refs.sportsRef) {
          if (newValue == 0) {
            return;
          }
          // this.$refs.sportsRef[newValue].setFold();
        }
      });
    },
  },
  created() {
    this.getHomePopup()
    EventBus.$on('tokenChanged', this.handleTokenChange);
  },
  mounted() {
    this.getLink();
    if (localStorage.getItem('noDown') == 1) {
      this.downPopShow = false;
    } else {
      // if (this.isApp()) {
      //   this.downPopShow = true;
      // }
    }

    if (this.$route.query.code !== undefined) {
      localStorage.setItem("myCode", this.$route.query.code);
    }
    if (this.$route.query.url !== undefined) {
      localStorage.setItem("inUrl", this.$route.query.url);
    }
    if (this.$route.query.tabName !== undefined) {
      this.tabName = this.$route.query.tabName;
    }
    if (this.$route.query.tabs) {
      this.tabs = this.$route.query.tabs;
    }

    this.isLogin = localStorage.getItem("token");
    if (localStorage.getItem("downShow")) {
      this.downShow = false;
    } else {
      if (this.isApp()) {
        this.downShow = true;
      } else {
        this.downShow = false;
      }
      // if (this.isLogin || localStorage.getItem("userYou") == "tourists") {
      //   //检查是否为app false yes
      //   if (this.isApp()) {
      //     this.downShow = true;
      //   } else {
      //     this.downShow = false;
      //   }
      // } else {
      //   this.downShow = false;
      // }
    }
    // window.addEventListener("scroll", this.handleScroll); // 监听页面滚动事件
    window.addEventListener("storage", this.handleLocalStorageChange);

    if (this.isLogin) {
      this.getUserInfo();
      if (localStorage.getItem('yumNotPopShow') == 1) {
        this.yumNotPopShow = false;
      } else {
        this.yumNotPopShow = true;

      }
    } else {
      this.getHomeImportant_notification("no");
    }
    this.updateTime();
    this.timer = setInterval(this.updateTime, 1000);
    //this.getHomeInit()
    this.getHomeBanner();
    this.getPlatformList();

    // let el = document.querySelector('.yumPop')
    // if (el) {
    //   if (el.previousElementSibling.className == 'van-overlay') {

    //     el.previousElementSibling.onclick = () => {
    //       console.log(456);

    //     }
    //   }
    // }
  },
  // watch: {
  //   tabs(newValue, oldValue) {
  //     // if(this.tabBtnLock){
  //     //   return
  //     // }
  //     const itemRefs = this.$refs.itemTabRef;
  //     var itemLeft = 0;
  //     if (itemRefs && itemRefs[newValue]) {
  //       itemLeft = itemRefs[newValue].offsetWidth;
  //     }
  //     if (oldValue > newValue) {
  //       this.$refs.scrollContainerTab.scrollBy({
  //         left: -itemLeft,
  //         behavior: "smooth",
  //       });
  //     } else if (newValue != 0) {
  //       this.$refs.scrollContainerTab.scrollBy({
  //         left: itemLeft,
  //         behavior: "smooth",
  //       });
  //     }
  //   },
  // },
  methods: {
    ...mapActions(["setLoginShow","setSuccessfulShow"]),
   
    goMyUrl(url){
      this.$router.push(url)
    },
    async yjbctp() {
      this.yjbLoading = true;
      // 获取要截图的 div 元素
      const captureDiv = this.$refs.myDiv;

      // 设置你想要限制的截图高度（单位：px）
      const maxHeight = window.innerHeight;

      // 获取捕获元素的当前高度
      const divHeight = captureDiv.scrollHeight;

      // 如果元素的高度超过最大高度，截取部分内容
      const heightToCapture = divHeight > maxHeight ? maxHeight : divHeight;

      // 使用 html2canvas 生成 Canvas，限制截图的高度
      const canvas = await html2canvas(captureDiv, {
        useCORS: true,  // 启用跨域图片支持
        height: heightToCapture,  // 限制截图的高度
        scrollX: 0,
        scrollY: -window.scrollY, // 防止滚动条影响截图
      });

      // 将 Canvas 转换为图片格式
      const imageData = canvas.toDataURL("image/png");

      // 创建一个下载链接并触发下载
      const downloadLink = document.createElement("a");
      downloadLink.href = imageData;
      downloadLink.download = "pd.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      this.yjbLoading = false;

    },
    getHomePopup() {
      homePopup()
        .then(res => {
          this.homePopupList = res.data
          this.$nextTick(() => {
            let urlList = document.querySelectorAll('.van-tabs__content .van-tab__pane')
            try {
              urlList.forEach(item => {
                item.onclick = () => {
                  this.$router.push(item.getElementsByTagName('img')[0].getAttribute('url')?.slice(2))
                }
              })
            } catch (e) {
              console.error(e);
              
            }
          })
        })
    },
    getLink() {
      homeInit().then((res) => {

        var myList = res.data.domain.split(',');
        this.domain.push('pd520.com');
        this.domain.push(...myList)
      })
    },
    goImg(item) {
      if (item.link) {
        this.$router.push(item.link);
      }
    },
    updateTime() {
      this.currentTime = new Date().toLocaleString(); // 更新当前时间
    },
    closeDownPop() {
      if (this.isYes) {
        localStorage.setItem("noDown", 1);
      }
      this.downPopShow = false;
    },
    closeYum() {

      localStorage.setItem("yumNotPopShow", 1);

      this.yumNotPopShow = false;
    },
    // 计算滚动动画的样式
    updateScrollStyle() {
      // 获取容器宽度和内容宽度
      const containerWidth = this.$refs.noticeainer ? this.$refs.noticeainer.offsetWidth : 0;
      const contentWidth = this.$refs.noticeainer ? this.$refs.noticeainer.scrollWidth : 0;
      console.log("宽度", containerWidth, contentWidth)
      // 如果容器宽度和内容宽度都有效，则计算动画持续时间
      if (containerWidth && contentWidth) {
        const duration = contentWidth / this.announcementText.length; // 滚动时间 = 内容宽度 / 固定滚动速度
        // 动态更新样式
        this.contentStyle = {
          // animation: `scroll-left ${duration}s linear infinite`,
          animationDuration: `${duration * 4}s`,
        };
        console.log(this.contentStyle)
      }
    },
    scrollToItem(index) {
      this.$nextTick(() => {
        const container = this.$refs.container;
        const item = this.$refs["itemTab-" + index][0];
        if (container && item) {
          const itemOffsetLeft = item.offsetLeft;
          // const containerScrollLeft = container.scrollLeft;
          const containerWidth = container.clientWidth;
          const itemWidth = item.clientWidth;

          // 计算滚动位置
          const scrollTo = itemOffsetLeft - containerWidth / 2 + itemWidth / 2;

          container.scrollTo({
            left: scrollTo,
            behavior: "smooth",
          });
        }
      });
    },
    getplatformCoin_down() { },
    getUserInfo() {
      userInfo().then((res) => {
        if (res.code == 0) {
          this.uid = res.data.id;
          this.getHomeImportant_notification("login");
        }
      });
    },
    closeNotBtn1() {
      if (!this.isLogin) {
        this.not1Show = false;
        return;
      }
      if (localStorage.getItem("not1id")) {
        var not1id = JSON.parse(localStorage.getItem("not1id"));
        not1id = [
          {
            name: this.uid,
            createTime: this.notiList[0].createTime,
          },
        ];
        localStorage.setItem("not1id", JSON.stringify(not1id));
      } else {
        var notid = [
          { name: this.uid, createTime: this.notiList[0].createTime },
        ];
        localStorage.setItem("not1id", JSON.stringify(notid));
      }

      this.not1Show = false;
    },
    closeNotBtn2() {
      if (!this.isLogin) {
        this.not2Show = false;
        return;
      }
      if (localStorage.getItem("not2id")) {
        var not2id = JSON.parse(localStorage.getItem("not2id"));
        not2id = [
          {
            name: this.uid,
            createTime: this.notiList[1].createTime,
          },
        ];
        localStorage.setItem("not2id", JSON.stringify(not2id));
      } else {
        var notid = [
          { name: this.uid, createTime: this.notiList[1].createTime },
        ];
        localStorage.setItem("not2id", JSON.stringify(notid));
      }

      this.not2Show = false;
    },
    getHomeImportant_notification(value) {
      homeImportant_notification().then((res) => {
        if (res.code == 0) {
          this.notiList = res.data.filter((item) => item.typeId === 0);
          var myData = this.notiList;
          // if(!this.isLogin){
          //   this.not2Show = true;
          //   this.not1Show = true;
          //   return;
          // }
          var gdgg = res.data.find((item) => item.typeId === -1)
          this.announcementText = gdgg.content;
          this.$nextTick(() => {
            this.updateScrollStyle();
          })
          if (value == "no" || myData.length <= 0) {
            this.not2Show = false;
            this.not1Show = false;
            return;
          }
          if (localStorage.getItem("not2id")) {
            var not2id = JSON.parse(localStorage.getItem("not2id"));

            const foundItem = not2id.find((item) => item.name === this.uid);
            if (foundItem) {
              //存在
              if (
                myData.length > 2 &&
                myData[1].createTime != foundItem.createTime
              ) {
                this.not2Show = true;
              } else {
                this.not2Show = false;
              }
            } else {
              if (myData.length >= 2) {
                this.not2Show = true;
              } else {
                this.not2Show = false;
              }
            }
            //   if(not2id.includes(this.uid) == false){
            //     this.not2Show = true;
            //   }
            // }else{
            //   this.not2Show = true;
            // }
          } else {
            if (myData.length >= 2) {
              this.not2Show = true;
            } else {
              this.not2Show = false;
            }
          }

          if (localStorage.getItem("not1id")) {
            var not1id = JSON.parse(localStorage.getItem("not1id"));
            const foundItem0 = not1id.find((item) => item.name === this.uid);
            console.log(foundItem0, "youm");
            if (foundItem0) {
              //存在
              if (myData[0].createTime != foundItem0.createTime) {
                this.not1Show = true;
              } else {
                this.not1Show = false;
              }
            } else {
              if (myData.length >= 1) {
                this.not1Show = true;
              } else {
                this.not2Show = false;
              }
            }

            // if(not1id.includes(this.uid) == false){
            //   this.not1Show = true;
            // }
          } else {
            if (myData.length >= 1) {
              this.not1Show = true;
            } else {
              this.not2Show = false;
            }
          }
        }
      });
    },
    closeDown() {
      var num = 0;
      if (localStorage.getItem("downClose")) {
        num = localStorage.getItem("downClose");
      }
      switch (num) {
        case 0:
          localStorage.setItem("downClose", 1);
          break;
        case 1:
          localStorage.setItem("downClose", 2);
          break;
        case 2:
          localStorage.setItem("downShow", true);
          break;
        default:
          localStorage.setItem("downShow", true);
          break;
      }
      // localStorage.setItem("downShow", true);
      this.downShow = false;
    },
    changeTabs(i) {
      // if (this.tabBtnLock) {
      //   return;
      // }
      // this.tabBtnLock = true;
      this.tabs = i;
      this.scrollToItem(i);
      if(this.tabs == 3){
        this.$refs.sportsRef[i].setKey();
      }
      // var tops = 0;

      // tops = this.$refs.sportsRef[i].$refs.scrollTarget.offsetTop - 55 - 48;

      // window.scrollTo({
      //   top: tops,
      //   behavior: "smooth",
      // });
      // var time = 500;
      // // if(this.list[i].platform){
      // // if(this.list[i].platform.length<=6&&this.list[i].platform.length>=0){
      // //   time = 500;
      // // }else if(this.list[i].platform.length>6&&this.list[i].platform.length>15){
      // //   time = 1000
      // // }else{
      // //   time = 1500
      // // }
      // // }
      // this.timeer = setTimeout(() => {
      //   this.tabBtnLock = false;
      // }, time);

      // // this.$refs["sportsRef"].$refs["scrollTarget"].scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    isApp() {
      var origin = window.location.origin;
      if (origin.includes("app")) {
        localStorage.setItem("downShow", true);
      }
      return !origin.includes("app");
    },
    getHomeBanner() {
      homeBanner().then((res) => {
        if (res.code == 0) {
          this.images = res.data;
          this.isBanner = false;
        }
      });
    },
    getPlatformList() {
      this.$store.commit("setLoadingShowTrue");
      platformList().then((res) => {
        if (res.code == 0) {
          this.list.push(...res.data);
          this.tabShow = true;
          this.tabsList.push(...res.data);
          this.sportsComponents = this.list.map(() => ({}));
          this.scrollToItem(this.tabs);

        }
        this.$store.commit("setLoadingShowFalse");
      });
    },
    handleChange(index) {
      // 手动更新导航栏的选中状态
      this.$nextTick(() => {
        this.active = index;
        this.isFixed =
          window.scrollY >
          document.querySelector(".placeholder-tabs").offsetTop - 55;
      });
    },

    scrollToActiveElement() {
      // 获取当前被激活元素的DOM节点
      const activeElement =
        this.$refs.scrollContainerTab.getElementsByClassName("active")[0];

      if (activeElement) {
        // 滚动到当前激活元素的位置

        this.$refs.scrollContainerTab.scrollLeft = this.$refs.scrollContainerTab
          .getElementsByClassName("active")[0]
          .getBoundingClientRect().left;
      }
    },
    handleScroll() {
      // 当页面滚动位置大于导航栏的位置时，将导航栏浮动显示
      this.isFixed =
        window.scrollY >
        document.querySelector(".placeholder-tabs").offsetTop - 55;
      // console.log(this.$refs.sportsRef[0].getBoundingClientRect())
      // for(var i = 0;i<this.tabsList.length;i++){
      //   if( window.screenY >this.$refs.sportsRef[i].$refs.scrollTarget){
      //     this.tabs = i;
      //     return;
      //   }
      // }

      if (this.tabBtnLock) {
        return;
      }

      // console.log(this.$refs.sportsRef[0].$el.getBoundingClientRect())
      for (var i = 0; i < this.tabsList.length; i++) {
        // console.log(i,this.$refs.sportsRef[i].$el.getBoundingClientRect().top)
        if (
          this.$refs.sportsRef[i].$el.getBoundingClientRect().top < 130 &&
          this.$refs.sportsRef[i].$el.getBoundingClientRect().top > 80
        ) {
          this.tabs = i;
          // this.scrollToActiveElement();
          return;
        }
      }

      //   this.$refs.sportsRef.forEach((itemRef, index) => {
      //   const rect = itemRef.getBoundingClientRect();
      //   if(rect.top == 0){
      //     this.tabs = index;
      //     return;
      //   }

      // });
    },
    getHomeInit() {
      homeInit().then((res) => {
        console.log(res);
      });
    },
    goPage(uid) {
      if (!this.isLogin) {
        // this.$notify({
        //   message: "暂未登录，请先登录账号",
        //   duration: 2000,
        //   //background: '#1989fa'
        // });
        // this.$router.push("/login");
        this.setLoginShow(true);
        return;
      }

      // var url = "";
      switch (uid) {
        case 0:
          this.$router.push({ path: "/sports" });
          break;
        case 1:
          this.$router.push({ path: "/PersonReal" });
          break;
        case 2:
          this.$router.push({ path: "/Electronic" });
          break;
        case 3:
          this.$router.push({ path: "/Pescaria" });
          break;
        case 4:
          this.$router.push({ path: "/block" });
          break;
        default:
          break;
      }

      // var url = "";
      // switch (uid) {
      //   case 0:
      //     url = "https://uat-nc-ugs-login.ufweg.com/?token=" + localStorage.getItem('token') + "&operator=panda01gkS3i&lang=zh&timezone=UTC%2B8&category=Sportbook&redirecturl=http://www.pandapro.me"
      //     break;
      //   case 1:
      //     url = "https://uat-nc-ugs-login.ufweg.com/?token=" + localStorage.getItem('token') + "&operator=panda01gkS3i&lang=zh&timezone=UTC%2B8&category=Slot&redirecturl=http://www.pandapro.me"
      //     break;
      //   case 2:
      //     url = "https://uat-nc-ugs-login.ufweg.com/?token=" + localStorage.getItem('token') + "&operator=panda01gkS3i&lang=zh&timezone=UTC%2B8&category=Fishing&redirecturl=http://www.pandapro.me"
      //     break;
      //   case 3:
      //     url="https://uat-nc-ugs-login.ufweg.com/?token=" + localStorage.getItem('token') + "&operator=panda01gkS3i&lang=zh&timezone=UTC%2B8&category=Live&redirecturl=http://www.pandapro.me"
      //     break;
      //   case 4:
      //     url ="https://uat-nc-ugs-login.ufweg.com/?token=" + localStorage.getItem('token') + "&operator=panda01gkS3i&lang=zh&timezone=UTC%2B8&category=Blockchain&redirecturl=http://www.pandapro.me"
      //     break;
      //   default:
      //     break;
      // }
      // window.location.href=url;
    },
    handleLocalStorageChange(event) {
      if (event.key === "token") {
        this.isLogin = event.newValue;
        //this.localStorageItem = event.newValue;
      }
      if (event.key === "downShow") {
        if (localStorage.getItem("downShow")) {
          this.downShow = false;
        } else {
          if (this.isLogin || localStorage.getItem("userYou") == "tourists") {
            this.downShow = true;
          } else {
            this.downShow = false;
          }
        }
      }
    },
    handleTokenChange(newToken) {
      console.log('///Token has been updated:', newToken);
      this.isLogin = localStorage.getItem("token");
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      if (this.isLogin) {
        this.getUserInfo();
          if(localStorage.getItem('firstRegister') == 1){
          this.setSuccessfulShow(true)
          localStorage.removeItem('firstRegister')
          return;
          }
          if (localStorage.getItem('yumNotPopShow') == 1) {
            this.yumNotPopShow = false;
          } else {
            this.yumNotPopShow = true;

          }
       
       
      } else {
        // this.getHomeImportant_notification("no");
      }
     
    }
  },
  beforeDestroy() {
    // Cleanup: Remove the event listener when component is destroyed
    window.removeEventListener("storage", this.handleLocalStorageChange);
    // window.removeEventListener("scroll", this.handleScroll); // 移除页面滚动事件监听
    EventBus.$off('tokenChanged', this.handleTokenChange);
  },
};
</script>
<style lang="less" scoped>
@import '@/style/global.less';

// .home::before{
//   content:'';
//   position: fixed;
//   z-index: -1;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   // background-size: cover;
//   // background-image: url('../assets/home/home-bg.svg');
//   // background-repeat: no-repeat;
// }
.home {
  min-height: 100vh;
  // background-color: transparent;
  background: @allBj;

  padding-bottom: 40px;
  background-position: center;
  background-attachment: fixed;
  /* 将背景图固定 */

  .tabsOut {
    background: transparent;

    margin-top: 13px;

    // display: inline-block;
    /deep/.van-tab {
      padding-left: 5px;
    }

    /deep/.van-tabs__nav {
      //background: transparent;
      background: rgba(16, 29, 42, 0.9);

      backdrop-filter: blur(5px);
    }

    /deep/.van-tab__text {
      width: 63px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /deep/.van-tabs__line {
      display: none;
    }

    /deep/.van-tab--active {
      font-family: "PingFang SC";

      .tabTit {
        color: #0359ff;
      }
    }

    .van-tabs__wrap {
      background: transparent;
    }

    .tabTit {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 21px */
      color: #fff;
      width: 100px;
      white-space: nowrap;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  .banners {
    width: 89.3333%;
    height: 168px;
    position: absolute;
    top: 115px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 5;
    border-radius: 20px;
  }

  .my-swipe {

    // height: 130px;

    margin: 8px auto 0 auto;
    height: 150px;

    // border-radius: 20px;
    .van-siwper {
      overflow: auto;
    }

    .lazyImg {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }

    /deep/.my-swipe {}

    /deep/.van-swipe__track {
      height: 140px;
    }

    /deep/.van-swipe__indicator {
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.09);
    }

    /deep/.van-swipe__indicator--active {
      width: 38.5px;
      height: 4px;
      border-radius: 0;
      background: rgba(255, 255, 255, 0.65);
    }

    /deep/.van-swipe__indicators {
      width: 76%;
      bottom: 2px;
      justify-content: center;

      // left: 0;
      // right: 0;
      // transform: none;
      .van-swipe__indicator {
        background: #ffffff20;
        opacity: 1;
        // flex: 1;
      }

      @keyframes width {
        0% {
          width: 0%;
        }

        100% {
          width: 100%;
        }
      }

      i.van-swipe__indicator--active {
        opacity: 1;
        // flex: 2;
        position: relative;
        // animation: width 5s linear;
        border-radius: 100px;
        width: 30px;

        &::before {
          content: "";
          display: block;
          z-index: 999;
          height: 100%;
          position: absolute;
          background: #4774DA;
          top: 0;
          left: 0;
          bottom: 0;
          animation: width 5s linear;
          transition: all 5s;
          border-radius: 100px;
        }
      }
    }
  }

  .swipeTop-40 {
    top: 105px;
  }

  .downLoadOut {
    width: 100%;
    height: 48px;
  }

  .homeDownload {
    width: 100%;
    height: 48px;
    // background: #283643;
    background-image: url('../assets/home/xztBj.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 15px;
    position: fixed;
    top: 0;
    z-index: 5;

    .downLeft {
      display: flex;
      align-items: center;
    }

    .down-close {
      width: 24px;
      height: 24px;

      border-radius: 4px;
      // background: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .appLogo {
      display: flex;
      width: 36px;
      height: 36px;
      align-items: center;
      justify-content: center;
      margin: 0 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .fonts {
      .down-name {
        color: #fff;
        font-family: "SF Pro Display";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
        text-align: left;
      }

      .down-tips {
        color: #b1bad3;
        font-family: "SF Pro Display";
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 12px */
        text-align: left;
        margin-top: 2px;
      }
    }

    .down-title {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
      text-align: left;
    }

    .down-btn {
      width: 68px;
      height: 24px;
      display: inline-flex;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: #4774da;
      color: #fff;
      font-family: "SF Pro Display";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 12px */
      border: 0;
      background: url('@/assets/home/ljxz.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .headDemo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 6px 10px;

    .headLeft {
      width: 129px;
      height: 44px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .widthDemo {
    width: 89.3333%;
    height: 158px;
    border-radius: 16px;
    background: linear-gradient(270deg, #ea4058 0%, #aa0018 100%);
    margin: 0 auto;
    padding: 20px 15px;

    .tips {
      color: rgba(255, 255, 255, 0.65);
      font-family: "PingFang SC";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }

    .title {
      margin-top: 18px;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 24px */
      text-align: left;
    }

    .titTwo {
      margin-top: 5px;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 18px */
      text-align: left;
    }

    .inbtn {
      display: flex;
      width: 88px;
      height: 32px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex-shrink: 0;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.15);
      color: var(---100, #fff);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 12px;
    }
  }

  .physical {
    background-image: url("../assets/home/home-physical.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .photos {
    background-image: url("../assets/home/home-photo.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .block {
    background-image: url("../assets/home/home-block.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 15px;
  }

  .twoDemo {
    width: 89.3333%;
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .boxLimt {
      width: 48.59%;
      height: 158px;
      flex-shrink: 0;
      border-radius: 10px;
      background: linear-gradient(180deg,
          rgba(223, 120, 83, 0) 59.5%,
          #cd3043 100%);
      padding: 10px 15px;
      display: flex;
      align-content: end;
      flex-wrap: wrap;

      .title {
        color: #fff;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        text-align: left;
      }

      .tiops {
        color: #fff;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        text-align: left;
        width: 100%;
        margin-top: 5px;
      }
    }

    .titger {
      background-image: url("../assets/home/home-titger.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .shork {
      background-image: url("../assets/home/home-shork.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

.placeholder {
  height: 55px;
  /* 导航栏高度 */
}

.navbar {
  width: 100%;
}

.fixDemo {
  position: fixed;
  right: 5.3335%;
  bottom: 60px;
  z-index: 10;

  .conDown {
    width: 56px;
    height: 56px;
    //flex-shrink: 0;
    //border-radius: 4px;
    // border: 0.05px solid #FFF;
    //background: linear-gradient(270deg, rgba(35, 70, 108, 0.80) 0%, rgba(24, 34, 42, 0.80) 100%);
    //backdrop-filter: blur(2px);
    //padding-top: 7px;
    //display: flex;
    //justify-content: center;
    //flex-wrap: wrap;

    img {
      width: 32px;
      height: 32px;
    }

    .fixname {
      width: 100%;
      text-align: center;
      text-shadow: 0px 4px 8px rgba(71, 117, 255, 0.25);
      font-family: "SF Pro Display";
      font-size: 10px;
      font-style: normal;
      color: #fff;
      font-weight: 400;
      line-height: 150%;
      /* 15px */
      text-transform: uppercase;
    }
  }

  .conTop {
    background-image: url("@/assets/home/homeKf.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 10px;

    .fixname {
      // margin-top: -3px;
    }
  }

  .downAppBj {
    background-image: url("@/assets/home/homeApp.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.qsryxmc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  gap: 10px;
  flex-shrink: 0;
  padding: 0px 10px;
  border-radius: 8px;
  border-bottom: 1px solid rgba(155, 155, 186, 0.04);
  background: radial-gradient(200% 100% at 0% 0%, rgba(0, 0, 0, 0.16) 31.69%, rgba(0, 0, 0, 0.04) 100%);
  width: 92%;
  margin: 10px auto 0 auto;
  color: rgba(179, 185, 210, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 14px;
  height: 36px;

  .cearch-ss {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.ggOut {
  position: relative;
  width: 100%;
  margin: 5px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  // border: 1px solid rgba(155, 155, 186, 0.04);
  // background: radial-gradient(114.24% 141.42% at 0% 0%, rgba(155, 155, 186, 0.16) 31.69%, rgba(155, 155, 186, 0.04) 100%);
  padding: 0px 10px;
  gap: 5px;

  .ggImg {
    // position: absolute;
    // left: 0;
    // top: 0;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .announcement-container {
    width: calc(100% - 20px);
    /* 容器宽度 */

    overflow: hidden;
    /* 隐藏溢出的部分 */

    color: #7D84A2;
    /* 文字颜色 */
    font-size: 11px;
    /* 字体大小 */
    position: relative;
    font-family: PingFang SC;

    .announcement-content {

      display: inline-block;
      /* 让内容横向排列 */
      white-space: nowrap;
      /* 防止文本换行 */
      animation: scroll-left 100s linear infinite;
      /* 动画效果 */
    }


  }

  .ggIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 0 4px;
    border-radius: 99px 0px 0px 99px;
    background: var(--Linear, linear-gradient(92deg, #0385FF 0%, #0359FF 100%));
    color: #FFF;
    height: 17px;
    text-align: center;
    text-overflow: ellipsis;
    font-family: "PingFang SC";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

    .rmImg {
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}




.placeholder-tabs {
  width: 100%;
  // height: 38px;
  margin: 10px auto 8px auto;
  position: sticky;
  top: 48px;
  z-index: 3;
  transition: all 0.5s;

  .tabOut {
    width: 100%;
    // height: 38px;

  }

  .tabs {
    width: 100%;
    // margin: 0 auto 0 auto;
    padding: 0 10px;

    .wrapper {
      width: 100%;
      // height: 38px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      // border-radius: 24px 0px 0px 24px;
      // background: #15212d;
      border-radius: 12px 0 0 12px;
      // border: 1px solid rgba(155, 155, 186, 0.09);

      // padding: 5px 8px;
      background: #9B9BBA0A;
      gap: 6px;

      // padding: 0 5px;
      // transition: all 0.5s ease;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

      // white-space: nowrap;
      .item {
        transition: all 0.5s ease;
        // min-width: 79px;
        width: 75px;
        display: flex;
        height: 38px;
        // padding: 4px 10px;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        border-radius: 12px;
        gap: 5px;
        //   font-family: PingFang SC;
        //   font-size: 0.37333rem;
        //   font-style: normal;
        //   font-weight: 400;
        //   line-height: 120%;
        flex-shrink: 0;
        //   color: #fff;
        color: #B3B9D2;
        font-family: "PingFang SC";
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* 21px */
        // border: 1px solid rgba(155, 155, 186, 0.04);
        box-sizing: border-box;
        // background: radial-gradient(200% 100% at 0% 0%, rgba(155, 155, 186, 0.16) 31.69%, rgba(155, 155, 186, 0.04) 100%);

        &.active {
          // color: #f3b867;
          // font-family: PingFang SC;
          // font-size: 0.37333rem;
          // font-style: normal;
          // font-weight: 600;
          // line-height: 120%;
          white-space: nowrap;
          display: flex;
          height: 38px;
          // padding: 4px 15px;
          // padding: 4px 15px;
          align-items: center;
          gap: 5px;
          // border: 1px solid rgba(255, 255, 255, 0.04);
          box-sizing: border-box;
          background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
          color: #ffffff;
        }
      }

      .tabImg {
        width: 16px;
        height: 16px;
      }
    }


  }

  ::-webkit-scrollbar {
    /* width: 3px; */
    display: none;
    /* 设置滚动条的宽度 */
  }
}

.tab-fixed {
  position: fixed;
  top: 55px;
  left: 0;
  z-index: 999;
  /* 确保导航栏位于最顶层 */
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* 添加阴影效果 */
}

.dbOut {
  width: 100%;

  margin-top: 40px;
  // background-color: #15212d;
  // background-color: rgb(51,61,75);
}

.dbdemo {
  display: flex;
  align-items: center;
  width: 89.3333%;
  margin: 0 auto 0 auto;

  .dbLeft {
    width: 162px;

    .dbTit {
      text-align: left;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 14px */
    }

    .dbImghzhb {
      margin-top: 11px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .flba {
        width: 102px;
        height: 48px;
      }

      .guracao {
        width: 114px;
        height: 42px;
      }
    }
  }
}

.yxtgs {
  width: 89.3333%;
  margin: 20px auto 0 auto;

  .dbTit {
    text-align: left;
    color: #fff;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 14px */
  }

  .tgsImgDemo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .pragmatic {
      width: 110px;
      height: 48px;
    }

    .pgsoft {
      width: 119.467px;
      height: 28px;
      margin-left: 10px;
    }
  }
}

.fwtk {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 89.3333%;
  margin: 0px auto 20px auto;

  div {
    color: #5695e8;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 14px */
    // margin-right: 20px;
    margin: 0 10px;
    white-space: nowrap;
  }
}

.logos {
  margin: 0 auto 15px auto;
  width: 115.788px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.copyright {
  text-align: center;
  width: 89.3333%;
  margin: 0 auto;
  color: #b1bad3;

  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 12px */
}

.gcs {
  width: 91.733333%;
  margin: 15px auto 0 auto;
  color: #b1bad3;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
}

.dbImg {
  width: 93.5px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.gameWork {
  margin-top: 10px;

  .workImg {
    width: 89.3333%;
  }
}

.notPop {
  background: transparent;
  padding-top: 32px;

  .notIn {
    width: 280px;
    min-height: 320px;
    flex-shrink: 0;
    border-radius: 20px;
    background: linear-gradient(203deg, #a86a25 -30.44%, @allBj 65.13%);
    position: relative;
    padding: 40px 20px;

    .closeNot {
      position: absolute;
      top: -32px;
      width: 24px;
      height: 24px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 8;

      img {
        width: 100%;
        height: 100%;
        z-index: 8;
      }
    }

    .jiaob {
      position: absolute;
      top: -40px;
      width: 134px;
      height: 152px;
      right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;

      img {
        z-index: 0;
        width: 100%;
        height: 100%;
      }
    }

    .notTitle {
      color: #fff;
      font-family: "Microsoft YaHei UI";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      /* 24px */
      text-align: left;
    }

    .notTips {
      color: #fff;
      font-family: "Microsoft YaHei UI";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 19.2px */
      text-align: left;
      margin-top: 40px;
    }

    .notDetail {
      color: #82889b;
      font-family: "Microsoft YaHei UI";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 21px */
      text-align: left;
      margin-top: 15px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .notBomTips {
      color: #82889b;
      font-family: "Microsoft YaHei UI";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      /* 16.8px */
      text-align: left;
      margin-top: 30px;
    }
  }
}

.yumPop {

  background: transparent;
  width: 100%;
  // height: 100dvh;
  // position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  .yumOut {
    // padding: 37px 16px;
    position: relative;

    .jiaob {

      width: 169px !important;
      height: 159px !important;
      top: -48px;
      right: -20px;

      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      img {
        z-index: 0;
        width: 100%;
        height: 100%;
      }
    }

    .closeYum {
      position: absolute;
      top: -48px;
      width: 24px;
      height: 24px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 8;

      img {
        width: 100%;
        height: 100%;
        z-index: 8;
      }
    }

    .notIn {
      background: linear-gradient(203deg, #2964CC -30.44%, @allBj 65.13%) !important;
      width: 280px;

      flex-shrink: 0;
      border-radius: 20px;
      position: relative;
      padding: 40px 20px 15px;


      .notTitle {
        color: #fff;
        font-family: "Microsoft YaHei UI";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 24px */
        text-align: left;
      }

      .notTips {
        color: #fff;
        font-family: "Microsoft YaHei UI";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
        text-align: left;
        margin-top: 6px;
      }

      .linkOut {
        display: flex;
        justify-content: flex-start;
        margin-top: 15px;
        gap: 10px;
        flex-wrap: wrap;
        width: 100%;

      }

      .qnljyxgfym {
        color: #82889B;
        margin: 10px 0 15px;
        font-family: "Microsoft YaHei UI";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-align: left;
      }

      .linkLine {
        border-radius: 4px;
        background: #15212D;
        display: flex;
        width: fit-content;
        padding: 5px 10px;
        align-items: center;
        gap: 5px;
        justify-content: flex-start;
        color: #1894FF;
        height: 27px;
        font-family: "Microsoft YaHei UI";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;

        img {
          width: 12px;
          height: 12px;
          flex-shrink: 0;
        }
      }

      .notDetail {
        color: #82889b;
        font-family: "Microsoft YaHei UI";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
        text-align: left;
        margin-top: 15px;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .ymBomTips {
        color: #82889b;
        font-family: "Microsoft YaHei UI";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 16.8px */
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .yjbctp {
        border-radius: 8px;
        background: linear-gradient(90deg, rgba(71, 116, 218, 0.20) 0%, rgba(0, 68, 222, 0.20) 100%);
        margin-top: 22px;

        height: 36px;
        width: 100%;
        border: 0;
        color: #FFF;
        line-height: 36px;
        color: #FFF;

        font-family: "Microsoft YaHei UI";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  .popupview {
    .popup {
      border-radius: 10px;
      background: #182431;
      width: 342px;
      padding: 10px 15px 15px;
      box-sizing: border-box;

      .gonggao {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 3px;

        div {
          display: flex;
          align-items: center;
        }

        span {
          margin-left: 6px;
          color: #FFF;
          font-size: 16px;
          line-height: 16px;
        }

        svg {
          position: relative;
          // top: 2px;
          width: 14px;
          height: 14px;
        }
      }

      .closeYum {
        width: 28px;
        height: 28px;

        svg {
          width: 28px;
          height: 28px;
        }
      }
    }

    .closeYum1 {
      // width: 20px;
      margin-top: 15px;
      height: 26px;

      img {
        margin-right: 3px;
        width: 26px;
        height: 26px;
      }

      // width: 36px;
      // height: 36px;
      // margin-top: 10px;
      // margin: 10px auto 0;
    }

    ::v-deep .van-tabs {
      .van-tabs__wrap {
        border-radius: 90px;
        background: #101c29;
        // width: 100%;
        padding: 5px;
        margin: 10px 0;
        height: 50px;
      }

      .van-tabs__nav {
        background: none;
        color: #7D84A2;
        padding-left: 0;
        padding-right: 0;
        // height: 46px;
      }

      .van-tab__text--ellipsis {
        white-space: nowrap;
        overflow: visible;
      }

      .van-tab--active {
        border-radius: 99px;
        background: #293C4A;
        color: #fff;
        height: 100%;
      }

      .van-tabs__line {
        display: none;
      }

      img {
        width: 100%;
        border-radius: 10px;
        height: 300px;
      }
    }
  }

}

.downPop {
  background: transparent;
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;

  .downOut {
    padding: 64px 10px;
  }

  .downIn {
    width: 244px;
    height: auto;

    // background: linear-gradient(203.45deg, rgba(117, 124, 237, 1) -30.44%, rgba(32, 44, 55, 1) 65.68%);
    background: linear-gradient(62deg, rgba(32, 44, 55, 0.60) 31.52%, rgba(41, 100, 204, 0.60) 155.78%);

    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 20px;
    border: 1px solid #0E4373;
    padding: 43px 9px 33px;
    position: relative;

    .huoji {
      width: 168px;
      height: 168px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: -64px;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .downClose {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .downLogo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 10px auto;

      img {}
    }

    .dwon-tit-big {
      color: #FFF;

      text-align: center;
      font-family: "Microsoft YaHei UI";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .dwon-tit {
      align-items: center;
      color: rgba(255, 255, 255, 1);
      font-family: "Microsoft YaHei UI";
      font-weight: 700;
      font-size: 16px;

    }

    .goDown {
      width: 128px;
      height: 36px;

      gap: 10px;
      flex-shrink: 0;
      padding: 0px 10px;
      border-radius: 22px;
      background: linear-gradient(90deg, #4774DA 0%, #0044DE 100%);
      align-items: center;
      color: rgba(255, 255, 255, 1);
      font-family: "Microsoft YaHei UI";
      font-weight: 400;
      font-size: 16px;
      margin-top: 13px;
      border: 0;
      letter-spacing: 1px;
      position: absolute;
      bottom: -18px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .bztx {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.5px;
    color: rgba(130, 136, 155, 1);
    font-family: "Microsoft YaHei UI";
    font-weight: 400;
    font-size: 14px;
    margin-top: 14px;


  }
}

/* 定义横向滚动的动画 */
@keyframes scroll-left {
  0% {
    transform: translateX(10.1dvw);
    /* 从右边开始 */
  }

  100% {
    transform: translateX(-100%);
    /* 滚动到左边 */
  }
}

.mt15 {
  margin-top: 15px;
}

.mt6 {
  margin-top: 6px;
}

.sticky {
  background: @allBj;

}

.isDownTop {
  top: 96px;
}
.isDz{
  margin-bottom: 5px!important;
}
</style>