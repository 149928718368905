<template>
  <div class="headOut">
    <div class="homeHeader" :class="{ 'navbar-fixed': isHome, toptop: downShow&&isLogin }" :style="{
      'background': isHome ? 'rgba(25,48,69)' : 'rgba(25,48,69)',
      'box-shadow': nowBom ? '0px 0px 0px 0px!important' : '0px 4px 4px 0px rgba(0, 0, 0, 0.2)',
    }">
      <!--        v-if="isHome"                                               linear-gradient(0deg, #0B0A12 30%, #152F43 70%) -->
      <div class="headLeft">
        <img src="../assets/logoGif.gif" />
    
      </div>
      <!-- <div class="homeBtn" v-if="!isHome" @click="goHome">
        <div class="btnImg"><img src="../assets/home/homeIcon.svg" /></div>
        <div>首页</div>
      </div> -->
      <div class="headRight" v-show="!isLogin">
        <div class="headBtn" @click="setLoginShow(true)">登录</div>
        <div class="loginBtn" @click="setRegisterShow(true)">注册</div>


        <!-- @click="initShow = true" -->
      </div>
      <div class="headRight" v-show="isLogin">
        <div class="headIn">
          <div class="inLeft">
            <img class="redY" src="../assets/home/head-yy.svg" />
            <div>
              {{ balanceOne }}.<span class="hlw">{{ balanceTwo }}</span>
              <!-- <span class="hlw">00</span> -->

            </div>
          </div>
          <div class="shu"></div>
          <div class="inRight" @click="$router.push('/deposit')">存款</div>
        </div>
        <!-- <div class="mnusBtn" @click="initShow = true">
                    <img src="../assets/home/head-mnus.svg" />
                </div> -->
        <!-- <div class="service" @click="$store.commit('goService')">
          <img src="@/assets/home/homeService.svg" />
        </div> -->
      </div>
    </div>
    <van-popup v-model="initShow" position="bottom">
      <div class="tsDemo">
        <div class="tiao"></div>
        <div class="tsOne">
          <div class="oneLeft">
            <div class="userImg">
              <img src="../assets/headUser.svg" />
            </div>
            <div class="loginTs" v-show="!isLogin">点击登录/注册</div>
            <div class="loginTs" v-show="isLogin">
              <div class="headName">{{ userData.username }}</div>
              <div class="headJR">加入PANDA第{{ userData.registerDay }}天</div>
            </div>
          </div>
          <div class="oneRight">
            <div class="btn" @click="goPage('/setup')">
              <img src="../assets/home/head-set.svg" />
              安全
            </div>
            <div class="btn" @click="goPage('/info/index')">
              <img src="../assets/home/head-xx.svg" />
              消息
            </div>
          </div>
        </div>

        <div class="zzct">
          <div class="zzcLeft">
            <div class="zzcTit">总资产</div>
            <div class="dlhck" v-show="!isLogin">登录后查看</div>
            <div class="moneyHan" v-show="isLogin">
              <div class="yag">￥</div>
              <div class="number" v-show="showBalance">
                {{ balanceOne }}.<span>{{ balanceTwo }}</span>
              </div>
              <div class="number" v-show="!showBalance">****</div>
              <div class="eyes" @click="showBalance = !showBalance">
                <img v-show="showBalance" src="../assets/home/head-eye.svg" />
                <img v-show="!showBalance" src="../assets/pwd-colse-eye.svg" />
              </div>
              <div class="eyes" @click="init()">
                <img class="reload" :class="{ rotate: getUserLoading }" src="../assets/home/head-sx.svg" />
              </div>
            </div>
          </div>
          <div class="zzcRight">
            <div class="btn" @click="$router.push('/deposit')">
              <div class="btnImg"><img src="../assets/home/head-ck.svg" /></div>
              存款
            </div>
            <div class="btn" @click="$router.push('/withdrawals')">
              <div class="btnImg"><img src="../assets/home/head-qk.svg" /></div>
              取款
            </div>
          </div>
        </div>
        <div class="tsList">
          <div class="tsDem" @click="$router.push('/record')">
            <div class="listImg">
              <img src="../assets/home/head-Record.svg" />
            </div>
            交易记录
          </div>
          <div class="tsDem" @click="$router.push('/record/postRecord')">
            <div class="listImg">
              <img src="../assets/home/head-Record1.svg" />
            </div>
            投注记录
          </div>
          <div class="tsDem" @click="$router.push('/account/index')">
            <div class="listImg">
              <img src="../assets/home/head-Record2.svg" />
            </div>
            账户管理
          </div>
          <div class="tsDem" @click="$store.commit('goService')">
            <div class="listImg">
              <img class="toBig" src="../assets/home/head-Record3.svg" />
            </div>
            在线客服
          </div>
        </div>
        <div class="tsBom">
          <div class="bomDemo" @click="$router.push('/share/index')">
            <img class="bomImg" src="../assets/home/head-fx.png" />
            分享赚钱
          </div>

          <!-- $router.push('/welfare/index') -->
          <div class="bomDemo flzx" @click="$router.push('/details')">
            <img class="bomImg" src="../assets/home/head-fl.png" />
            福利中心
          </div>
        </div>

        <van-button class="cloLogBtn" :disabled="subLoading" :loading="subLoading" @click="submit">
          <div class="btnIn">
            <img src="../assets/home/home-loginOut.svg" /> 退出登录
          </div>
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { EventBus } from '@/unit/event-bus'; 
import { mapActions } from 'vuex';
import _ from 'lodash';
import {
  userInfo,
  syslogout,
  platformEeturn_recovery,
  platformBalance,
} from "@/request/api";
export default {
  name: "homeHeader",
  props: {
    msg: String,
    isHome: Boolean,
    nowBom: Boolean,
    downShow: Boolean,
    isNewHome: Boolean,
  },
  data() {
    return {
      isLogin: false,
      initShow: false,
      userData: {},
      balanceOne: 0,
      balanceTwo: "00",
      showBalance: true,
      getUserLoading: false,
      subLoading: false,
      timer: null,
      autoUpdateTimer: null,
    };
  },
  // watch:{
  //   downShow(newValue){
  //     console.log(newValue)
  //     // this.downShow = newValue
  //   },
  // },
  methods: {
    ...mapActions(['setLoginShow', 'setRegisterShow']),
    getPlatformEeturn_recovery() {
      platformEeturn_recovery().then((res) => {
        // this.getPlatformBalance();
        this.getUserInfo()

        if (res.code == 0) {
          // this.getPlatformBalance();
          // this.getUserInfo()
        } else {
          // this.getPlatformEeturn_recovery()
        }
      });
    },
    submit() {
      this.subLoading = true;
      syslogout().then((res) => {
        if (res.code == 0) {
          this.$store.commit('clearLocal')
          // localStorage.removeItem("token");
          // localStorage.removeItem("downShow");
          this.$notify({
            message: "退出成功",
            duration: 2000,
            background: "#1989fa",
          });
          if (this.$route.path == "/") {
            this.$router.go(0);
            return;
          }
          this.$router.push("/");
        }
        this.subLoading = false;
      });
    },
    goHome() {
      if (this.$route.path == "/") {
        return;
      }
      this.$router.push("/");
    },
    goPage(e) {
      this.initShow = true;
      this.$router.push(e);
    },
    handleLocalStorageChange(event) {
      if (event.key === "token") {
        this.isLogin = event.newValue;
        //this.localStorageItem = event.newValue;
      }
    },
    getUserInfo() {
      if (this.getUserLoading) {
        return;
      }
      this.getUserLoading = true;

      userInfo().then((res) => {
        if (res.code == 0) {
          // var balan = res.data.balance.toFixed(2);
          var balan = (Math.floor(res.data.balance * 100) / 100).toFixed(2);
          var dotIndex = balan.indexOf(".");
          if (dotIndex == -1) {
            res.data.balance = `${res.data.balance}.00`;
          }
          // this.balanceOne = balan.slice(0, dotIndex + 1);
          // this.balanceTwo = balan.slice(dotIndex + 1);
          sessionStorage.setItem('userinfo', JSON.stringify(res.data))
          this.balanceOne = balan.slice(0, dotIndex);
          this.balanceTwo = balan.slice(dotIndex + 1);
          this.userData = res.data;
        }
        this.getUserLoading = false;
      });
    },
    getPlatformBalance() {
      platformBalance().then((res) => {
        console.log(res)
        if (res.code == 0) {
          var balan = (res.data.balance + res.data.freeze + res.data.totalAmount).toFixed(2);
          if (balan.indexOf(".") == -1) {
            balan = `${balan}.00`;
          }
          var dotIndex = balan.indexOf(".");
          this.balanceOne = balan.slice(0, dotIndex + 1);
          this.balanceTwo = balan.slice(dotIndex + 1);
        }
      })
    },
    init() {

      if (this.isNewHome) {
        this.getPlatformEeturn_recovery();
      } else {
        // this.getPlatformBalance();
        this.getUserInfo();

      }
    },
    throttledGetUserInfo: _.throttle(function () {
      if (localStorage.getItem("token")) {
        this.getUserInfo();
      }

    }, 1000),
    startAutoUpdate() {
      if (this.autoUpdateTimer) {
        clearInterval(this.autoUpdateTimer);
      }
      this.autoUpdateTimer = setInterval(() => {
        if (this.$route.path != '/block') {
          this.throttledGetUserInfo();
        }

      }, 10000);
    },
    stopAutoUpdate() {
      if (this.autoUpdateTimer) {
        clearInterval(this.autoUpdateTimer);
        this.autoUpdateTimer = null;
      }
    },
    handleTokenChange(newToken) {
      console.log('Token has been updated:', newToken);
      this.isLogin = localStorage.getItem("token");
    if (this.isLogin) {
      this.init();
      this.startAutoUpdate();
    }
    }
  },

  mounted() {
    this.isLogin = localStorage.getItem("token");
    if (this.isLogin) {
      this.init();
      this.startAutoUpdate();
    }

    window.addEventListener("storage", this.handleLocalStorageChange);
    EventBus.$on('tokenChanged', this.handleTokenChange);
  },
  beforeDestroy() {
    // Cleanup: Remove the event listener when component is destroyed
    window.removeEventListener("storage", this.handleLocalStorageChange);
    this.stopAutoUpdate();
    clearInterval(this.timer);
    if (this.throttledGetUserInfo && this.throttledGetUserInfo.cancel) {
      this.throttledGetUserInfo.cancel();
    }
    EventBus.$off('tokenChanged', this.handleTokenChange);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.headOut {
  height: 48px;
}

.reload {
  &.rotate {
    animation: spin 1s linear infinite;
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.homeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  //background: linear-gradient(0deg, #0B0A12 30%, #152F43 70%);
  width: 100%;
  padding: 0 10px;
  position: relative;
  z-index: 3;

  .homeBtn {
    border-radius: 8px;
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(180deg, #12283f 0%, #0d0f19 100%);

    width: 72px;
    height: 28px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.85);
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    /* 14px */
    .btnImg {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3px;
      margin-left: -5px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .headLeft {
    display: flex;
    align-items: center;
    width: 102px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
    }

    .logoLeft {
      margin-right: 3px;
      width: 28px;
      height: 28px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .logoRight {
      width: 90px;
      height: 19px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .headRight {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    position: absolute;
    right: 15px;
    bottom:4px;

    .headBtn {
      display: inline-flex;
      width: 68px;
      height: 41px;
      border-radius: 6px;
      margin-right: 5px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;

      //border: 0.1px solid rgba(255, 255, 255, 0.30);

      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 14px;
      line-height: 41px;

    }

    .loginBtn {
      width: 68px;
      height: 41px;
      border-radius: 6px;
      // background: rgba(71, 116, 218, 1);
      color: rgba(255, 255, 255, 1);
      background: linear-gradient(92deg, #0385FF 0%, #0359FF 100%);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 14px;
      line-height: 41px;
      /* 24px */
    }

    .headIn {
      display: flex;
      align-items: center;

      // border: 0.5px solid rgba(255, 255, 255, 0.10);
      border-radius: 36px;

      position: relative;
      // background: linear-gradient(90deg, #263E74 0%, #3659A7 50%, #263E74 100%);
      background: linear-gradient(180deg, rgba(38, 62, 116, 0.20) 0%, rgba(54, 89, 167, 0.10) 50%, rgba(38, 62, 116, 0.00) 100%);

      .inLeft {
        min-width: 80px;
        height: 32px;
        // border-radius: 14px 0px 0px 14px;
        // background: #202c37;
        background-clip: padding-box;
        /*important*/
        // background: linear-gradient(180deg, #12283F 0%, #0D0F19 100%);
        padding: 0 10px;
        display: flex;
        align-items: center;
        color: #fff;
        text-align: right;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 16.8px */
        justify-content: space-evenly;

        .redY {
          width: 28px;
          height: 32px;
          fill: #ff3c44;
          filter: drop-shadow(0px 8px 12px rgba(255, 60, 68, 0.4));
          margin: 6px 0 0 -7px;
        }

        .hlw {
          color: rgba(255, 255, 255, 0.45);
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }

      .shu {
        width: 1px;
        height: 16px;
        background: #144068;
      }

      .inRight {
        // background: #202c37;
        background-clip: padding-box;
        /*important*/

        height: 32px;
        padding: 5px 10px 5px 8px;
        // border-radius: 0px 14px 14px 0px;
        margin-left: 1px;
        // border-left: 0.5px solid rgba(255, 255, 255, 0.1);
        // background: linear-gradient(180deg, #12283F 0%, #0D0F19 100%);
        color: #fff;
        text-align: right;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 14px */
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        span {
          height: 6px;
          margin-left: -3px;
          margin-top: -2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .headIn::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      margin: -0.5px 0 1px 0;
      // border-radius: inherit; /*important*/
      // background: linear-gradient(
      //   180deg,
      //   rgba(255, 255, 255, 0.1) 0%,
      //   rgba(102, 102, 102, 0) 100%
      // );

      border-radius: 36px;
      border-top: 0.5px solid #144068;
      // background: #144068;
    }

    .mnusBtn {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 0.5px solid rgba(255, 255, 255, 0.05);
      background: linear-gradient(180deg, #12283f 0%, #0d0f19 100%);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .service {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.toptop {
  top: 48px !important;
}

.tsDemo {
  width: 100%;
  height: 415px;
  //max-height: 60vh;;
  flex-shrink: 0;
  border-radius: 20px 20px 0px 0px;
  background: rgba(24, 35, 39, 0.6);
  backdrop-filter: blur(2px);
  padding-top: 10px;

  .tiao {
    width: 80px;
    height: 4px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #283e4c;
    margin: 0 auto;
  }

  .tsOne {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 92%;
    margin: 20px auto 0 auto;

    .oneLeft {
      display: flex;
      align-items: center;

      .userImg {
        width: 48px;
        height: 48px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .loginTs {
        color: #fff;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
        margin-left: 12px;

        .headName {
          color: #fff;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          text-align: left;
          font-weight: 500;
          line-height: 150%;
          /* 21px */
          margin-bottom: 5px;
        }

        .headJR {
          color: #fff;
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-align: left;
          /* 15px */
        }
      }
    }

    .oneRight {
      display: flex;
      align-items: center;

      .btn {
        display: flex;
        align-items: center;
        color: #fff;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
        margin-left: 20px;

        img {
          margin-right: 3px;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }
      }
    }
  }

  .zzct {
    margin: 15px auto 0 auto;
    width: 351px;
    height: 65px;
    flex-shrink: 0;
    border-radius: 10px;
    background: linear-gradient(90deg, #283e4c 0%, #192f37 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    .zzcLeft {
      .zzcTit {
        color: #fff;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 18px */
        text-align: left;
        margin-bottom: 4px;
      }

      .dlhck {
        color: rgba(255, 255, 255, 0.85);

        /* 内容/M-大多数文字 */
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 21px */
      }

      .moneyHan {
        display: flex;
        align-items: center;

        .yag {
          color: #fff;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          /* 21px */
        }

        .number {
          color: var(---100, #fff);
          font-family: DIN;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;

          /* 21.6px */
          span {
            color: rgba(255, 255, 255, 0.45);
          }
        }

        .eyes {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .zzcRight {
      display: flex;
      align-items: center;

      .btn {
        color: #fff;
        font-family: "PingFang SC";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 15px */
        width: 40px;
        height: 36px;
        flex-shrink: 0;
        border-radius: 12px;
        border: 0.5px solid rgba(255, 255, 255, 0.1);
        background: linear-gradient(180deg, #39657b 0%, #1b313a 100%);
        margin-left: 10px;
        padding: 3px 0;

        .btnImg {
          width: 16px;
          height: 16px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .tsList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 82%;
    margin: 25px auto 0 auto;

    .tsDem {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 15px */

      .listImg {
        background: rgba(22, 103, 224, 0.1);
        border-radius: 50%;
        fill: rgba(22, 103, 224, 0.1);
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 5px auto;

        img {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
        }

        .toBig {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .tsBom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94%;
    margin: 20px auto 0 auto;

    .bomDemo {
      display: flex;
      align-items: center;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      /* 18px */
      border-radius: 12px;
      background: linear-gradient(270deg,
          rgba(29, 149, 228, 0) 0%,
          #005790 100%);
      width: 171px;
      height: 65px;
      flex-shrink: 0;
      padding-left: 16px;

      .bomImg {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        margin-right: 11px;
      }
    }

    .flzx {
      background: linear-gradient(270deg,
          rgba(199, 111, 32, 0) 0%,
          #c76f20 100%);
    }
  }

  .cloLogBtn {
    width: 94%;
    margin: 20px auto 0 auto;
    display: flex;
    border-radius: 8px;
    border: 0;
    background: rgba(255, 255, 255, 0); //0.04
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    height: 20px;
    line-height: 20px;

    /* 内容/M-大多数文字 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    .btnIn {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
}

.navbar-fixed {
  position: fixed;
  top: 0;
  // left: 0;
  z-index: 999;
  /* 确保导航栏位于最顶层 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* 添加阴影效果 */
}

/deep/.van-popup {
  background: rgba(24, 35, 39, 0.6);
  border-radius: 20px 20px 0px 0px;
}
</style>
