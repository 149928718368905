<template>
  <div class="homeDemo" :style="{ height: isFold ? '38px' : 'auto' }">
    <!-- <van-swipe
        class="my-swipeTwo"
        v-if="name == '体育'"
        :autoplay="3000"
        :show-indicators="false"
        :vertical="true"
        :touchable="false"
      >
        <van-swipe-item>
          <img class="lazyImg" src="@/assets/home/tab/sportSmall0.webp" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="lazyImg" src="@/assets/home/tab/sportSmall1.webp" />
        </van-swipe-item>
      </van-swipe> -->

    <!-- <div class="titleOut">体育投注</div> -->
    <div class="fold" @click="isFold = !isFold" ref="scrollTarget" v-if="false">
      <div class="foldLeft">
        <img :src="icon" />
        {{ name }}
      </div>

      <div @click.stop="$router.push('/sport')" class="lazyImg">
        <img v-if="name == '体育'" src="@/assets/home/tab/sportSmall0.webp" />
      </div>


      <div class="foldRight">
        <img src="@/assets/home/arrow.svg" :style="{ transform: isFold ? 'rotate(180deg)' : 'rotate(0deg)' }" />
      </div>
    </div>
    <!-- <div class="zjc" v-if=" name == '电子'"><img src="@/assets/home/zjc.png" /></div> -->
    <!-- <scroll-number :value="num.toString()" v-if=" name == '电子'"></scroll-number> -->
    <div class="numOut" v-if="name == '电子'">
      <div class="zjj"></div>
      <div class="digOut" v-for="(item, index) in numberList" :key="index" :class="{isDi:item === '.' || item === ','}">
        <div class="diand" v-show="item === '.' || item === ','" ></div>
        <DigitRoll v-show="!(item === '.' || item === ',')" :rollDigits="Number(item)" :key="digitKey" />
      </div>
      
    </div>
    <!-- 体育 -->
    <div class="" v-if="
      name == '体育' || name == '电子' || name == '区块链' || name == '电竞' || name == '彩票' || name == '真人' || name == '棋牌' || name == '捕鱼'
    ">
      <!-- :style="{
          backgroundImage: `url(${item.h5Image})`,
          backgroundSize: '100% 100%',
        }" -->
      <div class="widthDemo" v-for="(item, index) in list" :key="index" @click="goPage(item)"
        v-show="item.status != 2 && item.id != 10" :ref="'boxs' + index" @touchstart="handleTouchStart(index, $event)"
        @touchend="handleTouchEnd(index)">
        <img class="imgIn" :src="item.h5Image" />
        <div class="maintainDemo" v-show="item.status != 0">
          <img src="@/assets/home/tab/homeWhz.svg" />{{
            item.status | getStatus
          }}
        </div>
      </div>
    </div>

    <div class="smailOut" v-else :class="{ zerenOut: name == '真人' }">
      <div class="smailList" v-for="(item, index) in list" :key="index" @click="goPage(item)"
        :class="{ caicaic: name == '彩票', zeren: name == '真人' }" :style="{
          backgroundImage: `url(${item.h5Image})`,
          backgroundSize: '100% 100%',
        }" v-show="item.status != 2" :ref="'myboxs' + index" @touchstart="handleMyTouchStart(index)"
        @touchend="handleMyTouchEnd(index)">
        <!-- <div class="smailBj" :style="{'backgroundImage': `url(${item.image})` }"> -->
        <div class="smailMaintainDemo" v-show="item.status != 0">
          <img src="@/assets/home/tab/homeWhz.svg" />{{
            item.status | getStatus
          }}
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { pdgameLaunch, platformLogin } from "@/request/api";
// import ScrollNumber from "./ScrollNumber.vue";
import DigitRoll from '@huoyu/vue-digitroll';
export default {
  name: "sportsDemo",
  props: ["list", "name", "icon", "tabs"],
  components: {
    //ScrollNumber
    DigitRoll
  },
  data() {
    return {
      isFold: false,
      num: 154960543.12,
      digitKey: 0,
    };
  },
  computed:{
             numberList() {
    let strValue = String(this.num);
    // 处理小数点和每三位数字加一个分隔符
    let [integer, decimal] = strValue.split('.');

    // 给整数部分每三位添加一个分隔符
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // 确保小数部分有两位，不足的补充0
    if (decimal) {
      // 如果有小数部分，但小于两位，则补充0
      decimal = decimal.length === 1 ? decimal + '0' : decimal;
    } else {
      // 如果没有小数部分，补充00
      decimal = '00';
    }

    // 返回合并后的数组
    return [...integer.split(''), '.', ...decimal.split('')];
  },
  },
  methods: {
    ...mapActions(['setLoginShow', 'setRegisterShow']),
    setFold() {
      this.isFold = false;
    },
    setKey() {
      this.digitKey += 1;
    },
    goPage(item) {

      if (item.status != 0) {
        return;
      }

      if (!localStorage.getItem("token")) {
        // if (localStorage.getItem("userYou") && localStorage.getItem("userYou") == "tourists") {

        if (localStorage.getItem('loginStatus')) {
          this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setLoginShow(true)
          return;
        } else {
          this.$notify({
            message: "请注册后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setRegisterShow(true)
          return;
        }
      }

      if (item.launchCode) {

        this.$store.commit("setLoadingShowTrue");
        platformLogin({ code: item.launchCode, id: item.id }).then((res) => {
          if (res.code == 0) {
            this.$store.commit("setUrl", res.data.url);

            if (item.name == "VR彩票" || item.name == "WE捕鱼") {
              window.open(res.data.url, "_blank");
            } else {
              this.$router.push({
                path: "/block",
                query: { link: "/", tabs: this.tabs, url: res.data.url },
              });
            }
            setTimeout(() => {
              this.$store.commit("setLoadingShowFalse");
            }, 5000);
          } else {
            this.$store.commit("setLoadingShowFalse");
          }
        });
      } else {
        if (this.name != '电子') {
          var regex = /we/i;
          if (!regex.test(item.name)) {
            console.log("no");
          } else {
            console.log("we");
            this.oldSports(item);

            return;
          }
        }


        this.$router.push({
          path: "/classIndex",
          query: {
            list: JSON.stringify(item),
            id: item.id,
            name: this.name,
            tabs: this.tabs,
          },
        });
      }
    },

    oldSports(item) {
      if (item.status != 0) {
        return;
      }
      var regex = /we/i;
      var opt = {};
      if (item.name == "SABA") {
        //saba单独走
        opt = {
          code: item.launchCode ? item.launchCode : item.code,
        };
      } else if (!regex.test(item.name)) {
        if (item.gameCount > 0) {
          this.$router.push({
            path: "/classIndex",
            query: {
              list: JSON.stringify(item),
              id: item.id,
              name: this.name,
              tabs: this.tabs,
            },
          });
          return;
        }
      } else {
        opt = {
          code: item.code,
        };
      }
      //到这里就是名字有pd的 或者数量只有一个的   还有一个saba
      this.$store.commit("setLoadingShowTrue");

      pdgameLaunch(opt).then((res) => {
        if (res.code == 0) {
          // window.location.href = res.data
          this.$store.commit("setUrl", res.data);

          // if(!regex.test(this.list[index].name)){
          //     this.$router.push({path:'/block'})
          // }else{
          //     this.$router.push({path:'/sports'})
          // }
          if (item.name == "VR彩票" || item.name == "WE捕鱼") {
            window.open(res.data, "_blank");
          } else {
            this.$router.push({
              path: "/block",
              query: { link: "/", tabs: this.tabs, url: res.data },
            });
          }
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    },

    goPages(index) {
      if (this.list[index].status != 0) {
        return;
      }
      // var regex = /we/i;
      // if (!regex.test(this.list[index].name)) {
      if (this.list[index].gameCount > 0) {
        this.$router.push({
          path: "/classIndex",
          query: { list: JSON.stringify(this.list[index]) },
        });
        return;
      }
      // }
      this.$store.commit("setLoadingShowTrue");
      var opt = {
        code: this.list[index].code,
      };
      pdgameLaunch(opt).then((res) => {
        if (res.code == 0) {
          // window.location.href = res.data
          this.$store.commit("setUrl", res.data);
          // if(!regex.test(this.list[index].name)){
          //     this.$router.push({path:'/block'})
          // }else{
          //     this.$router.push({path:'/sports'})
          // }
          this.$router.push({ path: "/block" });
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    },
    handleTouchStart(index) {

      this.$refs['boxs' + index][0].style.transform = `translateY(-7px)`;
    },

    handleTouchEnd(index) {
      setTimeout(() => {
        this.$refs['boxs' + index][0].style.transform = '';
      }, 500)

    },
    handleMyTouchStart(index) {

      this.$refs['myboxs' + index][0].style.transform = `translateY(-7px)`;
    },

    handleMyTouchEnd(index) {
      setTimeout(() => {
        this.$refs['myboxs' + index][0].style.transform = '';
      }, 500)

    }
  },
  filters: {
    getStatus(status) {
      switch (status) {
        case 0:
          return "";
        case 1:
          return "维护中";
        case 2:
          return "已停用";
        default:
          return "";
      }
    },
  },
  mounted() {
    if (localStorage.getItem('zjcNum')) {
      this.num = localStorage.getItem('zjcNum')
    }
    setInterval(() => {
      this.num = parseFloat((Number(this.num) + (Math.random() * 100)).toFixed(2))//((Math.random() * 10000) * (Math.random() < 0.5 ? -1 : 1)).toFixed(2);
      localStorage.setItem('zjcNum', this.num)
      this.setKey();
    }, 10000)
    setInterval(() => {
      this.num = parseFloat((this.num + (Math.floor(Math.random() * 10) * 100)).toFixed(2));
      localStorage.setItem('zjcNum', this.num)
      this.setKey();
    }, 1800000)

  },
};
</script>

<style lang="less" scoped>
.homeDemo {
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0 10px;

  // margin-bottom: 8px;
  .fold {
    width: 100%;
    margin: 9px auto 12px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 38px;
    transition: all 0.5s ease;
    position: relative;

    .foldLeft {
      display: flex;
      align-items: center;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    .foldRight {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        transition: all 0.5s ease;
      }
    }
  }

  // .zjc{
  //   width: 100%;
  //   height: 56px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   img{
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
  .titleOut {
    color: #fff;
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    text-align: left;
    width: 89.3333%;
    margin: 20px auto 0 auto;
  }

  .physical {
    background: linear-gradient(270deg, #ea4058 0%, #aa0018 100%);
    background-image: url("@/assets/home/tab/tabBj/tab1.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .physicalWe {
    background: linear-gradient(270deg, #e7b852 0%, #e0802e 100%);
    background-image: url("@/assets/home/tab/tabBj/tab2.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .physicalxnty {
    background: linear-gradient(270deg, #532ade 0%, #28208e 100%);
    background-image: url("@/assets/home/tab/tabBj/tab3.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .widthDemo {
    width: 100%;
    // height: 130px;
    // border-radius: 16px;
    // overflow: hidden;
    margin: 0px auto 10px auto;
    // padding: 19px 20px;
    position: relative;
    box-sizing: border-box;
    transition: all 0.5s;

    // background: radial-gradient(
    //   114.24% 141.42% at 0% 0%,
    //   rgba(155, 155, 186, 0.16) 31.69%,
    //   rgba(155, 155, 186, 0.04) 100%
    // );
    .imgIn {
      width: 100%;
      vertical-align: middle;
    }

    .maintainDemo {
      width: 138.5px;
      height: 90%;
      position: absolute;
      background-image: url("@/assets/home/tab/home-mark.svg");
      background-size: cover;
      border-radius: 0 0 12px 0;
      right: -0.5px;

      bottom: 0;
      margin: auto;
      z-index: 3;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: #fff;
      padding: 0 10px 12px 0;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }

    .tips {
      color: rgba(255, 255, 255, 0.65);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }

    .title {
      margin-top: 14px;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 24px */
      text-align: left;
      display: flex;
      align-items: flex-end;

      .titLeft {
        color: #fff;
        font-family: "FONTSPRING DEMO - Integral CF Demi Bold";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 95%;
        /* 30px */
      }

      .titRight {
        display: inline-flex;
        padding: 0 12px;
        height: 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.15);
        margin-left: 8px;
      }
    }

    .titTwo {
      margin-top: 10px;
      /* 18px */
      text-align: left;

      color: #fff;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    .inbtn {
      display: flex;
      width: 88px;
      height: 32px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex-shrink: 0;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.15);
      color: var(---100, #fff);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 12px;
    }
  }

  .smailOut {
    display: grid;

    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 7px;
    width: 100%;
    margin: 0px auto 0 auto;
    flex-wrap: wrap;

    .smailList {
      width: 110px;
      height: 148px;
      border-radius: 12px;

      background: radial-gradient(114.24% 141.42% at 0% 0%,
          rgba(155, 155, 186, 0.16) 31.69%,
          rgba(155, 155, 186, 0.04) 100%);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      transition: all 0.5s;

      .smailBj {
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }

      .smailMaintainDemo {
        width: 100%;
        height: 70px;
        position: absolute;
        background: linear-gradient(180deg,
            rgba(16, 27, 31, 0) 0%,
            rgba(16, 27, 31, 0.75) 66%);
        background-size: cover;
        border-radius: 0 0 12px 12px;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        color: #fff;
        padding: 0 10px 12px 0;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }

    .caicaic {
      width: 115px !important;
      height: 177px !important;
    }

    .zeren {
      width: 165px !important;
      height: 130px !important;
    }
  }

  .zerenOut {
    grid-template-columns: repeat(2, 165px) !important;
    grid-gap: 15px;
  }
}

.my-swipeTwo {
  width: 138px;
  height: 28px;
  position: absolute;
  top: 6px;

  left: 0;
  right: 0;
  margin: auto;
  z-index: 4;

  // border-radius: 20px;
  .lazyImg {
    width: 138px;
    height: 28px;
  }
}

.lazyImg {
  width: 138px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 4;

  img {
    width: 100%;
    height: 100%;

  }
}

// .widthDemo:active{
//       // transform: translateY(-7px);
//       animation: bounce 0.5s ease;
//     }
// .smailList:active{
//       // transform: translateY(-7px);
//       animation: bounce 0.5s ease;
//     }/* 弹跳动画 */

.numOut {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background: url('@/assets/home/zjc.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 36px;
  margin-bottom: 10px;

  .zjj {
    background: url('@/assets/home/zjcTit.png') no-repeat;
    background-size: 100% 100%;
    width: 48px;
    height: 14px;
    margin-right: 8px;
  }
  .digOut{
    
  }
  .isDi{
    background: transparent!important;
    width: 6px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 5px;
    .diand{
        background: rgba(225, 162, 69, 1);
        width: 2.6px;
        height: 2.6px;
    }
}
  /deep/.d-roll-list {
    width: fit-content;
    font-size: 20px;
    color: rgba(225, 162, 69, 1);
    font-family: "DIN Alternate", "DIN-Regular", "DIN Pro";
    gap:2px;
    display: flex;
    align-items: center;
    .d-roll-item {
      position: relative;
      width: 17px;
      flex-shrink: 0;
      flex-grow: 0;
      height: 24px;
      overflow: hidden;
      .d-roll-bar{
        width: 17px;
        div{
          width: 17px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(1px);
        }
      }
    }
    .d-roll-item::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      content: ' ';
      background: url('@/assets/home/numBj.png') no-repeat;
      background-size: 100% 100%;
      width: 17px;
      height: 24px;
      z-index: 1;
    }
    .d-roll-bar {
      position: relative;
      z-index: 2;
    }
  }
}
</style>
