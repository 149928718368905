<template>
	<div class="details" id='rightdata' :style="$store.getters['sport/$theme']">
		<div class="top flex">
			<div class="list flex">
				<div :class="[topActive == 0 ? 'item active' : 'item']" @click='topActive = 0'>
					未结算
					<!-- <img v-if='topActive == 0' src="@/assets/sport/shuaxin-active.svg">
					<img v-else src="@/assets/sport/shuaxin.svg"> -->
					<!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
						@click='refresh' :class='{ refresh: isRefresh }'>
						<g clip-path="url(#clip0_3095_448)">
							<path
								d="M12.2016 14.1C12.05 14.1 11.9 14.0312 11.8016 13.9C11.6359 13.6797 11.6797 13.3656 11.9016 13.2C13.5531 11.9594 14.5 10.0641 14.5 8C14.5 4.41563 11.5844 1.5 8 1.5C7.72344 1.5 7.5 1.27656 7.5 1C7.5 0.723437 7.72344 0.5 8 0.5C12.1359 0.5 15.5 3.86406 15.5 8C15.5 10.3812 14.4062 12.5688 12.5 14C12.4109 14.0672 12.3047 14.1 12.2016 14.1ZM8 15.5C3.86406 15.5 0.5 12.1359 0.5 8C0.5 5.96875 1.29844 4.06719 2.75 2.64375C2.94688 2.45 3.26406 2.45313 3.45781 2.65C3.65156 2.84688 3.64844 3.16406 3.45156 3.35781C2.19219 4.59062 1.5 6.23906 1.5 8C1.5 11.5844 4.41563 14.5 8 14.5C8.27656 14.5 8.5 14.7234 8.5 15C8.5 15.2766 8.27656 15.5 8 15.5Z"
								fill="white" />
							<path
								d="M9.4 3.6C9.23906 3.6 9.07969 3.52188 8.98281 3.37656L7.58437 1.27813C7.43125 1.04844 7.49375 0.7375 7.72344 0.584375C7.95312 0.43125 8.26406 0.49375 8.41719 0.723437L9.81719 2.82344C9.97031 3.05313 9.90781 3.36406 9.67812 3.51719C9.59219 3.57344 9.49531 3.6 9.4 3.6ZM8 15.5C7.83906 15.5 7.67969 15.4219 7.58281 15.2766L6.18281 13.1766C6.02969 12.9469 6.09219 12.6359 6.32187 12.4828C6.55156 12.3297 6.86094 12.3922 7.01562 12.6219L8.41562 14.7219C8.56875 14.9516 8.50625 15.2625 8.27656 15.4156C8.19219 15.4734 8.09531 15.5 8 15.5Z"
								fill="white" />
						</g>
						<defs>
							<clipPath id="clip0_3095_448">
								<rect width="16" height="16" fill="white" />
							</clipPath>
						</defs>
					</svg> -->

				</div>
				<div :class="[topActive == 1 ? 'item active' : 'item']" @click='topActive = 1'>
					已结算
				</div>
			</div>
			<!-- "$router.back() -->
			<div class="close flex" @click="$emit('close')">
				<van-icon name="cross" />
			</div>
		</div>
		<div class="content">
			<transition name="fade">
				<div class="orderlist" v-if="topActive == 0">
					<div class="total" v-if='sts && sts.length'>
						<div class="zongji flex-c">
							<span class="bt">总计</span>
							<span>
								{{ sts[0].ct || 0 }}
								<span class='small'>单</span>
							</span>
						</div>
						<div class="touzhue flex-c">
							<span class="bt">投注额</span>
							<span>
								<img src="@/assets/sport/guoqi.png" alt="">
								<span v-html='numToStr(sts[0].sa, "fs")'></span>
							</span>
						</div>
					</div>
					<van-skeleton :loading="loading">
						<van-list v-model="resloading" :finished="finished" finished-text='' @load="onLoad" offset='100'>
							<van-cell class="ul1" v-for="(item, i) in list" :key="i">
								<div class="li1">
									<div v-if='!item.sert'>
										<div class='space-c-b pb4 bb'>
											<div>
												<p class='f16'>{{ item.ops[0].mn }}</p>
												<p class='f12 _B3B9D2'>{{ item.ops[0].ln }}</p>
											</div>
											<div>
												<span class='f16 _8391B8'>
													已确认
												</span>
											</div>
										</div>
										<div class='pt6 pb4 bb'>
											<div class='space-c-b f12 _B3B9D2 mb4'>
												<p v-if="item.ops[0]?.mc?.r">
													{{ item.ops[0].scs[0] }}-{{ item.ops[0].scs[1] }}
													{{ codeToName(item.ops[0].mc.pe) }}
													{{ secondsToHMS(item.ops[0].mc.s) }}
												</p>
												<p v-else>
													开赛
													<span class="time">
														{{ formatTimestamp(item.ops[0].bt) }}
													</span>
												</p>
												<!-- <p>赛果0-0</p> -->
											</div>
											<div class='space-c-b'>
												<div>
													<p class='f16 mb2'>
														{{ item.ops[0].onm }}
														<span>@{{ item.ops[0].od }}</span>
													</p>
													<p class="_788A9A f14">
														<span>{{ item.ops[0].mgn }}</span>
														<span>{{ item.ops[0].re }}</span>
														<span>{{ oddsType[item.ops[0].ty] }}</span>
													</p>
												</div>
											</div>
										</div>
									</div>

									<div v-else>
										<div class='space-c-b pb4 f16 bb'>
											<div class="bold">
												{{ item.al }}场
												{{ item.bt.replace('x', '串') }}
											</div>
											<div class='_8391B8'>已确认</div>
										</div>
										<div class="pt6 pb6 bb" v-for="(attr, a) in item.ops" :key="a">
											<p class="mb2 f16">
												{{item.ops[0].mn}}
											</p>
											<p class="mb2 f12 _788A9A">
												{{ item.ops[0].ln }}
											</p>
											<p class="mb2 f12 _B3B9D2" v-if="attr?.mc?.r">
												{{ attr.scs[0] }}-{{ attr.scs[1] }}
												{{ codeToName(attr.mc.pe) }}
												{{ secondsToHMS(attr.mc.s) }}
											</p>
											<p class="mb2 f12 _B3B9D2" v-else>
												开赛
												<span class="time">
													{{ formatTimestamp(attr.bt) }}
												</span>
											</p>
											<p class="mb2 f16">
												{{ attr.onm }}
												<span>@{{ attr.od }}</span>
											</p>
											<p class="mb2 f14 _788A9A">
												<span>{{ attr.mgn }}</span>
												<span>{{ attr.re }}</span>
												<span>{{ oddsType[attr.ty] }}</span>
											</p>
										</div>
										
									</div>

									<div class="space-c-b mt6 pb6 bb">
										<div class="money">
											<span class="f14 _B3B9D2">本金</span>
											<span class="flex" style="color: #fff">
												<img class="guoqi mr4" src="@/assets/sport/guoqi.png">
												<span class="numtostr" v-html='numToStr(item.sat, "fs")'></span>
											</span>
										</div>
										<div class="money">
											<span class="f14 _B3B9D2">可赢</span>
											<span class="flex" :style="{ color: 3 ? color.up : color.down }">
												<img class="guoqi mr6" src="@/assets/sport/guoqi.png">
												<span class="numtostr _FF3B30" v-html='numToStr(item.lwa, "fs")'></span>
											</span>
										</div>

									</div>

									<!-- 串关结算 -->
									<div class="jiesuan bb" v-if="priceObj[item.id]?.amt && item.sert == 1">
										<div :class="[item.open || item.startjiesuan ? 'btn focus' : 'btn']">
											<!-- 0:订单创建 1：订单确认中，2：订单拒绝 3：订单取消，4：订单已接单 5；订单已结算 101：预约提前结算中 102：提前结算进行中 -->
											<div class="bigbtn" @click="handleTiqianBtn(item)" v-if="priceObj[item.id]?.st != 102">
												提前结算返还
												<span>CNY</span>
												<!-- <span>{{ (item.sat*priceObj[item.id]?.amt).toFixed(2) }}</span> -->
												<span v-html='numToStr(item.sat * priceObj[item.id]?.amt, "fs")'></span>
											</div>
											<div class="bigbtn" v-else>
												正在结算中
												<span class="flex">
													<img v-if="$store.state.sport.theme == 'light'" src="@/assets/sport/load1.svg" alt="">
													<img v-else src="@/assets/sport/load.svg" alt="">
												</span>
											</div>
											<div class="icon" :class="{ rcool: item.rcool }" @click='item.open = !item.open' v-if="false">
												<van-icon :name="!item.open ? 'setting-o' : 'revoke'" color="#4774DA" size="22" />
											</div>
										</div>

										<div class="jiesuan-config" :style="{ maxHeight: item.open ? '200px' : '0' }" v-if="false">
											<div>
												<div class="slider">
													<p>预约提前返还</p>
													<van-slider v-model="item.value" :max="item.mla"
														:min="(item.sat * priceObj[item.id]?.amt).toFixed(2)" active-color="#4774DA">
														<template #button>
															<div class="custom-button">{{ item.value }}</div>
														</template>
													</van-slider>
												</div>
												<div class="sure-btn" v-if='!item.rcool' @click="handleSureYuYue(item)">
													确定预约
												</div>
												<div class="has-yuyue" v-else>
													<span class="cancel" @click="handleCashCancel(item)">取消</span>
													<span class="edit" @click="handleSureYuYue(item)">修改</span>
												</div>
											</div>
										</div>
									</div>

									<!-- 非串关提前结算 -->
									<div class="jiesuan bb" v-if="priceObj[item.id]?.amt && item.sert == 0">
										<div :class="[item.open || item.startjiesuan ? 'btn focus' : 'btn']">
											<div class="bigbtn" @click="handleTiqianBtn(item)" v-if="priceObj[item.id]?.st != 102">
												提前结算返还
												<span>CNY</span>
												<!-- <span>{{ (item.sat*priceObj[item.id]?.amt).toFixed(2) }}</span> -->
												<span v-html='numToStr(item.sat * priceObj[item.id]?.amt, "fs")'></span>
											</div>
											<div class="bigbtn load" v-else>
												正在结算中
												<span class="flex">
													<img v-if="$store.state.sport.theme == 'light'" src="@/assets/sport/load1.svg" alt="">
													<img v-else src="@/assets/sport/load.svg" alt="">
												</span>
											</div>
											<div class="icon" :class="{ rcool: item.rcool }" @click='item.open = !item.open' v-if="false">
												<van-icon :name="!item.open ? 'setting-o' : 'revoke'" color="#4774DA" size="22" />
											</div>
										</div>
										<div class="jiesuan-config" :style="{ maxHeight: item.open ? '300px' : '0' }" v-if="false">
											<div class="jiesuan-tab">
												<span @click="item.active = 0" :class='{ active: item.active == 0 }'>部分提前结算</span>
												<span @click="item.active = 1" :class='{ active: item.active == 1 }'>
													{{ !item.rcool ? "预约结算" : "预约中" }}
												</span>
											</div>
											<div v-if="!item.active">
												<div class="slider">
													<p>提前结算本金</p>
													<!-- {{ item.sat }} -->
													<van-slider v-model="item.value2" :max="item.sat" :min="priceObj[item.id].smis"
														active-color="#4774DA">
														<template #button>
															<div class="custom-button">{{ item.value2 }}</div>
														</template>
													</van-slider>
												</div>
												<div class="sure-btn" @click="handleTiqianBtn(item)">
													<!-- 确定 CNY {{ (item.value2*priceObj[item.id]?.amt).toFixed(2) }} -->
													<span v-html='numToStr(item.value2 * priceObj[item.id]?.amt, "fs")'></span>
												</div>
											</div>

											<div v-if="item.active">
												<div class="slider">
													<p>预约提前结算本金</p>
													<van-slider @input="item.value1 = item.value * priceObj[item.id]?.amt" v-model="item.value2"
														:max="item.sat" :min="priceObj[item.id].smis" active-color="#4774DA">
														<template #button>
															<div class="custom-button">{{ item.value2 }}</div>
														</template>
													</van-slider>
												</div>
												<div class="slider">
													<p>预约提前结算返还</p>
													<!-- {{ item.value2*193.7/100 }} -->
													<!-- {{item.value2*priceObj[item.id]?.amt*193.7}} -->
													<van-slider v-model="item.value1" :min="item.value2 * priceObj[item.id]?.amt"
														:max='item.value2 * 203 / 100' step="0.01" active-color="#4774DA">
														<template #button>
															<div class="custom-button">{{ item.value1 }}</div>
														</template>
													</van-slider>
												</div>
												<div class="sure-btn" v-if='!item.rcool' @click="handleSureYuYue(item)">
													确定预约
												</div>
												<div class="has-yuyue" v-else>
													<span class="cancel" @click="handleCashCancel(item)">取消</span>
													<span class="edit" @click="handleSureYuYue(item)">修改</span>
												</div>
											</div>
										</div>
									</div>

									<div class="xiangqing pt6" :class="{ open: item.open }" v-if="item.crl && priceObj[item.id]?.st != 102">
										<p class='f14 _B3B9D2 mb6' @click="item.open = !item.open">
											提前结算详情
											<span>
												<van-icon name="arrow-down" />
											</span>
										</p>

										<ul class="_B3B9D2">
											<li class="mb10 f12 space-c-b">
												<span>提前结算本金</span>
												<div>
													<img class="guoqi mr4" src="@/assets/sport/guoqi.png" alt="">
													<span v-html='numToStr(item?.crl[0]?.cst, "fs")'></span>
												</div>
											</li>
											<li class="mb10 f12 space-c-b">
												<span>提前结算返还</span>
												<div>
													<img class="guoqi mr4" src="@/assets/sport/guoqi.png" alt="">
													<span v-html='numToStr(item?.crl[0]?.cops, "fs")'></span>
												</div>
											</li>
											<li class='space-c-b f12'>
												<span>正常结算本金</span>
												<div>
													<img class="guoqi mr4" src="@/assets/sport/guoqi.png" alt="">
													<span v-html='numToStr(item?.sa, "fs")'></span>
												</div>
											</li>
										</ul>
									</div>

									<div class="jsid space-c-b _788A9A f11 mt6">
										<div class="order flex" @click="copy(item.id)">
											{{ item.id }}
											<svg t="1719406899321" class="icon" viewBox="0 0 1024 1024" version="1.1"
												xmlns="http://www.w3.org/2000/svg" p-id="5953" width="64" height="64">
												<path
													d="M725.333333 341.333333h128v512H341.333333v-128H213.333333V213.333333h512v128z m0 42.666667v341.333333H384v85.333334h426.666667V384h-85.333334zM256 256v426.666667h426.666667V256H256z"
													fill="#788a9a" p-id="5954"></path>
											</svg>
										</div>
										<div class="time">
											投注时间 {{ formatTimestamp(item.cte) }}
										</div>
									</div> 
								</div>
							</van-cell>
							<van-empty v-if="!list.length" class="custom-image" :image="emptyimg" description="暂无数据" />
						</van-list>
					</van-skeleton>
				</div>
			</transition>
			<transition name="fade">
				<div class="orderlist" v-if="topActive == 1">
					<div class="tabs">
						<div v-for="(item, i) in tabs1List" :key="i" :class='{ active: tabs1Active == i }' @click="changeTabs1(i)">
							<span>{{ item }}</span>
							<span class="hr"></span>
						</div>
					</div>
					<div class="total tabs2-total" v-if='sts && sts.length'>
						<div class="zongji flex-c">
							<span class="bt">总计</span>
							<span>
								{{ sts[0].ct || 0 }}
								<span class='small'>单</span>
							</span>
						</div>
						<div class="touzhue flex-c">
							<span class="bt">投注额</span>
							<span>
								<img src="@/assets/sport/guoqi.png" alt="">
								<span v-html='numToStr(sts[0].sa, "fs")'></span>
							</span>
						</div>
						<div class="shuying flex-c">
							<span class="bt">输赢</span>
							<span>
								<img src="@/assets/sport/guoqi.png" alt="">
								<span v-html='numToStr(sts[0].cwl, "fs")'></span>
							</span>
						</div>
					</div>
					<van-skeleton :loading="loading">
						<van-list v-model="resloading" :finished="finished" finished-text='' @load="onLoad" offset='100'>
							<van-empty v-if="!list.length" class="custom-image" :image="emptyimg" description="没有数据" />
							<van-cell class="ul1" v-for="(item, i) in list" :key="i">
								<div class="li1">
									<!-- 非串关 -->
									<div v-if='!item.sert'>
										<div class='space-c-b pb4 bb'>
											<div>
												<p class='f16'>{{ item.ops[0].mn }}</p>
												<p class='f12 _B3B9D2'>{{ item.ops[0].ln }}</p>
											</div>
											<div>
												<span class='f16 _8391B8'>
													{{ item.rj ? '已拒绝' : '已结算' }}
												</span>
											</div>
										</div>
										<div class='pt6 pb4 bb' @click="goSaiguo(item)">
											<div class='space-c-b f12 _B3B9D2 mb4'>
												<p v-if="item.ops[0]?.mc?.r">
													{{ item.ops[0].scs[0] }}-{{ item.ops[0].scs[1] }}
													{{ codeToName(item.ops[0].mc.pe) }}
													{{ secondsToHMS(item.ops[0].mc.s) }}
												</p>
												<p v-else>
													开赛
													<span class="time">
														{{ formatTimestamp(item.ops[0].bt) }}
													</span>
												</p>
												<div>
													<p v-if="item?.ops[0].rs">赛果 {{ item.ops[0].rs }}</p>
												</div>
											</div>
											<div class='space-c-b'>
												<div>
													<p class='f16 mb2'>
														{{ item.ops[0].onm }}
														<span>@{{ item.ops[0].od }}</span>
													</p>
													<p class="_788A9A f14">
														<span>{{ item.ops[0].mgn }}</span>
														<span>{{ item.ops[0].re }}</span>
														<span>{{ oddsType[item.ops[0].ty] }}</span>
													</p>
												</div>
												<div class='shuying1'>
													<i v-if="item.ops[0].sr == 4" :style="getColor(item.uwl)" class="iconfont icon-zhong-ying"></i>
													<i v-if="item.ops[0].sr == 5" :style="getColor(item.uwl)"
														class="iconfont icon-zhong-yingban"></i>
													<i v-if="item.ops[0].sr == 3" :style="getColor(item.uwl)" class="iconfont icon-zhong-shu"></i>
													<i v-if="item.ops[0].sr == 6" :style="getColor(item.uwl)"
														class="iconfont icon-zhong-shuban"></i>
													<i v-if="item.ops[0].sr == 2" class="iconfont icon-zhong-he"></i>
													<i v-if="!item.ops[0].sr && item.uwl > 0" :style="getColor(item.uwl)"
														class="iconfont icon-zhong-ying"></i>
													<i v-if="!item.ops[0].sr && item.uwl < 0" :style="getColor(item.uwl)"
														class="iconfont icon-zhong-shu"></i>
												</div>
											</div>
										</div>
										
										
									</div>
									<div v-else>
										<div class='space-c-b pb4 f16 bb'>
											<div class="bold">
												{{ item.al }}场
												{{ item.bt.replace('x', '串') }}
											</div>
											<div class='_8391B8 shuying'>
												已结算
												<i v-if="item.ops[0].sr == 4" :style="getColor(item.uwl)" class="iconfont icon-zhong-ying"></i>
												<i v-if="item.ops[0].sr == 5" :style="getColor(item.uwl)" class="iconfont icon-zhong-yingban"></i>
												<i v-if="item.ops[0].sr == 3" :style="getColor(item.uwl)" class="iconfont icon-zhong-shu"></i>
												<i v-if="item.ops[0].sr == 6" :style="getColor(item.uwl)" class="iconfont icon-zhong-shuban"></i>
												<i v-if="item.ops[0].sr == 2" style="color:#ccc" class="iconfont icon-zhong-he"></i>
											</div>
										</div>

										<div class="pt6 pb6 bb" v-for="(attr, a) in item.ops" :key="a">
											<div class="space-c-b"  @click="goSaiguo(item)">
												<div>
													<p class="mb2 f16">
														{{item.ops[0].mn}}
													</p>
													<p class="mb2 f12 _788A9A">
														{{ item.ops[0].ln }}
													</p>
													<p class="mb2 f12 _B3B9D2" v-if="attr?.mc?.r">
														{{ attr.scs[0] }}-{{ attr.scs[1] }}
														{{ codeToName(attr.mc.pe) }}
														{{ secondsToHMS(attr.mc.s) }}
													</p>
													<p class="mb2 f12 _B3B9D2" v-else>
														开赛
														<span class="time">
															{{ formatTimestamp(attr.bt) }}
														</span>
													</p>
													<p class="mb2 f16">
														{{ attr.onm }}
														<span>@{{ attr.od }}</span>
													</p>
													<p class="mb2 f14 _788A9A">
														<span>{{ attr.mgn }}</span>
														<span>{{ attr.re }}</span>
														<span>{{ oddsType[attr.ty] }}</span>
													</p>
												</div>
												<div class='saiguo _B3B9D2'>
													<p v-if='attr.sr == 2'>和</p>
													<p :style="{color: $store.state.sport.sjcolor.down}" v-if='attr.sr == 6'>输</p>
													<p :style="{color: $store.state.sport.sjcolor.up}" v-if='attr.sr == 5'>赢</p>
													<p :style="{color: $store.state.sport.sjcolor.up}" v-if='attr.sr == 4'>赢</p>
													<p :style="{color: $store.state.sport.sjcolor.down}" v-if='attr.sr == 3'>输</p>
													<div class="down" v-if="attr.rs">
														赛果 {{ attr.rs }}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="space-c-b mt6 pb6 bb">
										<div class="money">
											<span class="f14 _B3B9D2">本金</span>
											<span class="flex" style="color: #fff">
												<img class="guoqi mr4" src="@/assets/sport/guoqi.png">
												<span class="numtostr" v-html='numToStr(item.sat, "fs")'></span>
											</span>
										</div>
										<div class="money">
											<span class="f14 _B3B9D2">返还额</span>
											<span class="flex" :style="{ color: 3 ? color.up : color.down }">
												<img class="guoqi mr6" src="@/assets/sport/guoqi.png">
												<span class="numtostr _FF3B30" v-html='numToStr(item.sat*1 + item.uwl*1, "fs")'></span>
											</span>
										</div>
									</div>

									<div class="xiangqing pt6" :class="{ open: item.xqopen }" v-if="item.crl">
										<p class='f14 _B3B9D2 mb6' @click="item.xqopen = !item.xqopen">
											提前结算详情
											<span>
												<van-icon name="arrow-down" />
											</span>
										</p>

										<ul class="_B3B9D2">
											<li class="mb10 f12 space-c-b">
												<span>提前结算本金</span>
												<div>
													<img class="guoqi mr4" src="@/assets/sport/guoqi.png" alt="">
													<span v-html='numToStr(item?.crl[0]?.cst, "fs")'></span>
												</div>
											</li>
											<li class="mb10 f12 space-c-b">
												<span>提前结算返还</span>
												<div>
													<img class="guoqi mr4" src="@/assets/sport/guoqi.png" alt="">
													<span v-html='numToStr(item?.crl[0]?.cops, "fs")'></span>
												</div>
											</li>
											<li class='space-c-b f12'>
												<span>正常结算本金</span>
												<div>
													<img class="guoqi mr4" src="@/assets/sport/guoqi.png" alt="">
													<span v-html='numToStr(item?.sa, "fs")'></span>
												</div>
											</li>
										</ul>
									</div>

									<div class="jsid space-c-b _788A9A f11 mt6">
										<div class="order flex" @click="copy(item.id)">
											{{ item.id }}
											<svg t="1719406899321" class="icon" viewBox="0 0 1024 1024" version="1.1"
												xmlns="http://www.w3.org/2000/svg" p-id="5953" width="64" height="64">
												<path
													d="M725.333333 341.333333h128v512H341.333333v-128H213.333333V213.333333h512v128z m0 42.666667v341.333333H384v85.333334h426.666667V384h-85.333334zM256 256v426.666667h426.666667V256H256z"
													fill="#788a9a" p-id="5954"></path>
											</svg>
										</div>
										<div class="time">
											投注时间 {{ formatTimestamp(item.cte) }}
										</div>
									</div> 
								</div>
							</van-cell>
						</van-list>
					</van-skeleton>
				</div>
			</transition>
		</div>

		<van-popup v-model="bottomShow" round position="bottom">
			<div class="sure-jiesuan">
				<div class="title">
					确认提前结算
				</div>
				<div class="money" v-if="tqjsItem">
					<div class="left">
						<span class="name">提前结算本金</span>
						<span class="flex">
							CNY
							<span>
								<!-- {{ tqjsItem.sat }} -->
								<span class='moneyColor' v-html='numToStr(tqjsItem.sat, "fs")'></span>
							</span>
						</span>
					</div>
					<div class="right">
						<span class="name">返还额(含本金)</span>
						<span class="flex">
							CNY
							<span>
								<!-- {{ (tqjsItem.sat*priceObj[tqjsItem.id]?.amt).toFixed(2) }} -->
								<span v-html='numToStr(tqjsItem.sat * priceObj[tqjsItem.id]?.amt, "fs")'></span>
							</span>
						</span>
					</div>
				</div>
				<div class="sure" @click='handleJiesuan'>
					确认结算
				</div>
				<div class="cancel" @click='bottomShow = false'>
					我在想想
				</div>
			</div>
		</van-popup>

	</div>
</template>

<script>
import {
	list, getBetParameter, cashCancel, price, cashOutReserve, earlySettlementBet
} from '@/request/sportApi.js'
import {
	match_period
} from '../utils/config'

import mixin from '../utils/mixin'
export default {
	name: "rightSlider",
	mixins: [mixin],
	data() {
		return {

			size: 50,	// 每页订单条数
			current: 1,	// 当前分页页数，从1开始
			startTime: '',	// 开始时间
			endTime: '',	// 结束时间
			tqjsItem: null, // 选中提前结算时的对象
			bottomShow: false,	// 底部弹框
			loading: true,
			topActive: 0,

			finished: false,
			resloading: false,
			// oddsType: {
			//   "1": "欧洲盘",
			//   "2": "香港盘",
			//   "3": "马来盘",
			//   "4": "印尼盘",
			//   "5": "美式盘",
			// },

			tabs1List: [
				'今天', '昨天', '7天', '近30天'
			],
			tabs1Active: 5,

			isRefresh: false,
			priceObj: {},	// 提前结算时的价格，对象
			sts: [],
			list: [],

			rightTimer: null,
			rightCalcTimer: null,

			observer: null,

			reserve_order_status: {
				"4": "确认中",
				"0": "预约中",
				"1": "预约成功",
				"2": "预约失败",
				"3": "已取消",
			}
		}
	},
	computed: {
		color() {
			return this.$store.state.sport.sjcolor
		},
		requestData() {
			let obj = {
				"languageType": "CMN",
				"isSettled": this.topActive ? true : false,
				"size": this.size,	// 每页订单条数
				"current": this.current,	// 当前分页页数，从1开始
			}
			if (this.topActive == 1) {
				obj.startTime = this.startTime
				obj.endTime = this.endTime
			}
			return obj
		}
	},
	watch: {
		topActive() {
			if (this.topActive == 0) {
				this.initializationData()
				this.tabs1Active = 5
				this.getData()
			}
			if (this.topActive == 1) {
				this.tabs1Active = 0
			}
		},
		tabs1Active() {
			let time
			this.loading = true
			if (this.tabs1Active > 4) return
			if (this.tabs1Active == 0) {
				time = this.getStartEndTime(1)
			} else if (this.tabs1Active == 1) {
				time = this.getStartEndTime(1, 1)
			} else if (this.tabs1Active == 2) {
				time = this.getStartEndTime(7)
			} else {
				time = this.getStartEndTime(30)
			}
			this.startTime = time.startTime
			this.endTime = time.endTime
			this.initializationData()
			this.getData()
		},
	},
	created() {
		this.getData()
	},
	mounted() {
		localStorage.setItem('back1', 1)
		history.pushState('2', '', '');
	},
	destroyed() {
		this.getData = null
		this.rightCalcTimerr = null
		clearInterval(this.rightTimer)
		clearInterval(this.rightCalcTimer)
		this.rightTimer = null
		this.rightCalcTimer = null
		localStorage.removeItem('back1')
	},

	methods: {
		initializationData() {
			this.loading = true
			this.sts = []
			this.list = []
			this.current = 1
			this.resloading = false
			this.finished = true
		},

		onLoad() {
			this.current++
			
			setTimeout(() => {
				this.getData()
			}, 1000)
		},

		goSaiguo(item) {
			// this.$emit('saiguo', item)
			this.$router.push('/saiguodetails?mid=' + item.ops[0].mid)
		},

		// 请求数据
		getData() {
			clearInterval(this.rightCalcTimer)
			clearInterval(this.rightTimer)
			this.rightCalcTimer = null
			this.rightTimer = null
			
			return new Promise(response => {
				list(this.requestData)
					.then(res => {
						res.data.records.forEach((item,i) => {
							let io = this.list[i]
							item.open = io ? io.open : false
							item.xqopen = io ? io.xqopen : true
							item.jiesuan = io ? open.jiesuan : false
							item.active = 0
							// rcool 预约订单结算列表
							if (item.rcool) {
								item.value = item.rcool[0].cops
								item.value1 = item.rcool[0].cops
							}
							item.value2 = item.sat
						})
						if (res.data.sts && res.data.sts.length) {
							this.sts = res.data.sts
						}
						if (this.resloading) {
							this.list = this.list.concat(res.data.records)
						} else {
							this.list = this.list.slice(0, -res.data.records.length).concat(res.data.records)
						}
						if (this.topActive == 0) {
							price({
								"languageType": "CMN",
								"orderIds": this.list.map(item => item.id)
							})
								.then(res => {
									res.data?.pr.forEach(item => {
										this.$set(this.priceObj, item.oid, item)
									})
									console.log(this.priceObj, 'this.priceObj,');
								})
						}

						if (this.topActive == 0) {
							this.rightCalcTimer = setInterval(this.rightCalcTimerr, 1000)
							this.rightTimer = setInterval(this.getData, 5000)
						}
						if (this.list.length >= res.data.total) {
							this.finished = true
						} else {
							this.finished = false
						}
						this.loading = false
						this.resloading = false

						response()
					})
					.catch(() => { })
			})
		},

		// 确定预约
		handleSureYuYue(item) {

			let data = {
				"languageType": "CMN",
				"orderId": item.id,
				"cashOutStake": item.sat,
				"cashOutPayoutStake": !item.active ? item.value : item.value1,
				"parlay": true,
				"platForm": "h5"
			}
			cashOutReserve(data)
				.then(res => {
					if (res.code == 0) {
						item.open = false
						this.getData()
					} else {
						this.$notify(res)
					}
				})
		},


		// 取消预约结算返还
		handleCashCancel(item) {
			cashCancel({
				languageType: "CMN",
				reserveCashOutId: item.rcool[0].id
			})
				.then(res => {
					if (res.code == 0) {
						item.open = false
						item.rcool = ''
					} else {
						this.$notify(res)
					}
				})
		},

		// 获取最大最小投注金额
		getPrice(item) {
			getBetParameter({
				"languageType": "CMN",
				"matchId": item.ops[0].mid,
				"marketId": item.ops[0].mrid,
				"currencyId": 1
			})
				.then(res => {
					if (res.code == 0) {
						item.open = false
						item.min = res.data.mis
						item.max = res.data.mly
					} else {
						this.$notify('获取信息失败')
					}
				})
		},

		// 第一级按钮切换
		changeTabs1(i) {
			this.tabs1Active = i
		},

		// 底部弹出框确认按钮结算
		handleJiesuan() {
			this.bottomShow = false
			let data = {
				"languageType": "CMN",
				"cashOutStake": this.tqjsItem.sat,
				"orderId": this.tqjsItem.id,
				// "unitCashOutPayoutStake":  this.tqjsItem.ops[0].od,
				"unitCashOutPayoutStake": this.priceObj[this.tqjsItem.id]?.amt,
				"acceptOddsChange": false,
				"parlay": false,
				"platForm": "h5"
			}
			earlySettlementBet(data)
				.then(res => {
					if (res.code == 0) {
						this.tqjsItem.startjiesuan = true
						this.$toast('提前结算成功')
						this.getData()
					} else {
						this.$toast(res)
					}
				})
		},

		// 提前结算按钮结算
		handleTiqianBtn(item) {
			// if (item.open) return
			this.bottomShow = true
			this.item = item
			this.tqjsItem = item
		},

		// 计算得到请求时间
		getStartEndTime(n, m) {
			// 获取当前时间的日期对象
			let now = new Date()
			let startTime, endTime
			if (!m) {
				// 设置时间为今天结束（23:59:59）
				now.setHours(23, 59, 59, 999);
				// 获取今天结束的时间戳
				endTime = now.getTime();
				let ago = new Date(now);
				ago.setDate(ago.getDate() - n);
				startTime = ago.getTime() + 1
			} else {
				let yesterday = new Date(now);
				yesterday.setDate(yesterday.getDate() - 1);
				yesterday.setHours(0, 0, 0, 0);
				startTime = yesterday.getTime();
				yesterday.setHours(23, 59, 59, 999);
				endTime = yesterday.getTime();
			}
			return { startTime, endTime }
		},


		rightCalcTimerr() {
			console.log(123);
			this.list.forEach(attr => {
				attr.ops.forEach(item => {
					// el.mc.forEach(item => {
					if (item?.mc?.r) {
						if (item.mc.tp == 1) {
							item.mc.s++
						} else {
							if (item.mc.r >= 0) {
								item.mc.s--
							}
						}
					}
					// })
				})
			})
		},

		// 根据code筛选出name，并过滤掉相关字段
		codeToName(pe) {
			return match_period.find(m => m.CODE == pe).DESC_CN
		},

		// 根据配置获取颜色
		getColor(n) {
			if (!n) return {}
			if (n > 0) {
				return {
					color: this.$store.state.sport.sjcolor.up
				}
			} else {
				return {
					color: this.$store.state.sport.sjcolor.down
				}
			}
		},

		refresh() {
			this.isRefresh = true
			this.getData()
				.then(() => {
					this.isRefresh = false
				})

		}
	}
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';

div,
ul {
	&::-webkit-scrollbar {
		display: none;
	}
}

.flex {
	display: flex;
	align-items: center;
}

.flex-c {
	display: flex;
	flex-direction: column;
}

span,
div {
	box-sizing: border-box;
}

.small {
	font-size: 10px;
	margin: 3px 0 0 2px;
}

.van-empty {
	padding-top: 200px;
}

.van-skeleton__row {
	background: @gujia-bg;
}

.fade-enter-active,
.fade-leave-active {
	transition: all .3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.resloading-data {
	height: 40px;
}

.van-cell::after {
	border-bottom: none;
}


.details {
	text-align: left;
	color: #000;
	height: 100vh;

	color: @font-color;
	font-family: "PingFang SC";
	// font-size: 15px;
	font-size: 14px;
	// background-image: url('@/assets/sport/right-slider-top.png');
	background: @bg-color;
	background-size: 100% 100%;


	.top {
		padding-left: 16px;
		width: 100%;
		height: 48px;
		overflow: hidden;
		color: @theme-text;
		justify-content: space-between;
		background: #1A2F44;
		// background: @304b62;

		.list {
			gap: 8px;
			flex: 1;

			.item {
				flex: 1;
				// background: hsla(0, 0%, 100%, 0.2);
				background: #FFFFFF17;
				height: 30px;
				line-height: 30px;
				color: #fff;
				border-radius: 20px;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					width: 16px;
					height: 16px;
					margin-left: 4px;
				}

				.refresh {
					transform: rotate(360deg);
					transition: all .3s;
				}

				&.active {
					background: #fff;
					// background: @theme-text;
					background: #8391B8;
					color: #fff;
				}
			}
		}

		.close {
			color: #fff;
			justify-content: center;
			width: 44px;
			height: 44px;
		}
	}

	.content {
		height: calc(100vh - 48px);
		background: @bg-color;
		// border-radius: 8px 8px 0 0;
		overflow: auto;
		position: relative;
		z-index: 1;


		.orderlist {
			left: 0;
			right: 0;
			height: 100%;
			overflow: auto;
			padding-bottom: 100px;
			background: #15212D;
			font-size: 12px;

			.van-list {
				height: 100%;
			}

			.total {
				height: 47px;
				background-color: #1A2F44;
				// background-color: @304b62;
				margin: 10px;
				// margin: 4px 10px;
				border-radius: 10px;
				display: flex;
				justify-content: space-between;
				padding: 4px 10px 0;

				&.tabs2-total {
					.flex-c {
						width: 30%;
					}
					.touzhue {
						width: 40%;
					}
				}

				.zongji {
					width: 35%;
				}

				.touzhue {
					flex: 1;
				}

				.flex-c {
					align-items: flex-start;
					padding-top: 4px;

					span {
						display: flex;
						// align-items: center;
						align-items: baseline;

						img {
							width: 12px;
							height: 12px;
							margin-right: 4px;
							position: relative;
							top: 2px;
						}

						.xiaoshu {
							font-size: 12px;
						}
					}

					span.bt {
						color: @theme-summary-title;
						// margin-bottom: 4px;
						// font-size: 10px;
						transform: scale(0.9);
						// transform-origin: bottom;
					}
				}
			}

			.tabs {
				height: 40px;
				line-height: 40px;
				background-color: #1A2F44;
				// background-color: @304b62;
				// margin-bottom: 4px;
				padding: 0 10px;
				display: flex;
				justify-content: space-around;
				color: @B3B9D2;
				display: flex;
				position: sticky;
				top: 0;
				z-index: 888;
				

				div {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					position: relative;

					&.active {
						color: @theme-text;

						.hr {
							display: inline-block;
						}
					}

					height: 100%;

					.hr {
						position: absolute;
						bottom: 0;
						display: none;
						width: 30px;
						height: 2px;
						border-radius: 2px 2px 0 0;
						background: @theme-text;
					}

					.w40 {
						width: 40px;
					}
				}
			}

			.van-cell {
				width: auto;
				padding: 0;

				.van-cell__value {
					overflow: visible;
				}
			}

			.ul1 {
				margin: 4px 10px 10px;
				border-radius: 8px;
				overflow: hidden;
				position: relative;
				background-color: #1A2F44;
				padding: 10px 0;
				&:before {
					content: "";
					position: absolute;
					top: 13px;
					left: 0;
					width: 4px;
					height: 16px;
					border-radius: 0 2px 2px 0;
					background: @theme-text;
				}
				.li1 {
					color: #fff;
					line-height: 1.4;
					&>div {
						padding-left: 10px;
						padding-right: 10px;
					}
					.guoqi {
						width: 12px;
						height: 12px;
						margin-right: 4px;
					}
					.numtostr {
						transform: scale(1.1);
					}
					.money {
						flex: 1;
						&:first-child {
							border-right: 1px solid #283E4C;
						}
						&:last-child {
							text-align: right;
							.flex {
								justify-content: end;
							}
						}
					}
					.jiesuan {
						padding: 12px 10px 6px;
						.btn {
							display: flex;
							justify-content: space-between;
							margin-bottom: 6px;

							div {
								height: 32px;
								display: flex;
								align-items: center;
								justify-content: center;
							}

							.bigbtn {
								flex: 1;
								// background-color: @theme-button-bg;
								color: @theme-text;
								border: 1px solid @theme-text;
								border-radius: 4px;
								font-size: 15px;

								@keyframes rotate {
									0% {
										transform: rotate(0);
									}

									100% {
										transform: rotate(360deg)
									}
								}

								img {
									width: 14px;
									height: 14px;
									animation: rotate 3s linear infinite;
								}
							}

							.icon {
								margin-left: 8px;
								width: 38px;
								background: #0385ff17;
								position: relative;
							}

							.rcool {
								&:after {
									content: '';
									width: 8px;
									height: 8px;
									background: red;
									border-radius: 50%;
									display: block;
									position: absolute;
									top: 0;
									right: 0;
								}
							}

							.load {
								color: #ccc !important;
								border: 1px solid #ccc !important;
							}

							&.focus {
								.bigbtn {
									// background-color: @theme-button-bg;
									color: #8d9198;
									border: none;
									background: #0385ff17;
								}
							}
						}

						.jiesuan-config {
							transition: all .3s;
							overflow: hidden;

							.jiesuan-tab {
								margin-bottom: 4px;
								height: 40px;
								color: @B3B9D2;
								display: flex;
								justify-content: space-between;
								border-bottom: 2px solid @right-slider-li-border;

								span {
									height: 100%;
									line-height: 40px;
									width: 50%;
									text-align: center;

									&.active {
										border-bottom: 2px solid @theme-text;
									}
								}
							}

							.slider {
								padding: 0 20px;
								margin-bottom: 14px;

								p {
									padding-top: 6px;
									text-align: center;
									color: @theme-tab-label;
									font-size: 12px;
									margin-bottom: 12px;
								}

								.custom-button {
									color: #fff;
									padding: 0 4px;
									font-size: 10px;
									line-height: 12px;
									text-align: center;
									background-color: #4774da;
									border-radius: 2px;
								}
							}

							.sure-btn {
								font-size: 15px;
								color: #fff;
								margin-top: 22px;
								width: 100%;
								height: 35px;
								border-radius: 4px;
								display: flex;
								align-items: center;
								justify-content: center;
								background: @theme-text;
							}

							.has-yuyue {
								display: flex;
								justify-content: space-between;
								gap: 10px;

								span {
									flex: 1;
									height: 30px;
									line-height: 30px;
									text-align: center;
									border-radius: 3px;
								}

								.edit {
									background: #b1873b;
									color: #fff;
								}

								.cancel {
									color: #8d9198;
									background: #f4f4f4;
								}
							}
						}
					}

					.shuying {
						display: flex;
						align-items: center;
						position: relative;
						padding-right: 42px;
						i {
							position: absolute;
							top: -14px;
							right: 0;
							font-size: 36px;
						}
					}
					.shuying1 {
						i {
							font-size: 36px;
						}
					}
					.saiguo {
						text-align: right;
						font-size: 13px;
						p {
							margin-bottom: 6px;
						}
					}

					.xiangqing {
						&.open {
							p {
								i {
									transform: rotate(180deg);
								}
							}

							ul {
								max-height: 300px;
								padding: 10px;
							}
						}
						ul {
							padding-left: 10px;
							padding-right: 10px;
							background: #20344D;
							transition: all .3s;
							overflow: hidden;
							max-height: 0;
							div {
								display: flex;
								align-items: center;
							}
						}
					}
					.jsid {
						display: flex;
						align-items: center;
						svg {
							margin-left: 4px;	
							width: 14px;
							height: 14px;
						}
					}
						
				}
			}


		}
	}

	.sure-jiesuan {
		background: @202C37;
		padding-bottom: 10px;

		.title {
			text-align: center;
			padding: 12px 0;
			font-size: 15px;
			font-weight: 800;
		}

		.money {
			display: flex;
			justify-content: space-between;
			margin-bottom: 12px;
			padding: 10px 20px;
			border-top: 1px solid @right-slider-li-border;

			div {
				display: flex;
				flex-direction: column;
				width: 50%;

				.name {
					font-size: 14px;
					margin-bottom: 6px;
				}
			}

			.left {
				border-right: 1px solid @right-slider-li-border;
			}

			.right {
				display: flex;
				align-items: end;

				.flex span {
					color: red;
				}
			}

			.flex {
				span {
					margin-left: 6px;

					b {
						font-size: 12px;
						font-weight: 500;
					}
				}
			}
		}

		.cancel,
		.sure {
			display: flex;
			width: 90%;
			margin: 0 auto;
			border-radius: 30px;
			align-items: center;
			justify-content: center;
			height: 44px;
			color: #fff;
		}

		.cancel {
			font-size: 13px;
			color: @B3B9D2;
			background: #eeeeee20;
			margin-top: 12px;
		}

		.sure {
			background: @theme-text;
		}
	}
}
</style>