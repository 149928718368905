import Vue from 'vue'
import Vuex from 'vuex'
import sportModule from './sport.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMessage:false,
    loadingShow:false,
    url:"",
    usdData:{},
    OptStatus:false,
    loadTextShow:false,
    loginPopShow:false,
    registerPopShow:false,
    successfulPopShow:false,
    messagePop:false,
    messageData:'',
    serviceUrl:"https://direct.lc.chat/19083927/",
  },
  getters: {
  },
  mutations: {
    getUsdData(state,data){
      state.usdData = data;
    },
    setLoadingShowTrue(state){
      state.loadingShow= true;
    },
    setLoadingShowFalse(state){
      state.loadingShow= false;
    },
    setLoadingOpt0(state){
      state.OptStatus = false;
    },
    setLoadingOpt1(state){
      state.OptStatus = true;
    },
    setLoadTextShowTrue(state){
      state.loadTextShow = true;
    },
    setLoadTextShowFalse(state){
      state.loadTextShow = false;
    },
    setUrl(state,url){
      state.url = url;
    },
    getUrl(state){
      return state.url;
    },
    goService(state){
      state.loadingShow = true;
      setTimeout(()=>{
        state.loadingShow = false;
      },3000)
      
      window.open(state.serviceUrl,'_blank')

    },
    setLoginPopShow(state,payload){
      state.loginPopShow = payload
    },
    setRegisterPopShow(state,payload){
      state.registerPopShow = payload
    },
    setSuccessfulPopShow(state,payload){
      state.successfulPopShow = payload
    },
    openMessagePop(state,data){
      console.log("asdzxchzxhsauhduas ")
      state.messagePop = true;
      state.messageData = data;
    },
    closeMessagePop(state){
      state.messagePop = false;
      state.messageData = '';
    },
    clearLocal(){
      var code = '';
          if(localStorage.getItem('myCode')){
            code = localStorage.getItem('myCode')
          }
          var loginName = '';
          if(localStorage.getItem('userPadaLogin')){
            loginName = localStorage.getItem('userPadaLogin')
          }
          var userPadaPhoneLogin = "";
          if(localStorage.getItem('userPadaPhoneLogin')){
            userPadaPhoneLogin = localStorage.getItem('userPadaPhoneLogin')
          }
          var loginStatus = "";
          if(localStorage.getItem('loginStatus')){
            loginStatus = localStorage.getItem('loginStatus')
          }
          localStorage.clear()
          if(code){
            localStorage.setItem('myCode',code)
          }
          if(loginName){
            localStorage.setItem('userPadaLogin',loginName)
          }
          if(userPadaPhoneLogin){
            localStorage.setItem('userPadaPhoneLogin',userPadaPhoneLogin)
          }
          if(loginStatus){
            localStorage.setItem('loginStatus',loginStatus)
          }
          
    },
  },
  actions: {
    setLoginShow({ commit }, payload) {
      if(payload){
        commit('setRegisterPopShow',false)
      }
      commit('setLoginPopShow', payload);
    },
    setRegisterShow({ commit }, payload) {
      if(payload){
        commit('setLoginPopShow',false)
      }
      commit('setRegisterPopShow', payload);
    },
    setSuccessfulShow({ commit }, payload){
     
      commit('setSuccessfulPopShow',payload)
    },
    setMessagePop({ commit }, payload) {
      if(payload){
        if(payload.type == 1){
          commit('openMessagePop',payload.data)
        }else{
          commit('closeMessagePop')
        }
      }else{
        commit('closeMessagePop')
      }
      
    },
  },
  modules: {
    sport: sportModule
  }
})
