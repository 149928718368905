<template>
  <div class="myPersonal">
    <div class="headers">
      <div class="disFlex alignItemCenter information justSpBet">
        <div class="disFlex alignItemCenter infoLeft">
          <div class="disFlex alignItemCenter userHeadImg" @click="userData.username? $router.push('/setHeadImg'):''">
            <img :src="userData.headImg? userData.headImg : require('@/assets/personal/logo.webp') " :class="{loginImg: userData.headImg}" />
          </div>
          <div class="">
            <div class="userName" v-if="userData.username">{{ userData.username }}</div>
            <div class="userNameDl" v-else >点击<span @click="setLoginShow(true)">登录</span>/<span @click="setRegisterShow(true)">注册</span></div>
            <div v-if="userData.username" class="disFlex alignItemCenter userDay">
              <img  src="@/assets/personal/start.svg" />
              这是您加入的第{{ userData.registerDay }}天
            </div>
          </div>
        </div>
        <div class="infoRight disFlex alignItemCenter">
          <div class="infoBtn" @click="goPage('/info/index')">
            <div class="infoIcon disFlex alignItemCenter">
              <img  v-show="noReadCount>0" src="@/assets/personal/newsIcon.svg" />
              
              <img v-show="noReadCount<=0" src="@/assets/personal/noNewsIcon.svg" />
            </div>
            <div class="infoBtnName">消息</div>
          </div>
          <!--  @click="$store.commit('goService')" -->
          <div class="infoBtn marginLeft20" @click="$router.push('/service')">
            <div class="infoIcon disFlex alignItemCenter">
              <img src="@/assets/home/homeService.svg" />
            </div>
            <div class="infoBtnName">客服</div>
          </div>
          <!-- <div class="infoBtn marginLeft20" @click="$router.push('/newSetUp')">
            <div class="infoIcon disFlex alignItemCenter">
              <img src="@/assets/personal/setIcon.svg" />
            </div>
            <div class="infoBtnName">设置</div>
          </div> -->
        </div>
      </div>

      <div class="alignItemCenter disFlex justSpBet wallet">
        <div class="wallLeft">
          <div class="alignItemCenter disFlex topMy">
            中心钱包
            <div class="alignItemCenter disFlex justCenter eyes" @click="showBalance = !showBalance">
              <img v-show="showBalance" src="@/assets/personal/eye.svg" />
              <img v-show="!showBalance" src="@/assets/pwd-colse-eye.svg" />
            </div>
            <div
                class="reload"
                @click="init()"
                :class="{
                  rotate:
                  subLoading || getUserLoading || getHomeLoading
                }"
              ></div>
          </div>
          <div class="alignItemCenter disFlex moneys">
            <div class="alignItemCenter disFlex justCenter yang">
              <img src="@/assets/personal/yang.svg" />
            </div>
            <div class="moneyNum" v-show="showBalance">
              {{ balanceOne }}<span class="dia">.</span
              >
              <!-- <span>00</span> -->
              <span>{{ balanceTwo }}</span>
            </div>
            <div class="moneyNum" v-show="!showBalance">
             ****
            </div>
          </div>
        </div>
        <div class="shu"></div>
        <div class="alignItemCenter disFlex wallRight">
          <div class="wallBtn" @click="goPage('/deposit')">
            <div class="alignItemCenter disFlex justCenter wallImg">
              <img src="@/assets/personal/depositIcon.svg" />
            </div>
            <div class="wallNameBtn">存款</div>
          </div>

          <div class="wallBtn"  @click="goPage('/withdrawals')">
            <div class="alignItemCenter disFlex justCenter wallImg">
              <img src="@/assets/personal/takeIcon.svg" />
            </div>
            <div class="wallNameBtn">取款</div>
          </div>
          <div class="wallBtn" @click="goPage('/convert')">
            <div class="alignItemCenter disFlex justCenter wallImg">
              <img src="@/assets/personal/turnIcon.svg" />
            </div>
            <div class="wallNameBtn">转账</div>
          </div>
        </div>
      </div>
    </div>

    <div class="vip">
      <div class="more" @click="goPage('/vip')">更多等级权益 ></div>
      <div class="vipOne">
        <div class="live">
          <div class="liveLeft"></div>
          <div class="liveConter">{{ `Lv.${myData.currLevel||0}` }}</div>
          <div class="liveRight"></div>
        </div>
        <div class="exclusive">专享权益</div>
      </div>
      <div class="jind">
        <div class="tiao" :style="{'width': myData.score >= myData.totalScore ? '100%': (myData.score/myData.totalScore*100)+'%'}"><div class="tou" ></div></div>
      </div>
      <div class="alignItemCenter disFlex jindNum">
        <div class="jdsj">{{ `${myData.score?Math.trunc(myData.score):0}/${myData.totalScore?myData.totalScore:0}` }}</div>
        <div class="hui">
          还需<span>{{ myData.differenceScore||0 }}</span>有效投注，可升级 <span>{{ `Lv${myData.nextLevel||0}` }}</span>
        </div>
      </div>
      <div class="vipLabel">
        <div class="labelDemo">
          <div class="alignItemCenter disFlex justCenter dgou">
            <img src="@/assets/personal/gou.svg" />
          </div>
          <div>晋级礼金</div>
        </div>
        <!-- <div class="labelDemo">
          <div class="alignItemCenter disFlex justCenter dgou">
            <img src="@/assets/personal/gou.svg" />
          </div>
          <div>每月红包</div>
        </div> -->
        <div class="labelDemo">
          <div class="alignItemCenter disFlex justCenter dgou">
            <img src="@/assets/personal/gou.svg" />
          </div>
          <div>每周红包</div>
        </div>
        <div class="labelDemo">
          <div class="alignItemCenter disFlex justCenter dgou">
            <img src="@/assets/personal/gou.svg" />
          </div>
          <div>生日礼金</div>
        </div>
        <div class="labelDemo">
          <div class="alignItemCenter disFlex justCenter dgou">
            <img src="@/assets/personal/gou.svg" />
          </div>
          <div>专属豪礼</div>
        </div>
      </div>
    </div>

   <div class="hyjh" @click="goPage('/share/index')"><img class="bigImg" src="@/assets/personal/hyjh.png" />
    <div class="hyjhGif">
      <img src="@/assets/personal/ywfc.gif" />
    </div>
  </div>


    <!-- <div class="gift">
      <div class="giftLeft">
        <div class="giftImg">
          <img src="@/assets/personal/gift.png" />
        </div>
        <div class="giftContent">
          <div class="giftTitle">福利中心</div>
          <div class="giftTips">一键领取福利，好礼不错过</div>
        </div>
      </div>

      <div class="giftRight">
        <div class="btnOut">
          <van-button class="goReceive" @click="goPage('/welfare/index')"> 去领取 </van-button>
        </div>

        <div class="wait">待领取<span>{{ waitReceiveCount }}</span></div>
      </div>
    </div> -->

    <div class="functions">
      <!-- <div class="funTitle">基础服务</div> -->
       <div class="jcfwTop">
        <div class="lqflBtn" @click="goPage('/activityOut?tab=2')"></div>
       </div>

      <div class="listOut">
        <div class="list" v-for="(item, index) in list" :key="index" @click="goLink(item,index)" v-show="item.id == 404? isApp() : true">
          <div class="listIcon">
            <img :src="require(`@/assets/personal/list/${index}.png`)" />
          </div>
          <div class="listName">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <van-button class="cloLogBtn" v-show="!tourists&&userData.username" @click="submit">退出登录</van-button>

    <div class="copyright">服务器时间: {{ currentTime }}</div>
    <div class="copyright">Copyright © 2024 PANDA Group. All Rights Reserved</div>

    <van-popup v-model="cloLogShow" class="cloLogPop">
      <div class="outLoginTipsPop">
        <div class="tipsPopTit">是否退出账号？</div>
        <!-- <div class="tczhhfw">退出账号后访问需重新登录。</div> -->
        <div class="btnLine">
          <van-button class="btn" @click="cloLogShow = false">我再想想</van-button>
          <van-button
            class="btn qued"
            :disabled="subLoading"
            :loading="subLoading"
            @click="outLogin"
            >确认退出</van-button
          >
        </div>
      </div>
    </van-popup>
 
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { userInfo,syslogout,userHome,platformBalance } from "@/request/api";
export default {
  name: "personalIndex",
  
  data() {
    return {
      list: [
        { name: "我的信息",link:'/setup' },
        { name: "账户管理",link:'/account/index'},
        { name: "交易记录",link:'/record' },
        { name: "投注记录",link:'/record/postRecord' },
        { name: "账户报表",link:'/overview'},
        // { name: "账户管理",link:'/account/index'},
        { name: "下载APP" ,link:'/download',id: 404},
        { name: "分享赚钱",link:'/share/index' },
        { name: "卖币教程", link:'/betraySell'},
        { name: "商务合作",link:'/cooperation' },
        { name: "关于我们",link:'/about' },
        { name: "服务条款" ,link:'/terms?tab=0'},
        //{ name: "在线客服" ,},
        { name: "实用工具",link:'/tools'}
      ],
      balanceOne: 0,
      balanceTwo: 0,
      userData: {
        username:'',
        registerDay:"1",
      },
      showBalance:true,
      subLoading: false,
      cloLogShow: false,
      tourists:false,
      noReadCount:0,
      waitReceiveCount:0,
      myData:{},
      userType:false,
      currentTime:"",
      timer:null,
      getUserLoading:false,
      getHomeLoading:false,
    };
  },
  mounted() {
    if(localStorage.getItem('pdUserInfo')){
      this.userData = JSON.parse(localStorage.getItem('pdUserInfo'))
    }
  // 每秒更新时间
  this.updateTime();
    this.timer = setInterval(this.updateTime, 1000);
    if(localStorage.getItem('userYou')){
      if(localStorage.getItem('userYou') == 'tourists'){
        this.tourists = true;
        this.userData.username = '游客';
        this.userData.headImg = require('@/assets/personal/userImg.png')
        return;
      }
    }
    // this.getPlatformBalance();
    if(localStorage.getItem('token')){
    this.getUserInfo();
    this.getUserHome();
    }
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions(['setLoginShow','setRegisterShow']),
    init(){
      if (this.getUserLoading || this.getHomeLoading || this.subLoading ) {
        return;
      }
      this.getUserInfo();
    this.getUserHome();
    },
    isApp() {
      var origin = window.location.origin;
      if (origin.includes("app")) {
        localStorage.setItem("downShow", true);
      }
      return !origin.includes("app");
    },
    updateTime() {
      this.currentTime = new Date().toLocaleString(); // 更新当前时间
    },
    showChatWidget() {
      console.log(this.$route.name)
      return this.$route.name === 'targetPage';
    },
    getUserHome(){
      this.getHomeLoading = true;
      userHome().then((res)=>{
        if(res.code == 0){
          this.noReadCount = res.data.noReadCount
          this.waitReceiveCount = res.data.waitReceiveCount
          this.myData = res.data
        }
        this.getHomeLoading = false;
      })
    },
    submit() {
      this.cloLogShow = true;
    },
    outLogin() {
      this.subLoading = true;
      syslogout().then((res) => {
        if (res.code == 0) {
          this.$store.commit('clearLocal')
          this.$notify({
            message: "退出成功",
            duration: 2000,
            background: "#1989fa",
          });
          this.$router.push("/");
        }
        this.subLoading = false;
      });
    },
    goPage(link){
      if(!this.userData.username){
        if(localStorage.getItem('loginStatus')){
          this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setLoginShow(true)
          return;
        }else{
          this.$notify({
            message: "请注册后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setRegisterShow(true)
        return;
        }
        
      }
      if(this.getUserStatus()){
        return;
      }
      this.$router.push(link)
    },
   
    goLink(item){
      if(this.getUserStatus()){
        return;
      }
      
      // if(index == 11){
      //   this.$store.commit('goService')
      //   return;
      // }
      this.$router.push(item.link)
    },
    getUserStatus(){
      if(!this.userData.username){
        if(localStorage.getItem('loginStatus')){
          this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setLoginShow(true)
          return true;
        }else{
          this.$notify({
            message: "请注册后查看",
            duration: 2000,
            background: "#FF4337",
          });
          this.setRegisterShow(true)
        return true;
        }
      }
      if(this.tourists){
        this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          // this.$router.push('/login')
          this.setRegisterShow(true)
        return true;
      }
      return false;
    },
    getPlatformBalance(){
      platformBalance().then((res)=>{
        console.log(res)
        if(res.code == 0){
          var balan = (res.data.balance+res.data.freeze+res.data.totalAmount).toFixed(2);
          if (balan.indexOf(".") == -1) {
            balan = `${balan}.00`;
          }
          var dotIndex = balan.indexOf(".");
          this.balanceOne = balan.slice(0, dotIndex);
          this.balanceTwo = balan.slice(dotIndex + 1);
        }
      })
    },
    getUserInfo() {
      this.getUserLoading = true;
      this.$store.commit("setLoadingShowTrue");
      userInfo().then((res) => {
        if (res.code == 0) {
          // var balan = res.data.balance.toFixed(2);
          var balan = (Math.floor(res.data.balance * 100 ) / 100).toFixed(2);
          if (balan.indexOf(".") == -1) {
            res.data.balance = `${res.data.balance}.00`;
          }
          var dotIndex = balan.indexOf(".");
          this.balanceOne = balan.slice(0, dotIndex);
          this.balanceTwo = balan.slice(dotIndex + 1);
          this.userData = res.data;
          this.userType = true;
          


        }
        this.getUserLoading = false;
        this.$store.commit("setLoadingShowFalse");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.disFlex {
  display: flex;
}
.alignItemCenter {
  align-items: center;
}
.justCenter {
  justify-content: center;
}
.justSpBet {
  justify-content: space-between;
}
.myPersonal {
  min-height: 100dvh;
  padding-bottom: 97px;
  background: rgba(25, 48, 69, 1);
}
.headers {
  width: 100%;
  padding-top: 18px;
  position: relative;
  z-index: 1;
}
.headers::before {
  content: " ";
  background-image: url("@/assets/personal/football.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 146px;
  height: 146px;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 39px;
}
.information {
  width: 89.333333%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .infoLeft {
    .userHeadImg {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      border-radius: 50%;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        
      }
    }
    .loginImg{
      border-radius: 50%;
    }
    .userName {
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: DIN;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 20px */
      text-align: left;
    }
    .userNameDl{
      text-align: right;
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 600;
      font-size: 16px;
    }
    .userDay {
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 15px */
      margin-top: 6px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
    }
  }
  .infoRight {
    .infoBtn {
      .infoIcon {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .infoBtnName {
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 15px */
        margin-top: 3px;
      }
    }
    .marginLeft20 {
      margin-left: 20px;
    }
  }
}
.wallet {
  width: 85.3333333%;
  margin: 18px auto 0 auto;
  position: relative;
  z-index: 1;
  .wallLeft {
    padding-top: 4px;
    .topMy {
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
      .eyes {
        width: 16px;
        height: 16px;
        margin-left: 7px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .reload {
            background: url("@/assets/postWith/sgya.svg") no-repeat;
            background-size: 100% 100%;
            width: 12px;
            height: 12px;
            margin-left: 6px;
          }
    }
    .moneys {
      margin-top: 13px;
      .yang {
        width: 12px;
        height: 14px;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .moneyNum {
        color: rgba(255, 255, 255, 0.85);
        leading-trim: both;
        text-edge: cap;
        font-family: DIN;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 28px */
        span {
          color: rgba(255, 255, 255, 0.45);
          leading-trim: both;
          text-edge: cap;
          font-family: DIN;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
        }
        .dia {
          color: rgba(255, 255, 255, 0.85);
          leading-trim: both;
          text-edge: cap;
          font-family: DIN;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
        }
      }
    }
  }
  .shu {
    width: 1px;
    height: 32px;
    background: #344555;
  }
  .wallRight {
    justify-content: flex-end;
    gap: 15px;
    .wallBtn {
      .wallImg {
        width: 38px;
        height: 38px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .wallNameBtn {
        color: #fff;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
      }
    }
  }
}
.vip {
  width: 92%;
  height: 125px;
  flex-shrink: 0;
  // background: #283643;
  margin: 10px auto 0 auto;
  border-radius: 8px;
  background-image: url("@/assets/personal/vipBj.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 15px;
  padding-top: 17px;
  position: relative;
  .more {
    padding: 4px 5px 4px 12px;
    flex-shrink: 0;
    border-radius: 14px 0px 0px 14px;
    background: linear-gradient(90deg, #435567 96%, #293d51 100%);
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "PingFang SC";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    position: absolute;
    right: 0;
    top: 15px;
  }
  .vipOne {
    display: flex;
    align-items: center;
    .live {
      display: flex;
      align-content: center;
      justify-content: center;
      .liveLeft {
        background-image: url("@/assets/personal/liveLeft.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 10px;
        height: 18px;
        margin-right: -3px;
      }
      .liveConter {
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        background: linear-gradient(
          232deg,
          #7ce7ff -22.06%,
          #0a5dac 14.65%,
          #233564 97.02%
        );
        box-shadow: 0px 0px 4px 0px #5cabfa inset;
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px; /* 12px */
      }
      .liveRight {
        background-image: url("@/assets/personal/liveRight.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 10px;
        height: 18px;
        margin-left: -3px;
      }
    }
    .exclusive {
      margin-left: 5px;
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: AliHYAiHei;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
  .jind {
    width: 220px;
    height: 3px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #15212d;
    margin-top: 12px;
    .tiao {
      width: 0%;
      height: 3px;
      flex-shrink: 0;
      border-radius: 2px;
      transition: all 0.5s ease;
      background: linear-gradient(90deg, #fff 0%, #fff 83.21%, #fff 92.05%);
      position: relative;
      z-index: 1;
      .tou {
        width: 7px;
        height: 7px;
        flex-shrink: 0;
        filter: blur(2px);
        -webkit-filter: blur(2px);
        background-image: url("@/assets/personal/tou.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 0;
        top: -2px;
        z-index: 2;
      }
    }
  }
  .jindNum {
    margin-top: 5px;
    .jdsj {
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: DIN;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 12px */
    }
    .hui {
      overflow: hidden;
      color: #b3b9d2;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 10px */
      margin-left: 4px;
      span {
        font-family: "SF Pro Display";
        margin: 0 2px;
      }
    }
  }
  .vipLabel {
    display: flex;
    gap: 9.67px;
    margin-top: 15px;
    .labelDemo {
      display: flex;
      align-items: center;

      padding: 2px 7px;
      gap: 2px;
      border-radius: 22px;
      background: rgba(65, 112, 217, 0.15);
      overflow: hidden;
      color: #b3b9d2;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 15px */
      .dgou {
        width: 13px;
        height: 13px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.hyjh{
  margin: 10px auto 0 auto;
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  .bigImg{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
  .hyjhGif{
    width: 112px;
    height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      left: 0 ;
      right:0;
      bottom: 19px;
      margin: auto;
    img{
      width: 100%;
      height: 100%;
    }
  }
  
}
.gift {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  border-radius: 6px;
  background: linear-gradient(90deg, #2c3f59 0%, #283643 100%);
  width: 92%;
  margin: 10px auto 0 auto;
  height: 80px;
  .giftLeft {
    display: flex;
    align-items: center;
    .giftImg {
      width: 92px;
      height: 75px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .giftContent {
      .giftTitle {
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 20px */
        text-align: left;
      }
      .giftTips {
        margin-top: 5px;
        text-align: left;
        overflow: hidden;
        color: #b3b9d2;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 10px */
      }
    }
  }
  .giftRight {
    .btnOut {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .goReceive {
      height: 28px;
      padding: 8px 15px;
      border-radius: 22px;
      background: #4774da;
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      border: 0;
    }
    .wait {
      overflow: hidden;
      color: #b3b9d2;
      text-overflow: ellipsis;
      font-family: "PingFang SC";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 10px */
      margin-top: 4px;
      width: 100%;
      span {
        margin-left: 2px;
        font-family: DIN;
        font-weight: 700;
      }
    }
  }
}
.functions {
  width: 92%;
  margin: 10px auto 0 auto;
  border-radius: 8px;
  // background: #283643;
  background: rgba(155, 155, 186, 0.04);

  padding: 0 0 15px;
  border-radius: 8px 18px 8px 8px;
  .jcfwTop{
    width: 100%;
    height: 37px;
    background: url('@/assets/personal/jcfwTop.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .lqflBtn{
      width: 118px;
      height: 33px;
      
    }
  }
  .funTitle {
    width: 91.3%;
    margin: 0 auto;
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-align: left;
  }
  .listOut {
    width: 92%;
    margin: 20px auto 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 52px);
    justify-content: space-between;
    grid-gap: 30px;
    .list {
      width: 52px;
      text-align: center;
      .listIcon {
        margin: 0 auto;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .listName {
        margin-top: 5px;
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
    }
  }
}

.cloLogBtn {
  width: 92%;
  margin: 15px auto 0 auto;
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  // background: #283643;
  background: rgba(155, 155, 186, 0.04);

  color: #fff;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  border: 0;
}
.cloLogPop {
  background: transparent;
  width: 100%;
  .outLoginTipsPop {
    width: 66.666666%;
    display: inline-flex;
    padding: 20px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #283643;
    .tipsPopTit {
      height: 36px;
      align-self: stretch;
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
    .tczhhfw {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
    .btnLine {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .btn {
        width: 90px;
        height: 40px;
        border: 0;
        color: #000;

        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
        background: #fff;
        border-radius: 4px;
        color: #000;
        border-radius: 8px;
      }
      .qued{
        background: #157BE2;
        color: #FFF;
      }
    }
  }
}
.myTime{

}
.copyright {
  text-align: center;
  width: 89.3333%;
  margin: 15px auto 0 auto;
  color: rgba(255, 255, 255, 0.45);
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 12px */
}

.reload {
  &.rotate {
    animation: spin 1s linear infinite;
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
