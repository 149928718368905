<template>
  <div class='touzhupopup' :style="$store.getters['sport/$theme']">
    <div class="head flex">
      <div class="success flex" v-if="status==4">
        <div class='flex'>
          <span>{{ orderStatus[status] }}</span>
          <span class="hr"></span>
        </div>
        <van-icon name="checked"  size="20"/>
      </div>
      <div class="loading flex" v-else>
        <div class='flex'>
          <span>{{ orderStatus[status] }}</span>
          <span class="hr"></span>
        </div>
        <van-icon name="replay" />
      </div>
      <div class="close" @click="$emit('close')">
        <van-icon name="cross" size="20"/>
      </div>
    </div>
    
    <div class="money">
      <div class="benjin">
        <span class="name">本金</span>
        <span class="flex">
          <img src="@/assets/sport/guoqi.png" alt="">
           <!-- {{allBj}} -->
           <span v-html="numToStr(allBj, 'fs')"></span>
        </span>
      </div>
      <div class="keying">
        <span class="name">可赢</span>
        <span class="flex" :style="{color: 3 ? color.up : color.down}">
          <img src="@/assets/sport/guoqi.png" alt="">
          <!-- <b>1</b>.95 -->
           <!-- {{ allky.toFixed(2) }} -->
           <span v-html="numToStr(allky, 'fs')"></span>
        </span>
      </div>
    </div>
    <div v-if="cgList.length" class="cgarr">
      <div v-for="(item,i) in cgList" :key="i">
        <span>{{ item.title }}</span>
        <span>
          本金
          <img src="@/assets/sport/guoqi.png" alt="">
          <!-- {{ item.tz }} -->
          <span v-html="numToStr(item.tz*item.in, 'fs')"></span>
        </span>
        <!-- <span>{{ item.title }}</span> -->
      </div>
    </div>

    <div class="ul">
  
      <div class="li" v-for="(item, i) in list" :key='i'>
        <div class="xinxi">
          <div class="info">
            <span class="one">
              <span v-if="item.ts.length && item?.na==item.ts[0]?.na || item?.na==item.ts[1]?.na">{{ item?.na }}</span>
              <span v-else> {{ item?.op.nm }}</span>
            </span>
            <p class="two">
              <span v-if="getType(item?.op.ty)">
                {{ getType(item?.op.ty) }}
              </span> 
              <span>{{ item.nm }}</span>
              <span >
                {{ item.re }}
              </span>
              <span>[{{ oddsType[item?.op.odt] }}]</span>
            </p>
            <p class="three" v-if='item.ts.length'>{{ item.ts[0]?.na }} vs {{ item.ts[1]?.na }} </p>
            <p class="three" v-if='item.lg?.na'>{{ item.lg?.na }}</p>
            <p class="three">{{ getTime(item.bt) }}</p>
          </div>
          <div class="price">
            <div class="od">
              <span>@ {{ item.op.od }}</span>
              <p v-if='item.res?.rj'>{{ rejectType[item.res.rj] }}</p>
            </div>
            <span v-if="status && !cgList.length">
              <span class="loading" v-if="item.res?.st==1">
                <van-icon name="replay" />
              </span>
              <van-icon v-else-if="item.res?.st==4" color="#36bb08" name="checked"  size="16"/>
              <span v-else>
                {{ orderStatus[item.res?.st] }}
              </span>
            </span>
          </div>
        </div>
        <div class="bottom" v-if="!cgList.length">
          <div class="keying">
            <span class="name">可赢</span>
            <span class="flex" :style="{color: 3 ? color.up : color.down}">
              <img src="@/assets/sport/guoqi.png" alt="">
              <!-- <b>1</b>.95 -->
              <!-- {{(item.tz * (item.op.od-1)).toFixed(2)}} -->
              <span v-html="numToStr(item.tz * (item.op.od-1), 'fs')"></span>
            </span>
          </div>
          <div class="benjin">
            <span class="name">本金</span>
            <span class="flex" style="color: #36bb08;">
              <img src="@/assets/sport/guoqi.png" alt="">
              <!-- <b>1</b>.95 -->
               <!-- {{ item.tz }} -->
               <span v-html="numToStr(item.tz, 'fs')"></span>
            </span>
          </div>
          
        </div>
      </div>
  
    </div>
    <div class="footer">
      <div class="sure flex" @click="sure">确定</div>
      <div class="baoliu flex" @click="save">保留投注选项</div>  
    </div>
    
  </div>
</template>

<script>
import {getStakeOrderStatus} from '@/request/sportApi.js'
import mixin from '../utils/mixin'
export default {
  name: 'touzhu-popup',
  mixins: [mixin],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    orderIdList: {
      type: Array,
      default: () => []
    },
    cgList: {
      type: Array,
      default: () => []
    },
    typeList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      status: 0,
      oddsType: {
        "1": "欧洲盘",
        "2": "香港盘",
        "3": "马来盘",
        "4": "印尼盘",
        "5": "美式盘",
      },
      orderStatus: {
        "0": "未确认",
        "1": "确认中",
        "2": "已拒单",
        "3": "已取消",
        "4": "已接单",
        "5": "已结算",
      },
      rejectType: {
        "1": "系统异常",
        "2": "参数错误拒单",
        "3": "风控限制拒单",
        "4": "玩法暂停拒单",
        "5": "操盘手拒单",
        "6": "支付失败",
        "7": "结算时订单未确认拒单",
      }
    }
  },
  computed: {
    color() {
			return this.$store.state.sport.sjcolor
		},
    allBj() {
      if (this.cgList.length) {
        return this.cgList.reduce((a,b) => a + b.tz*b.in, 0) || 0
      }
      return this.list.reduce((a,b) => a + b.tz, 0) || 0
    },
    allky() {
      if (this.cgList.length) {
        return this.cgList.reduce((a,b) => a + (b.sodd-b.in)*b.tz, 0) || 0
      }
      return this.list.reduce((a,b) => a + b.tz*(b.op.od-1), 0) || 0
    }
  },
  created() {
    // setTimeout(() => {
    //   this.status = true
    // }, 1000)
    // console.log(this.orderIdList, this.list);
    this.getOrderStatus()
    this.timerr = setInterval(this.getOrderStatus, 1000)


    console.log(this.cgList);
    
  },
  destroyed() {
    clearInterval(this.timerr)
    this.timerr = null
  },
  methods: {
    getOrderStatus() {
      getStakeOrderStatus({
        languageType: "CMN",
        orderIds: this.orderIdList.map(item => item.id)
      })
      .then(res => {
        // console.log(res.data.some(item => item.st==1));
        res.data.forEach(item => {
          let obj = this.orderIdList.find(attr => attr.id == item.oid)
          let o = this.list.find(attr => attr.id == obj.ops[0].mid)
          o.res = item
        })
        if (res.data.some(item => item.st==1)) {
          this.status = 1
        } else {
          this.status = 4
          clearInterval(this.timerr)
          this.timerr = null
        }
      })
    },
    sure() {
      this.$emit('close')
    },
    save() {
      // this.$emit('close')
      this.$emit('baoliu')
    },
    // 获取类型
    getType(n) {
      let res = this.typeList.find(item => item.ty == n)
      if (res) {
        return res.des
      } else {
				return ''
			}
    },

    // 时间转换
    getTime(time) {
      if (!time) return ''
      let date = new Date(time)
      let Y = date.getFullYear() + '-'
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
      return Y + M + D + h + m + s
    },
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
div, ul {
	&::-webkit-scrollbar {
		display: none;
	}
}
.flex {
	display: flex;
	align-items: center;
}

.flex-c {
	display: flex;
	flex-direction: column;
}

span,
div {
	box-sizing: border-box;
}
.touzhupopup {
  background: @bg-color;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  color: @index-font-color;
  position: relative;
  z-index: 9998;

  .loading {
      color: orange;
      .flex {
        .hr {
          background: orange;
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      i {
        animation: rotate 2s linear infinite;
      }
    }
  .head {
    // border-bottom: 1px solid @time-list-border;
    padding: 12px 12px 0;
    background: @calc-border;
    justify-content: space-between;
    .success, .loading {
      color: #36bb08;
      font-size: 18px;
      .flex{
        flex-direction: column;
        align-items: start;
        .hr {
          width: 20px;
          height: 4px;
          margin-top: 6px;
          background: #36bb08;
        }
      }
      i {
        margin-left: 12px;
      }
    }
    .loading {
      color: orange;
      .flex{
        .hr {
          background: orange;
        }
      }
    }
    
    .close {
      color: @theme-summary;
    }
  }

  .money {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 12px;
    background: @calc-border;
    padding: 12px;
    div {
      display: flex;
      flex-direction: column;
      width: 50%;
      .name {
        color: @theme-summary;
        opacity: .8;
        margin-bottom: 4px;
        text-align: left;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      &.keying {
        span { 
          text-align: right;
          justify-content: flex-end;
        }
      }
    }
  }
  .cgarr {
    padding: 4px 12px 12px;
    border-top: 1px solid @right-slider-li-border;
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;  
      color: #bbb;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
  

  .ul {
    flex: 1;
    background: @order-bg;
    overflow: auto;
    .li {
      overflow: hidden;
      background: @193146;;
      margin: 10px;
      border-radius: 8px;
      // margin-bottom: 8px;
      padding: 11px;
      border-radius: 8px;

      .xinxi {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid @right-slider-li-border;
        // padding-bottom: 10px;
      
        .info {
          display: flex;
          flex-direction: column;
          align-items: start;
          .one {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 4px;
            color: #fff;
          }
          .active {
            background: linear-gradient(90deg,#f9c732,#ff4c5b);
            color: #fff;
            padding: 0 4px;
            border-radius: 2px;
          }
          .two {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 4px;
            color: #fff;
            span {
              margin-right: 4px;
            }
          }
          .three {
            margin-bottom: 4px;
            color: @B3B9D2;
          }
        }
        .price {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .od {
            color: #fff;
            font-size: 16px;
          }
          i {
            margin-bottom: 6px;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 0;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name {
            color: @theme-summary;
            opacity: .8;
          }
          img {
            width: 16px;
            height: 16px;
            margin: 0 4px;
          }
          &.keying {
            span { 
              text-align: right;
              justify-content: flex-end;
            }
          }
        }
      }
        
   
    }
  }

  .footer {
    background:@193146;;
    .flex {
      height: 38px;
      justify-content: center;
    }
  
    .sure {
      background: @theme-text;
      color: #fff;
      margin: 10px;
      border-radius: 8px;
    }
    .baoliu {
      margin: 10px;
      color: @index-font-color;
      // background: @time-list-border;
    }

    
  }
  
}
</style>